import { useCallback, useEffect, useMemo, useState } from "react";

import { type CloudFlowNodeType, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Box, FormControl, Link, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import { cloudflowTexts } from "../../../../assets/texts";
import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useGetGcpProjects } from "../../hooks";
import { type GCPPermissionConfigValues } from "../ConfigurationPanel/hooks";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import { mapCloudConnectData, useMergedProjectIds } from "../utils/gcpUtils";
import { useFieldValidationProps } from "./useFieldValidationProps";

const permissionsProjectIdKey = "permissionsProjectId";

interface GCPPermissionFormProps {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<GCPPermissionConfigValues>;
  resetPermissions: () => void;
}

const GCPPermissionForm = ({
  inputModel,
  formikProps: { values, setFormikState, getFieldProps, ...rest },
  resetPermissions,
}: GCPPermissionFormProps) => {
  const { nodeConfig, setActiveTab } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { customer } = useCustomerContext();

  const [rawCloudConnectData] = useCloudConnectData(customer.id);

  const cloudConnectData = useMemo(() => mapCloudConnectData(rawCloudConnectData), [rawCloudConnectData]);

  const [organization, setOrganization] = useState<string>(values.organization || "");
  const [serviceAccount, setServiceAccount] = useState<string>(values.serviceAccount || "");
  const paramsProjectIdKey = useMemo(
    () =>
      Object.keys(inputModel.members ?? {}).find(
        (field) => !["organization", "serviceAccount", permissionsProjectIdKey].includes(field)
      ),
    [inputModel]
  );

  const paramsProjectId = paramsProjectIdKey ? nodeConfig?.parameters?.configurationValues?.[paramsProjectIdKey] : null;

  const [projectId, setProjectId] = useState<string | null>(
    paramsProjectIdKey ? null : values[permissionsProjectIdKey] || null
  );

  useEffect(() => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...(organization && { organization }),
        ...(serviceAccount && { serviceAccount }),
        [permissionsProjectIdKey]: projectId,
      },
    }));
  }, [organization, projectId, serviceAccount, setFormikState]);

  const handleServiceAccountChange = useCallback(
    (selectedProjectId?: string | null, selectedOrgName?: string) => {
      if (selectedProjectId) {
        const foundForProject = cloudConnectData.find((data) => data.project?.id === selectedProjectId);
        if (foundForProject?.project?.serviceAccount) {
          setServiceAccount(foundForProject.project.serviceAccount);
          return;
        }
      }

      if (selectedOrgName) {
        const foundForOrg = cloudConnectData.find((data) =>
          data.organizations.some((org) => org.name === selectedOrgName)
        );
        const matchedOrg = foundForOrg?.organizations.find((org) => org.name === selectedOrgName);
        if (matchedOrg?.serviceAccount) {
          setServiceAccount(matchedOrg.serviceAccount);
        }
      }
    },
    [cloudConnectData]
  );

  const handleOrganizationChange = useCallback(
    (orgName: string) => {
      setOrganization(orgName);
      handleServiceAccountChange(projectId, orgName);
    },
    [handleServiceAccountChange, projectId]
  );

  const handleProjectChange = useCallback(
    (newProjectId: string | null) => {
      setProjectId(newProjectId);
      handleServiceAccountChange(newProjectId, organization);
    },
    [handleServiceAccountChange, organization]
  );

  useEffect(() => {
    if (!organization && cloudConnectData.length > 0) {
      const firstOrg = cloudConnectData[0].organizations?.[0];
      if (firstOrg?.name) {
        handleOrganizationChange(firstOrg.name);
      }
    }
  }, [organization, cloudConnectData, handleOrganizationChange]);

  const allOrganizations = useMemo(() => cloudConnectData.flatMap((data) => data.organizations), [cloudConnectData]);

  const { data: projects, isLoading: gcpProjectsLoading } = useGetGcpProjects(customer.id, organization);

  const mergedProjectIds = useMergedProjectIds(cloudConnectData, projects);

  const projectIdFieldProps = useMemo(() => getFieldProps(permissionsProjectIdKey), [getFieldProps]);
  const { showError, errorMessage } = useFieldValidationProps(
    { values, setFormikState, getFieldProps, ...rest },
    projectIdFieldProps
  );

  return (
    <Stack sx={{ gap: 2 }}>
      <Box>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }} color="text.secondary">
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS_DESCRIPTION}
        </Typography>
      </Box>

      <Stack gap={1}>
        {organization ? (
          <Stack direction="row" gap={1} sx={{ mt: 1 }}>
            <Typography variant="body2">{cloudflowTexts.PERMISSIONS.ORGANIZATION}:</Typography>
            <Typography variant="subtitle2">
              {allOrganizations.find((org) => org.name === organization)?.displayName || organization}
            </Typography>
          </Stack>
        ) : (
          <FormControl fullWidth>
            <TextField
              id="organization"
              name="organization"
              variant="outlined"
              select
              label="Organization"
              value={organization}
              onChange={(e) => {
                handleOrganizationChange(e.target.value);
              }}
              required
            >
              {allOrganizations.map((org) => (
                <MenuItem key={org.name} value={org.name}>
                  {org.displayName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}

        {paramsProjectIdKey ? (
          <Stack direction="row" gap={1}>
            <Typography variant="body2">{cloudflowTexts.PERMISSIONS.PROJECT_ID}:</Typography>
            {paramsProjectId ? (
              <Typography variant="subtitle2">{paramsProjectId as string}</Typography>
            ) : (
              <Typography variant="subtitle2">{cloudflowTexts.PERMISSIONS.NO_PROJECT_ID}</Typography>
            )}
          </Stack>
        ) : (
          <FormControl fullWidth>
            <VirtualizedAutocomplete
              id="project"
              key={mergedProjectIds.length}
              loading={gcpProjectsLoading}
              options={mergedProjectIds}
              onChange={(_, newValue) => {
                handleProjectChange(newValue as string | null);
                resetPermissions();
              }}
              renderOption={(props, option) => (
                <MenuItem {...props} value={option}>
                  {option}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  label="Project"
                  name="Project"
                  fullWidth
                  error={showError}
                  helperText={errorMessage}
                  variant="outlined"
                  required
                />
              )}
              size="small"
              fullWidth
              value={projectId ?? null}
            />
          </FormControl>
        )}
      </Stack>
      {(paramsProjectIdKey || !organization) && (
        <Typography variant="body2" color="text.secondary">
          {cloudflowTexts.PERMISSIONS.UPDATE_VALUES}{" "}
          <Link
            variant="body2"
            underline="hover"
            onClick={() => {
              setActiveTab("Parameters");
            }}
            style={{ cursor: "pointer" }}
          >
            {cloudflowTexts.PERMISSIONS.PARAMETERS_TAB}
          </Link>
        </Typography>
      )}
    </Stack>
  );
};

export default GCPPermissionForm;
