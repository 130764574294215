import capitalize from "lodash/capitalize";

import { globalText, reportText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type ReportWSnap } from "../../../types";
import { type DataColumn } from "../../../types/FilterTable";

export const reportItemDescriptors: FilterTableItemDescriptor<ReportWSnap>[] = [
  {
    value: "data.name",
    headerText: reportText.TABLE_HEADERS.NAME,
    tooltip: reportText.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterOptions: {
      comparators: ["==", "!=", "contains"],
    },
    filterType: "string",
  },
  {
    value: "data.owner",
    headerText: reportText.TABLE_HEADERS.OWNER,
    tooltip: reportText.TABLE_HEADERS.OWNER,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "string",
  },
  {
    value: "data.type",
    headerText: reportText.TABLE_HEADERS.TYPE,
    tooltip: reportText.TABLE_HEADERS.TYPE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterOptions: {
      toOption: (value) => ({ value, label: capitalize(value) }),
    },
  },
  {
    value: "data.timeModified",
    headerText: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
    filterType: "date",
  },
  {
    value: "data.timeCreated",
    headerText: globalText.TIME_CREATED,
    tooltip: globalText.TIME_CREATED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    hiddenByDefault: true,
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
    filterType: "date",
  },
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "checkbox" },
  },
];

const additionalFilters: DataColumn<ReportWSnap>[] = [{ comparators: ["=="], label: "ID", path: "snapshot.id" }];

const { headerColumns, filters: baseFilters } = generateHeadersAndFilters(reportItemDescriptors);
const filters = [...additionalFilters, ...baseFilters];

export { filters, headerColumns };
