import { useEffect, useState } from "react";

import { type CustomerModel, type UserModel, UserPermissions } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import { isDoitEmployeeOrPartner } from "../../utils/common";
import useQueryString from "../hooks/useQueryString";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { awsMarketplaceTokenStorageKey, awsTokenQueryKey } from "./helpers/consts";
import { createMarketplaceContracts } from "./helpers/createMarketplaceContracts";
import { createNewAwsMarketplaceAccount } from "./helpers/createNewAwsMarketplaceAccount";
import {
  enrichAwsMarketplaceEntitlementsWithContracts,
  getAWSMarketplaceData,
  getAWSMarketplaceTiersData,
  turnOffPresentationModeIfNeeded,
  updateAwsMarketplaceAccount,
} from "./helpers/utils";

export function AwsMarketplaceNewSubscription() {
  const { isDoitEmployee, isDoitPartner, currentUser } = useAuthContext();
  const { userModel, userRoles } = useUserContext();
  const showError = useErrorSnackbar();
  const showSuccess = useSuccessSnackbar();
  const qs = useQueryString();
  const [isHandlingInvoked, setIsHandlingInvoked] = useState(false);
  const api = useApiContext();

  // if got the required query - saving awsToken to localStorage
  useEffect(() => {
    const awsTokenQuery = qs[awsTokenQueryKey];
    if (typeof awsTokenQuery !== "string") {
      return;
    }
    localStorage.setItem(awsMarketplaceTokenStorageKey, awsTokenQuery);
  }, [qs]);

  // if got awsToken on local storage and user is signed - saving awsToken to firestore
  useEffect(() => {
    async function init({
      awsToken,
      customerRef,
      userRef,
    }: {
      awsToken: string;
      userRef: ModelReference<UserModel>;
      customerRef: ModelReference<CustomerModel>;
    }) {
      // 1. create new awsMarketplaceAccount doc on firestore and triggers integration service for further handling
      const awsMpSubscriptionDocId = await createNewAwsMarketplaceAccount({
        awsToken,
        customerId: customerRef.id,
        userRef,
        api,
      });
      const errorMessage = "Failed to initialize aws marketplace subscription. Please try again later.";

      if (!awsMpSubscriptionDocId) {
        showError(errorMessage);
        consoleErrorWithSentry("AWS Marketplace subscription failed to initialize");
        return;
      }

      // 2. get sku from aws marketplace subscription
      const mpAccountData = await getAWSMarketplaceData(awsMpSubscriptionDocId);
      if (!mpAccountData?.tiersSKU) {
        showError(errorMessage);
        consoleErrorWithSentry(errorMessage, {
          message: `No tier SKU found in AWS Marketplace subscription data for given doc id: ${awsMpSubscriptionDocId}`,
        });
        return;
      }

      // 3. Get tier by SKU
      const tiers = await getAWSMarketplaceTiersData(mpAccountData.tiersSKU);
      if (!tiers) {
        showError(errorMessage);
        consoleErrorWithSentry(errorMessage, {
          message: `Tiers not found in for given SKUs: ${JSON.stringify(mpAccountData.tiersSKU)}`,
        });
        return;
      }

      // 4. mark that customer has an aws account and attach the tier
      await updateAwsMarketplaceAccount({ customerRef, tiers });

      // 5. turn off presentation mode if enabled
      await turnOffPresentationModeIfNeeded({ api, customerRef, tiers });

      // 6. create contracts
      const contractsResponse = await createMarketplaceContracts({
        api,
        customerRef,
        tiers,
        mpAccountData,
      });
      if (!contractsResponse) {
        showError(errorMessage);
        consoleErrorWithSentry(errorMessage, {
          message: `Failed to create contracts for given SKUs: ${JSON.stringify(mpAccountData.tiersSKU)}`,
        });
        return;
      }

      // 7. add contracts ids to entitlement
      await enrichAwsMarketplaceEntitlementsWithContracts({ awsMpSubscriptionDocId, customerRef });

      // 8. remove token from local storage only after we successfully saved it to firestore
      localStorage.removeItem(awsMarketplaceTokenStorageKey);

      showSuccess(`Subscribed successfully to following tiers: ${tiers.map((t) => t.displayName).join(", ")}`);
    }

    if (isHandlingInvoked || isDoitEmployee === undefined || isDoitPartner === undefined) {
      return; // waiting until got token
    }
    const awsToken = localStorage.getItem(awsMarketplaceTokenStorageKey);
    if (!awsToken) {
      setIsHandlingInvoked(true);
      return;
    }
    if (isDoitEmployeeOrPartner({ isDoitEmployee, isDoitPartner })) {
      // doit employees and partners don't relate to a specific customer so we can't link an AWS account based on those users account
      localStorage.removeItem(awsMarketplaceTokenStorageKey);
      const errorMessage =
        "Please use a user related to a customer and not a Doit Employee/Partner user for saving the aws marketplace token";
      showError(errorMessage);
      consoleErrorWithSentry(errorMessage);
      return;
    }
    if (!userRoles || !currentUser || !userModel?.customer?.ref.id || !userModel?.ref) {
      return; // waiting until got all required data
    }

    // validating required permission exists
    if (!userRoles?.has(UserPermissions.BillingProfilesAdmin)) {
      const errorMessage = "You don't hold required permission to complete marketplace subscription";
      showError(errorMessage);
      consoleErrorWithSentry(errorMessage);
      return;
    }

    try {
      init({ awsToken, customerRef: userModel.customer.ref, userRef: userModel.ref });
    } catch (e) {
      consoleErrorWithSentry(e);
    }
    setIsHandlingInvoked(true); // limiting to one execution (dependency list changing multiple times)
  }, [
    api,
    currentUser,
    isHandlingInvoked,
    userModel,
    showError,
    userRoles,
    showSuccess,
    isDoitEmployee,
    isDoitPartner,
  ]);

  return null;
}
