import React, { createContext, type FC, type ReactNode, useCallback, useContext, useMemo, useState } from "react";

import {
  type CloudflowDTO,
  type CloudFlowNode,
  type CreateNodeResponseDTO,
  type CreateOrUpdateNodesResponseDTO,
  type DeleteNodesResponseDTO,
  type PartialNodeUpdates,
  type UpdateNodeResponseDTO,
} from "../../types";

interface CloudFlowContextValue {
  cloudFlow: CloudflowDTO;
  cloudFlowNodes: CloudFlowNode[];
  addCloudFlowNode: (payload: PartialNodeUpdates<CreateNodeResponseDTO>) => void;
  deleteCloudFlowNode: (payload: PartialNodeUpdates<DeleteNodesResponseDTO>) => void;
  upsertCloudFlowNodes: (payload: PartialNodeUpdates<CreateOrUpdateNodesResponseDTO>) => void;
  updateCloudFlowNode: (payload: UpdateNodeResponseDTO) => void;
}

const CloudFlowContext = createContext<CloudFlowContextValue | undefined>(undefined);

interface CloudFlowProviderProps {
  flow: CloudflowDTO;
  children: ReactNode;
}

export const CloudFlowProvider: FC<CloudFlowProviderProps> = ({ flow, children }) => {
  const [nodes, setNodes] = useState<CloudFlowNode[]>(flow.nodes);

  const deleteNode = useCallback((payload: PartialNodeUpdates<DeleteNodesResponseDTO>) => {
    setNodes((prevNodes) => {
      const filteredNodes = prevNodes.filter((node) => !payload.deletedNodes?.includes(node.id));

      return filteredNodes.map((node) => {
        const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
        return { ...node, ...updatedNode };
      });
    });
  }, []);

  const addNode = useCallback((payload: PartialNodeUpdates<CreateNodeResponseDTO>) => {
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => {
        const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
        return { ...node, ...updatedNode };
      });

      return payload.addedNode ? [...updatedNodes, payload.addedNode] : updatedNodes;
    });
  }, []);

  const updateNode = useCallback((payload: UpdateNodeResponseDTO) => {
    setNodes((prevNodes) => prevNodes.map((node) => (node.id === payload.updatedNode.id ? payload.updatedNode : node)));
  }, []);

  const upsertCloudFlowNodes = useCallback((payload: PartialNodeUpdates<CreateOrUpdateNodesResponseDTO>) => {
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.map((node) => {
        const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
        return { ...node, ...updatedNode };
      });

      return payload.addedNodes ? [...updatedNodes, ...payload.addedNodes] : updatedNodes;
    });
  }, []);

  const contextValue = useMemo<CloudFlowContextValue>(
    () => ({
      cloudFlow: flow,
      cloudFlowNodes: nodes,
      addCloudFlowNode: addNode,
      deleteCloudFlowNode: deleteNode,
      updateCloudFlowNode: updateNode,
      upsertCloudFlowNodes,
    }),
    [flow, nodes, addNode, deleteNode, updateNode, upsertCloudFlowNodes]
  );

  return <CloudFlowContext.Provider value={contextValue}>{children}</CloudFlowContext.Provider>;
};

export const useCloudFlowContext = (): CloudFlowContextValue => {
  const context = useContext(CloudFlowContext);
  if (!context) {
    throw new Error("useCloudFlowContext must be used within a CloudFlowProvider");
  }
  return context;
};
