import { createContext, useCallback, useContext, useState } from "react";

import { type DialogType } from "./../../types";

interface ModalContextValue {
  isModalVisible: (modalType: DialogType) => boolean;
  openModal: (modalType: DialogType, focusedNodeId?: string) => void;
  closeModal: (modalType: DialogType) => void;
  closeAllModals: () => void;
  focusedNodeId?: string;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [focusedNodeId, setFocusedNodeId] = useState<string>();
  const [modalVisibilityMap, setModalVisibilityMap] = useState<Record<DialogType, boolean>>({
    action: false,
    blueprint: false,
    trigger: false,
  });

  const isModalVisible = useCallback((modalType: DialogType) => modalVisibilityMap[modalType], [modalVisibilityMap]);

  const openModal = useCallback(
    (modalType: DialogType, focusedNodeId?: string) => {
      setFocusedNodeId(focusedNodeId);
      setModalVisibilityMap((prev) => ({
        ...prev,
        [modalType]: true,
      }));
    },
    [setModalVisibilityMap]
  );

  const closeModal = useCallback(
    (modalType: DialogType) => {
      setFocusedNodeId(undefined);
      setModalVisibilityMap((prev) => ({
        ...prev,
        [modalType]: false,
      }));
    },
    [setModalVisibilityMap]
  );

  // implement closeAllModals function
  const closeAllModals = useCallback(() => {
    setFocusedNodeId(undefined);
    setModalVisibilityMap({
      action: false,
      blueprint: false,
      trigger: false,
    });
  }, [setModalVisibilityMap]);

  const contextValue: ModalContextValue = {
    isModalVisible,
    openModal,
    closeModal,
    closeAllModals,
    focusedNodeId,
  };

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const useModalManager = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalManager must be used within a ModalProvider");
  }
  return context;
};
