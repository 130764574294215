import {
  type Cluster,
  type KubernetesFeature,
  KubernetesFeatureStatus,
  type KubernetesFeatureType,
} from "@doitintl/cmp-models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { CardContent, CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface FeatureStatusProps {
  feature: KubernetesFeature;
  cluster: Cluster;
  loading: boolean;
}

const FeatureStatus = ({ feature, cluster, loading }: FeatureStatusProps) => {
  const clusterFeature = cluster.features[feature.id as KubernetesFeatureType] ?? null;

  if (!clusterFeature) {
    return <Typography variant="body2">Cluster feature not supported: {feature.id}</Typography>;
  }

  const getIcon = () => {
    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (clusterFeature.status === KubernetesFeatureStatus.HEALTHY) {
      return <CheckCircleIcon color="success" />;
    }

    return <ErrorIcon color="error" />;
  };

  const getDescription = () => {
    if (clusterFeature.status === KubernetesFeatureStatus.HEALTHY) {
      return "Successfully activated";
    }

    return `Failed to activate: ${clusterFeature.issues?.join(", ")}`;
  };

  return (
    <>
      <Divider />
      <CardContent key={feature.id}>
        <div style={{ display: "flex", gap: 16 }}>
          <Box>{getIcon()}</Box>
          <Box sx={{ whiteSpace: "normal", wordBreak: "break-word", overflow: "hidden" }}>
            <Typography fontWeight={400} variant="body1">
              {feature.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getDescription()}
            </Typography>
          </Box>
        </div>
      </CardContent>
    </>
  );
};

export default FeatureStatus;
