import { createContext, useContext, useMemo, useState } from "react";

import { useInstantSearch } from "react-instantsearch";

import { type Hit } from "./types/types";

type SearchFiltersContextType = {
  initialHits: Hit[];
};

const SearchFiltersContext = createContext<SearchFiltersContextType>({ initialHits: [] });

export const useSearchFilters = () => useContext(SearchFiltersContext);

export const SearchFiltersProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialHits, setInitialHits] = useState<Hit[]>([]);
  const { results } = useInstantSearch();

  // Update initial hits when results first come in
  if (initialHits.length === 0 && results?.hits?.length > 0) {
    setInitialHits(results.hits as Hit[]);
  }

  const value = useMemo(() => ({ initialHits }), [initialHits]);

  return <SearchFiltersContext.Provider value={value}>{children}</SearchFiltersContext.Provider>;
};
