import { type Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

type StyleProps = {
  type?: "language" | "specialty" | "skills";
  isActive?: boolean;
  selectedBgColor?: string;
  available?: boolean;
  unavailableColor?: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  chipBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
    flex: 1,
    alignItems: "center",
  },
  selectedChip: {
    outline: "2px solid",
    outlineOffset: "1px",
  },
  highlightedText: {
    backgroundColor: "rgba(255, 235, 59, 0.3)",
    borderRadius: "2px",
    padding: "0 2px",
  },
  skillBoxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  skillSection: {
    background: (props: StyleProps) => {
      switch (props.type) {
        case "language":
          return "linear-gradient(45deg, rgba(66, 165, 245, 0.08), rgba(66, 165, 245, 0.15))";
        case "specialty":
          return "linear-gradient(45deg, rgba(76, 175, 80, 0.08), rgba(76, 175, 80, 0.15))";
        case "skills":
          return "linear-gradient(45deg, rgba(156, 39, 176, 0.08), rgba(156, 39, 176, 0.15))";
        default:
          return "linear-gradient(45deg, rgba(156, 39, 176, 0.08), rgba(156, 39, 176, 0.15))";
      }
    },
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: "100%",
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    fontSize: "0.75rem",
    fontWeight: "bold",
    color: (props: StyleProps) => {
      switch (props.type) {
        case "language":
          return "#42A5F5";
        case "specialty":
          return "#4CAF50";
        case "skills":
          return "#9C27B0";
        default:
          return "#9C27B0";
      }
    },
  },
  skillsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
  },
  iconSmall: {
    fontSize: 16,
  },
  skillChipLabel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  skillsFlexBox: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
  },
  slackLogo: {
    width: 16,
    height: 16,
  },
  card: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  cardContent: {
    paddingBottom: 0,
    height: "300px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "300px",
    },
  },
  clickableChip: {
    cursor: "pointer",
  },
  skillAvatar: {
    backgroundColor: "inherit!important",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },
  availableContainer: {},
  contentBox: {
    flexGrow: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      borderRadius: "3px",
    },
  },
  availableContentBox: {},
  expandedFiltersContainer: {
    width: "100%",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  hitsContainer: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(0.5),
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  chip: {
    margin: theme.spacing(0.25),
    flexShrink: 0,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "10%",
    "& .MuiSelect-select": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  refinementListContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    gap: theme.spacing(1),
    width: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  searchField: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px",
      padding: "4px 8px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "&.Mui-focused": {
        borderColor: theme.palette.primary.main,
      },
      "& input": {
        padding: "4px 0",
        fontSize: "12px",
      },
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.action.active,
  },
  refinementChip: {
    fontWeight: "bold",
    flexShrink: 0,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dropdownContainer: {
    position: "relative",
  },
  dropdownButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: "inherit",
    color: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  activeDropdownButton: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  selectedCount: {
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  menuItem: {
    fontWeight: "normal",
    backgroundColor: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedMenuItem: {
    fontWeight: "bold",
    backgroundColor: (props) => (props.isActive ? props.selectedBgColor : theme.palette.action.selected),
  },
  dropdownContent: {
    width: "100%",
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  dropdownChipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  dropdownChip: {
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedDropdownChip: {
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  refinementTextField: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px",
      padding: "4px 8px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "&.Mui-focused": {
        borderColor: theme.palette.primary.main,
      },
      "& input": {
        padding: "4px 0",
        fontSize: "12px",
      },
    },
  },
  selectedOverflowCountBox: {
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  visibleItemChip: {
    fontWeight: "bold",
    flexShrink: 0,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dropdownIconButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: "inherit",
    color: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  refinementRootBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  refinementSecondLevelBox: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  refinementMenuItem: {
    "&.Mui-selected": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "&:not(.Mui-selected)": {
      fontWeight: "normal",
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    transition: theme.transitions.create(["background-color", "color"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  topBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxWrapper: {
    flexShrink: 0,
    width: "40%",
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  filtersButtonWrapper: {
    flexShrink: 0,
  },
  badge: {
    "& .MuiBadge-badge": {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      width: 16,
      height: 16,
      minWidth: 16,
      borderRadius: "50%",
      padding: 0,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  },
  available: {
    "& .MuiBadge-badge": {
      backgroundColor: "#8fea5e",
      color: "#8fea5e",
      "&::after": {
        animation: "$ripple 2s infinite ease-in-out",
      },
    },
  },
  unavailable: {
    "& .MuiBadge-badge": {
      backgroundColor: "#fa1955",
      color: "#fa1955",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
  personCard: {
    width: 298,
    position: "relative",
    cursor: "pointer",
  },
  personCardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    overflow: "hidden",
    position: "relative",
  },
  specialtyChip: {
    [theme.palette.mode === "dark" ? "&" : ""]: {
      "&::before": {
        content: '""',
        position: "absolute",
        top: -4,
        left: -4,
        right: -4,
        bottom: -4,
        borderRadius: "inherit",
      },
    },
  },
  chipBase: {
    margin: "0 4px 4px 0",
  },
  personInfoBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  personHeaderBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  avatarContainer: {
    position: "relative",
  },
  timezoneBox: {
    position: "absolute",
    top: 0,
    left: 0,
    bgcolor: "white",
    borderRadius: "50%",
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  timezoneBoxHighlighted: {
    border: "2px solid #4CAF50",
    zIndex: 2,
  },
  timezoneBoxNormal: {
    border: "1px solid #e0e0e0",
  },
  personInfoContent: {
    marginLeft: theme.spacing(3.5),
    flex: 1,
    maxWidth: "calc(100% - 120px)",
    overflow: "hidden",
  },
  nameText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "1.25rem",
    transition: "color 0.2s ease-in-out",
    display: "block",
    width: "100%",
  },
  slackBox: {
    display: "flex",
    alignItems: "center",
    mt: 1,
  },
  slackIcon: {
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
  },
  skillsBox: {
    mt: 2,
    overflow: "auto",
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },
  languageBox: {
    mb: 1,
  },
  skillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    mt: 1,
  },
  chipLabelBox: {
    display: "flex",
    alignItems: "center",
    gap: 0.5,
  },
  avatar: {
    height: 96,
    width: 96,
  },
  regularText: {
    fontWeight: "regular",
  },
  skillsTitle: {
    fontWeight: "regular",
  },
  nameLink: {
    textDecoration: "none",
    display: "block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  titleText: {
    noWrap: true,
    transition: "color 0.2s ease-in-out",
  },
  expandedCard: {
    height: 150,
  },
  normalCard: {
    height: 280,
  },
  countryEmoji: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(0.5),
    borderRadius: "50%",
    lineHeight: 1,
  },
  clickableCard: {
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "transform 0.2s ease-in-out",
    },
  },
  expandedPersonCard: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px !important",
    height: "80vh !important",
    zIndex: 1300,
    backgroundColor: theme.palette.mode === "dark" ? "#2c2c2c" : "white",
    [theme.palette.mode === "dark" ? "& $nameText" : ""]: {
      color: "rgba(255, 255, 255, 0.95)",
    },
    [theme.palette.mode === "dark" ? "& $skillsTitle, & $regularText" : ""]: {
      color: "rgba(255, 255, 255, 0.7)",
    },
    "& $personHeaderBox": {
      marginBottom: theme.spacing(3),
    },
    "& $skillsBox": {
      marginTop: theme.spacing(3),
      maxHeight: "calc(80vh - 200px)",
      overflowY: "auto",
      paddingRight: theme.spacing(2),
    },
    "& $languageBox": {
      marginBottom: theme.spacing(3),
    },
    "& $personCardContent": {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    "& $nameText": {
      fontSize: "1.75rem",
    },
    "& $titleText": {
      fontSize: "1.25rem",
    },
  },
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1200,
  },
  statusEmoji: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    fontSize: "1.2rem",
  },
  filterMenuItem: {
    minWidth: "200px",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    "&.Mui-disabled": { opacity: 0.6 },
  },
  filterButton: {
    height: "40px",
    whiteSpace: "nowrap",
    display: "flex",
    gap: theme.spacing(1),
  },
  filtersContainer: {
    display: "flex",
    gap: theme.spacing(1),
    flexShrink: 0,
  },
  showMoreMenuItem: {
    justifyContent: "center",
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
  },
  groupLabel: {
    opacity: 1,
    typography: "subtitle2",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  subGroupLabel: {
    opacity: 1,
    typography: "subtitle2",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
  filterMenu: {
    maxHeight: "70vh",
    overflowY: "auto",
  },
  filterDropdownMenuItem: {
    minWidth: "200px",
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    "&:hover": {
      bgcolor: (props) => (props.isActive ? props.selectedBgColor : theme.palette.action.hover),
    },
    bgcolor: (props) => (props.isActive ? props.selectedBgColor : "inherit"),
    color: (props) => (props.available ? "text.primary" : props.unavailableColor),
  },
  filterDropdownGroupHeader: {
    opacity: 1,
    typography: "subtitle2",
    py: 1,
    px: 2,
    color: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderBottom: 1,
    borderColor: "divider",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    fontWeight: "bold",
  },
  filterDropdownSubGroupHeader: {
    opacity: 1,
    typography: "subtitle2",
    py: 0.5,
    px: 3,
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  filterDropdownShowMore: {
    justifyContent: "center",
    color: "primary.main",
    fontSize: "0.875rem",
    py: 0.5,
  },
  filterDropdownButton: {
    height: "40px",
    whiteSpace: "nowrap",
    display: "flex",
    gap: 1,
  },
  filterDropdownMenuPaper: {
    maxHeight: "70vh",
    overflowY: "auto",
    "& .MuiList-root": {
      padding: 0,
    },
    "& ul": {
      padding: 0,
    },
  },
  expertBadge: {
    position: "absolute",
    top: -10,
    right: 16,
    backgroundColor: "white",
    color: "#4CAF50",
    padding: "0 8px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    zIndex: 10,
    pointerEvents: "none",
    border: "2px solid #4CAF50",
    borderRadius: "12px",
    height: "20px",
    display: "flex",
    alignItems: "center",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
    "&:focus-within": {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& input": {
      padding: "2px 0",
      fontSize: "0.875rem",
    },
  },
  clearButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

export default useStyles;
