import { memo } from "react";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

const InternalFilterNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title="Filter"
      status={data.nodeData.status || "error"}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={!!selected}
      icon={<FilterAltIcon color="primary" fontSize="small" />}
      name={`${data.stepNumber}. ${data.nodeData.name || "Filter"}`}
    />
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </>
);

export const FilterNode = memo(InternalFilterNode);
