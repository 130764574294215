import { Aggregator, ComparativeFeature, Feature, type ForecastSettings, Metadata, Sort } from "@doitintl/cmp-models";
import intersection from "lodash/intersection";
import sortBy from "lodash/sortBy";
import NaturalSort from "natsort";

import { type Transforms } from "../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { generateSubtotals, isSubtotalRow, sortRowsBySubtotals, sortRowsBySubtotalsATOZ } from "./subtotals";
import {
  comparativeValues,
  getComparativeHeader,
  isComparative,
  Labels,
  MetricOptions,
  WeekdaySortOrder,
} from "./utilities";

const getTotalDiff = (comparativeType: ComparativeFeature, prevColTotal: number, currColTotal: number) => {
  if (comparativeType === ComparativeFeature.PERCENT) {
    return 100 * ((currColTotal - prevColTotal) / prevColTotal);
  }
  if (comparativeType === ComparativeFeature.VALUES) {
    return currColTotal - prevColTotal;
  }
  return 0;
};

const getComparativeValue = (record: Array<any>, comparative: ComparativeFeature): number => {
  const diffIndex = record[record.length - 2];
  const diffValueData = record[diffIndex];
  if (comparative === ComparativeFeature.VALUES || comparative === ComparativeFeature.PERCENT) {
    const { key } = comparativeValues[comparative];
    const diffValue = diffValueData[key];
    return parseFloat(diffValue);
  }
  return 0;
};

export type ForecastItem = {
  date: string;
  value: number;
  id?: string | null;
  yhatLower?: number | null;
  yhatUpper?: number | null;
};

export type AggregatorObject = {
  comparative: ComparativeFeature;
  diff: number;
  sum: number;
  updateTotalDiff: (prevColTotal: number, currColTotal: number) => void;
  push: (record: Array<any>) => void;
  value: (isTotal?: boolean) => number;
  record: Array<any>;
};

const aggregatorTemplates = {
  sum() {
    return ([attr]) =>
      () => ({
        comparative: ComparativeFeature.NONE,
        diff: 0,
        sum: 0,
        record: null,
        updateTotalDiff(prevColTotal, currColTotal) {
          this.diff = getTotalDiff(this.comparative, prevColTotal, currColTotal);
        },

        push(record) {
          this.record = record;
          if (isComparative(record[record.length - 1])) {
            this.comparative = record[record.length - 1];
            this.diff = getComparativeValue(record, this.comparative);
          } else {
            if (!isNaN(parseFloat(record[attr]))) {
              this.sum += parseFloat(record[attr]);
            }
          }
        },
        value(isTotal?: boolean) {
          if (isComparative(this.comparative)) {
            return this.diff;
          }
          if (isTotal) {
            return this.sum;
          }
          if (this.record && this.record[attr] === null) {
            return null;
          }
          return this.sum;
        },
      });
  },

  sumOverSum() {
    return ([num, denom]) =>
      () => ({
        comparative: ComparativeFeature.NONE,
        diff: 0,
        sumNum: 0,
        sumDenom: 0,
        record: null,
        updateTotalDiff(prevColTotal, currColTotal) {
          this.diff = getTotalDiff(this.comparative, prevColTotal, currColTotal);
        },
        push(record) {
          this.record = record;
          if (isComparative(record[record.length - 1])) {
            this.comparative = record[record.length - 1];
            this.diff = getComparativeValue(record, this.comparative);
          } else {
            const n = parseFloat(record[num]);
            const d = parseFloat(record[denom]);
            if (!isNaN(n)) {
              this.sumNum += n;
            }
            if (!isNaN(d)) {
              this.sumDenom += d;
            }
          }
        },
        value() {
          if (isComparative(this.comparative)) {
            return this.diff;
          }

          // we want to treat very small denominators as if it is actual 0, so return NaN
          if (Math.abs(this.sumDenom) < 1e-7) {
            return NaN;
          }

          return this.sumNum / this.sumDenom;
        },
      });
  },

  fractionOf(wrapped, type = "total") {
    return (...x) =>
      (data, rowKey, colKey) => ({
        comparative: ComparativeFeature.NONE,
        diff: 0,
        record: null,
        selector: { total: [[], []], row: [rowKey, []], col: [[], colKey] }[type],
        inner: wrapped(...Array.from(x || []))(data, rowKey, colKey),
        updateTotalDiff(prevColTotal, currColTotal) {
          this.diff = getTotalDiff(this.comparative, prevColTotal, currColTotal);
        },
        push(record) {
          this.record = record;
          if (isComparative(record[record.length - 1])) {
            this.comparative = record[record.length - 1];
            this.diff = getComparativeValue(record, this.comparative);
          } else {
            this.inner.push(record);
          }
        },
        value() {
          if (isComparative(this.comparative)) {
            return this.diff;
          }
          return this.inner.value() / data.getAggregator(...Array.from(this.selector || [])).inner.value();
        },
      });
  },
};

export const aggregators = ((tpl) => ({
  [Aggregator.TOTAL]: tpl.sum(),
  [Aggregator.TOTAL_OVER_TOTAL]: tpl.sumOverSum(),
  [Aggregator.PERCENT_TOTAL]: tpl.fractionOf(tpl.sum(), "total"),
  [Aggregator.PERCENT_ROW]: tpl.fractionOf(tpl.sum(), "row"),
  [Aggregator.PERCENT_COL]: tpl.fractionOf(tpl.sum(), "col"),
  [Aggregator.COUNT]: tpl.sum(),
}))(aggregatorTemplates);

export const naturalSort = (av, bv) => {
  // nulls first
  if (av === null || av === undefined) {
    return -1;
  }

  if (bv === null || bv === undefined) {
    return 1;
  }
  const sorter = NaturalSort();
  return sorter(av, bv);
};

/**
 * defaultSorters creates the iteratees for the default sorting "sortBy" function.
 * For all fields except Weekday, this returns the identity (key will be sorted by value).
 * Weekday will be sorted by the day number defined in weekdaySortOrder.
 *
 * @param fields the report grouping/dimensions (this.rows or this.cols)
 */
const defaultSorters = (fields: Array<any>) =>
  fields.map((f, i) => {
    if (f.id === `${Metadata.DATETIME}:week_day`) {
      return (keys) => WeekdaySortOrder.get(keys[i]);
    }
    return i;
  });

const sortArrayKeys = (order: Order, keys: Array<Array<string>>, getValue) => {
  switch (order) {
    case Sort.ASC:
      keys.sort((a, b) => naturalSort(getValue(a), getValue(b)));
      break;
    case Sort.DESC:
      keys.sort((a, b) => -naturalSort(getValue(a), getValue(b)));
      break;
  }
};

export type DataRecord = {
  id: string;
  type?: string;
  field?: string;
  key?: string;
  position?: string;
  label?: string;
  nullFallback: string;
};

type Order = Omit<Sort, Sort.A_TO_Z>;

export type ColKeySort = { key: Array<string> | null; order: Order };

export type CurrentLastPeriods = {
  lastPeriod: number;
  currentPeriod: number;
} | null;

type GetValue = (rowKeys: Array<string>, colKeys: Array<string>) => number;

interface ReportDataProps {
  data?: Array<any>;
  cols?: Array<any>;
  rows?: Array<any>;
  vals?: Array<number> | null;
  aggregator?: Aggregator;
  rowOrder?: Sort;
  colOrder?: Sort;
  transforms?: any;
  features?: Array<string>;
  forecastSettings?: ForecastSettings;
  forecastRows?: Array<any>;
  numMetrics?: number;
  aggregators?: Array<any>;
  comparative?: string;
  colKeySort?: ColKeySort;
  includeSubtotals?: boolean;
}

class ReportData implements ReportDataProps {
  static defaultProps = {
    data: [],
    cols: [],
    rows: [],
    vals: [],
    aggregator: Aggregator.TOTAL,
    rowOrder: Sort.A_TO_Z,
    colOrder: Sort.A_TO_Z,
    transforms: {},
    features: [],
    forecastRows: [],
    numMetrics: MetricOptions.length,
    comparative: ComparativeFeature.NONE,
    colKeySort: { key: null, order: Sort.ASC },
    aggregators,
    includeSubtotals: false,
  };

  props: any;

  data: Array<Array<any>>;

  rows: Array<DataRecord>;

  cols: Array<DataRecord>;

  transforms: Transforms | null;

  featureApplicable: boolean;

  forecasts: ForecastItem[] | null;

  forecastStartIndex: number;

  forecastSettings?: ForecastSettings;

  rowStartIndex: number;

  aggregator!: any;

  allTotal!: AggregatorObject;

  tree!: { [key: string]: any };

  rowKeys!: Array<Array<string>>;

  colKeys!: Array<Array<string>>;

  colKeysSorted!: Array<Array<string>>;

  rowTotals!: { [key: string]: AggregatorObject };

  colTotals!: { [key: string]: AggregatorObject };

  numRecords!: number;

  diffModes!: Array<string>;

  rowKeysWithSubtotals!: Array<Array<string>>;

  includeSubtotals!: boolean;

  constructor(inputProps = {}) {
    Object.assign(this, inputProps);
    this.props = Object.assign({}, ReportData.defaultProps, inputProps);
    this.data = this.props.data ?? [];
    this.rows = this.props.rows ?? [];
    this.cols = this.props.cols ?? [];
    this.transforms = this.props.transforms;
    this.featureApplicable = true;
    this.forecasts = null;
    this.forecastStartIndex = 0;
    this.forecastSettings = this.props.forecastSettings;
    this.rowStartIndex = 0;
    this.process();
  }

  getRecordRowsKey(record, rowsNum) {
    return record.slice(0, rowsNum).join(String.fromCharCode(0));
  }

  getComparativeRecord(record, diffHeaderIndex, diffValueIndex, comparative) {
    const newRecord = [...record];
    newRecord[diffHeaderIndex] = getComparativeHeader(comparative, record[diffHeaderIndex]);
    newRecord.push.apply(newRecord, [diffValueIndex, comparative]);
    return newRecord;
  }

  computeDiffTotals() {
    const isBoth: boolean = this.props.comparative === ComparativeFeature.BOTH;
    const firstDiff = 2;
    const jump = isBoth ? 3 : 2;
    const stepBack = isBoth ? 4 : 3;
    // in some cases we process the records before the colKeys are ready (sorted), one case is with value limits.
    // Since this is relatively cheap, we can just sort the colKeys here.
    this.colKeys = this.getColKeys();

    for (let i = firstDiff; i < this.colKeys.length; i += jump) {
      const currCol = this.colKeys[i - 1];
      const prevCol = i === firstDiff ? this.colKeys[i - firstDiff] : this.colKeys[i - stepBack];
      if (prevCol && currCol) {
        const prevColTotal = this.getAggregator([], prevCol).value();
        const currColTotal = this.getAggregator([], currCol).value();
        const flatColKey = this.colKeys[i].join(String.fromCharCode(0));
        // handle with percent or values according to record
        this.colTotals[flatColKey].updateTotalDiff(prevColTotal, currColTotal);

        if (isBoth) {
          const flatColKeyNext = this.colKeys[i + 1].join(String.fromCharCode(0));
          this.colTotals[flatColKeyNext].updateTotalDiff(prevColTotal, currColTotal);
        }
      }
    }
  }

  isSameRowRecords(recordA: Array<any>, recordB: Array<any>): boolean {
    const recordRowsKey = this.getRecordRowsKey(recordA, this.rows.length);
    const prevRecordRowKey = this.getRecordRowsKey(recordB, this.rows.length);
    return prevRecordRowKey === recordRowsKey;
  }

  process() {
    this.aggregator = this.props.aggregators[this.props.aggregator](this.props.vals);
    this.allTotal = this.aggregator(this, [], []);
    this.tree = {};
    this.rowKeys = [];
    this.rowKeysWithSubtotals = [];
    this.colKeys = [];
    this.rowTotals = {};
    this.colTotals = {};
    this.numRecords = 0;
    this.diffModes = [];

    // The index of the main metric we are currently working on
    const metricIndex = this.props.vals[0] as number;

    // The index for the trend feature
    const trendIndex = isComparative(this.props.comparative)
      ? metricIndex + this.props.numMetrics * 2 + 1
      : metricIndex + (this.props.numMetrics as number);

    // Filter features array for trend features
    const trendFeatures = intersection(this.props.features, [Feature.TREND_UP, Feature.TREND_DOWN, Feature.TREND_NONE]);

    // Should we filter data according to the trend features currently selected
    const usingTrendFeatures = trendFeatures.length > 0 && trendIndex < this.props.data?.[0]?.length;
    const diffHeaderIndex = this.rows.length + this.cols.length - 1;
    const diffValueIndex = metricIndex + (this.props.numMetrics as number);
    let newRecords = this.props.data;

    if (isComparative(this.props.comparative)) {
      newRecords = [];
      this.diffModes =
        this.props.comparative === ComparativeFeature.BOTH
          ? [ComparativeFeature.PERCENT, ComparativeFeature.VALUES]
          : [this.props.comparative];

      const subtotalRows = this.includeSubtotals
        ? generateSubtotals(this.data, this.rows.length, this.cols.length, this.props.numMetrics, true)
        : [];

      this.props.data = [...this.props.data, ...subtotalRows];
      for (let i = 0; i < this.props.data.length; i++) {
        const record = [...this.props.data[i]];
        if (record[trendIndex - 1] !== "no metric value") {
          // push last value- as comparative boolean
          record.push(ComparativeFeature.NONE);
          newRecords.push(record);
        }

        // add comparative row only for record that has any record before in same row
        if (i > 0 && this.isSameRowRecords(record, this.props.data[i - 1])) {
          this.diffModes.forEach((diffMode) => {
            const newRecord = this.getComparativeRecord(record, diffHeaderIndex, diffValueIndex, diffMode);
            newRecords.push(newRecord);
          });
        }
      }
    } else {
      const subtotalRows = this.includeSubtotals
        ? generateSubtotals(this.data, this.rows.length, this.cols.length, this.props.numMetrics)
        : [];
      newRecords = [...newRecords, ...subtotalRows];
    }

    newRecords.forEach((record) => {
      if (usingTrendFeatures && record[trendIndex] && !trendFeatures.includes(record[trendIndex])) {
        return;
      }
      this.processRecord(record);
    });

    if (isComparative(this.props.comparative)) {
      this.computeDiffTotals();
    }

    this.getForecastData();
  }

  async update(
    aggregator: string,
    vals: null | Array<number>,
    features: Array<any>,
    comparative = ComparativeFeature.NONE
  ) {
    this.props = Object.assign(this.props, { aggregator, vals, features, comparative });
    this.process();
  }

  setSort(rowOrder: Sort, colOrder: Sort, colKeySort?: ColKeySort | null) {
    this.props = Object.assign(this.props, { rowOrder, colOrder, colKeySort });
  }

  sortRowKeysWithSubtotals() {
    if (this.props.rowOrder === Sort.A_TO_Z) {
      this.rowKeysWithSubtotals = sortRowsBySubtotalsATOZ(this.rowKeysWithSubtotals, this.rows.length);
    } else {
      const getRowTotal = (rowKeys) => this.getAggregator(rowKeys, []).value(true);
      this.rowKeysWithSubtotals = sortRowsBySubtotals(
        this.rowKeysWithSubtotals,
        this.rows.length,
        this.props.rowOrder,
        getRowTotal
      );
    }
  }

  sortRowKeysWithoutSubtotals(getValue: GetValue) {
    if (this.props.rowOrder === Sort.A_TO_Z) {
      this.rowKeys = sortBy(this.rowKeys, defaultSorters(this.rows));
    } else {
      sortArrayKeys(this.props.rowOrder, this.rowKeys, (a) => getValue(a, []));
    }
  }

  sortRowKeys(getValue: GetValue, includeSubtotals: boolean) {
    if (this.props.colKeySort?.key && this.props.colKeySort?.order) {
      const getRowValue = (rowKeys) => getValue(rowKeys, this.props.colKeySort.key);
      if (includeSubtotals) {
        this.rowKeysWithSubtotals = sortRowsBySubtotals(
          this.rowKeysWithSubtotals,
          this.rows.length,
          this.props.colKeySort.order,
          getRowValue
        );
      } else {
        sortArrayKeys(this.props.colKeySort.order, this.rowKeys, getRowValue);
      }
    } else if (includeSubtotals) {
      this.sortRowKeysWithSubtotals();
    } else {
      this.sortRowKeysWithoutSubtotals(getValue);
    }
  }

  sortColKeys(getValue: GetValue) {
    if (isComparative(this.props.comparative) || this.props.colOrder === Sort.A_TO_Z) {
      this.colKeys = sortBy(this.colKeys, defaultSorters(this.cols));
    } else {
      sortArrayKeys(this.props.colOrder, this.colKeys, (a) => getValue([], a));
    }
  }

  sortKeys(sortRowsOrCols: "rows" | "cols", includeSubtotals = false) {
    const getValue = (rowKeys, colKeys) => this.getAggregator(rowKeys, colKeys).value();
    if (sortRowsOrCols === "rows") {
      this.sortRowKeys(getValue, includeSubtotals);
    } else {
      this.sortColKeys(getValue);
    }
  }

  getNumRecords(): number {
    return this.numRecords;
  }

  getCols() {
    return this.cols;
  }

  getRows() {
    return this.rows;
  }

  getRowHeaders(): Array<string | undefined> {
    return this.rows.map((row) => row.label);
  }

  getColHeaders(): Array<string | undefined> {
    return this.cols.map((col) => col.label);
  }

  getColKeys() {
    this.sortKeys("cols");
    return this.colKeys.slice();
  }

  getRowKeys(includeSubtotals = false) {
    this.sortKeys("rows", includeSubtotals);
    return includeSubtotals ? this.rowKeysWithSubtotals.slice() : this.rowKeys.slice();
  }

  getCurrentLastPeriods(): CurrentLastPeriods {
    const actualTotals = Object.entries(this.colTotals)
      .filter(([_, value]) => value.record !== null)
      .sort(([a], [b]) => b.localeCompare(a));

    if (actualTotals.length >= 2) {
      const [, lastPeriod] = actualTotals[1];
      const [, currentPeriod] = actualTotals[0];

      return { lastPeriod: lastPeriod.sum, currentPeriod: currentPeriod.sum };
    }
    return null;
  }

  getRowTotals(includeSubtotals = false) {
    if (includeSubtotals) {
      return this.rowTotals;
    }

    const filteredRowTotals = { ...this.rowTotals };

    for (const [key, value] of Object.entries(this.rowTotals)) {
      if (isSubtotalRow(value.record)) {
        delete filteredRowTotals[key];
      }
    }
    return filteredRowTotals;
  }

  keyFromValue(value: any, id: string, nullFallback?: string): string {
    return this.transforms?.[id]?.(value) ?? value?.toString() ?? nullFallback ?? "[N/A]";
  }

  getColsKeysFromRecord(record: Array<any>): Array<string> {
    const colKeys: Array<string> = [];

    const [label] = record;

    this.cols.forEach((col, j) => {
      const i = this.rows.length + j;
      if (label === Labels.FORECAST) {
        colKeys.push(record[j + 1]); // Forecast record structure ["Forecast", DATE, ""]
      } else {
        colKeys.push(this.keyFromValue(record[i], col.id, col.nullFallback));
      }
    });

    return colKeys;
  }

  getRowKeysFromRecord(record: Array<any>): Array<string> {
    const rowKeys: Array<string> = [];

    const [label] = record;

    this.rows.forEach((row, i) => {
      if (label !== Labels.FORECAST) {
        rowKeys.push(this.keyFromValue(record[i], row.id, row.nullFallback));
      }
    });

    return rowKeys;
  }

  // add the record to the rowKeys, and add the record to the total
  updateRowKeys(record: Array<any>, rowKeys: Array<string>) {
    if (rowKeys.length > 0) {
      const flatRowKeys = rowKeys.join(String.fromCharCode(0));
      const isComparRecord = isComparative(record[record.length - 1]);

      if (!this.rowTotals[flatRowKeys]) {
        if (!isSubtotalRow(record)) {
          this.rowKeys.push(rowKeys);
        }
        this.rowKeysWithSubtotals.push(rowKeys);
        this.rowTotals[flatRowKeys] = this.aggregator(this, rowKeys, []);
      }
      // sum only regular records in rawTotal
      !isComparRecord && this.rowTotals[flatRowKeys].push(record);
    }
  }

  updateColKeys(record: Array<any>, colKeys: Array<string>) {
    if (colKeys.length > 0) {
      const flatColKeys = colKeys.join(String.fromCharCode(0));
      const [label] = record;

      if (!this.colTotals[flatColKeys]) {
        this.colKeys.push(colKeys);
        this.colTotals[flatColKeys] = this.aggregator(this, [], colKeys);
      }

      if (label !== Labels.FORECAST) {
        this.colTotals[flatColKeys].push(record);
      }
    }
  }

  updateTree(record: Array<any>, rowKeys: Array<string>, colKeys: Array<string>) {
    if (colKeys.length > 0 && rowKeys.length > 0) {
      const flatRowKeys = rowKeys.join(String.fromCharCode(0));
      const flatColKeys = colKeys.join(String.fromCharCode(0));

      if (!this.tree[flatRowKeys]) {
        this.tree[flatRowKeys] = {};
      }
      if (!this.tree[flatRowKeys][flatColKeys]) {
        this.tree[flatRowKeys][flatColKeys] = this.aggregator(this, rowKeys, colKeys);
      }
      this.tree[flatRowKeys][flatColKeys].push(record);
    }
  }

  processRecord(record: Array<any>) {
    const rowKeys = this.getRowKeysFromRecord(record);
    const colKeys = this.getColsKeysFromRecord(record);
    const subtotalRow = isSubtotalRow(record);

    if (record[0] !== Labels.FORECAST) {
      const isComparRecord = isComparative(record[record.length - 1]);
      if (!isComparRecord) {
        if (!subtotalRow) {
          this.allTotal.push(record);
        }
        this.numRecords++;
      }
    }

    this.updateRowKeys(record, rowKeys);
    if (!subtotalRow) {
      this.updateColKeys(record, colKeys);
    }
    this.updateTree(record, rowKeys, colKeys);
  }

  getAggregator(rowKeys: Array<string>, colKeys: Array<string>) {
    let agg: AggregatorObject;
    const flatRowKey = rowKeys.join(String.fromCharCode(0));
    const flatColKey = colKeys.join(String.fromCharCode(0));
    if (rowKeys.length === 0 && colKeys.length === 0) {
      agg = this.allTotal;
    } else if (rowKeys.length === 0) {
      agg = this.colTotals?.[flatColKey];
    } else if (colKeys.length === 0) {
      agg = this.rowTotals?.[flatRowKey];
    } else {
      agg = this.tree?.[flatRowKey]?.[flatColKey];
    }
    return (
      agg || {
        value() {
          return null;
        },
      }
    );
  }

  getForecastData() {
    if (!this.props.forecastRows) {
      return;
    }
    // Find where non forecast data starts since the forecast data in many cases is too far back
    const nonForecastColKeys = this.getColKeys().map((col) => col.join("-"));
    const firstActualDataRowPeriod = nonForecastColKeys[0];
    const forecasts: ForecastItem[] = [];
    const transformedIdsMap: Record<string, string> = {};

    this.props.forecastRows.forEach((fr, index) => {
      const dateArr: Array<string> = [];
      for (let i = 1; i < this.cols.length + 1; i++) {
        dateArr.push(fr[i]);
      }
      const dateString = dateArr.join("-");
      if (dateString < firstActualDataRowPeriod) {
        return;
      }
      const valueIndex = dateArr.length + 1;
      const value = fr[valueIndex] ?? null;

      const forecastItem: ForecastItem = {
        date: dateString,
        value,
      };

      const yhatLower = fr[valueIndex + 2];
      const yhatUpper = fr[valueIndex + 3];

      if (yhatLower !== undefined) {
        forecastItem.yhatLower = yhatLower;
      }

      if (yhatUpper !== undefined) {
        forecastItem.yhatUpper = yhatUpper;
      }

      if (this.forecastSettings?.mode === "grouping") {
        let transformedId: string;

        const id = fr[valueIndex + 1];

        // in case group by keys weren't provided the data will be aggregated
        if (!this.props.rows.length) {
          transformedId = "Total";
        } else if (transformedIdsMap[id]) {
          transformedId = transformedIdsMap[id];
        } else {
          const idArr: string[] = id.split(";");
          const transformedIdArr: string[] = [];
          this.props.rows.forEach((groupByKey, index) => {
            let idPart: string | null = idArr[index];
            if (idPart === "N/A") {
              idPart = null;
            }
            transformedIdArr.push(this.keyFromValue(idPart, groupByKey.id, groupByKey.nullFallback));
          });

          transformedId = transformedIdArr.join(";");
          transformedIdsMap[id] = transformedId;
        }

        forecastItem.id = transformedId;
      }

      forecasts.push(forecastItem);

      if (dateString === firstActualDataRowPeriod) {
        this.rowStartIndex = index;
      }
    });
    this.forecastStartIndex = nonForecastColKeys.length;
    this.forecasts = forecasts;
  }

  getForecastStartIndex(): number {
    return this.forecastStartIndex;
  }
}

export default ReportData;
