import { type JSX, useCallback, useEffect, useState } from "react";

import * as runtime from "react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { TimeInterval, type TimeSettingsConfig, TimeSettingsMode } from "@doitintl/cmp-models";
import { compile, run } from "@mdx-js/mdx";
import { Box, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

import useGenerateReport from "../../Components/hooks/useGenerateReport";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type RequestConfig } from "../../Pages/CloudAnalytics/generateReport/types";
import { consoleErrorWithSentry } from "../../utils";
import { AWSFeatureName } from "../Settings/AWS/types";
import { useAccounts, useRelevantTrustedAdvisorCustomerAccounts } from "./api";

export const useMdxContent = (mdxStringifyContent: string) => {
  const [content, setContent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const compileMdx = async () => {
      const code = String(await compile(mdxStringifyContent, { outputFormat: "function-body" }));
      const { default: Content } = await run(code, { ...runtime, Fragment: Box } as any);

      setContent(
        <ErrorBoundary
          fallback={<p>Error showing content, </p>}
          onError={(error) => {
            consoleErrorWithSentry(error);
          }}
        >
          <Content
            components={{
              // Any headings within the text should be formatted the same way
              h1: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h2: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h3: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h4: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h5: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              h6: ({ children }) => (
                <Typography
                  variant="h4"
                  sx={{
                    mb: 2,
                    mt: 3,
                  }}
                >
                  {children}
                </Typography>
              ),
              p: ({ children, style }) => (
                <Typography variant="body1" style={style} sx={{ mb: 2 }}>
                  {children}
                </Typography>
              ),
              a: ({ children, href }) => (
                <Link target="_blank" href={href}>
                  {children}
                </Link>
              ),
              Table: (props) => <Table sx={{ mb: 2 }} {...props} />,
              TableHead: (props) => <TableHead {...props} />,
              TableBody: (props) => <TableBody {...props} />,
              TableRow: (props) => <TableRow {...props} />,
              TableCell: (props) => <TableCell {...props} />,
            }}
          />
        </ErrorBoundary>
      );
    };

    compileMdx().catch((e) => {
      setContent(e.message);
    });
  }, [mdxStringifyContent]);

  return content;
};

export const defaultTimeSettingsConfig: TimeSettingsConfig = {
  amount: 30,
  mode: TimeSettingsMode.Last,
  unit: TimeInterval.DAY,
  includeCurrent: true,
};

export const useInsightsInvestigation = ({ insightName }) => {
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();
  const formattedCurrentTimestamp = new Date().toLocaleString();
  return useCallback(
    async ({ config }) => {
      const params = {
        name: `${insightName} report - ${formattedCurrentTimestamp}`,
        config: {
          ...config,
          fields: config.fields.map((field) => ({ ...field, type: "fixed" })),
          timeSettings: defaultTimeSettingsConfig,
        } satisfies RequestConfig,
        description: "",
      };

      const reportId = await generateReport(params, false);
      return `/customers/${customer.id}/analytics/reports/${reportId}?run-on-open=true`;
    },
    [customer.id, generateReport, insightName, formattedCurrentTimestamp]
  );
};

export const useRelevantAccountsWithoutTrustedAdvisorPermissions = (): string[] => {
  const relevantAccounts = useRelevantTrustedAdvisorCustomerAccounts();
  const [connectAccounts] = useAccounts();

  if (relevantAccounts === undefined || connectAccounts === undefined) {
    return [];
  }

  const connectedAccounts = connectAccounts ?? [];
  const relevantAccountIDs = relevantAccounts ?? [];

  const relevantAccountsWithoutPermissions: string[] = [];

  for (const accountId of relevantAccountIDs) {
    const connectedAccount = connectedAccounts.find((connectedAccount) => connectedAccount.accountId === accountId);

    if (connectedAccount === undefined) {
      // There's a relevant account that's not connected at all
      relevantAccountsWithoutPermissions.push(accountId);

      continue;
    }

    const hasTrustedAdvisorPermissions =
      connectedAccount &&
      (connectedAccount.supportedFeatures ?? []).some(
        (feature) => feature.name === AWSFeatureName.trustedAdvisorInsights && feature.hasRequiredPermissions
      );

    if (!hasTrustedAdvisorPermissions) {
      // The account is connected but doesn't have the required feature/permissions
      relevantAccountsWithoutPermissions.push(accountId);
    }
  }

  return relevantAccountsWithoutPermissions;
};
