import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import TransformIcon from "@mui/icons-material/Transform";
import { Card, Stack } from "@mui/material";

import { cloudflowTexts } from "../../../../../assets/texts";
import { Loader } from "../../../../../Components/Loader";
import { useReferenceableNodes } from "../../Common/hooks/useReferenceableNodes";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { NodeCardHeader } from "./NodeCardHeader";
import { TransformationActionPicker } from "./Transformation/TransformationActionPicker";
import TransformationNodeDataSourceForm from "./Transformation/TransformationNodeDataSourceForm";
import { TransformationNodeForm } from "./Transformation/TransformationNodeForm";

const TransformParametersTab = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.TRANSFORMATION>();
  const [referenceableNodes, loading] = useReferenceableNodes(nodeConfig.id);

  return (
    <Stack
      sx={{
        px: 2,
        justifyContent: "center",
        gap: 2,
        mt: 1,
      }}
    >
      <Card>
        <NodeCardHeader
          avatar={<TransformIcon color="primary" fontSize="small" />}
          subheader={cloudflowTexts.TRANSFORM_DESCRIPTION}
          title={cloudflowTexts.TRANSFORM_TITLE}
        />
      </Card>
      <Loader loading={loading}>
        <TransformationNodeForm
          nodeId={nodeConfig.id}
          nodeParameters={nodeConfig.parameters}
          onNodeConfigChange={updateNode}
          referenceableNodes={referenceableNodes}
        >
          <TransformationNodeDataSourceForm referenceableNodes={referenceableNodes} />
          <TransformationActionPicker />
        </TransformationNodeForm>
      </Loader>
    </Stack>
  );
};

export default TransformParametersTab;
