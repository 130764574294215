import { type FC } from "react";

import { type StringApiServiceModelDescriptor } from "@doitintl/cmp-models";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from "@mui/material";
import { type FieldInputProps } from "formik";

import { useToggle } from "../../../../../utils/useToggle";
import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

export const StringParam: FC<{
  fieldProps: FieldInputProps<string>;
  inputModel: StringApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
  disallowReferencedField?: boolean;
  renderAsNotRequired?: boolean;
}> = ({ fieldProps, inputModel, label, onRemove, disallowReferencedField, renderAsNotRequired }) => {
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined && !renderAsNotRequired);

  const [isVisible, , , toggleVisibility] = useToggle(false);

  const endAdornment = inputModel.sensitive ? (
    <InputAdornment position="end">
      <IconButton aria-label="toggle value visibility" onClick={toggleVisibility} edge="end">
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>
    </InputAdornment>
  ) : undefined;

  const textField = (
    <TextField
      {...commonFieldProps}
      type={!inputModel.sensitive || isVisible ? "text" : "password"}
      fullWidth
      select={inputModel.enum !== undefined}
      multiline={inputModel.multiline}
      rows={4}
      maxRows={10}
      slotProps={{
        input: {
          endAdornment,
        },
      }}
    >
      {inputModel.enum?.map((itemValue, index) => (
        <MenuItem key={itemValue} value={itemValue}>
          <Tooltip
            title={inputModel.enumDescriptions?.[index]}
            disableFocusListener
            enterDelay={1000}
            placement="right"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8],
                    },
                  },
                ],
              },
            }}
          >
            <Box flex={1}>{itemValue}</Box>
          </Tooltip>
        </MenuItem>
      ))}
    </TextField>
  );

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      {disallowReferencedField ? (
        textField
      ) : (
        <ReferencedFieldWrapper commonFieldProps={commonFieldProps} model={inputModel}>
          {textField}
        </ReferencedFieldWrapper>
      )}
    </OptionalParamWrapper>
  );
};
