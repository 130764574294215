import { MenuItem, TextField } from "@mui/material";

import { dciCommitmentTermOptions } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";

type Props = {
  disabled: boolean;
};

export const DciCommitmentMonthsSelector = ({ disabled }: Props) => {
  const { state, setState } = useContractFormContext();

  const onCommitmentMonthsChange = ({ target: { value } }) => {
    if (value === -1) {
      setState((prevState) => ({
        ...prevState,
        commitmentMonths: -1,
      }));

      return;
    }
    setState((prevState) => ({
      ...prevState,
      commitmentMonths: Number(value),
      endDate: state.startDate.plus({ months: Number(value) }),
    }));
  };

  return (
    <TextField
      label="Subscription term"
      onChange={onCommitmentMonthsChange}
      error={state.errors.commitmentMonths}
      disabled={disabled}
      fullWidth
      value={state.commitmentMonths ?? ""}
      variant="outlined"
      select
    >
      {dciCommitmentTermOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option} months
        </MenuItem>
      ))}
      <MenuItem value={-1}>Custom</MenuItem>
    </TextField>
  );
};
