import { createContext, useContext, useMemo, useState } from "react";

type SearchContextType = {
  chips: string[];
  setChips: (chips: string[]) => void;
};

const SearchContext = createContext<SearchContextType>({
  chips: [],
  setChips: () => {},
});

export const useSearch = () => useContext(SearchContext);

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [chips, setChips] = useState<string[]>([]);

  const value = useMemo(() => ({ chips, setChips }), [chips]);
  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
