import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsDataSource, Metadata } from "@doitintl/cmp-models";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useShallow } from "zustand/react/shallow";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { ExpandMore } from "../../../Components/ExpendMore";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { AttributionSelect } from "../../../Components/Selects/CloudAnalytics/AttributionSelect";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { type AttributionWRef } from "../../../types";
import useAttributionCreateFormula from "../attributions/AttributionCreate/useAttributionCreateFormula";
import { AllocationDraggableHolder } from "./AllocationDraggableHolder";
import useAllocationCreateSelector from "./hooks/useAllocationCreateSelector";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { formStoreManager } from "./hooks/useAllocationFormState";

type AllocationDraggableItemProps = {
  attributionNumber?: number;
  grabbing?: boolean;
  initialAttributionID: string;
};

const CONTENT_PADDING_LEFT = "56px";

export const AllocationDraggableItem = ({
  attributionNumber,
  grabbing,
  initialAttributionID,
}: AllocationDraggableItemProps) => {
  const useFormStore = formStoreManager.getStore(initialAttributionID);
  const [selectedAllocation, setSelectedAllocation] = useState<AttributionWRef | null>();
  const { values, setName, addFilterField, setFormula } = useFormStore(useShallow((state) => state));
  const { attributions } = useAttributionsContext();
  const { removeItem, addItem, items } = useAllocationDndStore(useShallow((state) => state));
  const canRemove = useMemo(() => items.main.length > 1, [items.main]);

  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = (e) => {
    e.preventDefault();
    setExpanded((prev) => !prev);
  };
  const { metadata: metadataSnapshots, fetchMetadata, hasDataHub } = useCloudAnalyticsMetadata();

  const displayNumber = attributionNumber !== undefined ? attributionNumber + 1 : undefined;

  const [selectedAllocationType, setSelectedAllocationType] = useState(allocationTxt.CREATE_NEW_ALLOCATION);
  const { dimensions = [], handleDimensionLabelsChange } = useAnalyticsDimensions({
    metadataSnapshots,
    defaultDataSource: hasDataHub ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
  });

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const filteredDimensions = useMemo(() => {
    const filtered = dimensions?.slice();
    filtered?.forEach((d) => {
      if (d.data.type === Metadata.DATETIME) {
        d._visible = false;
      } else if (d.data.type === Metadata.ATTRIBUTION) {
        const index = filtered.indexOf(d);
        if (index !== -1) {
          filtered.splice(index, 1);
        }
      }
    });
    return filtered;
  }, [dimensions]);

  const onRemoveRule = useCallback(() => {
    removeItem(initialAttributionID);
    formStoreManager.removeStore(initialAttributionID);
  }, [initialAttributionID, removeItem]);

  const onDuplicateRule = useCallback(() => {
    const newStoreId = crypto.randomUUID();
    const newStore = formStoreManager.getStore(newStoreId);
    newStore.setState((state) => ({
      ...state,
      values: {
        name: `${values.name} (Copy)`,
        filterFields: values.filterFields.map((field) => ({ ...field })),
        formula: values.formula,
      },
    }));

    addItem(newStoreId);
  }, [addItem, values.filterFields, values.formula, values.name]);

  const handleSelectExistingAllocation = useCallback((value: AttributionWRef | null) => {
    setSelectedAllocation(value);
  }, []);

  const excludeSelectMetadataIds = undefined;
  const { AttributionCreateSelector, filters } = useAllocationCreateSelector({
    initialFilters: values.filterFields ?? [],
    dimensions: filteredDimensions,
    handleDimensionLabelsChange,
    excludeSelectMetadataIds,
  });

  const { AttributionCreateFormula, formulaState } = useAttributionCreateFormula({
    formula: values.formula,
    filters: values.filterFields,
    allocationMode: true,
  });

  useEffect(() => {
    addFilterField(filters);
    setFormula(formulaState.value);
  }, [formulaState, filters, addFilterField, setFormula]);

  const handleAllocationName = useCallback(
    (event) => {
      setName(event.target.value as string);
    },
    [setName]
  );
  const handleToggleSource = useCallback((event, value) => {
    setSelectedAllocationType(value as string);
  }, []);

  useEffect(
    () => () => {
      formStoreManager.removeStore(initialAttributionID);
    },
    [initialAttributionID]
  );

  return (
    <Stack gap={2} sx={{ mb: 2 }}>
      {!grabbing && (
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{`Allocation Rule ${displayNumber}`}</Typography>
      )}
      <AllocationDraggableHolder
        grabbing={grabbing}
        height="auto"
        disabled={!canRemove}
        id={initialAttributionID}
        key={initialAttributionID}
        alignItems="flex-start"
      >
        <Box sx={{ p: 1, pb: 2, ml: -5 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              pl: CONTENT_PADDING_LEFT,
            }}
          >
            <FormControl>
              <RadioGroup row value={selectedAllocationType} onChange={handleToggleSource}>
                <FormControlLabel
                  value={allocationTxt.CREATE_NEW_ALLOCATION}
                  control={<Radio />}
                  label={allocationTxt.CREATE_NEW_ALLOCATION}
                />
                <FormControlLabel
                  value={allocationTxt.USE_EXISTING_ALLOCATION}
                  control={<Radio />}
                  label={allocationTxt.USE_EXISTING_ALLOCATION}
                />
              </RadioGroup>
            </FormControl>

            <Stack direction="row">
              <IconButton onClick={onDuplicateRule} aria-label="copy">
                <ContentCopyIcon color="action" />
              </IconButton>

              {/* <Tooltip title={globalText.REMOVE} placement="bottom"> */}
              <IconButton onClick={onRemoveRule} aria-label="delete" disabled={!canRemove}>
                <DeleteIcon color={!canRemove ? "disabled" : "action"} />
              </IconButton>
              {/* </Tooltip> */}
            </Stack>
          </Stack>
          <Grid
            container
            spacing={1}
            sx={{
              mt: 2,
            }}
          >
            <Grid>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
            {selectedAllocationType === allocationTxt.CREATE_NEW_ALLOCATION ? (
              <TextField
                required
                label={allocationTxt.NAME_YOUR_ALLOCATION}
                sx={({ palette }) => ({
                  marginLeft: 1,
                  width: "100%",
                  maxWidth: 540,
                  backgroundColor: palette.general.backgroundDefault,
                })}
                margin="none"
                fullWidth
                value={values.name}
                onChange={handleAllocationName}
              />
            ) : (
              <AttributionSelect
                attribution={selectedAllocation}
                attributions={attributions.filter((a) => a.data.type !== "managed")}
                sx={{ marginLeft: 1, width: "100%", maxWidth: 540 }}
                onChange={handleSelectExistingAllocation}
              />
            )}
          </Grid>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                pl: CONTENT_PADDING_LEFT,
                mt: 2,
              }}
            >
              {AttributionCreateSelector}
              {AttributionCreateFormula}
            </Box>
          </Collapse>
        </Box>
      </AllocationDraggableHolder>
    </Stack>
  );
};
