import { type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel, type CustomerModelOrganizationModel } from "./Customer";
import { type Metadata } from "./Dashboard";
import { type OrganizationsModel } from "./Organizations";

export enum MetadataCloud {
  GOOGLE_CLOUD = "google-cloud",
  AMAZON_WEB_SERVICES = "amazon-web-services",
  MICROSOFT_AZURE = "microsoft-azure",
  BQLENS = "bqlens",
  DATAHUB = "datahub",
  SNOWFLAKE = "snowflake",
  DATADOG = "datadog",
}

@subCollection("reportOrgMetadata")
export class ReportOrgMetadataModel {
  cloud!: string | string[];

  customer!: Reference<CustomerModel> | null;

  disableRegexpFilter!: boolean;

  field!: string;

  id!: string;

  key!: string;

  label!: string;

  nullFallback!: string | null;

  order!: number;

  organization!: Reference<OrganizationsModel> | Reference<CustomerModelOrganizationModel>;

  plural!: string;

  subType!: Metadata | "";

  timestamp!: Timestamp;

  type!: Metadata | "";

  values!: string[] | null;

  objectType?: string;
}
