import { type JSX, useCallback, useState } from "react";

import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { MPARemoveRetiredDialog } from "./MPARemoveRetiredDialog";
import { useMPARemoveRetiredRequest } from "./useMPARemoveRetiredRequest";

type RemoveRetiredMPADialogFacade = {
  showMPARemoveRetiredDialog: (masterPayerAccount: MasterPayerAccountsModelSnapshot) => void;
  MPARemoveRetiredDialogComponent: () => JSX.Element | null;
};

export const useMPARemoveRetiredDialogFacade = (): RemoveRetiredMPADialogFacade => {
  const removeRetiredMasterPayerAccount = useMPARemoveRetiredRequest();
  const [masterPayerAccount, setMasterPayerAccount] = useState<MasterPayerAccountsModelSnapshot | null>(null);
  const onCancel = () => {
    setMasterPayerAccount(null);
  };

  const MPARemoveRetiredDialogComponent = useCallback(
    () =>
      masterPayerAccount && (
        <MPARemoveRetiredDialog
          onCancel={onCancel}
          onRemoveRetired={() => removeRetiredMasterPayerAccount(masterPayerAccount)}
          masterPayerAccount={masterPayerAccount}
        />
      ),
    [removeRetiredMasterPayerAccount, masterPayerAccount]
  );

  return {
    showMPARemoveRetiredDialog: setMasterPayerAccount,
    MPARemoveRetiredDialogComponent,
  };
};
