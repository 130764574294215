import Box from "@mui/material/Box";
import ListSubheader from "@mui/material/ListSubheader";
import { type Theme, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { UserModel } from "@doitintl/cmp-models";
import type { ModelReference } from "@doitintl/models-firestore";
import type { DateTime } from "luxon";

import { NotificationsListItem } from "./NotificationListItem";
import type { InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";
import type { NotificationTimeSection } from "./NotificationsList";

type NotificationsListSectionProps = {
  section: NotificationTimeSection;
  notifications: InAppNotificationWithRead[];
  onlyShowUnread: boolean;
  handleClickNotification: (n: InAppNotificationWithRead, userRef: ModelReference<UserModel>) => Promise<void>;
  userRef: ModelReference<UserModel>;
  localNow: DateTime;
};

const notificationSectionTitle: Record<NotificationTimeSection, string> = {
  lastThreeDays: "LAST 3 DAYS",
  earlier: "EARLIER",
};

export const cyIds = {
  subheader: (section: NotificationTimeSection): string => `notifications-list-subheader-${section}`,
  item: (id: string): string => `notification-list-item-${id}`,
};

function NotificationsListSection({
  section,
  notifications,
  onlyShowUnread,
  handleClickNotification,
  userRef,
  localNow,
}: Readonly<NotificationsListSectionProps>) {
  const theme: Theme = useTheme();

  const unreadNotificationsInSection: number = notifications.filter((n) => !n.read).length;
  if (onlyShowUnread && unreadNotificationsInSection === 0) {
    return <></>;
  }

  if (!notifications.length) {
    return <></>;
  }

  return (
    <>
      <ListSubheader
        sx={{ height: theme.spacing(7), alignContent: "end", py: 1, borderBottom: "1px solid", borderColor: "divider" }}
        component={Box}
        data-cy={cyIds.subheader(section)}
      >
        <Typography variant="caption" component="h4">
          {notificationSectionTitle[section]}
        </Typography>
      </ListSubheader>
      {notifications.map((n) =>
        onlyShowUnread && n.read ? null : (
          <NotificationsListItem
            key={n.id}
            n={n}
            markAsRead={() => handleClickNotification(n, userRef)}
            now={localNow}
          />
        )
      )}
    </>
  );
}

export default NotificationsListSection;
