import { useMemo } from "react";

import { CloudflowEngineModel, type ExecutionNodeModel, type NodeExecutionStatus } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import { useQuery } from "@tanstack/react-query";

import { useApiContext } from "../../../../api/context";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { getExecutionNodeAudit } from "../../api";

export function useExecutionNodes(executionId: string): {
  nodes: ExecutionNodeModel[] | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const query = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowExecutions")
        .doc(executionId)
        .collection("executionNodes")
        .orderBy("startedAt", "asc"),
    [executionId]
  );

  const [nodes, loading, error] = useCollectionData(query);
  return { nodes, loading, error };
}

export function useExecutionNodeAudit(
  customerId: string,
  flowId: string,
  executionId: string,
  nodeId: string,
  enabled: boolean,
  executionNodeStatus: NodeExecutionStatus
) {
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();

  return useQuery(
    ["executionNodeAuditDetails", customerId, flowId, executionId, nodeId, executionNodeStatus],
    async () => {
      try {
        const res = await getExecutionNodeAudit(api, customerId, flowId, executionId, nodeId);
        return res.data;
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to get node details");
        throw e;
      }
    },
    {
      enabled,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );
}
