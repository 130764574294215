import { useCallback, useMemo, useState } from "react";

import { AccessLevels, type CloudConnectCategory, SaaSConsoleType, TierPackageTypes } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { isSaaSConsoleByType } from "../../../utils/common";
import { isHeritageTier } from "../General/utils";
import { usePermissionCategories } from "./usePermissionCategories";
import { getScopedCategoryPermissions } from "./utils";

export function useSelectItemList<T>(initialState: T[]): [T[], (value: T, isSelected: boolean) => void] {
  const [selectedItems, setSelectedItems] = useState(initialState);

  const onSelectItem = useCallback(
    (value: T, isSelected: boolean) => {
      setSelectedItems((current) => {
        if (isSelected) {
          return [...current, value];
        }

        return current.filter((currentId) => currentId !== value);
      });
    },
    [setSelectedItems]
  );

  return [selectedItems, onSelectItem];
}

export const usePermissionCategoriesByType = (type: "organization" | "project") => {
  const categories = usePermissionCategories();
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const { getCustomerTier } = useTier();

  const useOnlySaaSEnabledCategories = isSaaSConsoleByType(customer, isProductOnlyCustomer, SaaSConsoleType.GCP, true);
  const isHeritageCustomer = isHeritageTier(getCustomerTier(TierPackageTypes.NAVIGATOR));

  return useMemo(() => {
    let relevantCategories: CloudConnectCategory[];

    // For projects, take only categories that allow project level access, then filter permissions
    // For organizations, all categories apply, then filter permissions
    if (type === "project") {
      relevantCategories = categories
        .filter((category) => category.allowProjectAccessLevel)
        .map((category) => ({
          ...category,
          permissions: getScopedCategoryPermissions(category, AccessLevels.PROJECT),
        }));
    } else {
      relevantCategories = categories.map((category) => ({
        ...category,
        permissions: getScopedCategoryPermissions(category, AccessLevels.ORGANIZATION),
      }));
    }

    if (useOnlySaaSEnabledCategories) {
      relevantCategories = relevantCategories.filter((category) => category.enabledForSaaS);
    }

    if (!isHeritageCustomer) {
      relevantCategories = relevantCategories.filter((category) => !category.heritageOnly);
    }

    return relevantCategories;
  }, [categories, type, useOnlySaaSEnabledCategories, isHeritageCustomer]);
};

export const useIsEntitledToCategory = () => {
  const { isFeatureEntitled } = useTier();

  return useCallback(
    (category: CloudConnectCategory) => {
      // If there's no restrictions set, the user is entitled to the category
      if (!category.requiredEntitlements?.length) {
        return true;
      }

      // If there's any restriction set, the user must have all of them to be entitled to the category
      return category.requiredEntitlements.every((entitlement) => isFeatureEntitled(entitlement));
    },
    [isFeatureEntitled]
  );
};
