import { useMemo } from "react";

import { type ContractModel, type GoogleCloudAssetModel } from "@doitintl/cmp-models";
import { Box, List, ListItem, ListItemText, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import values from "lodash/values";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Asset } from "../../../types";
import { formatNumber, humanBoolean } from "../../../utils/common";
import { NotApplicable, NotAvailable } from "../../Assets/Tabs/components/typographics";
import { useContractTypesContext } from "../../ContractsTabs/ContractsTypesContext";
import { buildCommitmentPeriodBlock, contractDate } from "../utils";
import { DiscountPeriodsSection } from "./components/DiscountPeriodsSection";
import { getCommitmentTypeText } from "./utils";

export type ContractPageGcpProps = {
  contract: ContractModel;
  accountManagerName?: string;
  contractId: string;
};

export const cyIds = {
  text: {
    title: "title",
  },
  sections: {
    general: "general-section",
    details: "details-section",
    commitment: "commitment-section",
    discounts: "discounts-section",
  },
};

const ContractPageGcp = ({ contract, accountManagerName, contractId }: ContractPageGcpProps) => {
  const { entities, entitiesLoading, assets, assetsLoading } = useCustomerContext();
  const { getContractTypeLabel } = useContractTypesContext();
  const pageTitle = getContractTypeLabel(contract.type);

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  const billingAccounts = useMemo<string[]>(() => {
    if (assetsLoading) {
      return [];
    }
    const isGCP = (asset: Asset<any>): asset is Asset<GoogleCloudAssetModel> => asset.data.type === "google-cloud";

    return values(assets)
      .flatMap((assetList) => assetList)
      .filter((asset) => isGCP(asset))
      .filter(
        (asset) =>
          asset.data.entity?.id === entity?.id &&
          (contract.assets?.map((x) => x.id).includes(asset.id) || asset.data.contract?.id === contractId)
      )
      .map((asset) => asset.data.properties.billingAccountId)
      .filter((billingAccountId) => !!billingAccountId); // remove undefined values in the event that there was no billing account ID on properties
  }, [assets, assetsLoading, contract.assets, entity?.id, contractId]);

  const gcpFlexsaveOverride =
    contract.properties?.gcpFlexSaveOverwrite !== undefined
      ? `${100 - Number(contract.properties.gcpFlexSaveOverwrite)}%`
      : NotApplicable;

  const billingAccountsJsx = useMemo(
    () => (
      <List>
        {billingAccounts.map((ba) => (
          <ListItem disablePadding disableGutters key={ba}>
            <ListItemText>{ba}</ListItemText>
          </ListItem>
        ))}
      </List>
    ),
    [billingAccounts]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h1" data-cy={cyIds.text.title}>
        {pageTitle}
      </Typography>
      <Box component="section" data-cy={cyIds.sections.general}>
        <Typography variant="h2">General</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="billing-profile"
            field="Billing profile"
            value={entity ? `${entity.priorityId} - ${entity.name}` : "Unassigned"}
            loading={entitiesLoading}
          />
          <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
          <DataCouplet
            key="annual-value"
            field="Annualized value"
            value={`$${formatNumber(contract.estimatedValue, 0)}`}
            infoTooltip="The estimated yearly contract value in USD"
          />
        </Stack>
      </Box>
      <Box component="section" data-cy={cyIds.sections.details}>
        <Typography variant="h2">Contract details</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet key="commitment-deal" field="Commitment deal" value={getCommitmentTypeText(contract)} />
          <DataCouplet field="Start date" value={contractDate(contract.startDate)} key="contract-start-date" />
          <DataCouplet field="End date" value={contractDate(contract?.endDate)} key="contract-end-date" />
          {contract.type !== "saas-console-gcp" && (
            <>
              <DataCouplet
                field="Flexsave discount override"
                value={gcpFlexsaveOverride}
                infoTooltip="Override of default percentage of 1-year RI discount passed to client"
                key="flexsave-discount-override"
              />
              <DataCouplet
                field="Apply discount on preemptibles"
                value={humanBoolean(contract.properties?.discountPreemptible)}
                key="apply-discount-on-preemptibles"
              />
              <DataCouplet
                field="Billing accounts"
                value={billingAccountsJsx}
                key="contract-billing-accounts"
                loading={entitiesLoading || assetsLoading}
              />
            </>
          )}
        </Stack>
      </Box>
      {contract.isCommitment && (
        <Box component="section" data-cy={cyIds.sections.commitment}>
          <Typography variant="h2">Commitment</Typography>
          <Stack component="dl" spacing={2}>
            <DataCouplet
              field="Commitment rollover"
              value={humanBoolean(contract.commitmentRollover)}
              key="contract-commitment-rollover"
            />
            {contract?.commitmentPeriods?.map((period, index) => buildCommitmentPeriodBlock(period, index))}
          </Stack>
        </Box>
      )}

      {!!contract.discountPeriods?.length && (
        <DiscountPeriodsSection discountPeriods={contract.discountPeriods} data-cy={cyIds.sections.discounts} />
      )}
    </Stack>
  );
};

export default ContractPageGcp;
