import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

export const datadogAssetRowCyIds = {
  site: "datadog-asset-site-id",
  orgName: "datadog-asset-org-name",
  status: "status",
};
export type DatadogAssetRowData = {
  orgName: string;
  site: string;
  status: "Active" | "Inactive";
};

export type DatadogAssetRowProps = { data: DatadogAssetRowData };

const DatadogAssetRow = ({ data }: DatadogAssetRowProps) => (
  <>
    <TableCell>
      <Typography variant="body2" component="span" data-cy={datadogAssetRowCyIds.orgName}>
        {data.orgName}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body2" data-cy={datadogAssetRowCyIds.site}>
        {data.site}
      </Typography>
    </TableCell>
    <TableCell>
      <Chip
        size="small"
        label={data.status}
        color={data.status === "Active" ? "success" : "error"}
        data-cy={datadogAssetRowCyIds.status}
      />
    </TableCell>
    <TableCell />
  </>
);
export default DatadogAssetRow;
