import { SaaSConsoleType } from "@doitintl/cmp-models";

export const DEMO_LINK_AWS = "https://doit-international.navattic.com/ow6a03gr";
export const DEMO_LINK_GCP = "https://doit-international.navattic.com/602lw0bzu";

export const headerMap = {
  [SaaSConsoleType.AWS]: "AWS",
  [SaaSConsoleType.GCP]: "Google Cloud",
  [SaaSConsoleType.AZURE]: "Azure",
  [SaaSConsoleType.SNOWFLAKE]: "Snowflake",
  [SaaSConsoleType.DATADOG]: "Datadog",
};

export const SaasConsoleTypes = [
  SaaSConsoleType.AWS,
  SaaSConsoleType.GCP,
  SaaSConsoleType.AZURE,
  SaaSConsoleType.SNOWFLAKE,
  SaaSConsoleType.DATADOG,
];

export const ProductOnlyCustomerProspects = ["navigator", "solve"] as const;
