import { memo, useCallback, useEffect, useMemo } from "react";

import { useParams } from "react-router";
import { type AttributionFilter, type CloudAnalyticsModelAttributionGroupsModel } from "@doitintl/cmp-models";
import { useShallow } from "zustand/react/shallow";

import { useAttributionGroups } from "../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { type AttributionWRef } from "../../../types";
import { Allocation } from "./Allocation";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { formStoreManager } from "./hooks/useAllocationFormState";
import { type AttributionGroupWithRef } from "./types";

export const AllocationWrapper = memo(() => {
  const { allocationId } = useParams<{ allocationId: string }>();
  const { filteredAttributions } = useAttributionsContext();
  const [attributionGroups] = useAttributionGroups();

  const { addItem, replaceItem } = useAllocationDndStore(useShallow((state) => state));

  const findAttributionById = useCallback(
    (
      attributions: (AttributionWRef | AttributionGroupWithRef)[],
      id: string
    ): AttributionWRef | AttributionGroupWithRef | undefined => attributions?.find((item) => item?.ref.id === id),
    []
  );

  const mapAttributionsToGroup = useCallback(
    (
      attributionIds: Array<{ id: string }>,
      filteredAttributions: AttributionWRef[]
    ): (AttributionWRef | AttributionGroupWithRef)[] =>
      attributionIds
        .map((item) => findAttributionById(filteredAttributions, item.id))
        .filter((item): item is AttributionWRef | AttributionGroupWithRef => item !== undefined),
    [findAttributionById]
  );

  const allocation = useMemo((): (AttributionWRef | AttributionGroupWithRef)[] => {
    const allAttributions = [...filteredAttributions, ...attributionGroups];
    const attributionGroup = findAttributionById(allAttributions, allocationId);
    if (!attributionGroup) {
      return [];
    }

    const { attributions } = attributionGroup.data as CloudAnalyticsModelAttributionGroupsModel;

    return attributions ? mapAttributionsToGroup(attributions, filteredAttributions) : [attributionGroup];
  }, [allocationId, attributionGroups, filteredAttributions, findAttributionById, mapAttributionsToGroup]);

  const handleAllocations = useCallback(
    (allocation: (AttributionWRef | AttributionGroupWithRef)[]) => {
      allocation?.map((item: AttributionWRef | AttributionGroupWithRef) => {
        addItem(item?.ref.id);
        const { filters, formula, name } = item.data as { name: string; filters: AttributionFilter[]; formula: string };
        const newStore = formStoreManager.getStore(item?.ref.id);
        newStore.setState((state) => ({
          ...state,
          values: {
            name,
            filterFields: filters,
            formula,
          },
        }));
      });
    },
    [addItem]
  );

  useEffect(() => {
    handleAllocations(allocation);
    return () => {
      replaceItem([]);
    };
  }, [allocation, handleAllocations, replaceItem]);

  return <Allocation edit />;
});

AllocationWrapper.displayName = "Edit Allocation";
