import { useMemo } from "react";

import {
  AssetModel,
  AssetTypeGoogleCloud,
  ContractTypeGoogleCloudPartnerLedPremiumSupport,
  ContractTypeLooker,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField, { type TextFieldProps } from "@mui/material/TextField";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { getAssetLabel } from "../../../utils";
import { useContractFormContext } from "../../ContractsFormContext";

type Props = {
  label: string;
  helperText?: string;
  textFieldProps?: TextFieldProps;
  disabled: boolean;
};

export const AssetsAutocomplete = ({ label, helperText, textFieldProps, disabled }: Props) => {
  const { assets } = useCustomerContext();
  const { state, setState } = useContractFormContext();

  const assetsForType = useMemo(
    () =>
      (assets[state.entity] || []).filter(
        (asset) =>
          asset.data.type ===
          ([ContractTypeLooker, ContractTypeGoogleCloudPartnerLedPremiumSupport].includes(state.type)
            ? AssetTypeGoogleCloud
            : state.type)
      ),
    [assets, state.entity, state.type]
  );

  const options = useMemo(
    () =>
      assetsForType.map((asset) => ({
        id: asset.id,
        type: asset.data.type,
        label: getAssetLabel(asset),
      })),
    [assetsForType]
  );

  const memoValue = useMemo(
    () => options.filter((option) => state.assets.findIndex((v) => v.id === option.id) > -1),
    [options, state.assets]
  );

  const handleAssetsChange = (_, value) => {
    setState({
      ...state,
      assets: value?.filter((v) => !!v).map((v) => getCollection(AssetModel).doc(v.id)) ?? [],
      errors: {
        ...state.errors,
        assets: false,
      },
    });
  };

  return (
    <Autocomplete
      multiple
      id="assets-autocomplete"
      data-cy="assets-autocomplete"
      options={options}
      value={memoValue}
      onChange={handleAssetsChange}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      disableCloseOnSelect
      disableClearable
      disabled={state.isEditForbidden}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          disabled={disabled}
          fullWidth
          label={label}
          margin="dense"
          variant="outlined"
          helperText={helperText}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size="small" label={option.label} {...getTagProps({ index })} key={option.id} />
        ))
      }
    />
  );
};
