import { useMemo } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { Button, Stack, TableCell, Typography } from "@mui/material";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import { CellsWrapper } from "../../Components/FilterTable/Toolbar/CellsWrapper";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu } from "../../Components/ThreeDotsMenu";
import { useDataHubContext } from "../../Context/DataHubContext";
import { formatNumber, formatStringDate } from "../../utils/common";
import { type DatasetItem } from "./DataHubSources";
import { deleteProcessingMinutes, isAnySelectedUpdatedWithinLastXMinutes, metadataProcessingMinutes } from "./utils";

interface DataHubSourceRowProps {
  data: DatasetItem;
  handleExplore: (dataset?: string) => void;
  handleDelete: (dataset: DatasetItem) => void;
  onImportData: (datasetName: string) => void;
  datasetsRefreshed: boolean;
  isPresentationMode: boolean;
}

const DataHubSourceRow = ({
  data,
  handleExplore,
  handleDelete,
  onImportData,
  datasetsRefreshed,
  isPresentationMode,
}: DataHubSourceRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();
  const customerId = useCustomerId();
  const { fetchDatasetBatches } = useDataHubContext();
  const deleteDisabled = useMemo(
    () => isPresentationMode || isAnySelectedUpdatedWithinLastXMinutes([data], "lastUpdated", deleteProcessingMinutes),
    [data, isPresentationMode]
  );

  const openInReportsDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes([data], "lastUpdated", metadataProcessingMinutes),
    [data]
  );

  const deleteDatasetTooltipTitle = useMemo(() => {
    if (isPresentationMode) {
      return datahubTxt.DELETE_DISABLED_PRESENTATION_MODE;
    } else if (deleteDisabled) {
      return datahubTxt.DATASET_RECENTLY_INGESTED;
    }

    return undefined;
  }, [deleteDisabled, isPresentationMode]);

  const threeDotsMenuOptions = useMemo(() => {
    const options = [
      {
        label: <Typography>{datahubTxt.OPEN_IN_REPORTS}</Typography>,
        action: () => {
          handleExplore(data.dataset);
        },
        key: datahubTxt.OPEN_IN_REPORTS,
        tooltip: openInReportsDisabled ? datahubTxt.DATASET_REPORT_DATA_UNAVAILABLE : undefined,
        disabled: openInReportsDisabled,
      },
      {
        label: <Typography>{datahubTxt.IMPORT_DATA}</Typography>,
        action: () => {
          onImportData(data.dataset);
        },
        key: datahubTxt.IMPORT_DATA,
      },
      {
        label: <Typography>{datahubTxt.DELETE}</Typography>,
        action: () => {
          handleDelete(data);
        },
        key: datahubTxt.DELETE,
        tooltip: deleteDatasetTooltipTitle,
        disabled: deleteDisabled,
      },
    ];
    return options.filter((item) => !!item);
  }, [
    data,
    deleteDatasetTooltipTitle,
    deleteDisabled,
    handleDelete,
    handleExplore,
    onImportData,
    openInReportsDisabled,
  ]);

  const handleClickViewDetails = () => {
    if (datasetsRefreshed) {
      fetchDatasetBatches(data.dataset, true);
    }
    history.push(`/customers/${customerId}/datasets/${data.dataset}`);
  };

  return (
    <CellsWrapper>
      <TableCell>
        <Stack>
          <Typography
            component={RouterLink}
            to={{
              pathname: `${routeMatchURL}/${data.dataset}`,
              state: {
                prevPage: routeMatchURL,
              },
            }}
            color="inherit"
            variant="body2"
          >
            {data.dataset}
          </Typography>
          {!!data.description && (
            <Typography
              color="textSecondary"
              variant="body2"
              sx={{ whiteSpace: "noWrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "calc(100vw/4)" }}
              title={data.description}
            >
              {data.description}
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell>{data.updatedBy}</TableCell>
      <TableCell>
        {data.processing ? <i>Processing {data.processing} records...</i> : formatNumber(data.records)}
      </TableCell>
      <TableCell>{formatStringDate(data.lastUpdated)}</TableCell>
      <TableCell padding="none">
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
          }}
        >
          <Button variant="outlined" onClick={handleClickViewDetails} sx={{ whiteSpace: "nowrap", mr: 1 }}>
            {datahubTxt.VIEW_DETAILS}
          </Button>
          <ThreeDotsMenu options={threeDotsMenuOptions} disabled={false} />
        </Stack>
      </TableCell>
    </CellsWrapper>
  );
};

export default DataHubSourceRow;
