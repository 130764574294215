import { CloudflowExecutionStatus, NodeExecutionStatus } from "@doitintl/cmp-models";
import { Chip } from "@mui/material";
import { amber, yellow } from "@mui/material/colors";
import { type Theme, useTheme } from "@mui/material/styles";

import { executionStatusNames } from "../consts";

type StatusColorMap = (theme: Theme) => Record<string, { name: string; color: string }>;

export const executionStatus = (theme: Theme) => ({
  [CloudflowExecutionStatus.COMPLETE]: {
    name: executionStatusNames[CloudflowExecutionStatus.COMPLETE],
    color: theme.palette.success.main,
  },
  [CloudflowExecutionStatus.FAILED]: {
    name: executionStatusNames[CloudflowExecutionStatus.FAILED],
    color: theme.palette.error.main,
  },
  [CloudflowExecutionStatus.RUNNING]: {
    name: executionStatusNames[CloudflowExecutionStatus.RUNNING],
    color: theme.palette.info.main,
  },
  [CloudflowExecutionStatus.PENDING]: {
    name: executionStatusNames[CloudflowExecutionStatus.PENDING],
    color: yellow[500],
  },
  [CloudflowExecutionStatus.PENDING_APPROVAL]: {
    name: executionStatusNames[CloudflowExecutionStatus.PENDING_APPROVAL],
    color: amber[500],
  },
});

export const nodeExecutionStatus = (theme: Theme) => ({
  [NodeExecutionStatus.COMPLETE]: { name: "Complete", color: theme.palette.success.main },
  [NodeExecutionStatus.FAILED]: { name: "Has errors", color: theme.palette.error.main },
  [NodeExecutionStatus.IN_PROGRESS]: { name: "In progress", color: theme.palette.info.main },
  [NodeExecutionStatus.PENDING]: { name: "Pending", color: yellow[500] },
  [NodeExecutionStatus.REJECTED]: { name: "Rejected", color: theme.palette.error.dark },
  [NodeExecutionStatus.APPROVAL_TIMED_OUT]: { name: "Timed out", color: theme.palette.warning.dark },
  [NodeExecutionStatus.PENDING_APPROVAL]: { name: "Awaiting approval", color: amber[500] },
});

type Props = {
  status: string | undefined;
  statusMap: StatusColorMap;
};

const StatusChip = ({ status, statusMap }: Props) => {
  const theme = useTheme();
  const statusColors = statusMap(theme);
  const statusProps = status ? statusColors[status] : undefined;

  if (!statusProps) {
    return <Chip label="Unknown Status" size="small" />;
  }

  return (
    <Chip
      label={statusProps.name}
      size="small"
      sx={{
        backgroundColor: statusProps.color,
        color: theme.palette.getContrastText(statusProps.color),
      }}
    />
  );
};

export default StatusChip;
