import "@services/clouddiagrams/frontend/sass/ind/_cmp_dashboard.scss";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import awsLogoDark from "../../../assets/amazon-web-services-logo-white.png";
import awsLogo from "../../../assets/amazon-web-services-new-square.png";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

const Head = ({ handleCreateDiagram }) => {
  return (
    <Grid
      container
      spacing={1}
      size="grow"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid sx={{ paddingTop: "1rem" }}>
        <Typography
          data-cy="clouddiagrams-title"
          variant="h1"
          sx={{
            mb: 1,
          }}
        >
          Cloud diagrams
        </Typography>
        <Typography
          data-cy="clouddiagrams-description"
          variant="body1"
          color="textSecondary"
          sx={{
            fontWeight: 400,
            mb: 2,
          }}
        >
          Visualize and analyze your cloud infrastructure
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <TextField
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            },
          }}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleCreateDiagram} sx={{ marginLeft: "1rem" }}>
          Create diagram
        </Button>
      </Grid>
    </Grid>
  );
};

const BdColDiv = ({ children, isAwsLogo }) => {
  return (
    <Box className="__item" sx={{ borderColor: "divider" }}>
      <Box className="__text" sx={{ borderColor: isAwsLogo ? "transparent" : "text.secondary" }}>
        {children}
      </Box>
    </Box>
  );
};

const DiagramCard = () => {
  const darkMode = useDarkThemeCheck();

  return (
    <Card sx={{ minWidth: 100 }}>
      <CardContent sx={{ padding: "12px 16px 16px" }} gutterBottom>
        <Typography variant="h6" component="div">
          Dev diagram - Brad
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
          32423423423 (rebl-dev)
        </Typography>
        <Typography variant="body2" className="cd-dashboard-card-bottomline">
          <BdColDiv {...{ isAwsLogo: true }}>
            <img src={darkMode ? awsLogoDark : awsLogo} width={24} alt="aws logo" style={{ marginBottom: "-3px" }} />
          </BdColDiv>
          <BdColDiv>All services</BdColDiv>
          <BdColDiv>2 changes</BdColDiv>
        </Typography>
      </CardContent>
    </Card>
  );
};

const handleCreateDiagram = () => {
  return false;
};

const CloudDiagramsDashboard = () => {
  return (
    <>
      <Head {...{ handleCreateDiagram }} />
      <Grid container spacing={2} sx={{ padding: "0.5rem 0" }}>
        {[...Array(6)].map((_, i) => (
          <Grid size={{ xs: 6, md: 4 }} key={i}>
            <DiagramCard />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CloudDiagramsDashboard;
