import { type Cluster, ClusterType } from "@doitintl/cmp-models";
import { Divider, Stack, Typography } from "@mui/material";

interface SelectedAccountAndClusterProps {
  projectId: string | null;
  cluster: Cluster | null;
}

const SelectedAccountAndCluster = ({ projectId, cluster }: SelectedAccountAndClusterProps) => {
  const projectIdType = cluster?.type === ClusterType.EKS ? "Account ID" : "Project ID";

  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="body2" color="text.secondary">
        {projectIdType}: <strong style={{ fontWeight: 500 }}>{projectId}</strong>
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography variant="body2" color="text.secondary">
        Cluster ID: <strong style={{ fontWeight: 500 }}>{cluster?.id}</strong>
      </Typography>
    </Stack>
  );
};

export default SelectedAccountAndCluster;
