import { useCallback, useMemo, useState } from "react";

import { TimeInterval as TI, TimeSettingsMode } from "@doitintl/cmp-models";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, MenuItem, Popover, TextField } from "@mui/material";
import { DateTime } from "luxon";

import TimeRangeSelection from "../../../Pages/CloudAnalytics/report/config/TimeSelection/TimeRangeSelection";
import { useStyles } from "../../../Pages/CloudAnalytics/ReportStyles";
import {
  MenuProps,
  timeIntervalOptions,
  timeRangeInputValue,
  type TimeRangeOption,
} from "../../../Pages/CloudAnalytics/utilities";
import { type CustomTimeRange } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { type TimeIntervalName } from "./types";

export type TimeSelectionProps = {
  setCustomTimeRange: React.Dispatch<React.SetStateAction<CustomTimeRange | null>>;
  timeInterval: TimeIntervalName;
  handleChangeTimeInterval: (e: any) => void;
  queryRunning: boolean;
  excludePartialData: boolean;
  timeRangeOption: TimeRangeOption;
  setTimeRangeOption: (option: TimeRangeOption) => void;
  onExcludePartialChange: (newValue: boolean) => void;
  customTimeRange: CustomTimeRange | null;
};

const SimpleTimeSelection = ({
  setCustomTimeRange,
  timeInterval,
  handleChangeTimeInterval,
  timeRangeOption,
  setTimeRangeOption,
  customTimeRange,
}: TimeSelectionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [timeRangeOptionTemp, setTimeRangeOptionTemp] = useState(timeRangeOption);
  const [disabledIntervalList, setDisabledIntervalList] = useState<TI[]>([]);
  const value = useMemo(() => timeRangeInputValue(timeRangeOption), [timeRangeOption]);
  const { isMobile } = useFullScreen();
  const classes = useStyles();

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(
    (updateTimeRangeLocal?: boolean) => {
      setAnchorEl(null);
      if (updateTimeRangeLocal) {
        setTimeRangeOptionTemp(timeRangeOption);
      }
    },
    [timeRangeOption]
  );

  const disableIntervalOptionsByOverflow = useCallback((start: DateTime, end: DateTime) => {
    const disabledIntervalOptions: TI[] = [];
    if (end.diff(start).as("day") > 7) {
      disabledIntervalOptions.push(TI.HOUR);
    }
    if (end.diff(start).as("month") < 1) {
      disabledIntervalOptions.push(TI.MONTH);
    }
    if (end.diff(start).as("quarter") < 1) {
      disabledIntervalOptions.push(TI.QUARTER);
    }
    if (end.diff(start).as("year") < 1) {
      disabledIntervalOptions.push(TI.YEAR);
    }
    setDisabledIntervalList(disabledIntervalOptions);
    return disabledIntervalOptions;
  }, []);

  const onSave = useCallback(() => {
    if (timeRangeOptionTemp.mode === TimeSettingsMode.Fixed) {
      if (timeRangeOptionTemp.range?.start && timeRangeOptionTemp.range?.end) {
        const { start, end } = timeRangeOptionTemp.range;
        setCustomTimeRange({
          from: start,
          to: end,
        });
        const disabledIntervals = disableIntervalOptionsByOverflow(start, end);
        timeIntervalOptions.every((intervalOption) => {
          if (!disabledIntervals.includes(intervalOption.value)) {
            handleChangeTimeInterval(intervalOption.label);
            return false;
          }
          return true;
        });
      } else {
        return;
      }
    } else if (timeRangeOptionTemp.mode === TimeSettingsMode.Last) {
      const amount = timeRangeOptionTemp?.amount ?? 2;
      const minus = timeRangeOptionTemp.time === TI.DAY ? amount - 1 : amount;
      const start = DateTime.utc()
        .minus({ [`${timeRangeOptionTemp.time}s`]: minus })
        .startOf(timeRangeOptionTemp.time);
      const end = DateTime.utc();

      setCustomTimeRange({
        from: start,
        to: end,
      });
      const disabledIntervals = disableIntervalOptionsByOverflow(start, end);
      timeIntervalOptions.every((intervalOption) => {
        if (!disabledIntervals.includes(intervalOption.value)) {
          handleChangeTimeInterval(intervalOption.label);
          return false;
        }
        return true;
      });
    } else {
      setCustomTimeRange(null);
      setDisabledIntervalList([]);
    }

    setTimeRangeOption(timeRangeOptionTemp);
    handleClose();
  }, [
    timeRangeOptionTemp,
    setTimeRangeOption,
    handleClose,
    setCustomTimeRange,
    disableIntervalOptionsByOverflow,
    handleChangeTimeInterval,
  ]);

  const handleIntervalChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (DateTime.isDateTime(customTimeRange?.from) && DateTime.isDateTime(customTimeRange?.to)) {
        const duration = customTimeRange?.to.diff(customTimeRange?.from).as("day");
        const maxHourDuration = 7;
        if (duration && duration > maxHourDuration && e.target.value === "Hour") {
          setCustomTimeRange({
            from: DateTime.utc().minus({ days: maxHourDuration }).startOf("day"),
            to: DateTime.utc(),
          });
          setTimeRangeOption({
            mode: TimeSettingsMode.Last,
            amount: maxHourDuration,
            time: TI.DAY,
            includeCurrent: true,
          });
        }
      }
      handleChangeTimeInterval(e.target.value);
    },
    [customTimeRange?.from, customTimeRange?.to, handleChangeTimeInterval, setCustomTimeRange, setTimeRangeOption]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <TextField
        data-cy="time-range-button"
        fullWidth
        label="Time range"
        margin="dense"
        onClick={handleClick}
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          minWidth: 200,
          mx: !isMobile ? 2 : undefined,
        }}
        value={value}
        variant="outlined"
        slotProps={{
          input: {
            sx: { height: 40 },
            readOnly: true,
            classes: {
              root: classes.timeRangeRoot,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton className={classes.timeRangeEndAdornment}>
                  {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={() => {
          handleClose(true);
        }}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <TimeRangeSelection
          handleClose={handleClose}
          onSave={onSave}
          setTimeRangeOption={setTimeRangeOptionTemp}
          timeRangeOption={timeRangeOptionTemp}
        />
      </Popover>
      <TextField
        disabled={false}
        fullWidth
        label="Time interval"
        margin="dense"
        onChange={handleIntervalChange}
        select
        value={timeInterval}
        variant="outlined"
        sx={{ minWidth: 200 }}
        slotProps={{
          select: {
            classes: {
              select: classes.selectOutlined,
              outlined: classes.outlined,
            },
            MenuProps,
            sx: { height: 40 },
          },
        }}
      >
        {timeIntervalOptions.map((interval) => (
          <MenuItem
            disabled={disabledIntervalList.includes(interval.value)}
            dense
            key={interval.value}
            value={interval.label}
          >
            {interval.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default SimpleTimeSelection;
