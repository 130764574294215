import { Link, Typography } from "@mui/material";

export function ContactUsOnNoCustomerFound() {
  return (
    <Typography color="error" variant="body2">
      {"We couldn't find an account with that email."}
      <br />
      {"If you do not have an account but would like to try us out, we're happy to help!"}
      <br />
      <Link underline="none" href="https://doit.com/contact">
        Contact us to get started
      </Link>
    </Typography>
  );
}
