import { useEffect, useState } from "react";

import { AssetTypeGoogleCloud, type CloudConnectGoogleCloud, CustomerModel } from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";

export type GCPCloudConnectData = {
  data: WithFirebaseModel<CloudConnectGoogleCloud>;
  id: string;
};

export function useCloudConnectData(customerId: string): [GCPCloudConnectData[], boolean, boolean] {
  const [cloudConnectData, setCloudConnectData] = useState<GCPCloudConnectData[]>([]);
  const [hasConnectedServiceAccount, setHasConnectedServiceAccount] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(
    () =>
      getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeGoogleCloud)
        .narrow<CloudConnectGoogleCloud>()
        .onSnapshot((querySnapshot) => {
          setCloudConnectData(
            querySnapshot.docs.map((doc) => ({
              data: doc.asModelData(),
              id: doc.id,
            }))
          );
          setIsLoaded((prevState) => {
            if (!prevState) {
              setHasConnectedServiceAccount(querySnapshot.size > 0);
              return true;
            }

            return prevState;
          });
        }),
    [customerId]
  );
  return [cloudConnectData, hasConnectedServiceAccount, isLoaded];
}
