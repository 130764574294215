import { Stack, TextField, Typography } from "@mui/material";

type Props = {
  APIKey: string;
  onAPIKeyChange: (string) => void;
};

export default function APIKeyStep({ APIKey, onAPIKeyChange }: Props) {
  return (
    <Stack data-cy="api-key-step" spacing={4}>
      <Typography mb={4} variant="h3">
        Create an API key or provide an existing one
      </Typography>

      <Stack spacing={2}>
        <Typography variant="body1">1. Navigate to the API KEYS page</Typography>
        <Typography variant="body1">2. Select NEW API KEY</Typography>
        <Typography variant="body1">3. Choose a name that fits the usage</Typography>
        <Typography variant="body1">4. Copy the key</Typography>
        <Typography variant="body1">5. Paste it into the API Key field below</Typography>
      </Stack>

      <TextField
        label="API key"
        fullWidth
        value={APIKey}
        required
        onChange={(event) => {
          onAPIKeyChange(event.target.value);
        }}
      />
    </Stack>
  );
}
