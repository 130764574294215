import { CLOUD_FLOW_CREATION_STATUS, type CloudflowCreationStatus } from "@doitintl/cmp-models";
import { Chip, type Theme, useTheme } from "@mui/material";

type StatusChipConfig = {
  background?: string;
  color: string;
  label: string;
};

type Props = {
  status?: CloudflowCreationStatus;
};

const getStatusChipConfig = (status: CloudflowCreationStatus | undefined, theme: Theme): StatusChipConfig => {
  switch (status) {
    case CLOUD_FLOW_CREATION_STATUS.PUBLISHED:
    case CLOUD_FLOW_CREATION_STATUS.NEW:
      return {
        background: theme.palette.success.background,
        color: theme.palette.success.main,
        label: "Published",
      };
    case CLOUD_FLOW_CREATION_STATUS.DRAFT:
      return {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.secondary,
        label: "Draft",
      };
    default:
      return {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.secondary,
        label: "Loading...",
      };
  }
};

export const StatusChip = ({ status }: Props) => {
  const theme = useTheme();
  const { background, color, label } = getStatusChipConfig(status, theme);
  return (
    <Chip
      component="span"
      size="small"
      label={label}
      data-cy="cloudFlowStatus"
      sx={{
        background,
        color,
        py: 1.8,
        fontWeight: 400,
        borderRadius: 1,
      }}
    />
  );
};
