import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  Aggregator,
  AnalyticsResourceType,
  CalcMetricFormat,
  type CloudAnalyticsModelMetricModel,
  type CurrencyCode,
  DashboardModel,
  Metric as MetricEnum,
  type MetricVariable,
} from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";
import CopyIcon from "@mui/icons-material/FileCopyRounded";
import { CardContent, CardHeader, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";

import { metricText } from "../../../assets/texts";
import { metricTxt } from "../../../assets/texts/CloudAnalytics";
import { helpURLs } from "../../../assets/urls";
import { LearnMoreAlert } from "../../../Components/Alerts";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { useMetricFormatter } from "../../../Components/hooks/cloudAnalytics/useMetricFormatter";
import { useGotoParentUrl } from "../../../Components/hooks/useGotoParentUrl";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import SaveComponent from "../../../Components/SaveComponents/SaveComponent";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import Title from "../../../Components/Title/Title";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { type AttributionWRef, type MetricWSnap } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import { type FirestoreTimestamp, serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { getAttributionRefIdByName, textFieldBaseProps, textFieldSelectProps } from "../budgets/shared";
import { type CloudAnalyticsHistoryState } from "../types";
import { CalcMetricFormatOptions } from "../utilities";
import { copyMetric, saveMetricData } from "./db";
import { getVariableName, ValidateFormula } from "./MetricFormula";
import MetricPreview from "./MetricPreview";
import metricStyles, { inputLabelsStyles } from "./MetricStyles";
import { MetricVariableRow, type MetricVariableValue } from "./MetricVariableRow";

const useStyles = metricStyles;

const MAX_METRIC_ATTR = 5;

const emptyVar: MetricVariableValue = {
  metric: undefined,
  attribution: null,
};

export type MetricComponentProps = {
  metric?: MetricWSnap;
  attributions: AttributionWRef[];
  currency: CurrencyCode;
};

export const Metric = ({ metric, attributions, currency }: MetricComponentProps) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const classes = useStyles();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const routeMatchURL = useRouteMatchURL();
  const { isMobile: smDown } = useFullScreen();
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [name, setName] = useState(metric?.data.name ?? "");
  const [description, setDescription] = useState(metric?.data.description ?? "");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [alertBoxHidden, setAlertBoxHidden] = useState(false);
  const [variables, setVariables] = useState(metric?.data?.variables ?? []);
  const [variablesToPreview, setVariablesToPreview] = useState(metric?.data?.variables ?? []);
  const [formula, setFormula] = useState(metric?.data?.formula ?? "");
  const [format, setFormat] = useState(metric?.data?.format ?? CalcMetricFormat.NUMERIC);
  const [validMetric, setValidMetric] = useState(false);
  const [validFormula, setValidFormula] = useState(true);
  const [validationComplete, setValidationComplete] = useState(false);
  const [metricDataReady, setMetricDataReady] = useState(false);
  const [formulaTouched, setFormulaTouched] = useState(false);
  const [runPreview, setRunPreview] = useState(false);
  const [previewDataChanged, setPreviewDataChanged] = useState(true);
  const [previewDataReady, setPreviewDataReady] = useState(false);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [draft] = useState(!metric);
  const contentRef = useRef<HTMLDivElement>(null);
  const isUserEditor = draft || (!!currentUser && currentUser.email === metric?.data?.owner);

  useEffect(() => {
    if (hasUnsavedChanges) {
      activatePendingPrompt({
        exceptionsPath: [new RegExp(`^${routeMatchURL.replace("create", "")}(\\w+)$`)],
      });
    } else {
      clearPendingPrompt();
    }
  }, [activatePendingPrompt, clearPendingPrompt, hasUnsavedChanges, routeMatchURL]);

  const initVariableValues = useCallback(
    () =>
      variables.map(({ metric, extendedMetric, attribution }): MetricVariableValue => {
        const attr = attributions.find((a) => a.ref.id === attribution?.id);
        const attrName = attr?.data.name;
        return {
          metric,
          extendedMetric,
          attribution: attrName === undefined ? null : attrName,
        };
      }),
    [variables, attributions]
  );
  const [variablesValues, setVariablesValues] = useState<MetricVariableValue[]>(initVariableValues());

  const isViewMode = !isUserEditor || smDown;

  const handleCopyMetric = useCallback(async () => {
    if (!customer || !metric) {
      return;
    }

    const metricData = {
      ...metric.data,
      name,
      description,
      variables,
      formula,
      format,
    };
    try {
      const metricId = await copyMetric(customer.ref, currentUser.email, metricData);
      setName(`Copy of ${name}`);
      setHasUnsavedChanges(false);
      successSnackbar(metricTxt.METRIC_CLONE_SUCCESS);
      clearPendingPrompt();
      history.push(routeMatchURL.replace(metric.snapshot.id, metricId));
    } catch (e) {
      consoleErrorWithSentry(e);
      errorSnackbar(metricTxt.METRIC_CLONE_ERROR);
    }
  }, [
    clearPendingPrompt,
    currentUser.email,
    customer,
    description,
    errorSnackbar,
    format,
    formula,
    history,
    metric,
    name,
    routeMatchURL,
    successSnackbar,
    variables,
  ]);

  useMemo(() => {
    const setValidationState = (formulaState: boolean, metricState: boolean, runPreviewState: boolean) => {
      setValidFormula(formulaState);
      setValidMetric(metricState);
      setRunPreview(runPreviewState);
      setValidationComplete(true);
    };

    if (!formula) {
      if (variablesValues.length > 0) {
        if (variablesValues.find((v) => v.metric !== undefined && v.attribution)) {
          setValidationState(false, false, false);
          return;
        }
      }
      setValidationState(true, false, false);
      return;
    }

    if (ValidateFormula(formula, variablesValues.length)) {
      let canCommit = true;
      let formulaOk = true;
      let canPreview = true;
      for (let i = 0; i < variablesValues.length; i++) {
        const v = variablesValues[i];

        const vInFormula = formula.includes(getVariableName(i));

        if (!v.attribution || v.metric === undefined) {
          canCommit = false;
          if (vInFormula) {
            canPreview = false;
          }
        } else if (!vInFormula) {
          canCommit = false;
          formulaOk = false;
        }
        if (!canCommit && !formulaOk && !canPreview) {
          break;
        }
      }
      setValidationState(formulaOk, canCommit, canPreview);
      return;
    }
    setValidationState(false, false, false);
  }, [variablesValues, formula]);

  const metricNotReady = () => {
    setValidationComplete(false);
    setMetricDataReady(false);
    setPreviewDataReady(false);
  };

  const handleChangeMetric = useCallback(
    (idx: number, label: string | undefined, metric: MetricEnum) => {
      metricNotReady();

      setPreviewDataChanged(idx < variablesValues.length && formula.includes(getVariableName(idx)));
      let newVariables = cloneDeep(variablesValues);
      if (idx < variablesValues.length) {
        newVariables[idx].metric = metric;
        newVariables[idx].extendedMetric = metric === MetricEnum.EXTENDED ? label : "";
      } else {
        const newVar: MetricVariableValue = {
          metric,
          attribution: null,
          extendedMetric: metric === MetricEnum.EXTENDED ? label : "",
        };
        newVariables = [...variablesValues, newVar];
      }
      setVariablesValues(newVariables);
      setHasUnsavedChanges(true);
    },
    [variablesValues, formula]
  );

  const attrDefinedIdxs = useMemo(
    () => variablesValues.map((v, i) => (v.attribution ? i : -1)).filter((idx) => idx >= 0),
    [variablesValues]
  );

  const handleChangeAttribution = useCallback(
    (idx: number, value: string | null) => {
      metricNotReady();

      setPreviewDataChanged(idx < variablesValues.length && formula.includes(getVariableName(idx)));

      let newVariables = cloneDeep(variablesValues);
      if (idx < variablesValues.length) {
        newVariables[idx].attribution = value;
      } else {
        const newVar: MetricVariableValue = {
          attribution: value,
        };
        newVariables = [...variablesValues, newVar];
      }
      setVariablesValues(newVariables);
      setHasUnsavedChanges(true);
    },
    [variablesValues, formula]
  );

  const handleDeleteAttribution = useCallback(
    (idx: number) => {
      metricNotReady();

      setPreviewDataChanged(formula.includes(getVariableName(idx)));

      const newVariables = cloneDeep(variablesValues);
      newVariables.splice(idx, 1);

      let newFormula = formula;
      let i = idx + 1;
      for (; i < variablesValues.length; i++) {
        newFormula = newFormula.replace(getVariableName(i), getVariableName(i - 1));
      }
      setVariablesValues(newVariables);
      setFormula(newFormula);
      setHasUnsavedChanges(true);
    },
    [variablesValues, formula]
  );

  const handleSave = useCallback(
    async (saveName?: string): Promise<void> => {
      if (!name && !saveName) {
        setOpenNameDialog(true);
        return;
      }

      const metricData: CloudAnalyticsModelMetricModel = {
        customer: customer.ref,
        name: saveName ?? name,
        description,
        owner: currentUser.email,
        type: AnalyticsResourceType.CUSTOM,
        variables,
        formula,
        format,
        timeCreated: metric ? metric.data.timeCreated : (serverTimestamp() as FirestoreTimestamp),
        timeModified: serverTimestamp() as FirestoreTimestamp,
      };

      try {
        const savedMetricId = await saveMetricData(metricData, metric?.snapshot.id);
        setHasUnsavedChanges(false);
        setOpenNameDialog(false);
        successSnackbar(metricTxt.METRIC_SAVE_SUCCESS);
        mixpanel.track(`analytics.metrics.${metric ? "edit" : "create"}`, { id: savedMetricId });

        if (savedMetricId !== metric?.snapshot.id) {
          history.push(routeMatchURL.replace("create", savedMetricId));
        }
      } catch (e) {
        consoleErrorWithSentry(e);
        setOpenNameDialog(false);
        errorSnackbar(metricTxt.METRIC_SAVE_ERROR);
      }
    },
    [
      currentUser.email,
      description,
      errorSnackbar,
      format,
      formula,
      history,
      metric,
      name,
      customer.ref,
      routeMatchURL,
      successSnackbar,
      variables,
    ]
  );

  const handleSaveName = useCallback(
    async (newName: string) => {
      setName(newName);
      await handleSave(newName);
    },
    [handleSave]
  );

  const getVariableValue = useCallback(
    (v: MetricVariableValue): WithFirebaseModel<MetricVariable> => {
      const newValueRefID = getAttributionRefIdByName(v.attribution, attributions);
      const newValueRef = getCollection(DashboardModel)
        .doc("google-cloud-reports")
        .collection("attributions")
        .doc(newValueRefID);
      return {
        metric: Number(v.metric),
        attribution: newValueRef,
        extendedMetric: v.extendedMetric,
      };
    },
    [attributions]
  );

  useEffect(() => {
    if (validationComplete && previewDataChanged && runPreview) {
      setVariablesToPreview(
        variablesValues.filter((v, i) => formula.includes(getVariableName(i))).map((v) => getVariableValue(v))
      );
    }
  }, [validationComplete, previewDataChanged, runPreview, variablesValues, formula, getVariableValue]);

  useEffect(() => {
    if (validationComplete && runPreview) {
      setPreviewDataReady(true);
    }
  }, [variablesToPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    if (validationComplete && validMetric) {
      setVariables(variablesValues.map((v) => getVariableValue(v)));
    }
  }, [validationComplete, validMetric, variablesValues]);

  useEffect(() => {
    if (validationComplete && validMetric) {
      setMetricDataReady(true);
    }
  }, [variables]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormulaErrorText = () => {
    if (formulaTouched && !validFormula) {
      let missingVars = "";
      let i = 0;
      for (i; i < variablesValues.length; i++) {
        if (variablesValues[i].metric && variablesValues[i].attribution) {
          const name = getVariableName(i);
          if (!formula.includes(name)) {
            if (missingVars !== "") {
              missingVars += ", ";
            }
            missingVars += name;
          }
        }
      }
      if (missingVars !== "") {
        if (missingVars.indexOf(",") > 0) {
          return metricText.FORMULA_MISSING_ATTRS(missingVars);
        } else {
          return metricText.FORMULA_MISSING_ATTR(missingVars);
        }
      }
      return metricText.INVALID_FORMULA;
    }
    return " ";
  };

  const formatter = useMetricFormatter(Aggregator.TOTAL, currency, metric?.data ?? null);

  const allAttributionOptions = useMemo(() => attributions.map((a) => a.data.name).sort(), [attributions]);

  const attributionOptions = useMemo(() => attributions.map((a) => a.data.name).sort(), [attributions]);

  const parentUrl = useMemo(() => `/customers/${customer.id}/analytics/metrics`, [customer.id]);
  const handleBack = useGotoParentUrl(parentUrl);

  const getRowAttributions = (i: number) => {
    switch (variablesValues.length) {
      case 0:
        return allAttributionOptions;
      case 1:
        return i === 0 ? allAttributionOptions : attributionOptions;
      default: {
        if (attrDefinedIdxs.length === 0) {
          return allAttributionOptions;
        }
        if (attrDefinedIdxs.length === 1) {
          return i === attrDefinedIdxs[0] ? allAttributionOptions : attributionOptions;
        }
        return attributionOptions;
      }
    }
  };

  const onChangeTitleValues = (values: { title?: string; description?: string }) => {
    if (values.title !== undefined) {
      setName(values.title);
      setHasUnsavedChanges(true);
    } else if (values.description !== undefined) {
      setDescription(values.description);
      setHasUnsavedChanges(true);
    }
  };

  return (
    <>
      <DoitConsoleTitle pageName="Metrics" pageLevel1={name || metricText.NEW_METRIC} />
      <CardHeader
        sx={{
          "& .MuiCardHeader-content": {
            minWidth: 0,
          },
          "& .MuiCardHeader-action": {
            mt: "auto",
            mb: "auto",
          },
        }}
        disableTypography={true}
        title={
          <Title
            data-cy="metrics-name"
            values={{ title: name, description }}
            onChange={onChangeTitleValues}
            handleBack={handleBack}
            placeholder={metricTxt.METRIC_NAME}
            disabled={isViewMode}
          />
        }
        action={
          <>
            {!draft && (
              <Tooltip title={metricTxt.CLONE_METRIC}>
                <IconButton data-testid="metricClone" className={classes.copy} onClick={handleCopyMetric} size="small">
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            )}
            <SaveComponent
              disabled={isViewMode || !hasUnsavedChanges || !validMetric || !metricDataReady}
              onSave={handleSave}
            />
          </>
        }
      />
      <CardContent className={classes.cardContent} ref={contentRef}>
        <Grid container spacing={1}>
          {!isViewMode && (
            <Grid size={12}>
              <LearnMoreAlert
                text={metricText.LEARN_MORE_ALERT}
                url={helpURLs.CLOUD_ANALYTICS_METRICS}
                onClose={() => {
                  setAlertBoxHidden(true);
                }}
              />
            </Grid>
          )}
        </Grid>
        <>
          {variablesValues?.map((v, i) => (
            <MetricVariableRow
              key={`filter-row-${i}`}
              idx={i}
              variable={v}
              attributions={getRowAttributions(i)}
              validateVariables={formulaTouched}
              disabled={isViewMode}
              showDelete={
                metric?.data.type === AnalyticsResourceType.CUSTOM &&
                variablesValues?.length > 0 &&
                i < variablesValues?.length
              }
              allowDelete={!formula.includes(getVariableName(i))}
              handleChangeMetric={handleChangeMetric}
              handleChangeAttribution={handleChangeAttribution}
              handleDeleteAttribution={handleDeleteAttribution}
              smDown={smDown}
            />
          ))}
          {(draft || metric?.data.type === AnalyticsResourceType.CUSTOM) &&
            variablesValues.length < MAX_METRIC_ATTR && (
              <MetricVariableRow
                key={`filter-row-${variablesValues?.length}`}
                idx={variablesValues.length}
                variable={emptyVar}
                attributions={getRowAttributions(variablesValues.length)}
                validateVariables={formulaTouched}
                disabled={isViewMode}
                showDelete={false}
                allowDelete={false}
                handleChangeMetric={handleChangeMetric}
                handleChangeAttribution={handleChangeAttribution}
                handleDeleteAttribution={handleDeleteAttribution}
                smDown={smDown}
              />
            )}
          <Grid
            container
            className={classes.formulaRow}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid
              className={classes.flex}
              size={{
                lg: 4,
                md: 6,
                sm: 9,
                xs: 9,
              }}
            >
              <TextField
                data-cy={cloudAnalytics.metrics.metric.formula}
                size="small"
                label={metricText.FORMULA}
                error={formulaTouched && !validFormula}
                value={formula}
                {...textFieldBaseProps}
                onChange={(event) => {
                  setValidationComplete(false);
                  setFormula(event.target.value.toUpperCase().replace(/\s+$/g, " "));
                  setPreviewDataChanged(true);
                  setFormulaTouched(true);
                  setHasUnsavedChanges(true);
                }}
                helperText={getFormulaErrorText()}
                disabled={isViewMode}
                className={clsx(classes.formula, classes.floatRight)}
                slotProps={{
                  inputLabel: {
                    classes: inputLabelsStyles(),
                  },
                }}
              >
                {formula}
              </TextField>
            </Grid>
            <Grid className={!smDown ? classes.format : classes.formatSmall}>
              <TextField
                size="small"
                value={format}
                {...textFieldBaseProps}
                {...textFieldSelectProps}
                onChange={(event) => {
                  setHasUnsavedChanges(true);
                  setFormat(event.target.value as unknown as number);
                }}
                disabled={isViewMode}
              >
                {CalcMetricFormatOptions.map((fo) => (
                  <MenuItem value={fo.value} key={fo.value}>
                    <Typography variant="subtitle2">{fo.label}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </>
      </CardContent>
      <MetricPreview
        startPreview={runPreview && previewDataReady}
        metric={metric}
        variables={variablesToPreview}
        formula={formula}
        format={format}
        attributions={attributions}
        formatter={formatter}
        contentRef={contentRef}
        alertBoxHidden={alertBoxHidden}
        smDown={smDown}
      />
      {openNameDialog && (
        <SaveDialog
          title={metricTxt.NAME_BEFORE_SAVING}
          open={openNameDialog}
          onClose={() => {
            setOpenNameDialog(false);
          }}
          onSave={handleSaveName}
          textFieldProps={{ label: metricTxt.METRIC_NAME }}
        />
      )}
    </>
  );
};
