import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";

import { reportTxt } from "../../../../assets/texts/CloudAnalytics";

type Props = {
  reportCacheRefreshedAt: Date | undefined;
};

const CacheLastUpdated = ({ reportCacheRefreshedAt }: Props) => {
  const lastUpdated = reportCacheRefreshedAt ? DateTime.fromJSDate(reportCacheRefreshedAt) : undefined;

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      sx={{
        columnGap: 1,
        mt: 0.2,
      }}
    >
      <Chip
        variant="status"
        label={reportTxt.CACHED}
        color="default"
        icon={
          <Tooltip title={reportTxt.CACHED_REPORT_TOOLTIP} arrow>
            <InfoOutlinedIcon />
          </Tooltip>
        }
      />
      {lastUpdated?.isValid && (
        <>
          <Typography
            noWrap
            variant="body2"
            sx={{
              color: "text.secondary",
              fontWeight: 400,
              overflow: "initial",
              display: "flex",
              alignItems: "center",
              columnGap: 0.5,
            }}
          >
            {`Last updated: `}
            <Tooltip title={lastUpdated.toFormat("dd LLL yyyy, HH:mm")} arrow>
              <Typography
                sx={{
                  textDecoration: "underline",
                  textDecorationStyle: "dotted",
                }}
              >
                {lastUpdated.toRelative()}
              </Typography>
            </Tooltip>
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default CacheLastUpdated;
