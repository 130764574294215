import { Box, Typography } from "@mui/material";

import datadogLogo from "../../../../../assets/datadog-logo.png";
import datadogLogoDarkMode from "../../../../../assets/datadog-logo-white.png";
import { useDarkThemeCheck } from "../../../../../Components/hooks/useDarkThemeCheck";

export const cyIds = {
  header: "datadog-header",
  logo: "datadog-logo",
};

const DatadogAssetsTabHeader = () => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Typography variant="h1" data-cy={cyIds.header} sx={{ pt: 2, pb: 2.5 }}>
      <Box
        component="img"
        src={isDarkMode ? datadogLogoDarkMode : datadogLogo}
        sx={{ height: "0.8em", width: "auto", mr: 1 }}
        data-cy={cyIds.logo}
        aria-hidden
        alt-text="Datadog logo"
      />
      Datadog
    </Typography>
  );
};

export default DatadogAssetsTabHeader;
