export const ratingText = {
  DESCRIPTION: "Was this anomaly detected correctly?",
};

export const anomalySidePanelText = {
  BTN: "Additional info",
  TOOLTIP_DISABLE_TEXT: "Anomaly details are only available for anomalies detected after ",
};

export const anomalyDetailsText = {
  OPEN_IN_REPORT: "Open in Reports",
  OPEN_IN_REPORT_DISABLED_TOOLTIP:
    "Open in Cloud Analytics Reports does not support real-time usage data at this time. Review the list of contributory resources or explore Additional info for your investigation.",
};
