import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { AnalyticsResourceType, UserNotification } from "@doitintl/cmp-models";
import { Box, Button, CardHeader, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { globalText, reportText } from "../../../../assets/texts";
import { reportTxt, templateCreateText } from "../../../../assets/texts/CloudAnalytics";
import useReportSaver from "../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useGotoParentUrl } from "../../../../Components/hooks/useGotoParentUrl";
import { useReportSubscriptions } from "../../../../Components/NotificationSubscription/db";
import { SubscribeButton } from "../../../../Components/NotificationSubscription/SubscribeButton";
import SaveAsComponent from "../../../../Components/SaveComponents/SaveAsComponent";
import SaveComponent from "../../../../Components/SaveComponents/SaveComponent";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import Title from "../../../../Components/Title/Title";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { useDashboardsContext } from "../../../../Context/useDashboardsContext";
import { CREATE_TEMPLATE_QUERY_PARAM, EDIT_TEMPLATE_QUERY_PARAM } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { useFullScreen } from "../../../../utils/dialog";
import { useQuery } from "../../../../utils/useQuery";
import { useCloudAnalyticsContext } from "../../CloudAnalyticsContext";
import {
  CreateTemplateDataKind,
  useCreateTemplateContext,
  useReportConfig,
  useReportContext,
  useReportSaverContext,
  useReportTemplateContext,
} from "../../Context";
import { useStyles } from "../../ReportStyles";
import { type CloudAnalyticsHistoryState } from "../../types";
import { isEditor, isOwner } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ActionButtons from "./ActionButtons";
import CacheLastUpdated from "./CacheLastUpdated";
import AddToReports from "./templateButtons/AddToReports";
import SubmitForApproval from "./templateButtons/SubmitForApproval";
import TemplateButtons from "./templateButtons/TemplateButtons";

type Props = {
  removeReportFromCache: () => Promise<void>;
  handleRevert: () => void;
  isCachedReport: boolean;
  popoverAnchorEl: any;
  reportCacheRefreshedAt?: Date;
  reportError?: string;
  saveButtonDisabled: boolean;
  setGoogleDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleting: Dispatch<SetStateAction<boolean>>;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  setRightPanelOpen: Dispatch<SetStateAction<boolean>>;
  showRemoveFromCache: boolean;
  wasRun: boolean;
};

const maxDescriptionLength = 1000;
const maxTitleLength = 64;

const ReportHeader = ({
  removeReportFromCache,
  handleRevert,
  isCachedReport,
  popoverAnchorEl,
  reportCacheRefreshedAt,
  reportError,
  saveButtonDisabled,
  setGoogleDialogOpen,
  setIsDeleting,
  setPopoverAnchorEl,
  setRightPanelOpen,
  showRemoveFromCache,
  wasRun,
}: Props) => {
  const location = useLocation<{ prevPage?: string; dashboardId?: string }>();
  const query = useQuery();
  const dashboardIdRef = location.state?.dashboardId ?? query.get("dashboardId");
  const isEntitledAnalyticsReports = useIsFeatureEntitled("analytics:reports");
  const classes: any = useStyles();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const { customer } = useCustomerContext();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { isMobile: smDown } = useFullScreen();
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { handleSaveDefaultReportConfig } = useCloudAnalyticsContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { report } = useReportContext();
  const isCurrentUserEditor = !!(report?.data && isEditor(currentUser.email, report.data));
  const isCurrentUserOwner = !!(report?.data && isOwner(currentUser.email, report.data));
  const { onSaveReportHeader, prepareConfigFromDimension } = useReportSaver();
  const {
    reportSaver: { saved, hasUnsavedChanges },
  } = useReportSaverContext();
  const { dashboards, addWidgetToDashboard } = useDashboardsContext();
  const { createTemplateData, dispatchCreateTemplateData } = useCreateTemplateContext();
  const { isFavoriteReportTemplate, reportTemplateId } = useReportTemplateContext();
  const subscriptions = useReportSubscriptions({ reportRef: report.snapshot.ref });

  const [title, setTitle] = useState("");
  const [addedToDashboard, setAddedToDashboard] = useState(false);

  const handleSaveDefaultConfig = useCallback(async () => {
    try {
      await handleSaveDefaultReportConfig(prepareConfigFromDimension());

      showSharedSnackbar({
        message: reportText.SAVE_DEFAULT_CONFIG_SUCCESS,
        variant: "success",
        autoHideDuration: 5000,
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [handleSaveDefaultReportConfig, prepareConfigFromDimension, showSharedSnackbar]);

  const addReportToDashboard = useCallback(
    async (dashboardId: string, reportId: string) => {
      try {
        const reportName = `cloudReports::${customer.id}_${reportId}`;
        await addWidgetToDashboard(dashboardId, reportName, { width: 4 });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [addWidgetToDashboard, customer.id]
  );

  const handleSave = useCallback(
    async (name?: string) => {
      const reportId = await onSaveReportHeader(name, false);

      if (dashboardIdRef && !addedToDashboard) {
        addReportToDashboard(dashboardIdRef, reportId);
        setAddedToDashboard(true);
      }
    },
    [onSaveReportHeader, dashboardIdRef, addedToDashboard, addReportToDashboard]
  );

  const handleSaveAs = useCallback(
    async (name: string) => {
      await onSaveReportHeader(name, true);
    },
    [onSaveReportHeader]
  );

  let saveButtonTooltipText = "";
  if (!isCurrentUserEditor && report?.data.type === AnalyticsResourceType.CUSTOM) {
    saveButtonTooltipText = reportText.ONLY_OWNER;
  }

  if (!isCurrentUserEditor && report?.data.type === AnalyticsResourceType.PRESET) {
    saveButtonTooltipText = reportText.PRESET_NOEDIT;
  }

  if (isCurrentUserEditor && saved && !hasUnsavedChanges) {
    saveButtonTooltipText = reportText.ALL_CHANGES_ARE_SAVED;
  }

  const handleNavigateToDashboard = useCallback(() => {
    if (dashboardIdRef) {
      const foundDashboard = dashboards.find((d) => d.id === dashboardIdRef);
      if (foundDashboard) {
        history.push(`/customers/${customer.id}/dashboards/${foundDashboard.name}`);
      }
    }
  }, [customer.id, dashboardIdRef, dashboards, history]);

  const handleTitleChange = (values: { title?: string; description?: string }) => {
    if (values.title) {
      setTitle(values.title);
    } else if (values.description || values.description === "") {
      dispatchReportConfig({ payload: { description: values.description } });
    }
  };

  const handleBlur = () => {
    if (title) {
      dispatchReportConfig({ payload: { name: title } });
    }
  };

  useEffect(() => {
    if (isDoitEmployee && query.has(CREATE_TEMPLATE_QUERY_PARAM)) {
      dispatchCreateTemplateData({ payload: { isCreateTemplateOpen: true } });
    }
  }, [query, dispatchCreateTemplateData, isDoitEmployee]);

  const parentUrl = useMemo(() => `/customers/${customer.id}/analytics/reports`, [customer.id]);
  const handleBack = useGotoParentUrl(parentUrl);

  return (
    <Box
      sx={{
        borderBottom: "1px solid",
        borderBottomColor: "general.divider",
      }}
    >
      <CardHeader
        className={classes.cardHeader}
        sx={{
          py: 2,
          pl: 2.5,
          pr: 3,
        }}
        title={
          <Title
            data-cy="report-name"
            values={{
              title: reportConfig.name,
              description: reportConfig.description,
            }}
            onChange={handleTitleChange}
            onBlur={handleBlur}
            handleBack={handleBack}
            placeholder={reportText.REPORT_NAME}
            disabled={!isCurrentUserEditor || createTemplateData.isCreateTemplateOpen}
            maxTitleLength={maxTitleLength}
            maxDescriptionLength={maxDescriptionLength}
            showTemplateChip={!!reportTemplateId}
          />
        }
        action={
          !smDown &&
          report && (
            <Box
              sx={{
                mr: 1.5,
              }}
            >
              {isDoitEmployee && !reportTemplateId && createTemplateData.isCreateTemplateOpen ? (
                <Stack
                  direction="row"
                  sx={{
                    columnGap: 1.5,
                  }}
                >
                  <Button
                    data-cy="cancel-template-creation"
                    onClick={() => {
                      dispatchCreateTemplateData({ type: CreateTemplateDataKind.ON_CANCEL, payload: {} });
                    }}
                    variant="outlined"
                  >
                    {globalText.CANCEL}
                  </Button>
                  <SubmitForApproval
                    disabled={!createTemplateData.isValid}
                    tooltip={!createTemplateData.isValid ? templateCreateText.FILL_REQUIRED_FIELDS : ""}
                  />
                </Stack>
              ) : isDoitEmployee && reportTemplateId && query.has(EDIT_TEMPLATE_QUERY_PARAM) ? (
                <TemplateButtons />
              ) : (
                <Grid
                  container
                  spacing={2}
                  wrap="nowrap"
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {isCachedReport && <CacheLastUpdated reportCacheRefreshedAt={reportCacheRefreshedAt} />}
                  <ActionButtons
                    removeReportFromCache={removeReportFromCache}
                    disableShare={!reportConfig.name}
                    handleRevert={handleRevert}
                    handleSaveDefaultConfig={handleSaveDefaultConfig}
                    hasUnsavedChanges={hasUnsavedChanges}
                    isCurrentUserOwner={isCurrentUserOwner}
                    setIsDeleting={setIsDeleting}
                    popoverAnchorEl={popoverAnchorEl}
                    report={report}
                    reportError={reportError}
                    setRightPanelOpen={setRightPanelOpen}
                    setGoogleDialogOpen={setGoogleDialogOpen}
                    setPopoverAnchorEl={setPopoverAnchorEl}
                    showRemoveFromCache={showRemoveFromCache}
                    wasRun={wasRun}
                  />
                  <Grid container spacing={1} wrap="nowrap">
                    {!report.data.draft && report?.data?.type === AnalyticsResourceType.CUSTOM && (
                      <Grid sx={{ whiteSpace: "nowrap" }}>
                        <SubscribeButton
                          subscriptions={subscriptions}
                          subscriptionType={UserNotification.ReportSubscription}
                          subscriptionParent={{ ...report.data, ref: report.snapshot.ref, id: report.snapshot.id }}
                        />
                      </Grid>
                    )}
                    {!report.data.draft && (
                      <Grid sx={{ whiteSpace: "nowrap" }}>
                        <SaveAsComponent
                          variant="outlined"
                          disabled={!isEntitledAnalyticsReports && !isDoitEmployee}
                          onSaveAs={handleSaveAs}
                          saveAsTitle={reportText.SAVE_NEW}
                          textFieldProps={{
                            helperText: reportText.SAVE_AS_HELPER_TEXT,
                            label: reportText.SAVE_AS_LABEL,
                          }}
                          successMessage={reportText.SUCCESSFULLY_SAVED}
                        />
                      </Grid>
                    )}
                    <Grid>
                      {reportTemplateId ? (
                        <AddToReports
                          disabled={!isFavoriteReportTemplate ? hasUnsavedChanges : false}
                          tooltip={
                            !isFavoriteReportTemplate && hasUnsavedChanges
                              ? reportTxt.ADD_TO_MY_REPORTS_CUSTOMER_TOOLTIP
                              : ""
                          }
                        />
                      ) : (
                        <SaveComponent
                          disabled={saveButtonDisabled}
                          onSave={handleSave}
                          tooltip={saveButtonTooltipText}
                          successMessage={
                            dashboardIdRef && !addedToDashboard ? (
                              <>
                                Report successfully saved and will appear on your dashboard soon.
                                <Button sx={{ ml: 2 }} variant="contained" onClick={handleNavigateToDashboard}>
                                  View dashboard
                                </Button>
                              </>
                            ) : (
                              "Report successfully saved"
                            )
                          }
                          showDialog={!reportConfig.name}
                          saveDialogLabel={reportText.REPORT_NAME}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Box>
          )
        }
      />
    </Box>
  );
};

export default ReportHeader;
