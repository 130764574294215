import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { cyIds } from "./NotificationsList";

export function InAppNotificationsLoading() {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
      }}
      data-cy={cyIds.loading}
    >
      <CircularProgress />
      <Typography variant="body2" align="center" color="text.primary" sx={{ mt: 2 }}>
        Loading notifications
      </Typography>
    </Stack>
  );
}
