import { useMemo } from "react";

import { type AlgoliaConfig } from "@doitintl/cmp-models";
import { useQuery } from "@tanstack/react-query";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import type { AxiosError } from "axios";

import { useApiContext } from "../api/context";
import { isProduction } from "../constants";
import { consoleErrorWithSentry } from "../utils";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import type { AlgoliaIndexType } from "../Components/AlgoliaSearch/types";

const hooksParamsFromConfig = (config: AlgoliaConfig | undefined) => {
  if (config) {
    try {
      if (!isProduction && config.devAppId && config.devSearchKey) {
        return {
          searchClient: algoliasearch(config.devAppId, config.devSearchKey),
          restrictedIndices: config.restrictedIndices as AlgoliaIndexType[],
        };
      }

      return {
        searchClient: algoliasearch(config.appId, config.searchKey),
        restrictedIndices: config.restrictedIndices as AlgoliaIndexType[],
      } as const;
    } catch (e) {
      consoleErrorWithSentry(e);
    }
  }

  return {};
};

const useAlgoliaConfig = (enabled = true) => {
  const api = useApiContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext();

  const { data: userAlgoliaConfig } = useQuery<AlgoliaConfig, AxiosError>(
    [customer?.id ?? null, "algoliaConfig"],
    async () => {
      const response = await api.get<AlgoliaConfig>("/auth/algolia/config");

      return response.data;
    },
    // The key is valid for 24h
    {
      staleTime: 1000 * 60 * 60 * 23,
      enabled: enabled && isDoitEmployee !== undefined && (isDoitEmployee || Boolean(customer?.id)),
    }
  );

  return useMemo(() => hooksParamsFromConfig(userAlgoliaConfig), [userAlgoliaConfig]);
};

export const AlgoliaHooks = {
  useAlgoliaConfig,
};
