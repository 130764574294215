import {
  type ContractModel,
  ContractTypeDoitCloudNavigator,
  ContractTypeDoitCloudSolve,
  ContractTypeGoogleCloudStandalone,
  type DiscountPeriod,
  type ProductTypes,
  type VendorContractModel,
} from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { type TierWithRef } from "../../../Context/TierProvider";
import { formatDecimalNumber, sanitizeDate } from "../../../utils/common";
import { texts } from "./texts";
import {
  type FormattedContract,
  type FormattedContractData,
  type FormattedVendorContract,
  type FormattedVendorContractData,
} from "./types";

const now = DateTime.utc().startOf("day");

const vendorContractLaunchDate = new Date("2023-08-30");

export const getProductTypeLabel = (productTypes, type) => {
  switch (type) {
    case ContractTypeDoitCloudNavigator:
      return "DoiT Cloud Navigator subscription";
    case ContractTypeDoitCloudSolve:
      return "DoiT Cloud Solve subscription";
  }

  return productTypes?.find((pt) => pt.value === type)?.label || type;
};

export const getFlexsaveSpecificDiscount = (contract) =>
  contract.data.type === ContractTypeGoogleCloudStandalone &&
  contract.data.properties?.gcpFlexSaveOverwrite !== undefined
    ? formatDecimalNumber(100 - contract.data.properties?.gcpFlexSaveOverwrite)
    : null;

const getContractActiveDiscount = (contract: { data: { discountPeriods?: DiscountPeriod[] } }): number => {
  const now = new Date();
  for (const period of contract.data.discountPeriods ?? []) {
    if (period.startDate.toDate() <= now && (!period.endDate || period.endDate.toDate() > now)) {
      return period.discount;
    }
  }

  return 0;
};

const formatVendorContract = ({
  vendorContract,
  vendorContractId,
  entities,
}: {
  vendorContract: VendorContractModel;
  vendorContractId: string;
  entities: Entity[];
}): FormattedVendorContract => {
  const contract: FormattedVendorContract = {
    data: vendorContract as FormattedVendorContractData,
    id: vendorContractId,
  };
  const entity = (entities ?? []).find((e) => contract.data.entity?.id === e.id);
  contract.data._entity = entity ? `${entity.priorityId} -  ${entity.name}` : texts.UNASSIGNED;
  contract.data._startDate = sanitizeDate(DateTime.fromJSDate(contract.data.startDate.toDate()));
  contract.data._endDate = null;
  contract.data._activeDiscount = getContractActiveDiscount(contract);

  if (contract.data.endDate) {
    contract.data._endDate = sanitizeDate(DateTime.fromJSDate(contract.data.endDate.toDate()));
  }

  switch (true) {
    case contract.data.active && !contract.data.terminated:
      contract.data._status = "active";
      if (contract.data._endDate && now > contract.data._endDate) {
        contract.data._status = "expired";
      }
      break;

    case !contract.data.active && !contract.data.terminated && contract.data._startDate > now:
      contract.data._status = "inactive";
      break;

    case !contract.data.active && !contract.data.terminated:
      contract.data._status = "canceled";
      break;

    case !contract.data.active && contract.data.terminated:
      contract.data._status = "terminated";
      break;
  }

  contract.data._commitmentValue = (contract.data?.commitmentPeriods ?? []).reduce((memo, cp) => memo + cp.value, 0);
  return contract;
};

export const getCustomerCommitmentText = (contract: FormattedContract): string => {
  if (contract.data.type === ContractTypeDoitCloudNavigator || contract.data.type === ContractTypeDoitCloudSolve) {
    return contract.data.isCommitment ? texts.COMMITMENT : texts.ON_DEMAND;
  }
  if (contract.data.isCommitment) {
    return texts.HARD_COMMIT;
  }
  if (contract.data.isSoftCommitment) {
    return texts.SOFT_COMMIT;
  }
  return texts.ON_DEMAND;
};

export const getContractEndDate = (contract: FormattedContract) => {
  const {
    data: { _endDate, _endDateSuffix, endDate },
  } = contract;
  if (_endDate) {
    return _endDate.toLocaleString(DateTime.DATE_MED) + _endDateSuffix;
  }
  if (endDate) {
    return DateTime.fromJSDate(endDate.toDate()).toLocaleString(DateTime.DATE_MED);
  }
  return texts.NA;
};

export const formatContract = ({
  contract: contractData,
  vendorContract,
  vendorContractId,
  id,
  entities,
  productTypes,
  tiers,
}: {
  contract: ContractModel;
  id: string;
  vendorContract?: VendorContractModel;
  vendorContractId?: string;
  entities: Entity[];
  productTypes?: ProductTypes;
  tiers?: TierWithRef[];
}): FormattedContract => {
  const contract: FormattedContract = { data: contractData as FormattedContractData, id };
  const entity = (entities ?? []).find((e) => contract.data.entity?.id === e.id);
  contract.data._entity = entity ? `${entity.priorityId} -  ${entity.name}` : texts.UNASSIGNED;
  contract.data._startDate = sanitizeDate(DateTime.fromJSDate(contract.data.startDate?.toDate()));
  contract.data._endDate = null;
  contract.data._endDateSuffix = "";
  contract.data._product = getProductTypeLabel(productTypes, contract.data.type);
  contract.data._customerCommitmentType = getCustomerCommitmentText(contract);
  contract.data._tier = (tiers ?? []).find((t) => t.ref.id === contract.data.tier?.id)?.displayName;
  contract.data._activeDiscount = getContractActiveDiscount(contract);

  if (contract.data.isCommitment || contract.data.isSoftCommitment) {
    contract.data._endDate = contract.data.endDate
      ? sanitizeDate(DateTime.fromJSDate(contract.data.endDate.toDate()))
      : null;
  } else if (contract.data.discountEndDate) {
    contract.data._endDate = sanitizeDate(DateTime.fromJSDate(contract.data.discountEndDate.toDate()));
    contract.data._endDateSuffix = " (discount)";
  }

  switch (true) {
    case contract.data.active && !contract.data.terminated:
      contract.data._status = "active";
      if (
        (contract.data.isCommitment || contract.data.isSoftCommitment) &&
        contract.data._endDate &&
        now > contract.data._endDate
      ) {
        contract.data._status = "expired";
      }

      break;

    case !contract.data.active && !contract.data.terminated && contract.data._startDate > now:
      contract.data._status = "inactive";
      break;

    case !contract.data.active && !contract.data.terminated:
      contract.data._status = "canceled";
      break;

    case !contract.data.active && contract.data.terminated:
      contract.data._status = "terminated";
      break;
  }

  contract.data._commitmentValue = contract.data.isCommitment
    ? (contract.data?.commitmentPeriods ?? []).reduce((memo, cp) => memo + cp.value, 0)
    : 0;

  if (vendorContract && vendorContractId) {
    contract.data.vendorContractData = formatVendorContract({ vendorContract, vendorContractId, entities });
  }

  return contract;
};

const isOldContract = (contract: FormattedContract) =>
  contract.data.timeCreated?.toDate().getTime() < vendorContractLaunchDate.getTime();

export const getVendorCommitmentText = (contract: FormattedContract): string => {
  if (!contract.data.isCommitment) {
    return "";
  }

  const vendorContract = contract.data.vendorContractData;

  if (vendorContract?.data.commitmentType) {
    return texts.VENDOR_COMMITMENT;
  }

  return isOldContract(contract) ? texts.NO_VENDOR_COMMITMENT_DATA : texts.NO_VENDOR_COMMITMENT;
};

export const getVendorContractTypeText = (contract: FormattedContract): string => {
  if (!contract.data.isCommitment) {
    return "";
  }

  const vendorContract = contract.data.vendorContractData;

  if (vendorContract?.data.commitmentType) {
    return vendorContract?.data.commitmentType;
  }

  return isOldContract(contract) ? texts.UNKNOWN : texts.DOIT_ONLY;
};
