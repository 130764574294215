import {
  AnalyticsDataSource,
  type BudgetConfig,
  type Collaborator,
  type CurrencyCode,
  CurrencyCodes,
  Metric,
  Roles,
  TimeInterval,
} from "@doitintl/cmp-models";
import { DateTime } from "luxon";
import type { WithFirebaseModel } from "@doitintl/models-firestore";

import { useDefaultCurrency } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Customer, type DraftBudgetData } from "../../../types";
import { TimestampFromDate } from "../../../utils/firebase";
import { BudgetTypes } from "../utilities";
import { NoReplyEmail } from "./shared";

const newAlert = ({ percentage }) => ({
  amount: 0,
  percentage,
  forecastedDate: null,
  triggered: false,
});

export const defaultBudgetConfig = (
  customer: Customer,
  defaultCurrency: CurrencyCode
): WithFirebaseModel<BudgetConfig> => ({
  amount: 1000,
  originalAmount: 0,
  timeInterval: TimeInterval.MONTH,
  type: BudgetTypes.RECURRING,
  metric: Metric.COST,
  currency: customer.settings?.currency || defaultCurrency || CurrencyCodes.USD,
  startPeriod: TimestampFromDate(DateTime.utc().startOf(TimeInterval.MONTH).toJSDate()),
  alerts: [newAlert({ percentage: 50 }), newAlert({ percentage: 85 }), newAlert({ percentage: 100 })],
  scope: [],
  growthPerPeriod: 0,
  usePrevSpend: false,
  allowGrowth: false,
  dataSource: AnalyticsDataSource.BILLING,
});

export const useNewBudgetData = (): DraftBudgetData => {
  const defaultCurrency = useDefaultCurrency();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();

  const email = currentUser.email;

  const newCollaborator: Collaborator = {
    email,
    role: Roles.OWNER,
  };

  return {
    name: "",
    description: "",
    config: defaultBudgetConfig(customer, defaultCurrency),
    public: null,
    collaborators: [newCollaborator],
    recipients: customer.presentationMode?.isPredefined ? [NoReplyEmail] : [email],
    recipientsSlackChannels: [],
  };
};
