import {
  AccountManagerModel,
  AppModel,
  type AssetModel,
  type CommitmentPeriod,
  type ContractCredits as ContractCreditsModel,
  type ContractFile,
  type ContractModel,
  type ContractModelType,
  ContractTypeAws,
  ContractTypeAwsStandalone,
  ContractTypeDoitCloudIntelligence,
  ContractTypeDoitCloudNavigator,
  ContractTypeDoitCloudSolve,
  ContractTypeDoitOneTimeServiceFee,
  ContractTypeGoogleCloud,
  ContractTypeGoogleCloudPartnerLedPremiumSupport,
  ContractTypeGoogleCloudStandalone,
  ContractTypeGSuite,
  ContractTypeLooker,
  ContractTypeMicrosoftAzure,
  ContractTypeOffice365,
  ContractTypeSaasConsoleAws,
  ContractTypeSaasConsoleGcp,
  type CurrencyCode,
  type DiscountPeriod,
  EntityModel,
  type PointOfSaleType,
  type SubscriptionPaymentTerm,
  TierModel,
  TierPackageTypes,
  type VendorCommitmentType,
  type VendorContractModel,
  type VendorType,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";

import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { type Asset, type Assets, type Customer } from "../../../types";
import { formatDecimalNumber, getCurrencySymbol, sanitizeDate } from "../../../utils/common";
import { FirestoreTimestamp, firestoreTimestamp, serverTimestamp } from "../../../utils/firebase";
import { texts } from "../ContractsList/texts";
import { type FormattedContract } from "../ContractsList/types";
import { getCustomerCommitmentText } from "../ContractsList/utils";
import { canEditNext10Contract, isNext10Contract } from "../utils";
import { chargePerTermLabelMap } from "./const";
import { defaultVendorContractValues, lookerSkuDefaultValues } from "./ContractsFormContext";
import { creditsByType } from "./Steps/components/Credits";
import { skuStartDate } from "./Steps/LookerSubscriptionStep/LookerCalculator";
import {
  type CommitmentPeriodType,
  type ContractCredits,
  type ContractRequestPayload,
  type ContractStateType,
  type ContractType,
  type DiscountPeriodType,
  type FileData,
  type TransformedTierModel,
  type VendorContract,
} from "./types";

const formatCommitmentPeriodsFromFs = (periods): CommitmentPeriodType[] =>
  periods.map((period) => ({
    value: period.value,
    discount: period.discount ?? 0,
    startDate: sanitizeDate(DateTime.fromJSDate(period.startDate.toDate())),
    endDate: sanitizeDate(DateTime.fromJSDate(period.endDate.toDate())),
    errors: {},
  }));

const formatDiscountPeriodsFromFs = (periods, isCommitment): DiscountPeriodType[] =>
  periods.map((period) => ({
    startDate: sanitizeDate(DateTime.fromJSDate(period.startDate.toDate())),
    endDate: period.endDate ? sanitizeDate(DateTime.fromJSDate(period.endDate.toDate())) : null,
    discount: period.discount,
    hasNoEndDate: !isCommitment && !period.endDate,
  }));

export const formatContractFromFs = (
  contract: ContractModel,
  vendorContract?: WithFirebaseModel<VendorContractModel>,
  vendorContractId?: string
) => {
  const commitmentPeriods = contract.commitmentPeriods ? formatCommitmentPeriodsFromFs(contract.commitmentPeriods) : [];
  const discountPeriods = contract.discountPeriods
    ? formatDiscountPeriodsFromFs(contract.discountPeriods, contract.isCommitment)
    : [];
  const vendorContractCommitmentPeriods = vendorContract?.commitmentPeriods
    ? formatCommitmentPeriodsFromFs(vendorContract.commitmentPeriods)
    : [];
  const vendorContractDiscountPeriods = vendorContract?.discountPeriods
    ? formatDiscountPeriodsFromFs(vendorContract.discountPeriods, true)
    : [];

  const vendorContractFields: VendorContract = vendorContract
    ? {
        id: vendorContractId,
        assets: vendorContract.assets ?? [],
        commitmentPeriods: vendorContractCommitmentPeriods ?? [],
        commitmentType: vendorContract.commitmentType ?? null,
        contractFile: vendorContract.contractFile as FileData,
        credits: (vendorContract.credits ?? {}) as unknown as ContractCredits,
        discount: vendorContract.discount,
        endDate: sanitizeDate(DateTime.fromJSDate(vendorContract.endDate.toDate())),
        startDate: sanitizeDate(DateTime.fromJSDate(vendorContract.startDate.toDate())),
        vendor: vendorContract.vendor,
        discountPeriods: vendorContractDiscountPeriods,
        errors: {},
      }
    : ({ ...defaultVendorContractValues } as unknown as VendorContract);

  const properties = {
    gcpFlexSaveOverwrite:
      contract.properties?.gcpFlexSaveOverwrite !== undefined
        ? 100 - parseInt(contract.properties.gcpFlexSaveOverwrite as string)
        : 0,
    rebaseModifier: contract.properties?.rebaseModifier ?? 0,
    discountPreemptible: contract.properties?.discountPreemptible ?? false,
    specialDiscount: contract.properties?.specialDiscount ?? null,
    pricelistSheet: contract.properties?.pricelistSheet ?? null,
    support: contract.properties?.support ?? null,
    userEmail: contract.properties?.userEmail || "",
    lookerSalesProcess: contract.properties?.salesProcess || "",
    lookerSkus: contract.properties?.skus || [{ ...lookerSkuDefaultValues }],
    lookerInvoiceFrequency: contract.properties?.invoiceFrequency as number,
    lookerContractDuration: contract.properties?.contractDuration as number,
    gcpSupport: contract.properties?.gcpSupport,
  };

  const contractFields: Omit<
    ContractStateType,
    | "isEditForbidden"
    | "editMode"
    | "activeStep"
    | "loading"
    | "errors"
    | "selectedPricebooks"
    | "overlappingContractExists"
  > = {
    accountManager: contract.accountManager?.id,
    assets: (contract.assets ? contract.assets : []) as ModelReference<AssetModel>[],
    active: contract.active,
    commitmentPeriods,
    commitmentRollover: contract?.commitmentRollover ?? false,
    contractFile: contract.contractFile as FileData,
    credits: (contract.credits ?? {}) as unknown as ContractCredits,
    discount: contract.discount,
    discountEndDate: contract.discountEndDate
      ? sanitizeDate(DateTime.fromJSDate(contract.discountEndDate.toDate()))
      : null,
    discountPeriods,
    entity: contract.entity?.id ? contract.entity.id : "",
    estimatedValue: contract.estimatedValue,
    endDate: contract.endDate ? sanitizeDate(DateTime.fromJSDate(contract.endDate.toDate())) : null,
    isCommitment: contract.isCommitment,
    isSoftCommitment: contract.isSoftCommitment ?? false,
    isRenewal: contract.isRenewal,
    notes: contract.notes,
    partnerMargin: contract.partnerMargin ?? 0,
    purchaseOrder: contract.purchaseOrder,
    startDate: sanitizeDate(DateTime.fromJSDate(contract.startDate.toDate())),
    terminated: contract.terminated ?? false,
    type: contract.type,
    vendorContract: vendorContractFields,
    gcpContract: (contract.gcpContract ?? []) as ModelReference<ContractModel>[],
    plpsPercent: contract.plpsPercent as number,
    tierId: contract.tier?.id,
    commitmentMonths: contract.commitmentMonths,
    paymentTerm: contract.paymentTerm,
    chargePerTerm: contract.chargePerTerm,
    monthlyFlatRate: contract.monthlyFlatRate,
    isAdvantage: contract.isAdvantage,
    typeContext: contract?.properties?.typeContext?.id,
    estimatedFunding: contract?.properties?.estimatedFunding,
    currency: contract.currency ?? "USD",
    pointOfSale: contract.pointOfSale ?? "doit",
    minimumServiceFee: contract.minimumServiceFee,
    cloudSpendPercentage: contract.cloudSpendPercentage,
    ...properties,
  };

  return contractFields;
};

export const showDiscountField = (type: ContractType) => [ContractTypeGSuite, ContractTypeOffice365].includes(type);

export const productSupportsDiscount = (type: ContractType) =>
  [
    ContractTypeGSuite,
    ContractTypeOffice365,
    ContractTypeGoogleCloud,
    ContractTypeAws,
    ContractTypeLooker,
    ContractTypeDoitCloudNavigator,
    ContractTypeDoitCloudSolve,
  ].includes(type);

export const isMarketplace = (pointOfSale?: PointOfSaleType) =>
  ["aws-marketplace", "gcp-marketplace"].includes(pointOfSale ?? "");

export const productSupportsCredits = (type: ContractType) => [ContractTypeGoogleCloud, ContractTypeAws].includes(type);

export const ifProductWithoutEntity = (type: ContractType, pointOfSale?: PointOfSaleType) =>
  [ContractTypeGoogleCloudStandalone, ContractTypeSaasConsoleGcp].includes(type) ||
  (isNext10Contract(type) && isMarketplace(pointOfSale)) ||
  (type === ContractTypeDoitCloudIntelligence && isMarketplace(pointOfSale));

export const productAllowsNoEntity = (type: ContractType) => type === ContractTypeSaasConsoleAws;

export const isDoitCloudProduct = (type: ContractType) =>
  type === ContractTypeDoitCloudNavigator ||
  type === ContractTypeDoitCloudSolve ||
  type === ContractTypeDoitOneTimeServiceFee ||
  type === ContractTypeDoitCloudIntelligence;

export const getAssetsForCurrentContractType = (state: ContractStateType, assets: Assets): Asset[] =>
  assets[state.entity]?.filter(
    (asset) => asset.data.type === (state.type === ContractTypeLooker ? ContractTypeGoogleCloud : state.type)
  ) || [];

export const lookerEstimatedAnnualizedValue = (state: ContractStateType) =>
  state.lookerSkus.reduce(
    (acc, sku) => acc + Number(sku.monthlySalesPrice) * Number(sku.quantity) * Number(sku.months),
    0
  ) /
  (state.lookerContractDuration / 12);

export const getLookerSkuStartDates = (state: ContractStateType) => {
  const startDates = state.lookerSkus.map((sku) => {
    if (state.startDate && state.lookerContractDuration > 0 && Number(sku.months) > 0) {
      return skuStartDate(state.startDate, state.lookerContractDuration, Number(sku.months));
    }
  });
  return startDates as any;
};

const formatCommitmentPeriodsToFs = (
  commitmentPeriods: CommitmentPeriodType[]
): WithFirebaseModel<CommitmentPeriod>[] =>
  commitmentPeriods.map((cp) => ({
    value: cp.value as number,
    startDate: FirestoreTimestamp.fromDate(sanitizeDate(cp.startDate).toJSDate()),
    endDate: FirestoreTimestamp.fromDate(sanitizeDate(cp.endDate).toJSDate()),
    discount: cp.discount ?? 0,
  }));

const formatCreditsToFs = (credits: ContractCredits, isVendorContract: boolean, type?: ContractType) => {
  const formattedCredits: ContractCreditsModel = {};
  const availableCredits = isVendorContract ? creditsByType.vendorContract : creditsByType.customerContract[type ?? ""];

  availableCredits.forEach(({ creditsName }) => {
    if (credits[creditsName]) {
      formattedCredits[creditsName] = {
        amount: parseFloat(credits[creditsName].amount),
        description: credits[creditsName].description,
      };
    }
  });

  return isEmpty(formattedCredits) ? null : formattedCredits;
};

const formatContractFile = (contractFile: FileData | null): FileData | null => {
  if (!contractFile) {
    return null;
  }
  return {
    id: contractFile.id,
    parentId: contractFile.parentId ?? null,
    name: contractFile.name,
    url: contractFile.url,
    storage: contractFile.storage ?? null,
  };
};

const getDoitCloudProductDate = (state: ContractStateType): FirestoreTimestamp | null => {
  if (state.type === ContractTypeDoitOneTimeServiceFee) {
    return state.endDate ? (sanitizeDate(state.endDate).toJSDate() as unknown as FirestoreTimestamp) : null;
  }

  if (state.type === ContractTypeGoogleCloudPartnerLedPremiumSupport) {
    return state.endDate ? (sanitizeDate(state.endDate).toJSDate() as unknown as FirestoreTimestamp) : null;
  }

  return (state.isCommitment || state.isSoftCommitment) && state.endDate
    ? (sanitizeDate(state.endDate).toJSDate() as unknown as FirestoreTimestamp)
    : null;
};

export const productHasDiscountPeriods = (type: ContractType) =>
  [ContractTypeAws, ContractTypeGoogleCloud].includes(type);

const formatDiscountPeriodsToFs = (discountPeriods: DiscountPeriodType[]): WithFirebaseModel<DiscountPeriod>[] =>
  discountPeriods.map((dp) => ({
    discount: dp.discount,
    hasNoEndDate: dp.hasNoEndDate,
    startDate: sanitizeDate(dp.startDate).toJSDate() as unknown as FirestoreTimestamp,
    endDate: dp.endDate ? (sanitizeDate(dp.endDate).toJSDate() as unknown as FirestoreTimestamp) : null,
  }));

export const formatContractToFs = (
  state: ContractStateType,
  customer: Customer,
  currentUser: FirebaseUserWithEmail,
  assets: Assets
): WithFirebaseModel<ContractModel> => {
  const eligibleAssets = getAssetsForCurrentContractType(state, assets);
  const formattedAssets = state.assets.filter((assetRef) => eligibleAssets.find((asset) => asset.id === assetRef.id));
  const commitmentPeriods = state.isCommitment ? formatCommitmentPeriodsToFs(state.commitmentPeriods) : [];
  const entity = state.entity ? getCollection(EntityModel).doc(state.entity) : null;
  const accountManager = state.accountManager
    ? getCollection(AccountManagerModel).doc(state.accountManager)
    : undefined;
  const credits = productSupportsCredits(state.type) ? formatCreditsToFs(state.credits, false, state.type) : null;
  const discountPeriods = productHasDiscountPeriods(state.type) ? formatDiscountPeriodsToFs(state.discountPeriods) : [];

  const contract: any = {
    type: state.type as ContractModelType,
    customer: customer.ref,
    entity,
    accountManager,
    discountEndDate: state.discountEndDate
      ? FirestoreTimestamp.fromDate(sanitizeDate(state.discountEndDate).toJSDate())
      : undefined,
    discount: productSupportsDiscount(state.type) ? state.discount || 0 : 0,
    partnerMargin: (state.partnerMargin as number) ?? 0,
    assets: state.type === ContractTypeGoogleCloudPartnerLedPremiumSupport ? state.assets : formattedAssets,
    startDate: sanitizeDate(state.startDate).toJSDate() as unknown as FirestoreTimestamp,
    endDate: getDoitCloudProductDate(state),
    isCommitment: state.isCommitment,
    isSoftCommitment: state.isSoftCommitment,
    commitmentRollover: state.isCommitment ? (state?.commitmentRollover ?? false) : false,
    commitmentPeriods,
    discountPeriods,
    isRenewal: state.isRenewal,
    notes: state.notes,
    purchaseOrder: state.purchaseOrder,
    active: state.active,
    terminated: state.terminated,
    contractFile: formatContractFile(state.contractFile) as ContractFile,
    updatedBy: { name: currentUser.displayName, email: currentUser.email },
    timestamp: serverTimestamp() as unknown as FirestoreTimestamp,
    properties: {} as any,
    gcpContract: state.gcpContract,
    plpsPercent: state.plpsPercent,
    estimatedValue: state.estimatedValue || 0,
    isAdvantage: state.isAdvantage,
    credits: credits ?? undefined,
  };

  if (state.type === ContractTypeLooker) {
    contract.estimatedValue = lookerEstimatedAnnualizedValue(state);
  } else if (state.isCommitment) {
    contract.estimatedValue = state.commitmentPeriods.reduce((acc, cp) => acc + (cp.value ?? 0), 0);
  }

  // Type specific contract properties
  switch (state.type) {
    case ContractTypeGoogleCloud:
      contract.properties = {
        rebaseModifier: state.rebaseModifier,
        discountPreemptible: state.discountPreemptible,
        gcpFlexSaveOverwrite: 100 - state.gcpFlexSaveOverwrite,
        gcpSupport: state.gcpSupport,
      };
      if (state.isCommitment && state.pricelistSheet) {
        contract.properties.pricelistSheet = formatContractFile(state.pricelistSheet);
      } else {
        contract.properties.pricelistSheet = null;
      }
      break;
    case ContractTypeAws:
      contract.properties = {
        support: state.support,
      };
      break;
    case ContractTypeGSuite:
      contract.properties = {
        specialDiscount: state.specialDiscount,
      };
      break;
    case ContractTypeAwsStandalone:
    case ContractTypeGoogleCloudStandalone:
      contract.properties = {
        userEmail: state.userEmail,
        gcpFlexSaveOverwrite: 100 - state.gcpFlexSaveOverwrite,
      };
      break;
    case ContractTypeDoitCloudNavigator:
    case ContractTypeDoitCloudSolve:
      contract.tier = state.tierId ? getCollection(TierModel).doc(state.tierId) : undefined;
      contract.commitmentMonths = state.commitmentMonths;
      contract.paymentTerm = state.paymentTerm;
      contract.chargePerTerm = state.chargePerTerm;
      contract.currency = state.currency;
      contract.pointOfSale = state.pointOfSale;

      if (contract.isCommitment && contract.commitmentMonths) {
        if (contract.commitmentMonths > 0) {
          let contractEndDate = sanitizeDate(state.startDate);
          contractEndDate = contractEndDate.plus({ months: contract.commitmentMonths });
          contract.endDate = contractEndDate.toJSDate() as unknown as FirestoreTimestamp;
        } else if (contract.commitmentMonths === -1) {
          contract.endDate = sanitizeDate(state.endDate!).toJSDate() as unknown as FirestoreTimestamp;
        }
      }

      if (state.type === ContractTypeDoitCloudSolve) {
        contract.monthlyFlatRate = state.monthlyFlatRate;
      }
      break;
    case ContractTypeLooker: {
      const skuStartDates = getLookerSkuStartDates(state);
      contract.properties = {
        skus: state.lookerSkus.map((sku, i) => ({
          ...sku,
          quantity: Number(sku.quantity),
          months: Number(sku.months),
          monthlySalesPrice: Number(sku.monthlySalesPrice),
          startDate: sanitizeDate(skuStartDates[i]).toJSDate(),
        })),
        salesProcess: state.lookerSalesProcess,
        invoiceFrequency: state.lookerInvoiceFrequency,
        contractDuration: Number(state.lookerContractDuration),
      };
      let contractEndDate = sanitizeDate(state.startDate);
      if (contract.properties.contractDuration > 0) {
        contractEndDate = contractEndDate.plus({ months: contract.properties.contractDuration });
        contract.endDate = contractEndDate.toJSDate() as unknown as FirestoreTimestamp;
      }
      let totalCost = contract.properties.skus.reduce(
        (sum: number, sku) => sum + sku.monthlySalesPrice * sku.months * sku.quantity,
        0
      );
      if (contract.discount > 0) {
        totalCost *= 1 - state.discount / 100;
      }
      contract.commitmentPeriods = [
        {
          startDate: contract.startDate,
          endDate: contract.endDate as FirestoreTimestamp,
          discount: contract.discount,
          value: totalCost,
        },
      ];
      contract.isCommitment = contract.commitmentPeriods.length > 0;
      break;
    }
    case ContractTypeDoitOneTimeServiceFee: {
      const typeContextRef = state.typeContext
        ? getCollection(AppModel).doc("contracts").collection("doit-one-time-service-types").doc(state.typeContext)
        : undefined;

      contract.properties.typeContext = typeContextRef;
      contract.properties.estimatedFunding = state.estimatedFunding;
      contract.chargePerTerm = state.chargePerTerm;
      contract.pointOfSale = "doit";
      contract.currency = state.currency;
      break;
    }

    case ContractTypeDoitCloudIntelligence:
      contract.tier = state.tierId ? getCollection(TierModel).doc(state.tierId) : undefined;
      contract.commitmentMonths = state.commitmentMonths;
      contract.pointOfSale = state.pointOfSale;
      contract.currency = state.currency;
      contract.minimumServiceFee = state.minimumServiceFee;
      contract.cloudSpendPercentage = state.cloudSpendPercentage;
      contract.paymentTerm = state.paymentTerm;
      break;

    default:
      contract.properties = null;
  }

  if (!state.editMode) {
    contract.timeCreated = firestoreTimestamp();
  }

  return contract;
};

const formatDiscountPeriodsToApiFormat = (discountPeriods?: DiscountPeriod[]) =>
  discountPeriods?.map((discountPeriod) => ({
    startDate: discountPeriod.startDate as unknown as FirestoreTimestamp,
    endDate: discountPeriod.endDate as unknown as FirestoreTimestamp,
    discount: discountPeriod.discount,
  }));

export const contractApiFormattedPayload = (
  fsFormatContract: Omit<ContractModel, "subCollections">
): ContractRequestPayload => ({
  customerID: fsFormatContract.customer.id,
  tier: fsFormatContract.tier?.id,
  startDate: fsFormatContract.startDate as unknown as FirestoreTimestamp,
  endDate: fsFormatContract.endDate as unknown as FirestoreTimestamp,
  type: fsFormatContract.type,
  isCommitment: fsFormatContract.isCommitment,
  discount: fsFormatContract.discount,
  accountManager: fsFormatContract.accountManager?.id,
  notes: fsFormatContract.notes,
  estimatedValue: fsFormatContract.estimatedValue,
  purchaseOrder: fsFormatContract.purchaseOrder,
  commitmentMonths: fsFormatContract.commitmentMonths,
  paymentTerm: fsFormatContract.paymentTerm,
  chargePerTerm: fsFormatContract.chargePerTerm,
  entityId: fsFormatContract.entity?.id,
  monthlyFlatRate: fsFormatContract.monthlyFlatRate,
  contractFile: fsFormatContract.contractFile,
  typeContext: fsFormatContract.properties?.typeContext?.id,
  estimatedFunding: fsFormatContract.properties?.estimatedFunding,
  pointOfSale: fsFormatContract.pointOfSale,
  currency: fsFormatContract.currency as CurrencyCode,
  minimumServiceFee: fsFormatContract.minimumServiceFee,
  cloudSpendPercentage: fsFormatContract.cloudSpendPercentage,
  discountPeriods: formatDiscountPeriodsToApiFormat(fsFormatContract.discountPeriods),
});

export const formatVendorContractToFs = (
  state: ContractStateType,
  customer: Customer,
  currentUser: FirebaseUserWithEmail,
  assets: Assets
) => {
  const contract = state.vendorContract as VendorContract;
  const eligibleAssets = getAssetsForCurrentContractType(state, assets);
  const formattedAssets = contract.assets.filter((assetRef) =>
    eligibleAssets.find((asset) => asset.id === assetRef.id)
  );
  const commitmentPeriods = formatCommitmentPeriodsToFs(contract.commitmentPeriods);
  const discountPeriods = formatDiscountPeriodsToFs(contract.discountPeriods);
  const entity = state.entity ? getCollection(EntityModel).doc(state.entity) : null;

  const vendorContract: Omit<VendorContractModel, "customerContract" | "terminated"> = {
    active: true,
    assets: formattedAssets,
    commitmentPeriods,
    commitmentType: contract.commitmentType as VendorCommitmentType,
    contractFile: formatContractFile(contract.contractFile) as ContractFile,
    customer: customer.ref,
    discount: contract.discount || 0,
    endDate: sanitizeDate(contract.endDate).toJSDate() as unknown as FirestoreTimestamp,
    entity,
    startDate: sanitizeDate(contract.startDate).toJSDate() as unknown as FirestoreTimestamp,
    timestamp: serverTimestamp() as unknown as FirestoreTimestamp,
    updatedBy: { name: currentUser.displayName, email: currentUser.email },
    vendor: state.type as VendorType,
    timeCreated: undefined as unknown as FirestoreTimestamp,
    discountPeriods,
  };

  const credits = formatCreditsToFs(contract.credits, true);

  if (credits) {
    vendorContract.credits = credits;
  }

  if (!state.vendorContract?.id) {
    vendorContract.timeCreated = firestoreTimestamp();
  }

  return vendorContract as VendorContractModel;
};

const isLookerContractAndAM = (type, customer, currentUser) =>
  customer.accountTeam?.some(
    (am) => am.ref.id === currentUser.id && am.company === "doit" && type === ContractTypeLooker
  );

// user only allowed to edit contract details if is admin or within 5 days of creation date (grace period)
export const ifEditForbidden = ({
  isDoitContractOwner,
  isDoitContractAdmin,
  timeCreated,
  startDate,
  type,
  customer,
  currentUser,
  isActive,
}: {
  isDoitContractOwner: boolean;
  isDoitContractAdmin: boolean;
  timeCreated: FirestoreTimestamp;
  startDate: FirestoreTimestamp;
  type: ContractModelType;
  customer: Customer;
  currentUser: FirebaseUserWithEmail;
  isActive: boolean;
}) =>
  !isDoitContractOwner &&
  !(
    isNext10Contract(type) && canEditNext10Contract({ isDoitContractOwner, isDoitContractAdmin, startDate, isActive })
  ) &&
  !isDoitContractAdmin &&
  DateTime.fromJSDate(timeCreated?.toDate()).diffNow("days").days <= -5 &&
  !isLookerContractAndAM(type, customer, currentUser);

export const hasCommitmentPeriodDiscount = (type) => [ContractTypeMicrosoftAzure].includes(type);

export const getSteps = (type) => {
  const flowSteps = ["General", "Customer contract", "Customer commitment", "Finalize"];

  switch (type) {
    case ContractTypeAws:
      flowSteps.splice(2, 0, "Pricing");
      flowSteps.splice(4, 0, "Discounts", "Vendor contract", "Vendor commitment");
      break;

    case ContractTypeGoogleCloud:
      flowSteps.splice(3, 0, "Discounts", "Vendor contract", "Vendor commitment");
      break;

    case ContractTypeLooker:
      flowSteps.splice(1, 2, "Looker contract SKUs", "Looker contract subscription");
      break;

    case ContractTypeGoogleCloudPartnerLedPremiumSupport:
      flowSteps.splice(2, 1);
      break;

    case ContractTypeDoitCloudNavigator:
    case ContractTypeDoitCloudSolve:
    case ContractTypeDoitCloudIntelligence:
      flowSteps.splice(1, 2, "Subscription contract");
      break;

    case ContractTypeDoitOneTimeServiceFee:
      flowSteps.splice(1, 2, "Details");
      break;
  }

  return flowSteps;
};

export const ifContractDatesValid = (startDate: DateTime, endDate: DateTime | null): boolean => {
  if (!startDate?.isValid || !endDate?.isValid || endDate <= startDate) {
    return false;
  }
  return true;
};

const createPicker = async ({ getGapiToken, Scopes, customer, pickerCallback, isVendorContract, fileType }: any) => {
  const typeMap = {
    pdf: {
      docsView: window.google.picker.ViewId.DOCS,
      mimeType: "application/pdf",
      name: "contractFile",
      title: "Select the contract PDF File",
    },
    spreadsheet: {
      docsView: window.google.picker.ViewId.SPREADSHEETS,
      mimeType: "application/vnd.google-apps.spreadsheet",
      name: "pricelistSheet",
      title: "Select Pricelist Google Drive Spreadsheet",
    },
  };

  const token = await getGapiToken(Scopes.DriveFile);
  const view = new window.google.picker.DocsView(typeMap[fileType].docsView);
  view.setEnableDrives(true);
  view.setIncludeFolders(true);
  view.setMode(window.google.picker.DocsViewMode.LIST);
  if (customer?.sharedDriveFolderId) {
    view.setParent(customer.sharedDriveFolderId);
  } else {
    view.setParent("0AOWn6fazllPmUk9PVA");
  }
  view.setMimeTypes(typeMap[fileType].mimeType);
  const picker = new window.google.picker.PickerBuilder()
    .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
    .enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)
    .setAppId("135469130251")
    .setOAuthToken(token)
    .addView(view)
    .setCallback(pickerCallback(typeMap[fileType].name, isVendorContract))
    .setTitle(typeMap[fileType].title)
    .setMaxItems(1)
    .build();

  picker.setVisible(true);
};

export const createPDFPicker = async ({ getGapiToken, Scopes, customer, pickerCallback, isVendorContract }: any) => {
  createPicker({ getGapiToken, Scopes, customer, pickerCallback, isVendorContract, fileType: "pdf" });
};

export const createShowSheetPicker = async ({ getGapiToken, Scopes, pickerCallback }) => {
  createPicker({ getGapiToken, Scopes, pickerCallback, fileType: "spreadsheet" });
};

export const getContractLabel = (contract: FormattedContract) =>
  `${getCustomerCommitmentText(contract)} (${contract.data._startDate.toLocaleString(DateTime.DATE_MED)} - ${
    contract.data._endDate ? contract.data._endDate?.toLocaleString(DateTime.DATE_MED) : texts.NA
  })`;

export const getSelectedTier = (tiers?: TransformedTierModel[], tierId?: string) => tiers?.find((t) => t.id === tierId);

export const calculateTotalSalesPrice = (
  chargePerTerm: number,
  paymentTerm: SubscriptionPaymentTerm,
  discount: number,
  currency: CurrencyCode
) =>
  `${getCurrencySymbol(currency)} ${formatDecimalNumber(chargePerTerm * (1 - discount / 100), 0)} ${chargePerTermLabelMap[paymentTerm]?.text}`;

export const calculateChargePerTerm = (
  paymentTerm?: SubscriptionPaymentTerm,
  commitmentMonths?: number,
  tierMonthlyPrice?: number
) =>
  paymentTerm === "annual" && !!commitmentMonths && tierMonthlyPrice
    ? tierMonthlyPrice * commitmentMonths
    : (tierMonthlyPrice ?? 0);

export const isMonthlyFlatRateApplicable = (tier?: TierModel) =>
  tier?.packageType === TierPackageTypes.SOLVE && ["enhanced", "premium", "enterprise"].includes(tier?.name);
