import { useState } from "react";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Alert, Box, useTheme } from "@mui/material";

import NavTabs from "./NavTabs";
import TabPanel from "./TabPanel";
import { type RunDetailsAccordionProps, type TabConfig, type TabId } from "./types";

const Accordion = ({ executionError, executionNodeDetails }: RunDetailsAccordionProps) => {
  const theme = useTheme();
  const hasParameters = executionNodeDetails.parameters !== null;
  const hasResults = executionNodeDetails.results !== null;
  const hasApprovalDetails = executionNodeDetails.approvalDetails !== null;

  const tabConfig: TabConfig[] = [
    {
      id: "parameters",
      label: "Data in",
      data: executionNodeDetails.parameters,
      show: hasParameters,
    },
    {
      id: "results",
      label: "Data out",
      data: executionNodeDetails.results,
      show: hasResults,
    },
    {
      id: "approvalDetails",
      label: "Approval details",
      data: executionNodeDetails.approvalDetails,
      show: hasApprovalDetails,
    },
  ];

  const visibleTabs = tabConfig.filter((tab) => tab.show);

  const defaultActiveTab = visibleTabs.length > 0 ? visibleTabs[0].id : "parameters";
  const [activeTab, setActiveTab] = useState<TabId>(defaultActiveTab);

  return (
    <Box>
      {executionError && (
        <Alert sx={{ marginBottom: theme.spacing(2) }} severity="error" icon={<WarningAmberIcon />}>
          {executionError}
        </Alert>
      )}
      {visibleTabs.length > 0 && (
        <>
          <NavTabs visibleTabs={visibleTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabPanel tabConfig={tabConfig} activeTab={activeTab} />
        </>
      )}
    </Box>
  );
};

export default Accordion;
