import { useCallback, useState } from "react";

import {
  type ApiServiceBaseOperationDescriptor,
  type CloudFlowNodeType,
  CloudFlowProvider,
  type Member,
  ModelType,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Button, List, ListItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CopyCodeBlock } from "../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { Loader } from "../../../../../Components/Loader";
import LoadingButton from "../../../../../Components/LoadingButton";
import { PermissionValidityStatus } from "../../../types";
import { ApiActionParametersForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import CloudSpecificPermissionForm from "../../ApiActionParametersForm/CloudSpecificPermissionForm";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { type PermissionConfigValues, useCloudPermissions } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const PermissionForm = ({
  operation,
  permissionFormValues,
  setConfigurationFormValid,
  onConfigurationValuesChange,
  isOperationLoading,
  modelId,
  resetPermissions,
}: {
  operation: WithFirebaseModel<ApiServiceBaseOperationDescriptor> | null;
  permissionFormValues: object;
  setConfigurationFormValid: (valid: boolean) => void;
  onConfigurationValuesChange: (values: unknown) => void;
  isOperationLoading: boolean;
  modelId: string | null;
  resetPermissions: () => void;
}) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const isGCPProvider = nodeConfig.parameters.operation.provider === CloudFlowProvider.GCP;

  const permissionsFormModel = operation?.parameters && {
    ...operation.parameters,
    members: {
      ...operation.parameters.members,
      ...(isGCPProvider && {
        permissionsProjectId: {
          model: { type: ModelType.STRING },
        } satisfies Member,
      }),
    },
    requiredMembers: isGCPProvider ? ["organization", "serviceAccount"] : operation.parameters.requiredMembers,
  };

  return (
    <Stack
      sx={{
        p: 2,
        justifyContent: "center",
        gap: 2,
      }}
    >
      <Loader loading={isOperationLoading || modelId !== operation?.inputModel}>
        {permissionsFormModel && (
          <ApiActionParametersForm
            key={nodeConfig.id}
            inputModel={permissionsFormModel}
            values={permissionFormValues}
            onValidityChange={setConfigurationFormValid}
            onValuesChange={onConfigurationValuesChange}
          >
            <CloudSpecificPermissionForm
              resetPermissions={resetPermissions}
              inputModel={permissionsFormModel}
              provider={nodeConfig.parameters.operation.provider}
            />
          </ApiActionParametersForm>
        )}
      </Loader>
    </Stack>
  );
};

const PermissionsTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { configurationValues } = nodeConfig.parameters;

  const [permissionFormValues, setPermissionFormValues] = useState<PermissionConfigValues>(configurationValues);

  const { requiredPermissions, command, loading, updatePermissions, status, resetPermissions } = useCloudPermissions({
    permissionConfigValues: permissionFormValues,
  });

  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const { modelId } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);
  const [configurationFormValid, setConfigurationFormValid] = useState<boolean>(true);

  const onConfigurationValuesChange = useCallback((newValues: unknown) => {
    setPermissionFormValues(newValues as PermissionConfigValues);
  }, []);

  const getConsoleLink = (): string => {
    if (nodeConfig.parameters?.provider === "AWS" && nodeConfig.parameters?.configurationValues?.accountId) {
      return `https://${nodeConfig.parameters?.configurationValues.accountId}.signin.aws.amazon.com/console`;
    }
    if (nodeConfig.parameters?.provider === "GCP" && nodeConfig.parameters?.configurationValues?.organization) {
      const orgId = nodeConfig.parameters?.configurationValues?.organization.split("/").pop();
      return `https://console.cloud.google.com/iam-admin/iam?organizationId=${orgId}`;
    }

    return "#";
  };
  const PermissionFormComponent = (
    <PermissionForm
      resetPermissions={resetPermissions}
      operation={operation}
      permissionFormValues={permissionFormValues}
      setConfigurationFormValid={setConfigurationFormValid}
      onConfigurationValuesChange={onConfigurationValuesChange}
      isOperationLoading={isOperationLoading}
      modelId={modelId}
    />
  );

  if (status === PermissionValidityStatus.VALID) {
    return (
      <>
        {PermissionFormComponent}
        <Box
          sx={{
            px: 2,
          }}
        >
          <Alert severity="success">
            <Typography variant="body2">All permissions are in place to run this action</Typography>
          </Alert>
        </Box>
      </>
    );
  } else if (status === PermissionValidityStatus.UNABLE_TO_VERIFY) {
    return (
      <>
        {PermissionFormComponent}
        <Box
          sx={{
            px: 2,
          }}
        >
          <Alert severity="warning">
            <Typography variant="body2">We were unable to verify the permissions for this action</Typography>
          </Alert>
        </Box>
      </>
    );
  }

  if (requiredPermissions.length === 0) {
    return (
      <>
        {PermissionFormComponent}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            px: 2,
          }}
        >
          <LoadingButton
            variant="outlined"
            sx={{ px: 2, py: 0.875 }}
            onClick={updatePermissions}
            loading={loading}
            disabled={!configurationFormValid}
            mixpanelEventId="cloudflow.check-permissions"
          >
            Check for required permissions
          </LoadingButton>
        </Box>
      </>
    );
  }
  return (
    <>
      {PermissionFormComponent}
      <Stack
        sx={{
          alignContent: "center",
          px: 2,
          gap: 2,
        }}
      >
        <Box display="flex" alignItems={"flex-start"}>
          <LoadingButton
            mixpanelEventId="cloudflow.check-permissions"
            variant="outlined"
            sx={{ px: 2, py: 0.875 }}
            onClick={updatePermissions}
            loading={loading}
          >
            Check for required permissions
          </LoadingButton>
        </Box>
        <Alert severity="warning">
          <Typography variant="body2">We require the following permissions in order to run this node: </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
            {requiredPermissions?.map((permission) => (
              <ListItem key={permission} sx={{ display: "list-item", px: 0.5 }}>
                {permission}
              </ListItem>
            ))}
          </List>
        </Alert>
        <Stack
          sx={{
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Run the following command to grant the permission:
          </Typography>
          <CopyCodeBlock base={command} />
          <Button variant="outlined" component="a" href={getConsoleLink()} target="_blank">
            <OpenInNewIcon
              sx={{
                width: 20,
                height: 20,
                mr: 1,
              }}
            />
            Go to {nodeConfig.parameters?.provider} Console
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default PermissionsTab;
