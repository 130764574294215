import { useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import useGenerateReport from "../generateReport";
import { useNewReportHandler } from "../report/hooks";

const ReportCreate = () => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { defaultReportConfig, defaultConfigLoading } = useCloudAnalyticsContext();
  const { customer, userOrganization, init: customerCtxInit } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const generateReport = useGenerateReport();
  const errorSnackbar = useErrorSnackbar();
  const history = useHistory();
  const createReport = useNewReportHandler({
    baseUrl: `/customers/${customer.id}/analytics/reports`,
    mixpanelEventName: "analytics.reports.new",
    prevPageUrl: `/customers/${customer.id}/analytics/reports`,
  });
  const isCreatingReport = useRef<boolean>(false);

  useEffect(() => {
    if (customerCtxInit && currentUser.email && !defaultConfigLoading && !isCreatingReport.current) {
      isCreatingReport.current = true;
      void createReport();
    }
  }, [
    currentUser.email,
    customer.id,
    customer.ref,
    defaultReportConfig,
    generateReport,
    isDoitEmployee,
    userOrganization,
    history,
    customerCtxInit,
    errorSnackbar,
    defaultConfigLoading,
    createReport,
  ]);

  return <></>;
};

export default ReportCreate;
