import { useMemo } from "react";

import { type OperationPointer } from "@doitintl/cmp-models";

import { type GCPCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { type ProjectInfo } from "../../types";

export function mapCloudConnectData(gcpCloudConnectData: GCPCloudConnectData[]) {
  return gcpCloudConnectData.flatMap((connect) => {
    const organizations =
      connect.data?.organizations?.map((org) => ({
        name: org.name,
        displayName: org.displayName,
        serviceAccount: connect.data?.clientEmail,
      })) || [];

    return {
      organizations,
      ...(connect.data?.projectId && {
        project: {
          id: connect.data.projectId,
          serviceAccount: connect.data.clientEmail,
        },
      }),
    };
  });
}

export function useMergedProjectIds(cloudConnectData: ReturnType<typeof mapCloudConnectData>, projects: ProjectInfo[]) {
  return useMemo(() => {
    const idsFromCloudConnect = cloudConnectData.map((item) => item.project?.id).filter(Boolean);

    const idsFromApi = projects.map((proj) => proj.projectId).filter(Boolean);

    return Array.from(new Set([...idsFromCloudConnect, ...idsFromApi]));
  }, [cloudConnectData, projects]);
}
export function getGcpPermission(operation: OperationPointer) {
  const idSegments = operation.id.split(".");
  const [resource, method] = idSegments.slice(-2);

  // Adjust the resource name for logging service
  const permissionResource = operation.service === "logging" && resource === "entries" ? "logEntries" : resource;

  return `${operation.service}.${permissionResource}.${method}`;
}
