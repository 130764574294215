import { useMemo } from "react";

import { CustomerNoteModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { type Customer } from "../../../types";
import { type CustomerNote, type TagKeys } from "./types";
import { doesNoteHasTag, getAllNotes, isUserNote } from "./utils";

export function useCustomerNotes(customer: Customer, enable: boolean) {
  const query = getCollection(CustomerNoteModel)
    .where("customer", "==", customer.ref)
    .where("display", "==", true)
    .orderBy("timestamp", "desc")
    .limit(25);

  return useCollectionData(enable ? query : undefined, {
    idField: "id",
    refField: "ref",
  });
}

export function usePinnedNotes(customer: Customer, enable: boolean) {
  const query = getCollection(CustomerNoteModel)
    .where("customer", "==", customer.ref)
    .where("display", "==", true)
    .where("pinned", "==", true)
    .orderBy("timestamp", "desc");

  return useCollectionData(enable ? query : undefined, {
    idField: "id",
    refField: "ref",
  });
}

export function useNotesToDisplay(
  customerNotes: CustomerNote[] | undefined,
  pinnedNotes: CustomerNote[] | undefined,
  selectedTag?: TagKeys,
  showMyNotes?: boolean
) {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  return useMemo(() => {
    if (!customerNotes || !pinnedNotes) {
      return;
    }
    const allNotes = getAllNotes(customerNotes, pinnedNotes);
    if (selectedTag && showMyNotes) {
      return allNotes.filter((note) => doesNoteHasTag(note, selectedTag) && isUserNote(note, currentUser.email));
    } else if (selectedTag) {
      return allNotes.filter((note) => doesNoteHasTag(note, selectedTag));
    } else if (showMyNotes) {
      return allNotes.filter((note) => isUserNote(note, currentUser.email));
    } else {
      return allNotes;
    }
  }, [customerNotes, pinnedNotes, selectedTag, showMyNotes, currentUser.email]);
}
