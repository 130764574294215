import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  AssetTypeGoogleCloud,
  type CloudConnectGoogleCloud,
  CustomerModel,
  type SandboxFolder,
  type SandboxOrganization,
} from "@doitintl/cmp-models";
import { getBatch, getCollection, type ModelWithDataAndId } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import { Form, withFormik } from "formik";
import { type FormikProps } from "formik/dist/types";
import unionBy from "lodash/unionBy";
import { compose } from "recompose";
import * as yup from "yup";

import { useApiContext } from "../../api/context";
import { globalText } from "../../assets/texts";
import LoadingButton from "../../Components/LoadingButton";
import { useSnackbar, type WithSnackbar, withSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { type WithAuth, withAuth } from "../../Context/AuthContext";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";
import { withAssets } from "../../Context/customer/AssetContext";
import { useCustomerContext, type WithCustomer, withCustomerFlat } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { serverTimestamp } from "../../utils/firebase";
import mixpanel from "../../utils/mixpanel";
import PlatformConfigurationDialog from "../Settings/GCP/PlatformConfigurationDialog";
import { type SandboxPolicyWRef } from "./types";

const useStyles = makeStyles(() => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  spacer: {
    flex: "1 1 100%",
  },
  alertActionButton: {
    whiteSpace: "nowrap",
  },
}));

type BillingAccount = { id: string; displayName: string };

type OuterProps = {
  onClose: () => void;
  policy?: SandboxPolicyWRef;
};

type Values = {
  billingAccount: BillingAccount | null;
  folder: SandboxFolder | null;
  organization: SandboxOrganization | null;
  namePrefix: string;
  action: string;
  interval: string;
  amount: number | null;
  limit: number | null;
};

type Folder = {
  name: string;
  displayName: string;
};
const SandboxPolicyDialog = ({
  onClose,
  policy,
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
}: Props & FormikProps<Values>) => {
  const history = useHistory();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();
  const { isMobile: matches } = useFullScreen();
  const { customer, assets } = useCustomerContext();
  const classes = useStyles();
  const [organizations, setOrganizations] = useState<SandboxOrganization[]>([]);
  const [folders, setFolders] = useState<Folder[] | null>(null);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [cloudConnectServiceAccounts, setCloudConnectServiceAccounts] = useState<
    ModelWithDataAndId<CloudConnectGoogleCloud>[]
  >([]);
  const [cloudConnectDialogOpen, setCloudConnectDialogOpen] = useState(false);
  const [cloudConnectError, setCloudConnectError] = useState(true);
  const api = useApiContext();

  const customerRef = useMemo(() => getCollection(CustomerModel).doc(customer.id), [customer.id]);

  const billingAccounts = useMemo(
    () =>
      Object.values(assets).flatMap((assets) =>
        assets.flatMap((asset) => {
          if (asset.data.type === AssetTypeGoogleCloud) {
            return [
              {
                id: asset.data.properties.billingAccountId,
                displayName: asset.data.properties.displayName,
              },
            ];
          }

          return [];
        })
      ),
    [assets]
  );

  useEffect(
    () =>
      customerRef
        .collection("cloudConnect")
        .where("cloudPlatform", "==", "google-cloud")
        .narrow<CloudConnectGoogleCloud>()
        .onSnapshot((querySnapshot) => {
          const organizations = querySnapshot.docs.reduce<SandboxOrganization[]>(
            (acc, docSnap) => unionBy(docSnap.asModelData().organizations ?? [], acc, "name"),
            []
          );

          setOrganizations(organizations);

          setCloudConnectServiceAccounts((accounts) => {
            const newAccounts = (accounts ?? []).slice();
            arrayFromDocChange(newAccounts, querySnapshot, (doc) => ({
              data: doc.asModelData(),
              id: doc.id,
            }));
            return newAccounts;
          });
        }),
    [customerRef]
  );

  useEffect(() => {
    if (cloudConnectServiceAccounts) {
      cloudConnectServiceAccounts.forEach((service) => {
        if (service.data.cloudPlatform === AssetTypeGoogleCloud) {
          if (service.data.categoriesStatus.sandboxes === 1) {
            setCloudConnectError(false);
          }
        }
      });
    }
  }, [cloudConnectServiceAccounts]);

  useEffect(() => {
    if (policy) {
      setValues({
        organization: organizations.find((organization) => organization.name === policy.data.organization.name) ?? null,
        billingAccount:
          billingAccounts.find((billingAccount) => billingAccount.id === policy.data.billingAccount) ?? null,
        folder: policy.data.folder,
        namePrefix: policy.data.namePrefix,
        amount: policy.data.amount,
        action: policy.data.action,
        interval: policy.data.interval,
        limit: policy.data.limit,
      });
    }
  }, [policy, organizations, billingAccounts, setValues]);

  useEffect(() => {
    if (values.organization) {
      (async () => {
        const response = await api.request({
          method: "get",
          url: `/v1/customers/${customer.id}/google-cloud/folders`,
          params: {
            ...values.organization,
          },
        });
        const folders: Folder[] = response.data ?? [];
        setFolders(
          folders.map((folder) => ({
            name: folder.name,
            displayName: folder.displayName,
          }))
        );
      })();
    } else {
      setFolders(null);
    }
  }, [api, customer.id, values.organization]);

  const handleDisablePolicy = useCallback(async () => {
    if (!policy) {
      return;
    }

    try {
      await policy.ref.update({
        active: false,
      });
      openSnackbar({
        message: "Sandbox policy was disabled, users may no longer create sandbox accounts",
        variant: "success",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      onClose();
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [closeSnackbar, onClose, openSnackbar, policy]);
  const goToSettings = () => {
    history.push(`/customers/${customer.id}/settings`);
  };
  const handleEnablePolicy = useCallback(async () => {
    if (!policy) {
      return;
    }

    try {
      await policy.ref.update({
        active: true,
      });
      openSnackbar({
        message: "Sandbox policy was enabled",
        variant: "success",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [closeSnackbar, openSnackbar, policy]);

  const formItemDisabled = !(policy?.data?.active ?? true) || isSubmitting || !userRoles.sandboxAdmin;

  const getFirstError = (errors: any) => errors[Object.keys(errors)[0]];

  if (cloudConnectDialogOpen) {
    return (
      <PlatformConfigurationDialog
        title="Connect Google Cloud"
        subTitle=""
        type="google-cloud"
        open={cloudConnectDialogOpen}
        onClose={() => {
          setCloudConnectDialogOpen(false);
        }}
        customerID={customer.id}
        data={cloudConnectServiceAccounts ?? [{}]}
        onDelete={undefined}
        awsAccountId={undefined}
        settings={undefined}
        selectedOrg={undefined}
        handleChangeOrg={undefined}
        selectedCategories={undefined}
        accessLevel={undefined}
        onComplete={undefined}
      />
    );
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="sandbox-policy-dialog-title"
      onClose={preventOnCloseWhile(isSubmitting, onClose)}
      fullScreen={matches}
      fullWidth
      maxWidth="sm"
    >
      <Form
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        className={classes.dialogForm}
      >
        <DialogTitle id="sandbox-policy-dialog-title">Sandbox policy</DialogTitle>

        {showDisclaimer ? (
          <>
            <DialogContent>
              <Grid container spacing={1}>
                {cloudConnectError ? (
                  <Grid size={12}>
                    <Alert
                      variant="standard"
                      severity="error"
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          variant="outlined"
                          onClick={goToSettings}
                          className={classes.alertActionButton}
                        >
                          FIX NOW
                        </Button>
                      }
                    >
                      <AlertTitle>Error</AlertTitle>
                      Connect your Google Cloud organization using the "Connect Cloud Accounts" widget in your dashboard
                    </Alert>
                  </Grid>
                ) : (
                  <>
                    <Grid>
                      <Alert variant="standard" severity="info">
                        <AlertTitle>Notice</AlertTitle>
                        {/* Due to usage latency from the time that a resource is used, to the time that the
                  activity is billed, some additional costs might be incurred for usage that hasn't
                  arrived at the time the services are stopped. We recommend that if you have a hard
                  funds limit, you set your sandbox budget below your available funds to account for
                  delays. The sandbox policy is NOT a guarantee that you will not spend more than
                  the set budget amount. */}
                        Due to the Google Cloud billing latency, the sandbox policy is NOT a guarantee that you won't
                        exceed the budget. Consider setting the budget to a lower value to avoid spending more than your
                        actual budget.
                      </Alert>
                    </Grid>
                    <Grid>
                      <Alert variant="standard" severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        {/* Creating a sandbox project will create a Google cloud budget on the selected
                  billing account. Deleting or modifying aforementioned budget will cause
                  disruptions to the sandboxes service, which may result in sandbox projects
                  incurring much higher charges than the set budget amount. */}
                        We automatically create a Google Cloud Budget resource on the selected billing account. Deleting
                        or modifying the budget settings may result in incurring higher charges.
                      </Alert>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              {policy?.data?.active && userRoles.sandboxAdmin && (
                <>
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      "Disable policy to prevent the creation of additional sandboxes." +
                      " This action will not disable any preexisting sandboxes."
                    }
                  >
                    <span>
                      <Button variant="outlined" color="primary" onClick={handleDisablePolicy} disabled={isSubmitting}>
                        Disable
                      </Button>
                    </span>
                  </Tooltip>
                  <div className={classes.spacer} />
                </>
              )}
              <Button variant="text" onClick={onClose}>
                {globalText.CANCEL}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setShowDisclaimer(false);
                }}
                disabled={cloudConnectError}
              >
                {globalText.ACCEPT}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid size={12}>
                  <Autocomplete
                    autoHighlight={true}
                    options={billingAccounts}
                    getOptionLabel={(billingAccount) => `${billingAccount.id} (${billingAccount.displayName})`}
                    value={values.billingAccount}
                    onChange={(_event, newValue) => {
                      setFieldValue("billingAccount", newValue);
                    }}
                    forcePopupIcon={false}
                    disabled={formItemDisabled}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Billing account"
                        margin="dense"
                        variant="outlined"
                        helperText={
                          touched.billingAccount && errors.billingAccount ? getFirstError(errors.billingAccount) : ""
                        }
                        error={touched.billingAccount && Boolean(errors.billingAccount)}
                        data-cy="billing-account"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <Autocomplete
                    autoHighlight={true}
                    options={organizations}
                    getOptionLabel={(organization) => organization.displayName}
                    value={values.organization}
                    onChange={(_event, newValue) => {
                      setFieldValue("organization", newValue);
                    }}
                    forcePopupIcon={false}
                    disabled={formItemDisabled}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Organization"
                        margin="dense"
                        variant="outlined"
                        helperText={
                          touched.organization && errors.organization ? getFirstError(touched.organization) : ""
                        }
                        error={touched.organization && Boolean(errors.organization)}
                        data-cy="organization"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <Autocomplete
                    autoHighlight={true}
                    options={folders ?? []}
                    getOptionLabel={(folder: SandboxFolder | null) => folder?.displayName ?? ""}
                    value={values.folder}
                    onChange={(_event, newValue) => {
                      setFieldValue("folder", newValue);
                    }}
                    forcePopupIcon={false}
                    loading={folders === null}
                    disabled={formItemDisabled || !values.organization}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="IAM Folder"
                        margin="dense"
                        variant="outlined"
                        helperText={touched.folder && errors.folder ? getFirstError(errors.folder) : ""}
                        error={touched.folder && Boolean(errors.folder)}
                        data-cy="folder"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    type="number"
                    name="amount"
                    label="Budget"
                    variant="outlined"
                    margin="dense"
                    helperText={touched.amount && errors.amount ? getFirstError(errors.amount) : ""}
                    error={touched.amount && Boolean(errors.amount)}
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={formItemDisabled}
                    fullWidth
                    data-cy="budget"
                    slotProps={{
                      input: {
                        inputProps: {
                          step: "1",
                          min: "0",
                        },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      },
                    }}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    type="number"
                    name="limit"
                    label="Sandboxes per user"
                    variant="outlined"
                    margin="dense"
                    helperText={touched.limit && errors.limit ? getFirstError(errors.limit) : ""}
                    error={touched.limit && Boolean(errors.limit)}
                    value={values.limit ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={formItemDisabled}
                    fullWidth
                    data-cy="limit"
                    slotProps={{
                      input: {
                        inputProps: {
                          step: "1",
                          min: "0",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    name="namePrefix"
                    label="Sandbox name prefix"
                    variant="outlined"
                    margin="dense"
                    helperText={touched.namePrefix && errors.namePrefix ? getFirstError(errors.namePrefix) : ""}
                    error={touched.namePrefix && Boolean(errors.namePrefix)}
                    value={values.namePrefix}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={formItemDisabled}
                    fullWidth
                    data-cy="prefix"
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    name="interval"
                    label="Budget type"
                    variant="outlined"
                    margin="dense"
                    error={touched.interval && Boolean(errors.interval)}
                    value={values.interval}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={formItemDisabled}
                    fullWidth
                    select
                    data-cy="budget-type"
                  >
                    <MenuItem value="one_time">One Time</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    md: 6,
                  }}
                >
                  <TextField
                    name="action"
                    label="End of budget action"
                    variant="outlined"
                    margin="dense"
                    error={touched.action && Boolean(errors.action)}
                    value={values.action}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={formItemDisabled}
                    fullWidth
                    select
                    data-cy="budget-action"
                  >
                    <MenuItem value="send_alert">Send Alert</MenuItem>
                    <MenuItem value="disable_billing">Disable Billing</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent>
              <Grid container spacing={1}>
                {policy && (
                  <Grid size={12}>
                    <Alert variant="standard" severity="info">
                      Policy changes will affect only newly created sandboxes
                    </Alert>
                  </Grid>
                )}
                {!userRoles.sandboxAdmin && (
                  <Grid size={12}>
                    <Alert variant="standard" severity="error">
                      <AlertTitle>Error</AlertTitle>
                      Only users with the "Sandbox Admin" permission can update the policy
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              {policy && (
                <>
                  {policy.data.active ? (
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        "Disable policy to prevent the creation of additional sandboxes." +
                        " This action will not disable any preexisting sandboxes."
                      }
                    >
                      <span>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={handleDisablePolicy}
                          disabled={isSubmitting || !userRoles.sandboxAdmin}
                        >
                          Disable
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <span>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleEnablePolicy}
                        disabled={cloudConnectError || isSubmitting || !userRoles.sandboxAdmin}
                      >
                        ENABLE
                      </Button>
                    </span>
                  )}

                  <div className={classes.spacer} />
                </>
              )}
              <Button variant="text" onClick={onClose} disabled={isSubmitting}>
                {globalText.CANCEL}
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={cloudConnectError || formItemDisabled}
                data-cy="save"
                mixpanelEventId="sandbox.sandbox-policy.save"
              >
                {globalText.SAVE}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

const schema = yup.object().shape({
  namePrefix: yup
    .string()
    .matches(/^[a-z]([-a-z0-9]*[a-z0-9])?$/, {
      message: "Must have lowercase letters, digits or hyphens and must start with a lowercase letter",
      excludeEmptyString: true,
    })
    .min(6, "Must be at least 6 characters")
    .max(14, "Must be at most 14 characters")
    .required()
    .label("Name Prefix"),
  billingAccount: yup
    .object()
    .shape({
      id: yup.string().required(),
      displayName: yup.string().required(),
    })
    .required()
    .nullable()
    .label("Billing account"),
  folder: yup
    .object()
    .shape({
      name: yup.string().matches(/^folders\/\d+$/, {
        message: "Must match pattern /^folders/[0-9]+$/",
        excludeEmptyString: true,
      }),
      displayName: yup.string().required(),
    })
    .nullable(),
  organization: yup
    .object()
    .shape({
      name: yup.string().required(),
      displayName: yup.string().required(),
    })
    .required()
    .nullable()
    .label("Organization"),
  action: yup.string().oneOf(["send_alert", "disable_billing"]).required().label("Action"),
  interval: yup.string().oneOf(["monthly", "one_time"]).required().label("Sandbox interval type"),
  amount: yup
    .number()
    .integer()
    .typeError("Must be an integer number")
    .moreThan(0, "Must be at least $1")
    .lessThan(100000, "Must be at most $100,000")
    .required()
    .label("Amount"),
  limit: yup
    .number()
    .integer()
    .typeError("Must be an integer number")
    .moreThan(0, "Must be at least 1 or empty")
    .lessThan(11, "Must be at most 10")
    .nullable(),
});

type Props = OuterProps & WithAuth & WithCustomer & WithSnackbar;

const Comp = withFormik<Props, Values>({
  mapPropsToValues: () => ({
    billingAccount: null,
    folder: null,
    organization: null,
    namePrefix: "",
    action: "",
    interval: "",
    amount: null,
    limit: null,
  }),

  validateOnChange: true,
  validateOnBlur: true,
  validationSchema: schema,

  displayName: "SandboxPolicyDialogForm",

  handleSubmit: async (
    values,
    { props: { customer, policy, currentUser, onClose, showSnackbar, hideSnackbar }, setSubmitting, resetForm }
  ) => {
    if (!values.billingAccount) {
      return;
    }

    if (!values.organization) {
      return;
    }

    if (values.amount === null) {
      return;
    }

    if (values.limit === null) {
      return;
    }

    try {
      const newPolicy = {
        amount: values.amount,
        interval: values.interval,
        action: values.action,
        organization: values.organization,
        namePrefix: values.namePrefix,
        folder: values.folder,
        limit: values.limit,
        billingAccount: values.billingAccount.id,
        email: currentUser.email,
        type: "google-cloud",
        active: true,
        timestamp: serverTimestamp(),
      };
      mixpanel.track("assets.google-cloud.sandbox.set", {
        policy: JSON.stringify(newPolicy),
      });

      const batch = getBatch();
      if (policy) {
        batch.update(policy.ref, { active: false });
      }
      const customerRef = getCollection(CustomerModel).doc(customer.id);
      batch.set(customerRef.collection("sandboxPolicies").newDoc(), newPolicy);
      await batch.commit();

      resetForm();
      onClose();
      showSnackbar({
        message: "Sandbox policy saved successfully",
        variant: "success",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    } catch (error) {
      consoleErrorWithSentry(error);
      showSnackbar({
        message: "Failed to save sandbox policy",
        variant: "error",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }

    setSubmitting(false);
  },
})(SandboxPolicyDialog);
export default compose<Props, OuterProps>(withAuth, withCustomerFlat, withAssets, withSnackbar)(Comp);
