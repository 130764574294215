import { useCallback, useState } from "react";

import { Add } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { DraggableContainer } from "../../../Components/DragAndDrop/DraggableContainer";
import { DraggableGroup } from "../../../Components/DragAndDrop/DraggableGroup";
import { AllocationDraggableItem } from "./AllocationDraggableItem";
import AllocationSegment from "./AllocationSegment";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { UnallocatedCosts } from "./UnallocatedCosts";

export const AllocationDragAndDrop = () => {
  const [hasUnallocatedCosts, setHasUnallocatedCosts] = useState(false);
  const { items, addItem, setItems } = useAllocationDndStore();

  const handleAddAllocation = useCallback(() => {
    addItem(crypto.randomUUID());
  }, [addItem]);

  const handleAddUnallocatedCosts = useCallback(() => {
    setHasUnallocatedCosts(true);
  }, [setHasUnallocatedCosts]);

  const handleRemoveUnallocatedCosts = useCallback(() => {
    setHasUnallocatedCosts(false);
  }, [setHasUnallocatedCosts]);

  const renderDraggingItem = useCallback(
    (id: string) => <AllocationDraggableItem initialAttributionID={id} grabbing />,
    []
  );

  const renderContent = useCallback(
    (items: string[]) => (
      <>
        {items.map((id, index) => (
          <AllocationDraggableItem attributionNumber={index} initialAttributionID={id} key={id} />
        ))}
      </>
    ),
    []
  );

  return (
    <Stack gap={2}>
      <Stack>
        <DraggableContainer values={items} renderDraggingItem={renderDraggingItem} onItemsOrderChanged={setItems}>
          <Stack direction="column">
            <DraggableGroup id="main" renderContent={renderContent} />
          </Stack>
        </DraggableContainer>
        {hasUnallocatedCosts && (
          <Box>
            <Button variant="outlined" size="medium" startIcon={<Add />} onClick={handleAddAllocation}>
              {allocationTxt.ADD_ALLOCATION}
            </Button>
          </Box>
        )}
      </Stack>
      {hasUnallocatedCosts && (
        <Stack gap={2}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >{`Allocation Rule ${items.main.length + 1}`}</Typography>
          <UnallocatedCosts onRemove={handleRemoveUnallocatedCosts} />
        </Stack>
      )}
      {!hasUnallocatedCosts && (
        <AllocationSegment onAddAllocation={handleAddAllocation} onAddUnallocatedCosts={handleAddUnallocatedCosts} />
      )}
    </Stack>
  );
};
