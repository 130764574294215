import { MetadataCloud } from "@doitintl/cmp-models";
import uniq from "lodash/uniq";

import { type MetadataOption } from "../../../types";

// dimensionCloudProvidersValues picks only the relevant values from a dimension
// based on the providers that was provided.
export const dimensionProvidersValues = (dimension: MetadataOption, providers: string[]) => {
  const result: string[] = [];

  providers.forEach((cp) => {
    let cloudProviderValues: string[] = [];

    switch (cp) {
      case MetadataCloud.AMAZON_WEB_SERVICES:
        cloudProviderValues = dimension?._values?.awsValues ?? [];
        break;
      case MetadataCloud.GOOGLE_CLOUD:
        cloudProviderValues = dimension?._values?.gcpValues ?? [];
        break;
      case MetadataCloud.MICROSOFT_AZURE:
        cloudProviderValues = dimension?._values?.azureValues ?? [];
        break;
      case MetadataCloud.DATAHUB:
        cloudProviderValues = dimension?._values?.datahub ?? [];
        break;
      case MetadataCloud.SNOWFLAKE:
        cloudProviderValues = dimension?._values?.snowflake ?? [];
        break;
      case MetadataCloud.DATADOG:
        cloudProviderValues = dimension?._values?.datadog ?? [];
        break;

      default:
    }

    result.push(...cloudProviderValues);
  });

  return uniq(result);
};
