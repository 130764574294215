import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useShallow } from "zustand/react/shallow";

import { useApiContext } from "../../../api/context";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import LoadingButton from "../../../Components/LoadingButton";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { type CloudAnalyticsHistoryState } from "../types";
import { AllocationDragAndDrop } from "./AllocationDragGroup";
import { createAllocation } from "./db";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { formStoreManager } from "./hooks/useAllocationFormState";

export const Allocation = ({ edit }: { edit?: boolean }) => {
  const history = useHistory<CloudAnalyticsHistoryState>();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const items = useAllocationDndStore(useShallow((store) => store.items));

  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${customer.id}/analytics/allocations`);
  }, [history, customer.id]);

  const handleSave = useCallback(
    async (name?: string) => {
      const rules = items.main.map((item) => formStoreManager.getStore(item).getState().values);
      const formattedValues = {
        name: name || rules[0].name,
        description: name || rules[0].name,
        rules: rules.map((rule) => ({
          name: rule.name,
          action: "create",
          filters: rule.filterFields,
          formula: rule.formula,
          draft: false,
        })),
      };
      try {
        if (!edit) {
          await createAllocation(api, customer.id, formattedValues);
          successSnackbar(allocationTxt.SUCCESSFULLY_CREATED);
          setOpenNameDialog(false);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar(allocationTxt.FAILED_TO_SAVE);
        setOpenNameDialog(false);
      }
    },
    [api, customer.id, edit, errorSnackbar, items.main, successSnackbar]
  );

  const handleSaveDialog = useCallback(() => {
    if (items.main.length > 1) {
      setOpenNameDialog(true);
    } else {
      handleSave();
    }
  }, [handleSave, items.main.length]);

  return (
    <Box sx={{ height: "calc(100vh - 110px)", display: "grid", gridTemplateRows: "1fr 1px 60px" }}>
      <Container maxWidth="md" sx={{ overflow: "auto", mt: 2, mb: 4 }}>
        <Stack justifyContent="flex-start" alignItems="flex-start" rowGap={1}>
          <Stack direction="row" alignItems="center">
            <IconButton color="inherit" onClick={handleClose} size="large" sx={{ ml: "-25px" }}>
              <BackIcon />
            </IconButton>
            <Typography variant="h4">{allocationTxt.ALLOCATION_TITLE}</Typography>
          </Stack>
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {allocationTxt.ALLOCATION_DESCRIPTION}
            </Typography>
          </Box>
          <AllocationDragAndDrop />
        </Stack>
      </Container>

      <Divider />
      <Container maxWidth="md">
        <Stack direction="row" justifyContent="flex-end" spacing={2} p={1}>
          <Button color="primary" onClick={handleClose} variant="text" size="large" sx={{ mr: 3 }}>
            {allocationTxt.ALLOCATION_CANCEL}
          </Button>

          <LoadingButton
            color="primary"
            loading={false}
            onClick={handleSaveDialog}
            variant="contained"
            mixpanelEventId="allocation.save"
            data-cy="stepper-complete-button"
            size="large"
          >
            {allocationTxt.ALLOCATION_SAVE}
          </LoadingButton>
        </Stack>
      </Container>
      <SaveDialog
        title={allocationTxt.ALLOCATION_SAVE_TITLE}
        open={openNameDialog}
        description={allocationTxt.ALLOCATION_SAVE_DESCRIPTION}
        onClose={() => {
          setOpenNameDialog(false);
        }}
        onSave={handleSave}
        textFieldProps={{ label: allocationTxt.ALLOCATION_NAME_LABEL }}
      />
    </Box>
  );
};
