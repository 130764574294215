import { useEffect, useState } from "react";

import { type ExecutionNodeModel } from "@doitintl/cmp-models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CircularProgress, Collapse, IconButton, TableCell, TableRow } from "@mui/material";

import StatusChip, { nodeExecutionStatus } from "../Components/StatusChip";
import Accordion from "./Accordian/Accordion";
import { useExecutionNodeAudit } from "./hooks/useExecutionNode";
import { calculateDuration, createExecutionNodeDetails, formatCloudFlowRunDetailsTime } from "./utils";

type RunDetailsRowProps = {
  customerId: string;
  flowId: string;
  row: ExecutionNodeModel;
};

const CloudflowRunDetailsRow = ({ customerId, flowId, row }: RunDetailsRowProps) => {
  const [expanded, setExpanded] = useState(false);
  const {
    data: executionNodeAudit,
    isLoading: executionNodeAuditLoading,
    error: executionNodeAuditError,
  } = useExecutionNodeAudit(customerId, flowId, row.executionId, row.nodeId, expanded, row.status);

  const executionNodeDetails = createExecutionNodeDetails(
    executionNodeAudit?.parameters,
    executionNodeAudit?.results,
    row.approvalDetails
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (executionNodeAuditError) {
      setExpanded(false);
    }
  }, [executionNodeAuditError]);

  return (
    <>
      <TableRow
        sx={{
          "& > *": expanded ? { borderBottom: "unset" } : {},
        }}
      >
        <TableCell>{row.nodeName}</TableCell>
        <TableCell>
          <StatusChip status={row.status} statusMap={nodeExecutionStatus} />
        </TableCell>
        <TableCell>{formatCloudFlowRunDetailsTime(row.startedAt)}</TableCell>
        <TableCell>{formatCloudFlowRunDetailsTime(row.finishedAt)}</TableCell>
        <TableCell>{calculateDuration(row.status, row.startedAt, row.finishedAt)}</TableCell>
        <TableCell>
          <IconButton
            onClick={handleExpandClick}
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s",
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ padding: 0, borderBottom: expanded ? "1px solid divider" : "none" }}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {expanded && (
              <Box sx={{ margin: "auto", maxWidth: "95vw" }}>
                {executionNodeAuditLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 200,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Accordion executionError={row.error} executionNodeDetails={executionNodeDetails} />
                )}
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CloudflowRunDetailsRow;
