import {
  type AttributionFilter,
  type Collaborators,
  type DashboardModelAttributionModel,
  type PublicAccess,
} from "@doitintl/cmp-models";
import { type AxiosInstance, type Method } from "axios";
import { type Dict } from "mixpanel-browser";

import { globalText, httpMethods } from "../../../assets/texts";
import mixpanel from "../../../utils/mixpanel";

export const deleteAllocations = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string[],
  mixpanelFrom?: string
) => {
  mixpanel.track("analytics.attributions.delete", { allocationId, ...(mixpanelFrom && { from: mixpanelFrom }) });
  return api.request({
    method: globalText.DELETE as Method,
    url: `/v1/customers/${customerId}/analytics/allocations`,
    data: allocationId,
  });
};

export const shareAttribution = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string,
  collaborators: Collaborators,
  role: PublicAccess
) => {
  await api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customerId}/analytics/attributions/share`,
    data: {
      allocationId,
      collaborators,
      role,
    },
  });
};

export const createAllocation = async (
  api: AxiosInstance,
  customerId: string,
  data: {
    name: string;
    description: string;
    rules: { name: string; action: string; filters: AttributionFilter[]; formula: string; draft: boolean }[];
  },
  allocationId?: string,
  mixpanelProperties?: Dict
) => {
  mixpanel.track("analytics.allocations.create", { allocationId, ...mixpanelProperties });
  return api.request({
    method: httpMethods.POST as Method,
    url: `/v1/customers/${customerId}/analytics/allocations`,
    data,
  });
};

export const updateAllocation = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string,
  data: Partial<DashboardModelAttributionModel>,
  mixpanelProperties?: Dict
) => {
  mixpanel.track("analytics.allocations.update", {
    allocationId,
    ...mixpanelProperties,
  });
  return api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customerId}/analytics/allocations/${allocationId}`,
    data,
  });
};
