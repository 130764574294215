import { useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { cloudflowTexts, globalText } from "../../../assets/texts";
import { useModalManager } from "../CloudflowBuilder/Common/CloudflowModalsProvider";
import { getOptionByTrigger } from "../CloudflowBuilder/utils/triggerUtils";
import { CHANGE_TRIGGER_OPTIONS } from "../types";

type ChangeTriggerDialogProps = {
  onConfirm: (choice: CHANGE_TRIGGER_OPTIONS) => void;
  open: boolean;
  nodeType: CloudFlowNodeType;
};

export const ChangeTriggerDialog = ({ open, onConfirm, nodeType }: ChangeTriggerDialogProps) => {
  const [newChoice, setNewChoice] = useState<CHANGE_TRIGGER_OPTIONS>(CHANGE_TRIGGER_OPTIONS.NONE);
  const initialChoice = getOptionByTrigger(nodeType);
  const { closeModal } = useModalManager();
  const choice = newChoice !== CHANGE_TRIGGER_OPTIONS.NONE ? newChoice : initialChoice;

  return (
    <Dialog
      open={open}
      onClose={() => {
        closeModal("trigger");
      }}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>{cloudflowTexts.SELECT_TRIGGER}</DialogTitle>
      <DialogContent>
        <FormControl
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <RadioGroup
            name="trigger"
            sx={{
              gap: 2,
            }}
          >
            {[
              {
                name: "Scheduled",
                description: "Trigger set on a custom schedule",
                value: CHANGE_TRIGGER_OPTIONS.SCHEDULED,
              },
              {
                name: "Manual",
                description: "Triggered manually by the user",
                value: CHANGE_TRIGGER_OPTIONS.MANUAL,
              },
            ].map((trigger) => (
              <FormControlLabel
                key={trigger.value}
                value={trigger.value}
                sx={{
                  width: "100%",
                  m: 0,
                }}
                control={
                  <Card
                    sx={{
                      width: "100%",
                      borderColor: choice === trigger.value ? "primary.main" : undefined,
                    }}
                  >
                    <CardHeader
                      subheader={trigger.description}
                      title={trigger.name}
                      titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
                      subheaderTypographyProps={{ variant: "caption" }}
                      avatar={<Radio checked={choice === trigger.value} />}
                      sx={{
                        ".MuiCardHeader-action": { alignSelf: "center" },
                        p: 1,
                        pr: 2,
                        cursor: "pointer",
                        width: "100%",
                      }}
                    />
                  </Card>
                }
                onChange={() => {
                  setNewChoice(trigger.value);
                }}
                label={""}
                labelPlacement={"bottom"}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeModal("trigger");
          }}
        >
          {globalText.CANCEL}
        </Button>
        <Button
          onClick={() => {
            onConfirm(choice);
          }}
          disabled={newChoice === CHANGE_TRIGGER_OPTIONS.NONE || newChoice === initialChoice}
          color="primary"
          variant="contained"
        >
          {globalText.CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
