import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type Collaborators, type PublicAccess, Roles, type SlackChannel } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Budget } from "../../../types";
import { formatToLocaleTimestamp } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { type LabelWithRef } from "../labels/types";
import { type BudgetRowActions } from "../utilities";
import BudgetUtilizationProgressBar from "./BudgetUtilizationProgressBar";
import { buildBudgetViewPath, useBudgetThreeDotsMenu } from "./hooks";

const { budgets: budgetIds } = cloudAnalytics;

type BudgetRowProps = {
  actions: BudgetRowActions;
  data: BudgetRowData;
  tierLimitReached: boolean;
};

export type BudgetRowData = {
  name: string;
  timeModified: FirestoreTimestamp;
  timeCreated: FirestoreTimestamp;
  amount: string;
  currentSpend: string;
  varianceToDate: string;
  budgetAmountToDate: string;
  period?: string;
  currentPercentage: number;
  budgetProjectedDate: string;
  isRecipient: boolean;
  progressBarColor: { bar: any; root: string };
  snapshotId: string;
  collaborators: Collaborators;
  public: PublicAccess;
  recipients: string[];
  recipientsSlackChannels: SlackChannel[];
  row: Budget;
  labels: LabelWithRef[] | undefined;
};

export const BudgetRow = ({ actions, data, tierLimitReached }: BudgetRowProps) => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const { setOpenDeleteDialog, setSelected, setShareDialogOpen } = actions;
  const rowLabels = useMemo(
    () => data.labels?.filter((l) => data.row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [data.labels, data.row.data.labels]
  );

  const threeDotsMenu = useBudgetThreeDotsMenu({
    row: { ...data.row, ref: data.row.ref, id: data.row.ref.id },
    handleShare: () => {
      setSelected([data.row]);
      setShareDialogOpen(true);
    },
    handleDelete: () => {
      setSelected([data.row]);
      setOpenDeleteDialog(true);
    },
    tierLimitReached,
  });

  const viewBudgetUrl = buildBudgetViewPath(customer.ref.id, data.row.ref.id);

  return (
    <CellsWrapper>
      <TableCell data-cy={budgetIds.browser.budgetName}>
        <Stack spacing={1}>
          <Typography
            component={RouterLink}
            to={viewBudgetUrl}
            color="inherit"
            variant="body2"
            sx={{
              mb: 1,
            }}
          >
            {data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <TableCell data-cy={budgetIds.browser.budgetOwner}>
        {data.collaborators.find((c) => c.role === Roles.OWNER)?.email}
      </TableCell>
      <TableCell data-cy={budgetIds.browser.budgetPeriod}>{data.period}</TableCell>
      <TableCell data-cy={budgetIds.browser.budgetAmount}>{data.amount}</TableCell>
      <TableCell>{data.budgetAmountToDate}</TableCell>
      <TableCell>{data.currentSpend}</TableCell>
      <TableCell>{data.varianceToDate}</TableCell>
      <TableCell data-cy={budgetIds.browser.budgetPercent}>
        <BudgetUtilizationProgressBar value={data.currentPercentage} color={data.progressBarColor} />
      </TableCell>
      <TableCell>{formatToLocaleTimestamp(data.timeCreated)}</TableCell>
      <TableCell>{formatToLocaleTimestamp(data.timeModified)}</TableCell>
      <TableCell data-cy={budgetIds.browser.budgetOptions}>
        <Box
          sx={{
            m: -1.5,
          }}
        >
          {threeDotsMenu}
        </Box>
      </TableCell>
    </CellsWrapper>
  );
};
