import { useEffect, useMemo, useState } from "react";

import { BillingModel, type CurrencyCode, CurrencyCodes, CustomerModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce, useDocumentDataOnce } from "@doitintl/models-firestore";
import sum from "lodash/sum";

import { asyncConvertCurrencyTo } from "../../../Context/AsyncCurrencyConverterContext";
import { type FirestoreTimestamp, TimestampFromDate } from "../../../utils/firebase";
import { type Invoice, MonthEnum } from "../types";
import { type CustomerAdjustment, type Explainer, type ExplainerCostDifference, type ExplainerSummary } from "./types";

type CostValue = {
  cost: number;
  cost_type: string;
};

function getCostByType(costs: CostValue[] | undefined, costType: string, exchangeRate: number): number | undefined {
  if (!costs) {
    return;
  }
  const found = costs.find((value) => value.cost_type === costType);
  return found ? found.cost * exchangeRate : undefined;
}

function parseSupportCharges(data: any, exchangeRate: number): ExplainerSummary["supportCharges"] {
  if (!data) {
    return [];
  }
  return data.map((charge: any) => ({
    accountId: charge.project_id,
    baseCost: charge.base_cost * exchangeRate,
    supportLevel: charge.service_description,
    total: charge.cost * exchangeRate,
    description: charge.description,
  }));
}

function parseExplainerSummary(data: any, exchangeRate: number): ExplainerSummary {
  const services: ExplainerSummary["serviceCharges"] = {
    usage: getCostByType(data.serviceCharges, "usage", exchangeRate),
    savingsPlanUsage: getCostByType(data.serviceCharges, "savingsPlanCoveredUsage", exchangeRate),
    flexsaveCoveredUsage: getCostByType(data.serviceCharges, "flexsaveCoveredUsage", exchangeRate),
    savingsPlanUpfrontFee: getCostByType(data.serviceCharges, "savingsPlanUpfrontFee", exchangeRate),
  };

  const discounts: ExplainerSummary["discounts"] = {
    edp: getCostByType(data.discounts, "edpDiscount", exchangeRate),
    spp: getCostByType(data.discounts, "sppDiscount", exchangeRate),
    privateRate: getCostByType(data.discounts, "privateRateDiscount", exchangeRate),
    bundled: getCostByType(data.discounts, "bundledDiscount", exchangeRate),
  };

  const otherCharges: ExplainerSummary["otherCharges"] = {
    ocb: getCostByType(data.otherCharges, "ocbCharges", exchangeRate),
    savingsPlanRecurringFee: getCostByType(data.otherCharges, "savingsPlanRecurringFee", exchangeRate),
    flexsave: getCostByType(data.otherCharges, "flexsaveCharges", exchangeRate),
    reservationRecurringFee: getCostByType(data.otherCharges, "reservationRecurringFee", exchangeRate),
  };

  const savings: ExplainerSummary["savings"] = {
    flexsave: getCostByType(data.savings, "flexsaveSavings", exchangeRate),
    planNegation: getCostByType(data.savings, "savingsPlanNegation", exchangeRate),
    reservationAppliedUsage: getCostByType(data.savings, "reservationAppliedUsage", exchangeRate),
  };

  const supportCharges = parseSupportCharges(data.supportCharges.details, exchangeRate);
  return {
    refunds: getCostByType(data.refunds, "refund", exchangeRate),
    savings,
    savingsTotal: sum(Object.values(savings)),
    total: data.total * exchangeRate,
    tax: getCostByType(data.tax, "tax", exchangeRate),
    credits: getCostByType(data.credits, "credit", exchangeRate),
    discounts,
    supportCharges,
    supportChargesTotal: sum(supportCharges.map((charge) => charge.total)),
    discountTotal: sum(Object.values(discounts)),
    serviceCharges: services,
    servicesTotal: sum(Object.values(services)),
    otherCharges,
    customerOtherCharges: [],
    otherChargesTotal: sum(Object.values(otherCharges)),
  };
}

function adjustTimestamp(timestamp: FirestoreTimestamp) {
  const date = timestamp.toDate();
  const adjustedDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
  adjustedDate.setUTCHours(0, 0, 0, 0);
  return TimestampFromDate(adjustedDate);
}

export function useCustomerInvoiceAdjustments(
  invoice: Invoice | null
): [CustomerAdjustment[] | undefined, boolean, boolean] {
  const query = useMemo(() => {
    if (!invoice?.customer?.id) {
      return;
    }
    return getCollection(CustomerModel)
      .doc(invoice.customer.id)
      .collection("customerInvoiceAdjustments")
      .where("type", "==", "amazon-web-services")
      .where("invoiceMonths", "array-contains", adjustTimestamp(invoice.IVDATE));
  }, [invoice?.IVDATE, invoice?.customer?.id]);

  const [data, loading, error] = useCollectionDataOnce(query);

  return useMemo(() => {
    if (data && data?.length > 0 && invoice) {
      const adjustments = data.map((d) => ({
        amount: d.amount,
        details: d.details,
      }));

      const invoiceItemsDescriptions = invoice.INVOICEITEMS?.map((item) => item.DETAILS);
      const filtered = adjustments.filter((adjustment) => invoiceItemsDescriptions?.includes(adjustment.details));

      return [filtered, false, false];
    }
    return [[], loading, Boolean(error)];
  }, [data, invoice, loading, error]);
}

type State = {
  explainerData?: Explainer;
  isLoading: boolean;
  isError: boolean;
};

const convertExplainerCostDifferenceCurrency = (costDifference: ExplainerCostDifference, exchangeRate: number) => {
  const result: ExplainerCostDifference = {};
  for (const [costName, { doit, aws }] of Object.entries(costDifference)) {
    result[costName] = { doit: {}, aws: {} };

    for (const [category, cost] of Object.entries(aws)) {
      result[costName].aws[category] = cost * exchangeRate;
    }

    for (const [category, cost] of Object.entries(doit)) {
      result[costName].doit[category] = cost * exchangeRate;
    }
  }

  return result;
};

export function useInvoiceExplainer(invoice: Invoice | null): {
  explainer: Explainer | undefined;
  isLoading: boolean;
  isError: boolean;
  setCurrency: (currency: CurrencyCode) => void;
} {
  const [adjustments, loadingAdjustments] = useCustomerInvoiceAdjustments(invoice);
  const [currency, setCurrency] = useState<CurrencyCode>("USD");
  const [exchangeRate, setExchangeRate] = useState<number>(1);

  useEffect(() => {
    if (invoice) {
      if (invoice.SYMBOL === CurrencyCodes.ILS && currency === CurrencyCodes.ILS) {
        setExchangeRate(invoice.INVOICEITEMS[0].EXCH ?? 1);
      } else {
        asyncConvertCurrencyTo(1, invoice.IVDATE.toDate(), "USD", currency).then((rate) => {
          setExchangeRate(rate);
        });
      }
    }
  }, [currency, invoice]);

  const ref = useMemo(() => {
    if (!invoice?.ROTL_CMP_NUMBER) {
      return;
    }

    let year: string;
    let month: number;

    try {
      /**
       First, try to get the date from DETAILS.
       Invoice DETAILS are generated at server/services/scheduled-tasks/invoicing/main.go with the Go "January 2006" format, e.g. "Covering December 2024".
       */
      const [_, invoiceDetailsMonth, invoiceDetailsYear] = invoice.DETAILS.split(" ", 3);

      if (Number.isNaN(invoiceDetailsYear)) {
        throw Error("Year missing in invoice details");
      }

      year = invoiceDetailsYear;
      month = MonthEnum[invoiceDetailsMonth];
    } catch {
      /**
       The Finance team are allowed to change IVDATE, and in those cases DETAILS is more reliable, because IVDATE could be in a different month.
       Example where the IVDATE and DETAILS don't match, and the DETAILS have the right month: https://console.doit.com/customers/TvIT20WNDHPxjyUmKVE1/invoices/IghnzEPM5DXjwQVCaMtZ/IN244004936

       The Finance team are also allowed to change the DETAILS to a free text, for example https://console.doit.com/customers/WagkQfCsAvuheX1ftAYz/invoices/3lpStOWG5pbk3TlTRJ4C/IK24000858
       In those cases, the IVDATE is the last resort to get the date string.
       */
      const date = invoice.IVDATE.toDate();

      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
    }

    const dateString = `${year}-${month.toString().padStart(2, "0")}`;

    return getCollection(BillingModel)
      .doc("invoicing")
      .collection("invoicingMonths")
      .doc(dateString)
      .collection("monthInvoices")
      .doc(invoice.entity.id)
      .collection("entityInvoices")
      .doc(invoice.ROTL_CMP_NUMBER);
  }, [invoice]);

  const [doc, loading, error] = useDocumentDataOnce(ref);

  const [state, setState] = useState<State>({
    explainerData: undefined,
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    const fetchExplainerData = async () => {
      if (doc?.explainer && !loadingAdjustments) {
        const data: Explainer = {
          summary: {
            aws: parseExplainerSummary(doc.explainer.summary.aws, exchangeRate),
            doit: parseExplainerSummary(doc.explainer.summary.doit, exchangeRate),
          },
          service: convertExplainerCostDifferenceCurrency(doc.explainer.service, exchangeRate),
          account: convertExplainerCostDifferenceCurrency(doc.explainer.account, exchangeRate),
        };

        if (invoice) {
          data.summary.doit.tax = await asyncConvertCurrencyTo(
            invoice.VAT ?? 0,
            invoice.IVDATE,
            invoice.SYMBOL,
            currency
          );
          data.summary.doit.total += data.summary.doit.tax ?? 0;

          let extraRefundsSum = 0;
          let allAdjustmentsSum = 0;
          let otherChargesAdjustmentsSum = 0;
          adjustments?.forEach((a) => {
            allAdjustmentsSum += a.amount;
            if (a.amount < 0) {
              extraRefundsSum += a.amount;
            } else {
              otherChargesAdjustmentsSum += a.amount;
              data.summary.doit.customerOtherCharges.push(a);
            }
          });
          data.summary.doit.refunds = extraRefundsSum + (data.summary.doit.refunds ?? 0);
          data.summary.doit.otherChargesTotal += otherChargesAdjustmentsSum;
          data.summary.doit.total += allAdjustmentsSum;
        }

        return {
          explainerData: data,
          isLoading: false,
          isError: false,
        };
      } else {
        return {
          explainerData: undefined,
          isLoading: loading,
          isError: Boolean(error),
        };
      }
    };

    fetchExplainerData()
      .then(({ explainerData, isLoading, isError }) => {
        setState({
          explainerData,
          isLoading,
          isError,
        });
      })
      .catch(() => {
        setState({
          explainerData: undefined,
          isLoading: false,
          isError: true,
        });
      });
  }, [doc?.explainer, loadingAdjustments, loading, error, invoice, adjustments, exchangeRate, currency]);

  return { explainer: state.explainerData, isLoading: state.isLoading, isError: state.isError, setCurrency };
}
