import { type AxiosInstance } from "axios";

import {
  type AttachBlueprintInput,
  type CloudflowDTO,
  type CreateCloudflowInput,
  type CreateNodeResponseDTO,
  type CreateOrUpdateNode,
  type CreateOrUpdateNodesResponseDTO,
  type DeleteCloudflowInput,
  type DeleteNodesResponseDTO,
  type GetNodeAuditDetailsDTO,
  type ProjectInfo,
  type Region,
  type TestNodeRequest,
  type TestNodeResponse,
  type TriggerCloudflowResponce,
  type UpdateCloudflowInput,
  type UpdateCloudflowNodes,
} from "./types";

export const getCloudflowWithNodes = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.get<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}?nodes=true`);

export const createCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  createCloudflowInput: CreateCloudflowInput
) => api.post<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow`, createCloudflowInput);

export const createCloudflowFromTemplate = async (api: AxiosInstance, customerId: string, templateID: string) =>
  api.post<{ id: string }>(`/v1/customers/${customerId}/cloudflow/create-from-template/${templateID}`);

export const createOrUpdateNode = async (
  api: AxiosInstance,
  customerId: string,
  flowId: string,
  data: CreateOrUpdateNode
) => api.put<CreateNodeResponseDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/node`, data);

export const deleteNode = async (api: AxiosInstance, customerId: string, flowId: string, nodeId: any) =>
  api.delete<DeleteNodesResponseDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/node/${nodeId}`);

export const getExecutionNodeAudit = async (
  api: AxiosInstance,
  customerId: string,
  flowId: string,
  executionId: string,
  nodeId: string
) =>
  api.get<GetNodeAuditDetailsDTO>(
    `/v1/customers/${customerId}/cloudflow/${flowId}/execution/${executionId}/node/${nodeId}/audit`
  );

export const deleteCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  deleteCloudflowInput: DeleteCloudflowInput
) => api.delete<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${deleteCloudflowInput.id}`);

export const updateCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  flowId: string,
  updateCloudflowInput: UpdateCloudflowInput
) => api.put<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}`, updateCloudflowInput);

// Add/Update/Delete Cloudflow Nodes
export const updateCloudflowNodes = async (api: AxiosInstance, customerId: string, nodes: UpdateCloudflowNodes) =>
  api.post<CreateOrUpdateNodesResponseDTO>(`/v1/customers/${customerId}/cloudflow/update-nodes`, nodes);

export const triggerCloudflow = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.post<TriggerCloudflowResponce>(`/orchestrator/customers/${customerId}/trigger-cloudflow/${flowId}`);

export const testNode = async (api: AxiosInstance, customerId: string, data: TestNodeRequest) =>
  api.post<TestNodeResponse>(`/orchestrator/customers/${customerId}/test-node`, data);

export const getAwsRegions = async (api: AxiosInstance, customerId: string, accountId: string) =>
  api.get<Region[]>(`/v1/customers/${customerId}/cloudflow/get-aws-regions/${accountId}`);

export const publishCloudflow = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.post<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/publish`);

export const unpublishCloudflow = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.post<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/unpublish`);

export const getGcpProjects = async (api: AxiosInstance, customerId: string, organizationName: string) =>
  api.post<ProjectInfo[]>(`/v1/customers/${customerId}/cloudflow/get-gcp-projects`, { organizationName });

export const attachBlueprint = async (
  api: AxiosInstance,
  customerId: string,
  cloudflowId: string,
  attachBlueprintInput: AttachBlueprintInput
) =>
  api.post<CreateOrUpdateNodesResponseDTO>(
    `/v1/customers/${customerId}/cloudflow/${cloudflowId}/attach-blueprint`,
    attachBlueprintInput
  );
