import { Typography } from "@mui/material";

import { TextWithTooltip } from "./TextWithTooltip";

export type LimitedListWithTooltipProps = {
  items: string[];
  limit: number;
  title: string;
  noItemsText?: string;
};

export const LimitedListWithTooltip = ({ items, limit, title, noItemsText = "N/A" }: LimitedListWithTooltipProps) => {
  if (!items.length) {
    return noItemsText;
  }

  if (items.length <= limit) {
    return items.join(", ");
  }

  return (
    <TextWithTooltip
      triggerText={`${items.slice(0, limit).join(", ")} +${items.length - limit}`}
      tooltipComponent={
        <>
          <Typography variant="subtitle1" fontWeight={500} mb={1}>
            {title}
          </Typography>
          <Typography variant="body2">{items.join(", ")}</Typography>
        </>
      }
    />
  );
};
