import { useCallback, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  ContractTypeAws,
  ContractTypeAwsPpaCloudfront,
  ContractTypeAwsStandalone,
  ContractTypeDoitCloudIntelligence,
  ContractTypeDoitCloudNavigator,
  ContractTypeDoitCloudSolve,
  ContractTypeDoitOneTimeServiceFee,
  ContractTypeGoogleCloud,
  ContractTypeGoogleCloudPartnerLedPremiumSupport,
  ContractTypeGoogleCloudStandalone,
  ContractTypeGSuite,
  ContractTypeLooker,
  ContractTypeMicrosoftAzure,
  ContractTypeOffice365,
  ContractTypeSaasConsoleAws,
  ContractTypeSaasConsoleGcp,
} from "@doitintl/cmp-models";
import { Container, Skeleton, Stack } from "@mui/material";

import { BottomAppBar } from "../../../Components/BottomAppBar";
import { Guard } from "../../../Components/Guard";
import { useAuthContext } from "../../../Context/AuthContext";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { useCustomerContract } from "../hooks";
import ContractPageAws from "./ContractPageAws";
import ContractPageDci from "./ContractPageDci";
import ContractPageGcp from "./ContractPageGcp";
import ContractPageGsuite from "./ContractPageGsuite";
import { ContractPagePlps } from "./ContractPagePlps";
import ContractPageSubscription from "./ContractPageSubscription";

const ContractPage = () => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const { contractId } = useParams<{ contractId: string }>();
  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const customerId = customer.id;
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const { contract, loading } = useCustomerContract(contractId);

  const accountManagerName = useMemo<string | undefined>(() => {
    if (!accountManagers) {
      return;
    }

    return accountManagers.find((am) => am.id === contract?.accountManager?.id)?.name;
  }, [accountManagers, contract?.accountManager?.id]);

  const permitted = useMemo<boolean>(() => {
    if (!loading && !contract) {
      // Loading's done with no yielded return - time to return 404.
      return false;
    }
    if (isDoitEmployee) {
      return true;
    }

    const userCustomerId = userModel?.customer?.ref.id;
    const userMatchesUrlCustomer = userCustomerId === customerId;
    const userMatchesContractCustomer = userCustomerId === contract?.customer.id;

    return userMatchesUrlCustomer && userMatchesContractCustomer;
  }, [contract, customerId, isDoitEmployee, loading, userModel?.customer?.ref.id]);

  const buildPage = useCallback(() => {
    if (!contract?.type) {
      return null;
    }

    switch (contract.type) {
      case ContractTypeAws:
      case ContractTypeAwsStandalone:
      case ContractTypeAwsPpaCloudfront:
      case ContractTypeSaasConsoleAws:
        return <ContractPageAws contractId={contractId} contract={contract} accountManagerName={accountManagerName} />;
      case ContractTypeGoogleCloud:
      case ContractTypeGoogleCloudStandalone:
      case ContractTypeLooker:
      case ContractTypeSaasConsoleGcp:
        return <ContractPageGcp contract={contract} accountManagerName={accountManagerName} contractId={contractId} />;
      case ContractTypeDoitCloudNavigator:
      case ContractTypeDoitCloudSolve:
      case ContractTypeDoitOneTimeServiceFee:
        return <ContractPageSubscription contract={contract} accountManagerName={accountManagerName} />;
      case ContractTypeGSuite:
      case ContractTypeMicrosoftAzure:
      case ContractTypeOffice365:
        return (
          <ContractPageGsuite contract={contract} accountManagerName={accountManagerName} contractId={contractId} />
        );
      case ContractTypeGoogleCloudPartnerLedPremiumSupport:
        return <ContractPagePlps contract={contract} accountManagerName={accountManagerName} />;
      case ContractTypeDoitCloudIntelligence:
        return <ContractPageDci contract={contract} accountManagerName={accountManagerName} />;
      default:
        return <ContractPageGcp contract={contract} accountManagerName={accountManagerName} contractId={contractId} />;
    }
  }, [accountManagerName, contract, contractId]);

  const handleBack = useCallback<() => void>(() => {
    history.push(`/customers/${customerId}/contracts/contracts-list`);
  }, [customerId, history]);

  const loadingPlaceholder = (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Skeleton variant="text" height="2rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
      </Stack>
      <Stack spacing={2}>
        <Skeleton variant="text" height="2rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
      </Stack>
    </Stack>
  );

  return (
    <Container maxWidth="sm" sx={{ pt: "3rem", pb: "6rem" }}>
      {loading ? (
        <>
          {loadingPlaceholder}
          <BottomAppBar
            disabled={false}
            handlePrimaryButtonClicked={handleBack}
            primaryButtonName="Close"
            maxWidth="sm"
          />
        </>
      ) : (
        <Guard isAllowed={permitted}>
          {buildPage()}
          <BottomAppBar
            disabled={false}
            handlePrimaryButtonClicked={handleBack}
            primaryButtonName="Close"
            maxWidth="sm"
          />
        </Guard>
      )}
    </Container>
  );
};

export default ContractPage;
