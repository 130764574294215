import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";

import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";

type MPARemoveRetiredDialogProps = {
  onCancel: () => void;
  onRemoveRetired: () => Promise<void>;
  masterPayerAccount: MasterPayerAccountsModel;
};

export const MPARemoveRetiredDialog = ({
  onCancel,
  onRemoveRetired,
  masterPayerAccount,
}: MPARemoveRetiredDialogProps) => {
  const [isRemoving, onRemoveRetiredHandler] = useAsyncLoadingFunction(() => onRemoveRetired().then(onCancel));

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        <Box sx={{ typography: "h3" }}>Remove retired MPA</Box>
        <Box sx={{ typography: "body2", color: "text.secondary" }}>
          {masterPayerAccount.name} {masterPayerAccount.domain ? "|" : ""} {masterPayerAccount.domain}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <DialogContentText>
            You’re about to remove the retired MPA: {masterPayerAccount.name}
            {masterPayerAccount.domain ? " used by " : ""}
            {masterPayerAccount.domain}
          </DialogContentText>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton onClick={onRemoveRetiredHandler} autoFocus loading={isRemoving} variant="contained">
          Remove retired MPA
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
