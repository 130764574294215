import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomerSegmentValues, DoitRole, TierPackageTypes } from "@doitintl/cmp-models";
import { Box, Card, CardContent, CardHeader, Container, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Form, Formik } from "formik";

import { settingsGeneralTexts } from "../../../assets/texts";
import { BottomAppBar } from "../../../Components/BottomAppBar";
import { Currency } from "../../../Components/Currency/Currency";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { TimestampFromDateTime } from "../../../utils/common";
import { CustomerActionButtons } from "./CustomerActionButtons/CustomerActionButtons";
import { CloudProvider } from "./FormItems/CloudProvider";
import { CustomerClassification } from "./FormItems/CustomerClassification";
import { CustomerName } from "./FormItems/CustomerName";
import { Domains } from "./FormItems/Domains";
import { EarlyAccessFeatures } from "./FormItems/EarlyAccessFeatures";
import { LinesPerInvoice } from "./FormItems/LinesPerInvoice";
import { OverrideCustomerSegment } from "./FormItems/OverrideCustomerSegment";
import { SkipRemedyBreach } from "./FormItems/SkipRemedyBreach";
import { TiersManagement } from "./FormItems/TiersManagement";
import { Trials } from "./FormItems/Trials";
import {
  cloudProviderValidator,
  constructInitialValues,
  currencyValidator,
  customerClassificationValidator,
  customerNameValidator,
  DATA_CY_GENERAL_SETTINGS_SELECTORS,
  domainsValidator,
  earlyAccessFeaturesValidator,
  endDateValidator,
  getDirtyFields,
  isSubmitDisabled,
  linesPerInvoiceValidator,
  skipRemedyBreachValidator,
} from "./helpers";
import { useEndTrial, useExtendTrial, useExtendTrialCustomLength, useSubmit } from "./hooks";
import { text } from "./text";
import { type SettingsValuesType } from "./types";
import { UnsavedChangesPrompt } from "./UnsavedChangesPrompt";

export function General() {
  const { customer, navigatorTierTrialDates } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { getCustomerTier, getTrialTierRef } = useTier();
  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const solveTier = getCustomerTier(TierPackageTypes.SOLVE);
  const isTiersManagmementDisabled = !useDoitRoleCheck(DoitRole.CustomerTieringAdmin);

  const [initialValues, setInitialValues] = useState<SettingsValuesType>(
    constructInitialValues({
      currency: customer.settings?.currency,
      domains: customer.domains,
      primaryDomain: customer.primaryDomain,
      customerName: customer.name,
      linesPerInvoice: customer.settings?.invoicing?.maxLineItems,
      cloudProvider: customer.onboarding?.advantage?.providers?.[0],
      customerClassification: customer.classification,
      earlyAccessFeatures: customer.earlyAccessFeatures,
      skipRemedyBreach: customer.skipRemedyBreach,
      customerSegment: customer.customerSegment,
      navigatorTierTrialStartDate: navigatorTierTrialDates?.start,
      navigatorTierTrialEndDate: navigatorTierTrialDates?.end,
      navigatorTrialCustomLength: customer.tiers?.navigator?.trialCustomLength,
      navigatorTierId: navigatorTier?.ref.id,
      solveTierTrialStartDate: customer.tiers?.solve?.trialStartDate,
      solveTierTrialEndDate: customer.tiers?.solve?.trialEndDate,
      solveTierId: solveTier?.ref.id,
    })
  );

  useEffect(() => {
    setInitialValues(
      constructInitialValues({
        currency: customer.settings?.currency,
        domains: customer.domains,
        primaryDomain: customer.primaryDomain,
        customerName: customer.name,
        linesPerInvoice: customer.settings?.invoicing?.maxLineItems,
        cloudProvider: customer.onboarding?.advantage?.providers?.[0],
        customerClassification: customer.classification,
        earlyAccessFeatures: customer.earlyAccessFeatures,
        skipRemedyBreach: customer.skipRemedyBreach,
        customerSegment: customer.customerSegment,
        navigatorTierTrialStartDate: navigatorTierTrialDates?.start,
        navigatorTierTrialEndDate: navigatorTierTrialDates?.end,
        navigatorTierId: navigatorTier?.ref.id,
        navigatorTrialCustomLength: customer.tiers?.navigator?.trialCustomLength,
        solveTierTrialStartDate: customer.tiers?.solve?.trialStartDate,
        solveTierTrialEndDate: customer.tiers?.solve?.trialEndDate,
        solveTierId: solveTier?.ref.id,
      })
    );
  }, [
    customer.settings?.currency,
    customer.domains,
    customer.primaryDomain,
    customer.name,
    customer.settings?.invoicing?.maxLineItems,
    customer.onboarding?.advantage?.providers,
    customer.classification,
    customer.earlyAccessFeatures,
    customer.skipRemedyBreach,
    customer.customerSegment,
    navigatorTierTrialDates?.start,
    navigatorTierTrialDates?.end,
    customer.tiers?.navigator?.trialCustomLength,
    getCustomerTier,
    customer.tiers?.solve?.trialStartDate,
    customer.tiers?.solve?.trialEndDate,
    navigatorTier?.ref.id,
    solveTier?.ref.id,
  ]);

  const onSubmit = useSubmit(initialValues);

  async function validate(values: SettingsValuesType) {
    const dirtyFields = getDirtyFields(initialValues, values);
    type validatorResult = string | undefined;
    const errorsP: { [K in keyof SettingsValuesType]: Promise<validatorResult> | validatorResult } = {
      domains: dirtyFields?.domains
        ? domainsValidator({ value: values.domains, initialValue: initialValues.domains, customer })
        : undefined,
      currency: dirtyFields?.currency ? currencyValidator(values.currency) : undefined,
      linesPerInvoice: dirtyFields?.linesPerInvoice ? linesPerInvoiceValidator(values.linesPerInvoice) : undefined,
      customerName: dirtyFields?.customerName ? customerNameValidator(values.customerName) : undefined,
      cloudProvider: dirtyFields?.cloudProvider ? cloudProviderValidator(values.cloudProvider) : undefined,
      earlyAccessFeatures: dirtyFields?.earlyAccessFeatures
        ? earlyAccessFeaturesValidator(values.earlyAccessFeatures)
        : undefined,
      customerClassification: dirtyFields?.customerClassification
        ? customerClassificationValidator(values.customerClassification)
        : undefined,
      skipRemedyBreach: dirtyFields?.skipRemedyBreach ? skipRemedyBreachValidator(values.skipRemedyBreach) : undefined,
      customerSegment: undefined,
      navigatorTierTrialStartDate: undefined,
      navigatorTierTrialEndDate: dirtyFields?.navigatorTierTrialEndDate
        ? endDateValidator(values.navigatorTierTrialEndDate)
        : undefined,
      solveTierTrialStartDate: undefined,
      solveTierTrialEndDate: dirtyFields?.solveTierTrialEndDate
        ? endDateValidator(values.solveTierTrialEndDate)
        : undefined,
    };

    const errors: { [K in keyof Partial<SettingsValuesType>]: validatorResult } = {};
    for (const key in errorsP) {
      const error = await errorsP[key];
      if (error) {
        errors[key] = error;
      }
    }

    return errors;
  }

  const endTrial = useEndTrial();
  const endNavigatorTrial = useCallback(() => endTrial("navigator"), [endTrial]);
  const endSolveTrial = useCallback(() => endTrial("solve"), [endTrial]);

  const extendTrial = useExtendTrial();
  const extendTrialCustomLength = useExtendTrialCustomLength();
  const navigatorTierId = getTrialTierRef("navigator")?.id;
  const solveTierId = getTrialTierRef("solve")?.id;
  const extendNavigatorTrial = useMemo(() => extendTrial("navigator", navigatorTierId), [extendTrial, navigatorTierId]);
  const extendSolveTrial = useMemo(() => extendTrial("solve", solveTierId), [extendTrial, solveTierId]);

  return (
    <Container maxWidth="md">
      <Stack
        sx={{
          alignItems: "center",
        }}
      >
        <Formik<SettingsValuesType>
          initialValues={initialValues}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnMount={true}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ values, setFieldValue, isValid, dirty, submitForm, isSubmitting, isValidating, errors, resetForm }) => {
            function setFieldValueSafe<K extends keyof SettingsValuesType>(key: K, value: SettingsValuesType[K]) {
              setFieldValue(key, value);
            }

            return (
              <Form
                onKeyDown={(e) => {
                  // Prevent form submission when pressing "Enter" in the Form scope.
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              >
                <UnsavedChangesPrompt dirty={dirty} />
                <Card
                  sx={{
                    border: "none",
                    marginX: "auto",
                    overflowY: "auto",
                    mb: 10,
                    px: 1,
                  }}
                  data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.GENERAL_SETTINGS_FORM}
                >
                  <CardHeader
                    sx={{ mt: 2 }}
                    title={settingsGeneralTexts.CARD_TITLE}
                    titleTypographyProps={{ variant: "h1" }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        mb: 2,
                      }}
                    >
                      <Grid container spacing={isDoitEmployee ? 4 : 0}>
                        <Grid size={12}>
                          <CustomerActionButtons />
                        </Grid>
                        <Grid size={12}>
                          <Currency
                            value={values.currency}
                            onChange={(newValue) => {
                              setFieldValueSafe("currency", newValue);
                            }}
                          />
                        </Grid>
                        <Grid size={12}>
                          <LinesPerInvoice
                            value={values.linesPerInvoice}
                            onChange={(newValue) => {
                              setFieldValueSafe("linesPerInvoice", newValue);
                            }}
                            errMsg={errors.linesPerInvoice}
                          />
                        </Grid>
                        <Grid size={12}>
                          <CustomerName
                            value={values.customerName}
                            onChange={(newName: string) => {
                              setFieldValueSafe("customerName", newName);
                            }}
                            errMsg={errors.customerName}
                          />
                        </Grid>
                        <Grid size={12}>
                          <CloudProvider
                            value={values.cloudProvider}
                            onChange={(newValue) => {
                              setFieldValueSafe("cloudProvider", newValue);
                            }}
                            errMsg={errors.cloudProvider}
                          />
                        </Grid>
                        <Grid size={12}>
                          <EarlyAccessFeatures
                            value={values.earlyAccessFeatures}
                            onChange={(newValue) => {
                              setFieldValueSafe("earlyAccessFeatures", newValue);
                            }}
                            errMsg={
                              Array.isArray(errors?.earlyAccessFeatures)
                                ? errors.earlyAccessFeatures[0]
                                : errors?.earlyAccessFeatures
                            }
                          />
                        </Grid>
                        <Grid size={12}>
                          <CustomerClassification
                            value={values.customerClassification}
                            onChange={(newValue) => {
                              setFieldValueSafe("customerClassification", newValue);
                            }}
                            errMsg={errors.customerClassification}
                          />
                        </Grid>
                        <Grid size={12}>
                          <SkipRemedyBreach
                            value={values.skipRemedyBreach}
                            onChange={(newValue) => {
                              setFieldValueSafe("skipRemedyBreach", newValue);
                            }}
                            errMsg={errors.skipRemedyBreach}
                          />
                        </Grid>
                        {isDoitEmployee && (
                          <Grid size={12}>
                            <OverrideCustomerSegment
                              value={
                                values.customerSegment || {
                                  currentSegment: CustomerSegmentValues.NA,
                                  overrideSegment: null,
                                }
                              }
                              onChange={(newValue) => {
                                setFieldValueSafe("customerSegment", {
                                  currentSegment: customer.customerSegment?.currentSegment || CustomerSegmentValues.NA,
                                  overrideSegment: newValue,
                                });
                              }}
                            />
                          </Grid>
                        )}
                        {isDoitEmployee && (
                          <>
                            <Grid
                              sx={{
                                mt: 3,
                              }}
                              size={12}
                            >
                              <TiersManagement
                                navTierId={values.navigatorTierId}
                                solveTierId={values.solveTierId}
                                onChangeNavTier={(newNavTier) => {
                                  setFieldValueSafe("navigatorTierId", newNavTier);
                                }}
                                onChangeSolveTier={(newSolveTier) => {
                                  setFieldValueSafe("solveTierId", newSolveTier);
                                }}
                                isDisabled={isTiersManagmementDisabled}
                              />
                            </Grid>
                            <Grid
                              sx={{
                                mt: 3,
                              }}
                              size={12}
                            >
                              <Trials
                                title={text.NAVIGATOR_TRIAL_SETTINGS_LABEL}
                                tier={navigatorTier}
                                trialStartDate={values.navigatorTierTrialStartDate?.toDate()}
                                trialEndDate={initialValues.navigatorTierTrialEndDate?.toDate()}
                                trialCustomLength={initialValues.navigatorTrialCustomLength}
                                key={initialValues.navigatorTierTrialEndDate?.toMillis()}
                                currentTrialEndDate={values.navigatorTierTrialEndDate?.toDate()}
                                errMsg={errors?.navigatorTierTrialEndDate}
                                onChangeDate={(startDate, endDate) => {
                                  setFieldValueSafe("navigatorTierTrialStartDate", TimestampFromDateTime(startDate));
                                  setFieldValueSafe(
                                    "navigatorTierTrialEndDate",
                                    TimestampFromDateTime(endDate.endOf("day"))
                                  );
                                }}
                                onChangeTrialCustomLength={(trialLength) => {
                                  setFieldValueSafe("navigatorTrialCustomLength", trialLength);
                                }}
                                onResetChanges={() => {
                                  setFieldValueSafe(
                                    "navigatorTierTrialStartDate",
                                    initialValues.navigatorTierTrialStartDate
                                  );
                                  setFieldValueSafe(
                                    "navigatorTierTrialEndDate",
                                    initialValues.navigatorTierTrialEndDate
                                  );
                                }}
                                onEndTrial={endNavigatorTrial}
                                onExtendTrial={extendNavigatorTrial}
                                onExtendTrialCustomLength={extendTrialCustomLength}
                              />
                            </Grid>
                            <Grid
                              sx={{
                                mt: 3,
                              }}
                              size={12}
                            >
                              <Trials
                                title={text.SOLVE_TRIAL_SETTINGS_LABEL}
                                tier={solveTier}
                                trialStartDate={values.solveTierTrialStartDate?.toDate()}
                                trialEndDate={initialValues.solveTierTrialEndDate?.toDate()}
                                currentTrialEndDate={values.solveTierTrialEndDate?.toDate()}
                                key={initialValues.solveTierTrialEndDate?.toMillis()}
                                errMsg={errors?.solveTierTrialEndDate}
                                onChangeDate={(startDate, endDate) => {
                                  setFieldValueSafe("solveTierTrialStartDate", TimestampFromDateTime(startDate));
                                  setFieldValueSafe(
                                    "solveTierTrialEndDate",
                                    TimestampFromDateTime(endDate.endOf("day"))
                                  );
                                }}
                                onResetChanges={() => {
                                  setFieldValueSafe("solveTierTrialStartDate", initialValues.solveTierTrialStartDate);
                                  setFieldValueSafe("solveTierTrialEndDate", initialValues.solveTierTrialEndDate);
                                }}
                                onEndTrial={endSolveTrial}
                                onExtendTrial={extendSolveTrial}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid
                          sx={{
                            mt: 3,
                          }}
                          size={12}
                        >
                          <Domains
                            errMsg={Array.isArray(errors?.domains) ? errors.domains?.join(", ") : errors?.domains}
                            domains={values.domains}
                            isPrimaryDomainChanged={
                              initialValues.domains.find((d) => d.isPrimary)?.name !==
                              values.domains.find((d) => d.isPrimary)?.name
                            }
                            onAddDomain={(domain) => {
                              setFieldValueSafe("domains", [...values.domains, { name: domain, isPrimary: false }]);
                            }}
                            onRemoveDomain={(domain) => {
                              setFieldValueSafe(
                                "domains",
                                values.domains.filter((d) => d.name !== domain)
                              );
                            }}
                            onSetPrimaryDomain={(domain) => {
                              setFieldValueSafe(
                                "domains",
                                values.domains.map((d) => ({ ...d, isPrimary: d.name === domain }))
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <BottomAppBar
                    primaryButtonProps={{
                      onKeyDown: (e) => {
                        // enabling form submission when pressing "Enter" and form is valid
                        if (e.key === "Enter" && !isSubmitDisabled({ dirty, isValid, isSubmitting, isValidating })) {
                          e.preventDefault();
                          submitForm();
                        }
                      },
                    }}
                    primaryButtonName={settingsGeneralTexts.SAVE_BUTTON_TEXT}
                    disabled={isSubmitDisabled({ dirty, isValid, isSubmitting, isValidating })}
                    handlePrimaryButtonClicked={() => submitForm()}
                    loading={isSubmitting || isValidating}
                    secondaryButtonName={settingsGeneralTexts.CANCEL_BUTTON_TEXT}
                    handleSecondaryButtonClicked={() => {
                      resetForm();
                    }}
                    secondaryButtonProps={{
                      disabled: isSubmitting || !dirty,
                    }}
                    maxWidth="md"
                  />
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </Container>
  );
}
