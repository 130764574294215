import React, { type ReactNode } from "react";

import Typography from "@mui/material/Typography";

import type { InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";

type NoNotificationsNoticeProps = {
  notifications: Map<string, InAppNotificationWithRead>;
  onlyShowUnread: boolean;
  unreadNotificationsCount: number;
};

export const cyIds = {
  noNotifications: "no-notifications-notice",
  noUnread: "no-unread-notice",
};

function NoNotificationsNotice({
  notifications,
  onlyShowUnread,
  unreadNotificationsCount,
}: Readonly<NoNotificationsNoticeProps>): ReactNode {
  let notice: string | undefined;
  let cyId: string | undefined;
  if (notifications.size === 0) {
    notice = "You don't have any notifications";
    cyId = cyIds.noNotifications;
  } else if (onlyShowUnread && unreadNotificationsCount === 0) {
    notice = "You don't have any unread notifications";
    cyId = cyIds.noUnread;
  }

  if (!notice) {
    return <></>;
  }

  return (
    <Typography variant="body2" align="center" color="text.primary" sx={{ marginTop: 10 }} data-cy={cyId}>
      {notice}
    </Typography>
  );
}

export default NoNotificationsNotice;
