import { useCallback } from "react";

import { Box, Button, Tooltip } from "@mui/material";

import { reportTxt } from "../../../../../assets/texts/CloudAnalytics";
import { useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import mixpanel from "../../../../../utils/mixpanel";
import { useCloudAnalyticsContext } from "../../../CloudAnalyticsContext";
import { useReportTemplateContext } from "../../../Context";

type Props = {
  disabled: boolean;
  tooltip: string;
};

const AddToReports = ({ disabled, tooltip }: Props) => {
  const successSnackbar = useSuccessSnackbar();
  const { handleFavoriteReportTemplateUpdate } = useCloudAnalyticsContext();
  const { reportTemplateId, isFavoriteReportTemplate } = useReportTemplateContext();

  const onClick = useCallback(() => {
    handleFavoriteReportTemplateUpdate(reportTemplateId);
    if (isFavoriteReportTemplate) {
      mixpanel.track("analytics.templates.remove-from-reports-list");
      successSnackbar(reportTxt.REMOVED_FROM_MY_REPORTS);
    } else {
      mixpanel.track("analytics.templates.add-to-reports-list");
      successSnackbar(reportTxt.ADDED_TO_MY_REPORTS);
    }
  }, [handleFavoriteReportTemplateUpdate, isFavoriteReportTemplate, reportTemplateId, successSnackbar]);

  return (
    <Tooltip title={!!tooltip && <Box sx={{ width: "175px", whiteSpace: "pre-line" }}>{tooltip}</Box>}>
      <Box>
        <Button
          data-cy="add-to-my-reports"
          disabled={disabled}
          onClick={onClick}
          sx={{ textWrap: "nowrap" }}
          variant="contained"
        >
          {isFavoriteReportTemplate ? reportTxt.REMOVE_FROM_MY_REPORTS : reportTxt.ADD_TO_MY_REPORTS}
        </Button>
      </Box>
    </Tooltip>
  );
};

export default AddToReports;
