import { Button, Tooltip } from "@mui/material";

import { globalText } from "../../../../assets/texts";
import { type AnalyticsResources } from "../../analyticsResources/types";

type EditPermissionsButtonProps = {
  selectedResources: AnalyticsResources[];
  onClick: () => void;
  disabled?: boolean;
};

const EditPermissionsButton = ({ selectedResources, onClick, disabled = false }: EditPermissionsButtonProps) => {
  const tooltipTitle = selectedResources.length > 0 && disabled ? globalText.NO_PERMISSION_UPDATE_RESOURCE : "";

  return (
    <Tooltip arrow placement="bottom" title={tooltipTitle}>
      <span>
        <Button id="edit-permission-button" variant="text" onClick={onClick} disabled={disabled}>
          {globalText.EDIT_PERMISSIONS}
        </Button>
      </span>
    </Tooltip>
  );
};

export default EditPermissionsButton;
