import { DateTime } from "luxon";
import type { DateTimeMaybeValid } from "luxon/src/datetime";

import { getDateTimeFromFirestoreTimestamp } from "../../../../utils/common";
import type { InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";

/**
 * Returns the date of interest of the notification in local time, if it is valid.
 * The date of interest is the updated date if it exists, otherwise the created date.
 * @param notification notification for which a date is required
 */
export function getDateOfInterestLocal(notification: InAppNotificationWithRead): DateTime<true> | undefined {
  const dateOfInterest: DateTimeMaybeValid = notification.updated
    ? getDateTimeFromFirestoreTimestamp(notification.updated)
    : getDateTimeFromFirestoreTimestamp(notification.created);
  if (!dateOfInterest.isValid) {
    return undefined;
  }

  // Only transform to local time once we know for certain that the DateTime is valid
  return dateOfInterest.toLocal();
}

/**
 * Returns a string representing the date of either creation or update of the notification in a human-readable format.
 * If the notification was updated, the string will begin with "Last updated: ".
 * In all cases, the output will say "Today" or "Yesterday" instead of a date format if the date is today or yesterday.
 * @param n the notification of which to display the date
 * @param now a passed value of the current date time to ensure that all uses of this callback across a component can share a common value of the initial base time. **IMPORTANT** This must be in local time, as the date of interest is converted to local time before comparison.
 */
export function getNotificationDisplayDate(n: InAppNotificationWithRead, now: DateTime): string {
  const localDate = getDateOfInterestLocal(n);
  if (!localDate) {
    return "";
  }

  let displayValue: string;

  switch (true) {
    case localDate.hasSame(now, "day"):
      displayValue = `Today, ${localDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
      break;
    case localDate.hasSame(now.minus({ days: 1 }), "day"):
      displayValue = `Yesterday, ${localDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
      break;
    default:
      displayValue = localDate.toLocaleString(DateTime.DATE_MED);
  }

  return n.updated ? `Last updated: ${displayValue}` : displayValue;
}
