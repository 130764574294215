import { useEffect } from "react";

import { useShallow } from "zustand/react/shallow";

import { Allocation } from "./Allocation";
import useAllocationDndStore from "./hooks/useAllocationDnd";

export const CreateAllocationWrapper = () => {
  const { addItem, replaceItem } = useAllocationDndStore(useShallow((state) => state));

  useEffect(() => {
    addItem(crypto.randomUUID());
    return () => {
      replaceItem([]);
    };
  }, [addItem, replaceItem]);
  return <Allocation />;
};
