import { type ReactNode, useCallback, useState } from "react";

import { useApiContext } from "../../../api/context";
import { attributionText } from "../../../assets/texts";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import { isOwner } from "../utilities";
import { deleteAttributions } from "./db";
import { type AffectedResources, type AnalyticsResources, DeleteValidationDialog } from "./DeleteValidationDialog";

type deleteAttributionProps = {
  ids: string[];
  onResponsePopulated: (data: AffectedResources[]) => void;
  onResponseEmpty: () => void;
  onError: (error: any) => void;
};

export const useDeleteAttribution = (mixpanelFrom?: string) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const customerId = customer.id;

  return useCallback(
    async ({ ids, onResponsePopulated, onResponseEmpty, onError }: deleteAttributionProps) => {
      try {
        const response = await deleteAttributions(api, customerId, ids, mixpanelFrom);

        if (response?.data) {
          onResponsePopulated(response?.data);
        } else {
          onResponseEmpty();
        }
      } catch (error: any) {
        consoleErrorWithSentry(error);
        onError(error);
      }
    },
    [api, customerId, mixpanelFrom]
  );
};

export const useDeleteAttributionRowAdapter = ({ row }) => {
  const { handleDeleteValidationResponse } = useCloudAnalyticsContext();
  const deleteAttribution = useDeleteAttribution();
  const snackbar = useSnackbar();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const collaborators = row?.data?.collaborators;

  return useCallback(
    async ({ attributionId }: { attributionId: string }) => {
      if (
        !isOwner(currentUser.email, {
          collaborators,
        })
      ) {
        snackbar.onOpen({
          message: attributionText.CANNOT_DELETE,
          variant: "error",
          autoHideDuration: 5000,
        });

        return;
      }

      await deleteAttribution({
        ids: [attributionId],
        onResponsePopulated: (data) => {
          handleDeleteValidationResponse(data);
        },
        onResponseEmpty: () => {
          snackbar.onOpen({
            message: attributionText.DELETE_ATTR_SUCCESS,
            variant: "success",
            autoHideDuration: 5000,
          });
        },
        onError: () => {
          snackbar.onOpen({
            message: attributionText.DELETE_ATTR_ERROR,
            variant: "error",
            autoHideDuration: 5000,
          });
        },
      });
    },
    [currentUser.email, collaborators, snackbar, deleteAttribution, handleDeleteValidationResponse]
  );
};

export const useDeleteAttributionValidation = (
  resources: AnalyticsResources
): [() => ReactNode, (data: AffectedResources[]) => void] => {
  const [deleteValidationResponse, setDeleteValidationResponse] = useState<AffectedResources[] | null>(null);
  const DeleteAttributionValidation = useCallback(() => {
    if (!deleteValidationResponse) {
      return;
    }

    return (
      <DeleteValidationDialog
        resources={resources}
        deleteValidationResponse={deleteValidationResponse}
        onClose={() => {
          setDeleteValidationResponse(null);
        }}
      />
    );
  }, [deleteValidationResponse, resources]);

  return [DeleteAttributionValidation, setDeleteValidationResponse];
};
