import { useCallback } from "react";

import { ApprovalAction, type ApprovalDetails } from "@doitintl/cmp-models";
import { type Timestamp } from "firebase/firestore";

import { type ExecutionNodeDetails, type TabId } from "../Accordian/types";
import { formatCloudFlowRunDetailsTime } from "../utils";

type TabDataFormatter<T extends TabId> = {
  format(data: NonNullable<ExecutionNodeDetails[T]>): string;
};

const parametersFormatter: TabDataFormatter<"parameters"> = {
  format: (data): string => JSON.stringify(data, null, 2),
};

const resultsFormatter: TabDataFormatter<"results"> = {
  format: (data): string => JSON.stringify(data, null, 2),
};

const approvalDetailsFormatter: TabDataFormatter<"approvalDetails"> = {
  format: (data): string => {
    if (!data) return "";

    const prefix = data.action === ApprovalAction.REJECTED ? "Rejected" : "Approved";
    const labelMap = {
      sentAt: "Sent at",
      action: "Action",
      receivedAt: `${prefix} at`,
      approvedVia: `${prefix} via`,
      approvedBy: `${prefix} by`,
    } as const;

    return Object.entries(labelMap)
      .map(([key, label]) => {
        const value = data[key as keyof ApprovalDetails];
        if (!value) return null;

        if (key === "sentAt" || key === "receivedAt") {
          return `${label}: ${formatCloudFlowRunDetailsTime(value as Timestamp)}`;
        }

        return `${label}: ${String(value)}`;
      })
      .filter(Boolean)
      .join("\n");
  },
};

const formatters: Record<TabId, TabDataFormatter<TabId>> = {
  parameters: parametersFormatter,
  results: resultsFormatter,
  approvalDetails: approvalDetailsFormatter,
};

export const useTabDataFormat = (tabId: TabId) =>
  useCallback(
    (data: ExecutionNodeDetails[TabId]) => {
      if (!data) return "";

      const formatter = formatters[tabId];

      return formatter.format(data);
    },
    [tabId]
  );
