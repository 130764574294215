import { useHistory } from "react-router-dom";
import { AssetTypeDatadog, type DataConnectorDatadogModel } from "@doitintl/cmp-models";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import DatadogAssetsTabHeader from "./components/datadog/DatadogAssetsTabHeader";
import DatadogAssetTable from "./components/datadog/DatadogAssetTable";
import NoAssets from "./components/NoAssets";

const DatadogAssetsTab = ({ datadogConfigs }: { datadogConfigs: DataConnectorDatadogModel[] | undefined }) => {
  const { customer } = useCustomerContext();
  const history = useHistory();

  if (datadogConfigs?.length === 0) {
    return (
      <NoAssets assetType={AssetTypeDatadog}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/customers/${customer.id}/cloud-onboarding-datadog`);
          }}
        >
          Connect new account
        </Button>
      </NoAssets>
    );
  }

  return (
    <>
      <DatadogAssetsTabHeader />
      <DatadogAssetTable configs={datadogConfigs} />
    </>
  );
};

export default DatadogAssetsTab;
