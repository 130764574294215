import { type Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { TemplateVisibility } from "@doitintl/cmp-models";
import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import {
  type ReportTemplatesAction,
  reportTemplatesReducer,
} from "../../../Pages/CloudAnalytics/reducers/ReportTemplatesReducer";
import { fetchReportTemplatesData } from "../../../Pages/CloudAnalytics/templateLibrary/api";
import { transformToTemplateWithVersion } from "../../../Pages/CloudAnalytics/templateLibrary/transformToTemplateWithVersion";
import {
  type InitialTemplatesFromBackend,
  type ReportTemplateWithVersion,
} from "../../../Pages/CloudAnalytics/templateLibrary/types";
import { consoleErrorWithSentry } from "../../../utils";

export type ReportTemplatesValues = [
  ReportTemplateWithVersion[] | null,
  Dispatch<ReportTemplatesAction>,
  string[] | null,
  boolean,
];

// Do not use this hook directly!
export const useReportTemplates = (): ReportTemplatesValues => {
  const api = useApiContext();
  const { currentUser, isDoitEmployee } = useAuthContext();
  const [reportTemplates, dispatchReportTemplates] = useReducer(reportTemplatesReducer, null);
  const [loading, setLoading] = useState(true);
  const allowReportTemplate = useIsFeatureEntitled("analytics:reports:templates", true);

  const fetchTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const response: AxiosResponse<InitialTemplatesFromBackend> = await fetchReportTemplatesData(api);
      if (!response.data) {
        return;
      }

      const templatesWithVersions = response.data.templates?.flatMap((template) => {
        const version = response.data.versions.find((v) => v.template.ID === template.id);
        if (
          !version ||
          (version.visibility === TemplateVisibility.INTERNAL && !isDoitEmployee) ||
          (version.visibility === TemplateVisibility.PRIVATE && version.createdBy !== currentUser?.email)
        ) {
          return [];
        }
        return transformToTemplateWithVersion(template, version);
      });

      dispatchReportTemplates({
        type: "INITIALIZE_REPORT_TEMPLATES",
        payload: { reportTemplatesInitialList: templatesWithVersions },
      });
    } catch (err) {
      consoleErrorWithSentry(err);
    } finally {
      setLoading(false);
    }
  }, [api, currentUser?.email, isDoitEmployee]);

  useEffect(() => {
    if (allowReportTemplate) {
      fetchTemplates();
    }
  }, [fetchTemplates, allowReportTemplate]);

  const managedReportsIds = useMemo(
    () => reportTemplates?.map((reportTemplate) => reportTemplate.version.report.id) ?? null,
    [reportTemplates]
  );

  return useMemo(
    () => [reportTemplates, dispatchReportTemplates, managedReportsIds, loading],
    [loading, managedReportsIds, reportTemplates]
  );
};
