import { useCallback, useContext } from "react";

import { Button, Card } from "@mui/material";

import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetMenuWrapperContext } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import { WidgetMessageCardContent } from "../WidgetCards/Common/WidgetMessageCardContent";

type Props = {
  title: string;
  subHeader: string;
  widgetHeight: number;
};

export function ReportDeletedErrorCard({ widgetHeight, title, subHeader }: Props) {
  const [deleteWidgetOperation] = useContext(WidgetMenuWrapperContext);

  const handleWidgetDelete = useCallback(() => {
    if (deleteWidgetOperation) {
      deleteWidgetOperation();
    }
  }, [deleteWidgetOperation]);

  return (
    <Card>
      <WidgetCardHeader title={title} subheader={subHeader} />
      <WidgetMessageCardContent
        widgetHeight={widgetHeight}
        message="This report no longer exists. "
        extra={
          <Button sx={{ mt: 2 }} variant="outlined" color="error" onClick={handleWidgetDelete}>
            Remove this widget
          </Button>
        }
      />
    </Card>
  );
}
