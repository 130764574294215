import { type ChangeEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { cloudflowTexts, globalText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useCloudflowOperations } from "../CloudflowBuilder/Common/CloudflowOperationsProvider";

type ManageIfNodeDialogProps = {
  open: boolean;
  onCancel: () => void;
  onSave: (choice: CHOICE_OPTIONS) => void;
};

export enum CHOICE_OPTIONS {
  MOVE_ACTIONS_TO_TRUE = "Move actions to True",
  MOVE_ACTIONS_TO_FALSE = "Move actions to False",
  DELETE_ACTIONS = "Delete actions",
}

export const ManageIfNodeDialog = ({ open, onCancel, onSave }: ManageIfNodeDialogProps) => {
  const [choice, setChoice] = useState<CHOICE_OPTIONS | "">("");
  const { httpOperationLoading } = useCloudflowOperations();

  const handleChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChoice(event.target.value as CHOICE_OPTIONS);
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle>{cloudflowTexts.MANAGE_IF_NODE_DIALOG_TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{cloudflowTexts.MANAGE_IF_NODE_DIALOG_CONTENT}</DialogContentText>
        <RadioGroup value={choice} onChange={handleChoiceChange}>
          <FormControlLabel
            value={CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE}
            control={<Radio />}
            label={CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE}
          />
          <FormControlLabel
            value={CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE}
            control={<Radio />}
            label={CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE}
          />
          <FormControlLabel
            value={CHOICE_OPTIONS.DELETE_ACTIONS}
            control={<Radio />}
            label={CHOICE_OPTIONS.DELETE_ACTIONS}
          />
        </RadioGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>{globalText.CANCEL}</Button>
        <LoadingButton
          loading={httpOperationLoading}
          onClick={() => {
            if (!choice) {
              return;
            }
            onSave(choice);
          }}
          color="primary"
          variant="contained"
          disabled={!choice}
          mixpanelEventId="cloudflow-manage-if-node"
        >
          {globalText.CONFIRM}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
