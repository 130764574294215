import { memo } from "react";

import { Box } from "@mui/material";

import { TargetHandle } from "./Handle";

type Props = {
  id: string;
};

const InternalGhostNode = ({ id }: Props) => (
  <Box
    sx={{
      width: 350,
      height: 2,
    }}
  >
    <TargetHandle id={id} />
  </Box>
);

export const GhostNode = memo(InternalGhostNode);
