import { useCallback } from "react";

import {
  type AnalyticsDataSource,
  type AttributionFilter,
  Metadata,
  type ReportConfig,
  type TimeInterval,
} from "@doitintl/cmp-models";
import castArray from "lodash/castArray";
import uniq from "lodash/uniq";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useTier } from "../../../../Context/TierProvider";
import {
  awsOrgTagPrefix,
  eksLabelPrefix,
  eksLabelSecondPrefix,
  getTypeString,
  gkeLabelPrefix,
  modifySpecialLabel,
} from "../../../../Pages/CloudAnalytics/utilities";
import {
  type AttributionWRef,
  type MetadataOption,
  type MetadataOptionData,
  type MetricWSnap,
} from "../../../../types";
import { b64EncodeUnicode } from "../../../../utils/common";
import { type AnalyticsMetadata } from "../useCloudAnalyticsMetadata";
import {
  getAttributionsWithAccess,
  getFilterFromConfig,
  getUIVisibility,
  getValuesField,
  isDimensionDisabled,
} from "./analyticsDimensionsParser";

type UseDimensionManagerProps = {
  attributions?: AttributionWRef[];
  timeInterval?: TimeInterval;
  config?: ReportConfig;
  calculatedMetric?: MetricWSnap;
  isReport?: boolean;
  recentDimensions?: string[];
};

type createDimensionParams = {
  data: MetadataOptionData;
  values?: string[];
  labelKey?: string;
  awsValues?: string[];
  gcpValues?: string[];
  azureValues?: string[];
  datahub?: string[];
  snowflake?: string[];
  datadog?: string[];
  id?: string;
  existingFilter?: AttributionFilter;
  dataSource?: AnalyticsDataSource;
};

type UseDimensionManagerReturnType = {
  createDimension: (params: createDimensionParams) => MetadataOption;
  initialDimensionFromSnapshot: (docSnaps: AnalyticsMetadata) => {
    data: MetadataOptionData;
    valuesUnion: string[];
    gcpValues: string[];
    awsValues: string[];
    azureValues: string[];
    snowflake: string[];
    datadog: string[];
    datahub: string[];
  };
  getDimensionSubtype: (md: MetadataOption) => Metadata | "";
};

const checkEks = (data: MetadataOptionData, label: Metadata, labelStart: string) =>
  data.type === label && data.label.startsWith(labelStart);

const changeEksType = (subType: "" | Metadata, type: "" | Metadata, modifiedData: any) => {
  if (subType === "") {
    return modifiedData.type;
  } else {
    return subType !== Metadata.SYSTEM_LABEL && subType !== Metadata.LABEL ? type : subType;
  }
};

const popularKeys = ["cloud_provider", "service_description", "sku_description", "project_id"];

export const useDimensionManager = ({
  attributions,
  timeInterval,
  config,
  calculatedMetric,
  recentDimensions,
}: UseDimensionManagerProps): UseDimensionManagerReturnType => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { isFeatureEntitled } = useTier();

  const createDimension = useCallback(
    (params: createDimensionParams): MetadataOption => {
      const {
        data,
        values = [],
        labelKey = "",
        awsValues = [],
        gcpValues = [],
        azureValues = [],
        snowflake = [],
        datadog = [],
        dataSource,
        datahub = [],
      } = params;
      let { id = "", existingFilter } = params;
      const key = labelKey || data.key;
      let type = data.type;
      let subType = data.subType;
      let label = data.label;

      const isEks =
        checkEks(data, Metadata.SYSTEM_LABEL, eksLabelPrefix) ||
        checkEks(data, Metadata.SYSTEM_LABEL, eksLabelSecondPrefix) ||
        checkEks(data, Metadata.LABEL, eksLabelPrefix);

      const isGke = key.startsWith(gkeLabelPrefix);
      const isAWSOrg = key.startsWith(awsOrgTagPrefix);

      if (data.type === Metadata.OPTIONAL || isEks || isGke || isAWSOrg) {
        const { data: modifiedData, parsedLabel } = modifySpecialLabel(data, key);
        type = modifiedData.subType;
        type = changeEksType(subType, type, modifiedData);
        subType = modifiedData.subType;
        const isGkeCostAllocation = modifiedData.subType === Metadata.GKE_COST_ALLOCATION_LABEL;
        if (isGkeCostAllocation) {
          type = Metadata.LABEL;
          subType = Metadata.GKE_LABEL;
        }
        const isAWSOrgTag = modifiedData.subType === Metadata.AWS_ORG_TAG;
        if (isAWSOrgTag) {
          type = Metadata.PROJECT_LABEL;
          subType = Metadata.AWS_ORG_TAG;
        }

        const labelKeyBase64 = b64EncodeUnicode(key); // should encode the original key with the gke prefix
        id = `${type}:${labelKeyBase64}`;
        label = parsedLabel;
      }

      const isPopular = popularKeys.includes(key);
      const isRecent = recentDimensions?.includes(id);
      if (isPopular) {
        subType = Metadata.POPULAR;
      }
      if (isRecent) {
        subType = Metadata.RECENT;
      }
      // this condition aims to check that the filter is of the correct metadata
      if (existingFilter?.id !== id) {
        existingFilter = undefined;
      }
      const { position, available, visible } = getUIVisibility(id, type, key, timeInterval, config);
      const filterOptions = getFilterFromConfig(
        type,
        id,
        attributions,
        config,
        calculatedMetric,
        existingFilter,
        data.nullFallback
      );

      const md: MetadataOption = {
        data: {
          ...data,
          values,
          type,
          subType,
          label,
          key,
        },
        dataSource,
        _values: {
          awsValues,
          gcpValues,
          azureValues,
          datahub,
          snowflake,
          datadog,
        },
        _visible: visible,
        _position: position,
        _available: available,
        _isDragging: false,
        _disabled: false,
        ...filterOptions,
        id,
      };
      return {
        ...md,
        typeLabel: getTypeString(md, true),
        _disabled: isDimensionDisabled(md, config),
      };
    },
    [recentDimensions, timeInterval, config, attributions, calculatedMetric]
  );

  const initialDimensionFromSnapshot = (docSnaps: AnalyticsMetadata) => {
    const data = docSnaps[0].data();

    const accessibleAttributions = isDoitEmployee
      ? attributions
      : getAttributionsWithAccess(data, attributions, config, isFeatureEntitled);

    const { valuesUnion, gcpValues, awsValues, azureValues, datahub, snowflake, datadog } = getValuesField(
      docSnaps,
      accessibleAttributions
    );
    data.cloud = uniq(docSnaps.flatMap((docSnap) => castArray(docSnap.get("cloud")) ?? []));
    return { data, valuesUnion, gcpValues, awsValues, azureValues, datahub, snowflake, datadog };
  };

  const getDimensionSubtype = useCallback((md: MetadataOption) => {
    const data = md.data;
    const key = md.data.key;
    const isEks =
      checkEks(data, Metadata.SYSTEM_LABEL, eksLabelPrefix) ||
      checkEks(data, Metadata.SYSTEM_LABEL, eksLabelSecondPrefix) ||
      checkEks(data, Metadata.LABEL, eksLabelPrefix);
    const isGke = key.startsWith(gkeLabelPrefix);
    const isAWSOrg = key.startsWith(awsOrgTagPrefix);
    let subType: Metadata | "" = "";

    if (data.type === Metadata.OPTIONAL || isEks || isGke || isAWSOrg) {
      const { data: modifiedData } = modifySpecialLabel(data, key);
      subType = modifiedData.subType;
      const isGkeCostAllocation = modifiedData.subType === Metadata.GKE_COST_ALLOCATION_LABEL;
      if (isGkeCostAllocation) {
        subType = Metadata.GKE_LABEL;
      }
      const isAWSOrgTag = modifiedData.subType === Metadata.AWS_ORG_TAG;
      if (isAWSOrgTag) {
        subType = Metadata.AWS_ORG_TAG;
      }
    }

    if (popularKeys.includes(key)) {
      subType = Metadata.POPULAR;
    }

    return subType;
  }, []);

  return { createDimension, initialDimensionFromSnapshot, getDimensionSubtype };
};
