import { Tab, Tabs, useTheme } from "@mui/material";

import { type TabConfig, type TabId } from "./types";

type NavTabsProps = {
  visibleTabs: TabConfig[];
  activeTab: TabId;
  setActiveTab: (id: TabId) => void;
};

const NavTabs = ({ visibleTabs, activeTab, setActiveTab }: NavTabsProps) => {
  const theme = useTheme();

  return (
    <Tabs
      value={activeTab}
      onChange={(_, newValue: TabId) => {
        setActiveTab(newValue);
      }}
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        marginBottom: -0.25,
      }}
    >
      {visibleTabs.map((tab) => (
        <Tab
          key={tab.id}
          value={tab.id}
          label={tab.label}
          disableRipple
          sx={{
            textTransform: "none",
            "&.Mui-selected": {
              color: theme.palette.text.primary,
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default NavTabs;
