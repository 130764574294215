import { type ChartSeriesRecord } from "../../useChartsSeries";
import { type EChartType } from "../types";

export const typeSupportsClosesIndexSearch = (type: EChartType) =>
  type === "line" || type === "line-spline" || type === "area-spline" || type === "area";

export const findClosestIndex = (target: number, numbers: (number | undefined | null)[]): number => {
  if (numbers.length === 0) {
    throw new Error("The array must contain at least one element.");
  }

  // find first non-undefined element
  const firstDefinedValueIndex = numbers.findIndex((value) => value !== undefined && value !== null);

  if (firstDefinedValueIndex < 0) {
    return -1;
  }

  let closestIndex = firstDefinedValueIndex;
  // @ts-expect-error
  let closestDistance = Math.abs(target - numbers[firstDefinedValueIndex]);

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] === undefined || numbers[i] === null) {
      continue;
    }
    // @ts-expect-error
    const currentDistance = Math.abs(target - numbers[i]);
    if (currentDistance < closestDistance) {
      closestDistance = currentDistance;
      closestIndex = i;
    }
  }

  return closestIndex;
};

// dataset number that can start creating performance issues
export const isBigDataSet = (series: ChartSeriesRecord[]) => series.length > 100;

// check that highlighted row is forecast row starting with null and ending with numbers
export const checkIfForecastSeries = (row: (number | null)[]) => {
  if (!row.length || row[0] !== null) {
    return false;
  }

  let foundNumber = false;

  for (const element of row) {
    if (element === null && !foundNumber) {
      // Continue if it's null and we haven't found a number yet
      continue;
    } else if (element !== null) {
      // Once we encounter a number, we mark foundNumber as true
      foundNumber = true;
    } else {
      // If it's null after number was found
      return false;
    }
  }

  if (!foundNumber) {
    // if number was never found
    return false;
  }

  return true;
};
