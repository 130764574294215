import { memo, type ReactNode } from "react";

import { NODE_STATUS, type NodeStatus } from "@doitintl/cmp-models";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Card, CardContent, CardHeader, Chip, Stack, Tooltip, Typography } from "@mui/material";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { useCloudflowOperations } from "../Common/CloudflowOperationsProvider";
import { useIsCloudflowPublished } from "../Common/hooks/useIsCloudflowPublished";
import { NodeMenu } from "./NodeMenu";

interface Props {
  title: string;
  name: string;
  status: NodeStatus;
  errorMessages?: Record<string, string>;
  onDelete?: () => void;
  onEdit?: () => void;
  isActive: boolean;
  icon: ReactNode;
  children?: ReactNode;
}

const InternalNodeWrapper = ({
  children,
  title,
  status,
  errorMessages,
  onDelete,
  onEdit,
  isActive,
  icon,
  name,
}: Props) => {
  const isPublished = useIsCloudflowPublished();
  const isDarkMode = useDarkThemeCheck();
  const isErrorMessages = Object.entries(errorMessages ?? {}).length > 0;
  const { httpOperationLoading } = useCloudflowOperations();

  const NodeIcon = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: 26,
        height: 26,
        background: isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
      }}
    >
      {icon}
    </Box>
  );

  const StatusTooltip = status === NODE_STATUS.ERROR && (
    <Tooltip
      title={
        isErrorMessages
          ? Object.entries(errorMessages ?? {}).map(([key, val]) => (
              <Typography key={key} variant="body2">
                {val}
              </Typography>
            ))
          : null
      }
      arrow
      placement="top"
      slotProps={{
        popper: {
          sx: {
            "& .MuiTooltip-tooltip": {
              backgroundColor: "general.snackbar",
              color: isDarkMode ? "common.black" : "common.white",
              padding: 1,
            },
            "& .MuiTooltip-arrow": {
              color: "general.snackbar",
            },
          },
        },
      }}
    >
      <WarningAmberIcon color="warning" fontSize="small" />
    </Tooltip>
  );

  return (
    <Card
      sx={(theme) => ({
        width: 350,
        pb: 1,
        border: isActive ? `1px solid ${theme.palette.primary.main}` : undefined,
        borderRadius: 2,
        backgroundColor: isActive ? theme.palette.primary.hoverBackground : undefined,
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      })}
    >
      <CardHeader
        avatar={NodeIcon}
        title={
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Chip
              label={title}
              size="small"
              sx={{
                background: isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
                color: "text.primary",
              }}
            />
            {StatusTooltip}
          </Stack>
        }
        action={
          <NodeMenu
            onDelete={onDelete}
            onEdit={onEdit}
            httpOperationLoading={httpOperationLoading}
            isPublished={isPublished}
          />
        }
        sx={{
          ".MuiCardHeader-avatar": {
            mr: 1,
          },
        }}
      />
      <CardContent sx={{ py: 0, mb: -2, mt: -1 }}>
        <Stack
          spacing={1}
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
          >
            {name}
          </Typography>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};

const NodeWrapper = memo(InternalNodeWrapper);
export default NodeWrapper;
