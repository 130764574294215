import { type ChangeEvent } from "react";

import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";

import { parseNumber } from "./utils";

type Props = {
  onChange: (value: number | null) => void;
  value: number | null;
  required?: boolean;
  label: string;
  error?: boolean;
  prefix?: string;
  helperText?: string;
};

export default function FormattedNumericField({ onChange, value, required, error, prefix, label, helperText }: Props) {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value: number | null = null;

    if (event.target.value !== "") {
      value = parseNumber(event.target.value);

      if (isNaN(value) || value < 0 || !isFinite(value)) {
        value = null;
      }
    }

    onChange(value);
  };

  return (
    <NumericFormat
      customInput={TextField}
      thousandSeparator={true}
      onChange={handleChange}
      autoComplete="off"
      label={label}
      value={value}
      required={required}
      error={error}
      helperText={helperText}
      prefix={prefix}
    />
  );
}
