import { useMemo } from "react";

import { CloudflowEngineModel, type CloudflowExecutionModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

export function useExecutionModel(executionId: string): {
  execution: CloudflowExecutionModel | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const query = useMemo(
    () => getCollection(CloudflowEngineModel).doc("cloudflows").collection("cloudflowExecutions").doc(executionId),
    [executionId]
  );

  const [execution, loading, error] = useDocumentData(query);

  return { execution, loading, error };
}
