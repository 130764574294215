import * as Sentry from "@sentry/browser";

import { addIdealFirestoreHook, triggerOperations } from "./idealHooks";

// to activate the console tracer, set the sessionStorage item "useConsoleTracer" to "true" globalThis.sessionStorage.setItem("useConsoleTracer", "true")

const shouldUseConsoleTracer = () => {
  // under jest those are undefined
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!globalThis.sessionStorage) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!globalThis.localStorage) {
    return;
  }

  return (
    globalThis.sessionStorage.getItem("useConsoleTracer") === "true" ||
    globalThis.localStorage.getItem("useConsoleTracer") === "true"
  );
};

type Span = {
  end(data?: Record<string, any>): void;
};

type Tracer = {
  startInactiveSpan: (options: { name: string; op: string; attributes?: Readonly<Record<string, any>> }) => Span | null;
};

let tracer: Tracer | null = null;

class ConsoleSpan {
  private readonly startTime: Date;

  private ended = false;

  constructor(
    private readonly name: string,
    private readonly op: string,
    private readonly attributes: Record<string, any> = {}
  ) {
    this.startTime = new Date();
  }

  end(data?: Record<string, any>) {
    if (this.ended) {
      return;
    }
    this.ended = true;

    if (Object.keys(this.attributes).length > 0 || data) {
      // eslint-disable-next-line no-console
      console.log(
        `span ${this.name} attributes: ${JSON.stringify({ ...this.attributes, ...data })} took ${Date.now() - this.startTime.getTime()}ms`
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(`span ${this.name} took ${Date.now() - this.startTime.getTime()}ms`);
    }
  }
}

class ConsoleTracer {
  private traceStartTime = 0;

  constructor() {
    addIdealFirestoreHook("consoleTracer", 10000, (_name, { interval, triggers, startTime }) => {
      if (triggers > 0) {
        // eslint-disable-next-line no-console
        console.log(`spans in the last ${(Date.now() - startTime.getTime() - interval) / 1000} seconds: ${triggers}`);
      }
    });
  }

  startInactiveSpan({
    name,
    op,
    attributes,
  }: {
    name: string;
    op: string;
    attributes?: Readonly<Record<string, any>>;
  }) {
    triggerOperations();

    return new ConsoleSpan(name, op, attributes);
  }
}

class SentryTracer {
  startInactiveSpan({
    name,
    op,
    attributes,
  }: {
    name: string;
    op: string;
    attributes?: Readonly<Record<string, any>>;
  }) {
    const span = Sentry.startInactiveSpan({ name, op });

    if (attributes) {
      Object.entries(attributes).forEach(([key, value]) => {
        span.setAttribute(key, value);
      });
    }

    triggerOperations();

    return span;
  }
}

export const getTracer = (): Tracer => {
  if (!tracer) {
    tracer = shouldUseConsoleTracer() ? new ConsoleTracer() : (new SentryTracer() as Tracer);
  }

  return tracer;
};
