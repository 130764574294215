import { useEffect, useMemo, useState } from "react";

import {
  type CloudConnectAmazonWebServices,
  CustomerModel,
  InsightModel,
  type InsightStatusChangeModel,
  type InsightStatusChangesModel,
} from "@doitintl/cmp-models/src";
import { getCollection, useCollectionData, useDocumentDataOnce } from "@doitintl/models-firestore/src";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import orderBy from "lodash/orderBy";

import { useApiContext } from "../../api/context";
import { useAsyncCurrency } from "../../Components/hooks/useCurrency";
import { queryKeys } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import {
  ALL_CATEGORIES,
  type Category,
  type Insight,
  type InsightResultsResponse,
  type NoAccessInsight,
  type NonEntitledInsightsResults,
  type ServerInsight,
} from "./types";
import { computeStatusForAutmatedInsight } from "./utils";

const getInsightStatusDetails = (matchingStatusChangeDocument: InsightStatusChangeModel): InsightStatusChangesModel => {
  const sortedStatusChanges = orderBy(matchingStatusChangeDocument.statusChanges, ["timestamp.seconds"], ["desc"]);

  return sortedStatusChanges[0];
};

function normalizeInsightCategories(results: Insight[] | undefined): Insight[] {
  return (
    results?.map((datum) => {
      const categories = datum.categories?.length ? datum.categories : datum.otherTags;
      const validCategories = categories?.filter((category): category is Category =>
        ALL_CATEGORIES.some((ac) => ac.toLowerCase() === category.toLowerCase())
      );

      return {
        ...datum,
        categories: validCategories,
      };
    }) || []
  );
}

type InsightsState = {
  insights: Insight[] | undefined;
  nonEntitledSummary: NonEntitledInsightsResults | undefined;
  noAccessResults?: NoAccessInsight[];
  isLoading: boolean;
  error: Error | null;
};

export const useInsights = (): InsightsState => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();
  const { asyncConvertCurrency } = useAsyncCurrency();
  const [noAccessInsightsWithCurrency, setNoAccessInsightsWithCurrency] = useState<NoAccessInsight[] | undefined>();

  const { isFetching, data, error } = useQuery<
    InsightResultsResponse<ServerInsight>,
    AxiosError,
    InsightResultsResponse
  >({
    queryKey: [customer.id, queryKeys.insights],
    queryFn: async () => {
      const response = await api.get<InsightResultsResponse<ServerInsight>>("insights/results", {
        params: { customerID: customer.id },
      });
      return response.data;
    },
    select: (data: InsightResultsResponse<ServerInsight>) => {
      const normalizedResults = normalizeInsightCategories(data.results);
      return { ...data, results: normalizedResults };
    },
    retry: 1,
    retryDelay: 1000,
    retryOnMount: false,

    // Keep the data for an hour for now, as it's unlikely to change often - this way,
    // the user can navigate between insights without getting a loading indicator
    staleTime: 60 * 60 * 1000,
  });

  const [insightsStatusChanges] = useCollectionData(
    getCollection(InsightModel).doc("api").collection("insightsStatusChanges").where("customerId", "==", customer.id)
  );

  useEffect(() => {
    if (!data?.noAccessResults) {
      return;
    }

    const fn = async (insight) => {
      if (!insight.potentialDailySavings) {
        return insight;
      }

      return {
        ...insight,
        potentialDailySavings: {
          ...insight.potentialDailySavings,
          value: await asyncConvertCurrency(insight.potentialDailySavings.value),
        },
      };
    };

    Promise.all(data.noAccessResults.map(fn)).then((noAccessInsightsWithUpdatedCurrency) => {
      setNoAccessInsightsWithCurrency(noAccessInsightsWithUpdatedCurrency);
    });
  }, [asyncConvertCurrency, data?.noAccessResults]);

  const insightsWithStatuses = useMemo(() => {
    if (!data?.results) {
      return [];
    }

    if (!insightsStatusChanges) {
      return data.results;
    }

    return data.results.map((insight) => {
      const matchingStatusChangeDocument = insightsStatusChanges.find(
        (statusChange) => statusChange.insightRef.id === `${insight.customerId}#${insight.providerId}#${insight.key}`
      );

      if (!matchingStatusChangeDocument) {
        const status = computeStatusForAutmatedInsight(insight);

        return {
          ...insight,
          userStatusChanges: { status } as any,
        };
      }

      const userStatusChanges = getInsightStatusDetails(matchingStatusChangeDocument);

      return {
        ...insight,
        userStatusChanges,
      };
    });
  }, [data?.results, insightsStatusChanges]);

  // Double safety - the API does not return internal insights for non-DoiT employees,
  // but just in case something goes wrong there, filter them again
  const finalInsights = useMemo(() => {
    if (!isDoitEmployee) {
      return insightsWithStatuses.filter((insight) => !insight.isInternal);
    }
    return insightsWithStatuses;
  }, [insightsWithStatuses, isDoitEmployee]);

  // We are loading if the query is still fetching or if we have data, but have not yet processes the insightStatusChanges
  const isLoading = isFetching || (data !== undefined && insightsStatusChanges === undefined);

  return useMemo(() => {
    if (isLoading || !data || finalInsights.length === 0) {
      return {
        insights: undefined,
        nonEntitledSummary: undefined,
        noAccessResults: noAccessInsightsWithCurrency,
        error,
        isLoading,
      };
    }

    return {
      insights: finalInsights,
      nonEntitledSummary: data.nonEntitledResultsSummary,
      noAccessResults: noAccessInsightsWithCurrency,
      error,
      isLoading,
    };
  }, [data, noAccessInsightsWithCurrency, error, isLoading, finalInsights]);
};

export const createInsight = async ({ api, insight }) => api.post(`/insights/results`, insight);

export const useRelevantTrustedAdvisorCustomerAccounts = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const [trustedAdvisorCustomerDoc, loading, error] = useDocumentDataOnce(
    getCollection(InsightModel)
      .doc("trusted-advisor")
      .collection("relevantCustomersAccounts")
      .doc(customerOrPresentationModeCustomer.id)
  );

  if (loading) {
    return undefined;
  }

  if (error) {
    return null;
  }

  return trustedAdvisorCustomerDoc?.accounts;
};

export const useAccounts = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();
  const queryCloudConnect = getCollection(CustomerModel)
    .doc(customerOrPresentationModeCustomer.id)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", "amazon-web-services")
    .narrow<CloudConnectAmazonWebServices>();

  return useCollectionData(queryCloudConnect);
};

export const sendUpsellUpgradeNotification = async ({ api, customerID }) =>
  api.post(`/insights/notification/upsell-upgrade?customerID=${customerID}`);
