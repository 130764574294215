import { type MouseEvent, type ReactNode, useState } from "react";

import { Box, ClickAwayListener } from "@mui/material";

import HtmlTooltip from "./Tooltip/HtmlTooltip";

export type TextWithTooltipProps = {
  triggerText: string;
  tooltipComponent: ReactNode;
};

export const TextWithTooltip = ({ triggerText, tooltipComponent }: TextWithTooltipProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <span>
        <HtmlTooltip
          slotProps={{
            popper: {
              disablePortal: true,
            },
          }}
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipComponent}
        >
          <Box
            sx={{ borderBottom: "1px dotted", borderColor: "text.primary", cursor: "pointer" }}
            component="span"
            onClick={handleTooltipOpen}
          >
            {triggerText}
          </Box>
        </HtmlTooltip>
      </span>
    </ClickAwayListener>
  );
};
