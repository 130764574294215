import { ClusterType, type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";

export const DOIT_EKS_GROUP = "doit-eks-group";

export type KubernetesResourceVerb =
  | "get"
  | "list"
  | "watch"
  | "create"
  | "update"
  | "patch"
  | "delete"
  | "deletecollection"
  | "impersonate";

const apiGroupsByResource: Record<string, string[]> = {
  // Core API group (empty string)
  pods: [""],
  services: [""],
  configmaps: [""],
  secrets: [""],
  persistentvolumeclaims: [""],
  nodes: [""],
  persistentvolumes: [""],

  // Apps API group
  deployments: ["apps"],
  daemonsets: ["apps"],
  statefulsets: ["apps"],
  replicasets: ["apps"],

  // Batch API group
  jobs: ["batch"],
  cronjobs: ["batch"],

  // Policy API group
  poddisruptionbudgets: ["policy"],
  podsecuritypolicies: ["policy"],

  // Networking API group
  networkpolicies: ["networking.k8s.io"],
  ingresses: ["networking.k8s.io"],

  // RBAC API group
  roles: ["rbac.authorization.k8s.io"],
  rolebindings: ["rbac.authorization.k8s.io"],
  clusterroles: ["rbac.authorization.k8s.io"],
  clusterrolebindings: ["rbac.authorization.k8s.io"],

  // Storage API group
  storageclasses: ["storage.k8s.io"],
  volumeattachments: ["storage.k8s.io"],

  // Admissionregistration API group
  mutatingwebhookconfigurations: ["admissionregistration.k8s.io"],
  validatingwebhookconfigurations: ["admissionregistration.k8s.io"],

  // Autoscaling API group
  horizontalpodautoscalers: ["autoscaling"],

  // Events API group
  events: ["events.k8s.io"],

  default: [""],
};

export type KubernetesRbacConfig = Partial<Record<string, KubernetesResourceVerb[]>>;

const getRoleBindingSubject = (cluster: ClusterWithCloudConnect): string => {
  if (cluster.type === ClusterType.EKS) {
    return `subjects:
  - kind: Group
    name: ${DOIT_EKS_GROUP}`;
  } else if (cluster.type === ClusterType.GKE) {
    return `subjects:
  - kind: User
    name: ${cluster.cloudConnect?.clientEmail}`;
  }

  return "";
};

const getYamlContentForFeature = (cluster: ClusterWithCloudConnect, feature: KubernetesFeature): string => {
  const { id, name, requirements } = feature;

  const clusterPrefix = `doit-${cluster.region}-${cluster.name}`;
  const roleName = `${clusterPrefix}-${id}-role`;
  const roleBindingName = `${clusterPrefix}-${id}-binding`;

  const rules = requirements.map(
    ({ resource, verb, group }) =>
      `  - apiGroups: ["${group ?? (apiGroupsByResource[resource] || apiGroupsByResource.default).join('", "')}"]
    resources: ["${resource}"]
    verbs: ["${verb}"]`
  );

  return `# Define a ClusterRole with permissions for the feature: ${name}
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRole
metadata:
  name: ${roleName}
  labels:
    feature: ${id}
rules:
${rules.join("\n")}

---
# Bind the ClusterRole to a Group
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRoleBinding
metadata:
  name: ${roleBindingName}
  labels:
    feature: ${id}
${getRoleBindingSubject(cluster)}
roleRef:
  kind: ClusterRole
  name: ${roleName}
  apiGroup: rbac.authorization.k8s.io

`;
};

export const buildRbacYamlFileContent = (cluster: ClusterWithCloudConnect, features: KubernetesFeature[]): string =>
  features.map((feature) => getYamlContentForFeature(cluster, feature)).join("\n---\n");

export const getRbacYamlFileContent = (
  cluster: ClusterWithCloudConnect | null,
  features: KubernetesFeature[]
): string => {
  if (!cluster) {
    throw new Error("Cluster is required");
  }

  return buildRbacYamlFileContent(cluster, features);
};
