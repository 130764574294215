import { Box, styled, useTheme } from "@mui/material";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { useTabDataFormat } from "../hooks/useTabData";
import { type TabConfig, type TabId } from "./types";

const StyledPre = styled("pre")(({ theme }) => ({
  height: 400,
  overflow: "auto",
  overflowX: "auto",
  whiteSpace: "pre",
  margin: 0,
  padding: theme.spacing(2),
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
}));

type TabPanelProps = {
  tabConfig: TabConfig[];
  activeTab: TabId;
};

const TabPanel = ({ tabConfig, activeTab }: TabPanelProps) => {
  const theme = useTheme();
  const isDarkTheme = useDarkThemeCheck();
  const format = useTabDataFormat(activeTab);

  const backgroundColor = isDarkTheme ? cmpBaseColors.backgroundDark : cmpBaseColors.lightBackground;
  const activeConfig = tabConfig.find((config) => config.id === activeTab);

  if (!activeConfig?.show) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: theme.spacing(2) }}>
      <StyledPre sx={{ backgroundColor }}>
        <code>{format(activeConfig.data)}</code>
      </StyledPre>
    </Box>
  );
};

export default TabPanel;
