import { createContext, type ReactNode, useContext, useMemo } from "react";

import { type DataHubMetrics, type ExtendedMetric, type ReportConfig } from "@doitintl/cmp-models";

import { type AffectedResources } from "./attributions/DeleteValidationDialog";

export type CloudAnalyticsContextProps = {
  handleMissingPermission: (message: string) => void;
  extendedMetrics: ExtendedMetric[];
  datahubMetrics: DataHubMetrics[];
  defaultReportConfig: ReportConfig | null;
  defaultConfigLoading: boolean;
  handleSaveDefaultReportConfig: (config: ReportConfig) => Promise<void>;
  handleDeleteValidationResponse: (data: AffectedResources[]) => void;
  handleDeleteAttributionGroupValidationResponse: (data: AffectedResources[]) => void;
  handleFavoriteReportTemplateUpdate: (id: string) => void;
  getTemplateIdByReportId: (reportId: string) => string | undefined;
  loadAnalyticsLabels: () => void;
};

const cloudAnalyticsContext = createContext({} as CloudAnalyticsContextProps);

export const useCloudAnalyticsContext = () => useContext(cloudAnalyticsContext);

export const CloudAnalyticsContextProvider = cloudAnalyticsContext.Provider;

export const CloudAnalyticsContextProviderForTesting = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: Partial<CloudAnalyticsContextProps>;
}) => {
  const actualValue = useMemo(
    (): Partial<CloudAnalyticsContextProps> => ({
      ...value,
    }),
    [value]
  );

  return (
    <cloudAnalyticsContext.Provider value={actualValue as CloudAnalyticsContextProps}>
      {children}
    </cloudAnalyticsContext.Provider>
  );
};
