export const getLanguageEmoji = (language: string): string => {
  const languageEmojis: { [key: string]: string } = {
    English: "🇬🇧",
    Spanish: "🇪🇸",
    French: "🇫🇷",
    German: "🇩🇪",
    Italian: "🇮🇹",
    Portuguese: "🇵🇹",
    Russian: "🇷🇺",
    Chinese: "🇨🇳",
    Japanese: "🇯🇵",
    Korean: "🇰🇷",
    Arabic: "🇸🇦",
    Hindi: "🇮🇳",
    Bengali: "🇧🇩",
    Dutch: "🇳🇱",
    Turkish: "🇹🇷",
    Vietnamese: "🇻🇳",
    Polish: "🇵🇱",
    Ukrainian: "🇺🇦",
    Persian: "🇮🇷",
    Romanian: "🇷🇴",
    Greek: "🇬🇷",
    Czech: "🇨🇿",
    Swedish: "🇸🇪",
    Hungarian: "🇭🇺",
    Danish: "🇩🇰",
    Finnish: "🇫🇮",
    Norwegian: "🇳🇴",
    Slovak: "🇸🇰",
    Thai: "🇹🇭",
    Indonesian: "🇮🇩",
    Malay: "🇲🇾",
    Hebrew: "🇮🇱",
    Tagalog: "🇵🇭",
    Catalan: "🏴󠁥󠁳󠁣󠁴󠁿",
    Croatian: "🇭🇷",
    Serbian: "🇷🇸",
    Bulgarian: "🇧🇬",
    Latvian: "🇱🇻",
    Lithuanian: "🇱🇹",
    Estonian: "🇪🇪",
    Slovenian: "🇸🇮",
    Swahili: "🇹🇿",
    Urdu: "🇵🇰",
    Afrikaans: "🇿🇦",
    Icelandic: "🇮🇸",
    Malayalam: "🇮🇳",
    Tamil: "🇮🇳",
    Telugu: "🇮🇳",
    Kannada: "🇮🇳",
    Marathi: "🇮🇳",
    Punjabi: "🇮🇳",
    Welsh: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    Gaelic: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    Irish: "🇮🇪",
    Maltese: "🇲🇹",
    Luxembourgish: "🇱🇺",
    Mongolian: "🇲🇳",
    Nepali: "🇳🇵",
    Sinhala: "🇱🇰",
    Lao: "🇱🇦",
    Khmer: "🇰🇭",
    Burmese: "🇲🇲",
    Amharic: "🇪🇹",
    Somali: "🇸🇴",
    Hausa: "🇳🇬",
    Yoruba: "🇳🇬",
    Igbo: "🇳🇬",
    Zulu: "🇿🇦",
    Xhosa: "🇿🇦",
    Maori: "🇳🇿",
    Samoan: "🇼🇸",
    Tongan: "🇹🇴",
    Fijian: "🇫🇯",
    Hawaiian: "🇺🇸",
    Bosnian: "🇧🇦",
  };

  return languageEmojis[language] || "🌐";
};

export const timezoneToCountry = {
  "Africa/Abidjan": "Ivory Coast",
  "Africa/Accra": "Ghana",
  "Africa/Addis_Ababa": "Ethiopia",
  "Africa/Algiers": "Algeria",
  "Africa/Asmara": "Eritrea",
  "Africa/Bamako": "Mali",
  "Africa/Bangui": "Central African Republic",
  "Africa/Banjul": "Gambia",
  "Africa/Bissau": "Guinea-Bissau",
  "Africa/Blantyre": "Malawi",
  "Africa/Brazzaville": "Republic of the Congo",
  "Africa/Bujumbura": "Burundi",
  "Africa/Cairo": "Egypt",
  "Africa/Casablanca": "Morocco",
  "Africa/Ceuta": "Spain",
  "Africa/Conakry": "Guinea",
  "Africa/Dakar": "Senegal",
  "Africa/Dar_es_Salaam": "Tanzania",
  "Africa/Djibouti": "Djibouti",
  "Africa/Douala": "Cameroon",
  "Africa/El_Aaiun": "Western Sahara",
  "Africa/Freetown": "Sierra Leone",
  "Africa/Gaborone": "Botswana",
  "Africa/Harare": "Zimbabwe",
  "Africa/Johannesburg": "South Africa",
  "Africa/Juba": "South Sudan",
  "Africa/Kampala": "Uganda",
  "Africa/Khartoum": "Sudan",
  "Africa/Kigali": "Rwanda",
  "Africa/Kinshasa": "Democratic Republic of the Congo",
  "Africa/Lagos": "Nigeria",
  "Africa/Libreville": "Gabon",
  "Africa/Lome": "Togo",
  "Africa/Luanda": "Angola",
  "Africa/Lubumbashi": "Democratic Republic of the Congo",
  "Africa/Lusaka": "Zambia",
  "Africa/Malabo": "Equatorial Guinea",
  "Africa/Maputo": "Mozambique",
  "Africa/Maseru": "Lesotho",
  "Africa/Mbabane": "Eswatini",
  "Africa/Mogadishu": "Somalia",
  "Africa/Monrovia": "Liberia",
  "Africa/Nairobi": "Kenya",
  "Africa/Ndjamena": "Chad",
  "Africa/Niamey": "Niger",
  "Africa/Nouakchott": "Mauritania",
  "Africa/Ouagadougou": "Burkina Faso",
  "Africa/Porto-Novo": "Benin",
  "Africa/Sao_Tome": "Sao Tome and Principe",
  "Africa/Tripoli": "Libya",
  "Africa/Tunis": "Tunisia",
  "Africa/Windhoek": "Namibia",
  "America/Adak": "United States",
  "America/Anchorage": "United States",
  "America/Anguilla": "Anguilla",
  "America/Antigua": "Antigua and Barbuda",
  "America/Araguaina": "Brazil",
  "America/Argentina/Buenos_Aires": "Argentina",
  "America/Argentina/Catamarca": "Argentina",
  "America/Argentina/Cordoba": "Argentina",
  "America/Argentina/Jujuy": "Argentina",
  "America/Argentina/La_Rioja": "Argentina",
  "America/Argentina/Mendoza": "Argentina",
  "America/Argentina/Rio_Gallegos": "Argentina",
  "America/Argentina/Salta": "Argentina",
  "America/Argentina/San_Juan": "Argentina",
  "America/Argentina/San_Luis": "Argentina",
  "America/Argentina/Tucuman": "Argentina",
  "America/Argentina/Ushuaia": "Argentina",
  "America/Aruba": "Aruba",
  "America/Asuncion": "Paraguay",
  "America/Atikokan": "Canada",
  "America/Bahia": "Brazil",
  "America/Bahia_Banderas": "Mexico",
  "America/Barbados": "Barbados",
  "America/Belem": "Brazil",
  "America/Belize": "Belize",
  "America/Blanc-Sablon": "Canada",
  "America/Boa_Vista": "Brazil",
  "America/Bogota": "Colombia",
  "America/Boise": "United States",
  "America/Cambridge_Bay": "Canada",
  "America/Campo_Grande": "Brazil",
  "America/Cancun": "Mexico",
  "America/Caracas": "Venezuela",
  "America/Cayenne": "French Guiana",
  "America/Cayman": "Cayman Islands",
  "America/Chicago": "United States",
  "America/Chihuahua": "Mexico",
  "America/Costa_Rica": "Costa Rica",
  "America/Creston": "Canada",
  "America/Cuiaba": "Brazil",
  "America/Curacao": "Curacao",
  "America/Danmarkshavn": "Greenland",
  "America/Dawson": "Canada",
  "America/Dawson_Creek": "Canada",
  "America/Denver": "United States",
  "America/Detroit": "United States",
  "America/Dominica": "Dominica",
  "America/Edmonton": "Canada",
  "America/Eirunepe": "Brazil",
  "America/El_Salvador": "El Salvador",
  "America/Fort_Nelson": "Canada",
  "America/Fortaleza": "Brazil",
  "America/Glace_Bay": "Canada",
  "America/Goose_Bay": "Canada",
  "America/Grand_Turk": "Turks and Caicos Islands",
  "America/Grenada": "Grenada",
  "America/Guadeloupe": "Guadeloupe",
  "America/Guatemala": "Guatemala",
  "America/Guayaquil": "Ecuador",
  "America/Guyana": "Guyana",
  "America/Halifax": "Canada",
  "America/Havana": "Cuba",
  "America/Hermosillo": "Mexico",
  "America/Indiana/Indianapolis": "United States",
  "America/Indiana/Knox": "United States",
  "America/Indiana/Marengo": "United States",
  "America/Indiana/Petersburg": "United States",
  "America/Indiana/Tell_City": "United States",
  "America/Indiana/Vevay": "United States",
  "America/Indiana/Vincennes": "United States",
  "America/Indiana/Winamac": "United States",
  "America/Inuvik": "Canada",
  "America/Iqaluit": "Canada",
  "America/Jamaica": "Jamaica",
  "America/Juneau": "United States",
  "America/Kentucky/Louisville": "United States",
  "America/Kentucky/Monticello": "United States",
  "America/Kralendijk": "Bonaire",
  "America/La_Paz": "Bolivia",
  "America/Lima": "Peru",
  "America/Los_Angeles": "United States",
  "America/Lower_Princes": "Sint Maarten",
  "America/Maceio": "Brazil",
  "America/Managua": "Nicaragua",
  "America/Manaus": "Brazil",
  "America/Marigot": "Saint Martin",
  "America/Martinique": "Martinique",
  "America/Matamoros": "Mexico",
  "America/Mazatlan": "Mexico",
  "America/Menominee": "United States",
  "America/Merida": "Mexico",
  "America/Metlakatla": "United States",
  "America/Mexico_City": "Mexico",
  "America/Miquelon": "Saint Pierre and Miquelon",
  "America/Moncton": "Canada",
  "America/Monterrey": "Mexico",
  "America/Montevideo": "Uruguay",
  "America/Montserrat": "Montserrat",
  "America/Nassau": "Bahamas",
  "America/New_York": "United States",
  "America/Nipigon": "Canada",
  "America/Nome": "United States",
  "America/Noronha": "Brazil",
  "America/North_Dakota/Beulah": "United States",
  "America/North_Dakota/Center": "United States",
  "America/North_Dakota/New_Salem": "United States",
  "America/Nuuk": "Greenland",
  "America/Ojinaga": "Mexico",
  "America/Panama": "Panama",
  "America/Pangnirtung": "Canada",
  "America/Paramaribo": "Suriname",
  "America/Phoenix": "United States",
  "America/Port-au-Prince": "Haiti",
  "America/Port_of_Spain": "Trinidad and Tobago",
  "America/Porto_Velho": "Brazil",
  "America/Puerto_Rico": "Puerto Rico",
  "America/Punta_Arenas": "Chile",
  "America/Rainy_River": "Canada",
  "America/Rankin_Inlet": "Canada",
  "America/Recife": "Brazil",
  "America/Regina": "Canada",
  "America/Resolute": "Canada",
  "America/Rio_Branco": "Brazil",
  "America/Santarem": "Brazil",
  "America/Santiago": "Chile",
  "America/Santo_Domingo": "Dominican Republic",
  "America/Sao_Paulo": "Brazil",
  "America/Scoresbysund": "Greenland",
  "America/Sitka": "United States",
  "America/St_Barthelemy": "Saint Barthélemy",
  "America/St_Johns": "Canada",
  "America/St_Kitts": "Saint Kitts and Nevis",
  "America/St_Lucia": "Saint Lucia",
  "America/St_Thomas": "U.S. Virgin Islands",
  "America/St_Vincent": "Saint Vincent and the Grenadines",
  "America/Swift_Current": "Canada",
  "America/Tegucigalpa": "Honduras",
  "America/Thule": "Greenland",
  "America/Thunder_Bay": "Canada",
  "America/Tijuana": "Mexico",
  "America/Toronto": "Canada",
  "America/Tortola": "British Virgin Islands",
  "America/Vancouver": "Canada",
  "America/Whitehorse": "Canada",
  "America/Winnipeg": "Canada",
  "America/Yakutat": "United States",
  "America/Yellowknife": "Canada",
  "Antarctica/Casey": "Antarctica",
  "Antarctica/Davis": "Antarctica",
  "Antarctica/DumontDUrville": "Antarctica",
  "Antarctica/Macquarie": "Australia",
  "Antarctica/Mawson": "Antarctica",
  "Antarctica/McMurdo": "Antarctica",
  "Antarctica/Palmer": "Antarctica",
  "Antarctica/Rothera": "Antarctica",
  "Antarctica/Syowa": "Antarctica",
  "Antarctica/Troll": "Antarctica",
  "Antarctica/Vostok": "Antarctica",
  "Arctic/Longyearbyen": "Svalbard and Jan Mayen",
  "Asia/Aden": "Yemen",
  "Asia/Almaty": "Kazakhstan",
  "Asia/Amman": "Jordan",
  "Asia/Anadyr": "Russia",
  "Asia/Aqtau": "Kazakhstan",
  "Asia/Aqtobe": "Kazakhstan",
  "Asia/Ashgabat": "Turkmenistan",
  "Asia/Atyrau": "Kazakhstan",
  "Asia/Baghdad": "Iraq",
  "Asia/Bahrain": "Bahrain",
  "Asia/Baku": "Azerbaijan",
  "Asia/Bangkok": "Thailand",
  "Asia/Barnaul": "Russia",
  "Asia/Beirut": "Lebanon",
  "Asia/Bishkek": "Kyrgyzstan",
  "Asia/Brunei": "Brunei",
  "Asia/Chita": "Russia",
  "Asia/Choibalsan": "Mongolia",
  "Asia/Colombo": "Sri Lanka",
  "Asia/Damascus": "Syria",
  "Asia/Dhaka": "Bangladesh",
  "Asia/Dili": "East Timor",
  "Asia/Dubai": "United Arab Emirates",
  "Asia/Dushanbe": "Tajikistan",
  "Asia/Famagusta": "Cyprus",
  "Asia/Gaza": "Palestine",
  "Asia/Hebron": "Palestine",
  "Asia/Ho_Chi_Minh": "Vietnam",
  "Asia/Hong_Kong": "Hong Kong",
  "Asia/Hovd": "Mongolia",
  "Asia/Irkutsk": "Russia",
  "Asia/Jakarta": "Indonesia",
  "Asia/Jayapura": "Indonesia",
  "Asia/Jerusalem": "Israel",
  "Asia/Kabul": "Afghanistan",
  "Asia/Kamchatka": "Russia",
  "Asia/Karachi": "Pakistan",
  "Asia/Kathmandu": "Nepal",
  "Asia/Khandyga": "Russia",
  "Asia/Kolkata": "India",
  "Asia/Krasnoyarsk": "Russia",
  "Asia/Kuala_Lumpur": "Malaysia",
  "Asia/Kuching": "Malaysia",
  "Asia/Kuwait": "Kuwait",
  "Asia/Macau": "Macao",
  "Asia/Magadan": "Russia",
  "Asia/Makassar": "Indonesia",
  "Asia/Manila": "Philippines",
  "Asia/Muscat": "Oman",
  "Asia/Nicosia": "Cyprus",
  "Asia/Novokuznetsk": "Russia",
  "Asia/Novosibirsk": "Russia",
  "Asia/Omsk": "Russia",
  "Asia/Oral": "Kazakhstan",
  "Asia/Phnom_Penh": "Cambodia",
  "Asia/Pontianak": "Indonesia",
  "Asia/Pyongyang": "North Korea",
  "Asia/Qatar": "Qatar",
  "Asia/Qostanay": "Kazakhstan",
  "Asia/Qyzylorda": "Kazakhstan",
  "Asia/Riyadh": "Saudi Arabia",
  "Asia/Sakhalin": "Russia",
  "Asia/Samarkand": "Uzbekistan",
  "Asia/Seoul": "South Korea",
  "Asia/Shanghai": "China",
  "Asia/Singapore": "Singapore",
  "Asia/Srednekolymsk": "Russia",
  "Asia/Taipei": "Taiwan",
  "Asia/Tashkent": "Uzbekistan",
  "Asia/Tbilisi": "Georgia",
  "Asia/Tehran": "Iran",
  "Asia/Thimphu": "Bhutan",
  "Asia/Tokyo": "Japan",
  "Asia/Tomsk": "Russia",
  "Asia/Ulaanbaatar": "Mongolia",
  "Asia/Urumqi": "China",
  "Asia/Ust-Nera": "Russia",
  "Asia/Vientiane": "Laos",
  "Asia/Vladivostok": "Russia",
  "Asia/Yakutsk": "Russia",
  "Asia/Yangon": "Myanmar",
  "Asia/Yekaterinburg": "Russia",
  "Asia/Yerevan": "Armenia",
  "Atlantic/Azores": "Portugal",
  "Atlantic/Bermuda": "Bermuda",
  "Atlantic/Canary": "Spain",
  "Atlantic/Cape_Verde": "Cape Verde",
  "Atlantic/Faroe": "Faroe Islands",
  "Atlantic/Madeira": "Portugal",
  "Atlantic/Reykjavik": "Iceland",
  "Atlantic/South_Georgia": "South Georgia and the South Sandwich Islands",
  "Atlantic/St_Helena": "Saint Helena",
  "Atlantic/Stanley": "Falkland Islands",
  "Australia/Adelaide": "Australia",
  "Australia/Brisbane": "Australia",
  "Australia/Broken_Hill": "Australia",
  "Australia/Darwin": "Australia",
  "Australia/Eucla": "Australia",
  "Australia/Hobart": "Australia",
  "Australia/Lindeman": "Australia",
  "Australia/Lord_Howe": "Australia",
  "Australia/Melbourne": "Australia",
  "Australia/Perth": "Australia",
  "Australia/Sydney": "Australia",
  "Europe/Amsterdam": "Netherlands",
  "Europe/Andorra": "Andorra",
  "Europe/Astrakhan": "Russia",
  "Europe/Athens": "Greece",
  "Europe/Belgrade": "Serbia",
  "Europe/Berlin": "Germany",
  "Europe/Bratislava": "Slovakia",
  "Europe/Brussels": "Belgium",
  "Europe/Bucharest": "Romania",
  "Europe/Budapest": "Hungary",
  "Europe/Busingen": "Germany",
  "Europe/Chisinau": "Moldova",
  "Europe/Copenhagen": "Denmark",
  "Europe/Dublin": "Ireland",
  "Europe/Gibraltar": "Gibraltar",
  "Europe/Guernsey": "Guernsey",
  "Europe/Helsinki": "Finland",
  "Europe/Isle_of_Man": "Isle of Man",
  "Europe/Istanbul": "Turkey",
  "Europe/Jersey": "Jersey",
  "Europe/Kaliningrad": "Russia",
  "Europe/Kiev": "Ukraine",
  "Europe/Kirov": "Russia",
  "Europe/Lisbon": "Portugal",
  "Europe/Ljubljana": "Slovenia",
  "Europe/London": "United Kingdom",
  "Europe/Luxembourg": "Luxembourg",
  "Europe/Madrid": "Spain",
  "Europe/Malta": "Malta",
  "Europe/Mariehamn": "Åland Islands",
  "Europe/Minsk": "Belarus",
  "Europe/Monaco": "Monaco",
  "Europe/Moscow": "Russia",
  "Europe/Oslo": "Norway",
  "Europe/Paris": "France",
  "Europe/Podgorica": "Montenegro",
  "Europe/Prague": "Czech Republic",
  "Europe/Riga": "Latvia",
  "Europe/Rome": "Italy",
  "Europe/Samara": "Russia",
  "Europe/San_Marino": "San Marino",
  "Europe/Sarajevo": "Bosnia and Herzegovina",
  "Europe/Saratov": "Russia",
  "Europe/Simferopol": "Russia",
  "Europe/Skopje": "North Macedonia",
  "Europe/Sofia": "Bulgaria",
  "Europe/Stockholm": "Sweden",
  "Europe/Tallinn": "Estonia",
  "Europe/Tirane": "Albania",
  "Europe/Ulyanovsk": "Russia",
  "Europe/Uzhgorod": "Ukraine",
  "Europe/Vaduz": "Liechtenstein",
  "Europe/Vatican": "Vatican City",
  "Europe/Vienna": "Austria",
  "Europe/Vilnius": "Lithuania",
  "Europe/Volgograd": "Russia",
  "Europe/Warsaw": "Poland",
  "Europe/Zagreb": "Croatia",
  "Europe/Zaporozhye": "Ukraine",
  "Europe/Zurich": "Switzerland",
  "Indian/Antananarivo": "Madagascar",
  "Indian/Chagos": "British Indian Ocean Territory",
  "Indian/Christmas": "Christmas Island",
  "Indian/Cocos": "Cocos (Keeling) Islands",
  "Indian/Comoro": "Comoros",
  "Indian/Kerguelen": "French Southern Territories",
  "Indian/Mahe": "Seychelles",
  "Indian/Maldives": "Maldives",
  "Indian/Mauritius": "Mauritius",
  "Indian/Mayotte": "Mayotte",
  "Indian/Reunion": "Réunion",
  "Pacific/Apia": "Samoa",
  "Pacific/Auckland": "New Zealand",
  "Pacific/Bougainville": "Papua New Guinea",
  "Pacific/Chatham": "New Zealand",
  "Pacific/Chuuk": "Micronesia",
  "Pacific/Easter": "Chile",
  "Pacific/Efate": "Vanuatu",
  "Pacific/Enderbury": "Kiribati",
  "Pacific/Fakaofo": "Tokelau",
  "Pacific/Fiji": "Fiji",
  "Pacific/Funafuti": "Tuvalu",
  "Pacific/Galapagos": "Ecuador",
  "Pacific/Gambier": "French Polynesia",
  "Pacific/Guadalcanal": "Solomon Islands",
  "Pacific/Guam": "Guam",
  "Pacific/Honolulu": "United States",
  "Pacific/Kiritimati": "Kiribati",
  "Pacific/Kosrae": "Micronesia",
  "Pacific/Kwajalein": "Marshall Islands",
  "Pacific/Majuro": "Marshall Islands",
  "Pacific/Marquesas": "French Polynesia",
  "Pacific/Midway": "United States Minor Outlying Islands",
  "Pacific/Nauru": "Nauru",
  "Pacific/Niue": "Niue",
  "Pacific/Norfolk": "Norfolk Island",
  "Pacific/Noumea": "New Caledonia",
  "Pacific/Pago_Pago": "American Samoa",
  "Pacific/Palau": "Palau",
  "Pacific/Pitcairn": "Pitcairn Islands",
  "Pacific/Pohnpei": "Micronesia",
  "Pacific/Port_Moresby": "Papua New Guinea",
  "Pacific/Rarotonga": "Cook Islands",
  "Pacific/Saipan": "Northern Mariana Islands",
  "Pacific/Tahiti": "French Polynesia",
  "Pacific/Tarawa": "Kiribati",
  "Pacific/Tongatapu": "Tonga",
  "Pacific/Wake": "United States Minor Outlying Islands",
  "Pacific/Wallis": "Wallis and Futuna",
};

export const countryToFlagEmoji = {
  Afghanistan: "🇦🇫",
  Albania: "🇦🇱",
  Algeria: "🇩🇿",
  Angola: "🇦🇴",
  Argentina: "🇦🇷",
  Armenia: "🇦🇲",
  Australia: "🇦🇺",
  Austria: "🇦🇹",
  Azerbaijan: "🇦🇿",
  Bahrain: "🇧🇭",
  Bangladesh: "🇧🇩",
  Belarus: "🇧🇾",
  Belgium: "🇧🇪",
  Benin: "🇧🇯",
  Bolivia: "🇧🇴",
  "Bosnia and Herzegovina": "🇧🇦",
  Botswana: "🇧🇼",
  Brazil: "🇧🇷",
  Bulgaria: "🇧🇬",
  "Burkina Faso": "🇧🇫",
  Burundi: "🇧🇮",
  Cambodia: "🇰🇭",
  Cameroon: "🇨🇲",
  Canada: "🇨🇦",
  "Central African Republic": "🇨🇫",
  Chad: "🇹🇩",
  Chile: "🇨🇱",
  China: "🇨🇳",
  Colombia: "🇨🇴",
  Congo: "🇨🇬",
  "Costa Rica": "🇨🇷",
  Croatia: "🇭🇷",
  Cuba: "🇨🇺",
  "Czech Republic": "🇨🇿",
  Denmark: "🇩🇰",
  "Dominican Republic": "🇩🇴",
  "DR Congo": "🇨🇩",
  Ecuador: "🇪🇨",
  Egypt: "🇪🇬",
  "El Salvador": "🇸🇻",
  "Equatorial Guinea": "🇬🇶",
  Eritrea: "🇪🇷",
  Estonia: "🇪🇪",
  Ethiopia: "🇪🇹",
  Finland: "🇫🇮",
  France: "🇫🇷",
  Gabon: "🇬🇦",
  Gambia: "🇬🇲",
  Georgia: "🇬🇪",
  Germany: "🇩🇪",
  Ghana: "🇬🇭",
  Greece: "🇬🇷",
  Guatemala: "🇬🇹",
  Guinea: "🇬🇳",
  "Guinea-Bissau": "🇬🇼",
  Haiti: "🇭🇹",
  Honduras: "🇭🇳",
  Hungary: "🇭🇺",
  India: "🇮🇳",
  Indonesia: "🇮🇩",
  Iran: "🇮🇷",
  Iraq: "🇮🇶",
  Ireland: "🇮🇪",
  Israel: "🇮🇱",
  Italy: "🇮🇹",
  "Ivory Coast": "🇨🇮",
  Jamaica: "🇯🇲",
  Japan: "🇯🇵",
  Jordan: "🇯🇴",
  Kazakhstan: "🇰🇿",
  Kenya: "🇰🇪",
  Kuwait: "🇰🇼",
  Kyrgyzstan: "🇰🇬",
  Laos: "🇱🇦",
  Latvia: "🇱🇻",
  Lebanon: "🇱🇧",
  Lesotho: "🇱🇸",
  Liberia: "🇱🇷",
  Libya: "🇱🇾",
  Lithuania: "🇱🇹",
  Madagascar: "🇲🇬",
  Malawi: "🇲🇼",
  Malaysia: "🇲🇾",
  Mali: "🇲🇱",
  Mauritania: "🇲🇷",
  Mexico: "🇲🇽",
  Moldova: "🇲🇩",
  Mongolia: "🇲🇳",
  Montenegro: "🇲🇪",
  Morocco: "🇲🇦",
  Mozambique: "🇲🇿",
  Myanmar: "🇲🇲",
  Namibia: "🇳🇦",
  Nepal: "🇳🇵",
  Netherlands: "🇳🇱",
  "New Zealand": "🇳🇿",
  Nicaragua: "🇳🇮",
  Niger: "🇳🇪",
  Nigeria: "🇳🇬",
  "North Korea": "🇰🇵",
  "North Macedonia": "🇲🇰",
  Norway: "🇳🇴",
  Oman: "🇴🇲",
  Pakistan: "🇵🇰",
  Palestine: "🇵🇸",
  Panama: "🇵🇦",
  "Papua New Guinea": "🇵🇬",
  Paraguay: "🇵🇾",
  Peru: "🇵🇪",
  Philippines: "🇵🇭",
  Poland: "🇵🇱",
  Portugal: "🇵🇹",
  Qatar: "🇶🇦",
  Romania: "🇷🇴",
  Russia: "🇷🇺",
  Rwanda: "🇷🇼",
  "Saudi Arabia": "🇸🇦",
  Senegal: "🇸🇳",
  Serbia: "🇷🇸",
  "Sierra Leone": "🇸🇱",
  Singapore: "🇸🇬",
  Slovakia: "🇸🇰",
  Slovenia: "🇸🇮",
  Somalia: "🇸🇴",
  "South Africa": "🇿🇦",
  "South Korea": "🇰🇷",
  "South Sudan": "🇸🇸",
  Spain: "🇪🇸",
  "Sri Lanka": "🇱🇰",
  Sudan: "🇸🇩",
  Sweden: "🇸🇪",
  Switzerland: "🇨🇭",
  Syria: "🇸🇾",
  Taiwan: "🇹🇼",
  Tajikistan: "🇹🇯",
  Tanzania: "🇹🇿",
  Thailand: "🇹🇭",
  Togo: "🇹🇬",
  Tunisia: "🇹🇳",
  Turkey: "🇹🇷",
  Turkmenistan: "🇹🇲",
  Uganda: "🇺🇬",
  Ukraine: "🇺🇦",
  "United Arab Emirates": "🇦🇪",
  "United Kingdom": "🇬🇧",
  "United States": "🇺🇸",
  Uruguay: "🇺🇾",
  Uzbekistan: "🇺🇿",
  Venezuela: "🇻🇪",
  Vietnam: "🇻🇳",
  Yemen: "🇾🇪",
  Zambia: "🇿🇲",
  Zimbabwe: "🇿🇼",
};

export function decodeHTMLEntities(str: string): string {
  const entities: { [key: string]: string } = {
    "&amp;": "&",
    "&apos;": "'",
    "&#39;": "'",
    "&quot;": '"',
    "&lt;": "<",
    "&gt;": ">",
    "&nbsp;": " ",
    "&ndash;": "–",
    "&mdash;": "—",
    // Add more entities as needed
  };

  return str.replace(/&[a-zA-Z0-9#]+;/g, (entity) => entities[entity] || entity);
}
