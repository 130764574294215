import { useCallback, useState } from "react";

import { type AttributionFilter, type Positions } from "@doitintl/cmp-models";

import { RuleFilterOptions } from "../../../../Pages/CloudAnalytics/allocations/types";
import { type MetadataOption } from "../../../../types";
import { type KeyTypeValues } from "../../api";

const filterBase = (md: MetadataOption): AttributionFilter => ({
  id: md.id,
  field: md.data.field,
  type: md.data.type,
  key: md.data.key,
  allowNull: false,
  inverse: false,
  regexp: null,
  values: null,
});

type Props = {
  initialFilters?: AttributionFilter[];
  handleDimensionLabelsChange: (
    values: KeyTypeValues[],
    filter?: AttributionFilter,
    position?: Positions
  ) => Promise<MetadataOption | null>;
};

const useAttributionCreate = ({ initialFilters = [], handleDimensionLabelsChange }: Props) => {
  const [filters, setFilters] = useState(initialFilters);
  const [editFilter, setEditFilter] = useState<number | null>(null);
  const [selectedMetadata, setSelectedMetadata] = useState<MetadataOption | null>(null);

  const fetchNewMetadataValue = useCallback(
    async (option: MetadataOption, filter?: AttributionFilter) => {
      const updatedMetadata = await handleDimensionLabelsChange(
        [{ key: option.data.key, type: option.data.type }],
        filter
      );
      setSelectedMetadata(updatedMetadata);
    },
    [handleDimensionLabelsChange]
  );

  const handleChangeFilter = useCallback(
    (type: "regexp" | "values", value, inverse: boolean) => {
      setFilters((prevFilters) => {
        if (!selectedMetadata) {
          return prevFilters;
        }
        const filter = filterBase(selectedMetadata);
        let removeFilter = false;
        filter.inverse = inverse;
        if (type === "regexp") {
          filter.values = null;
          filter.regexp = value;
        } else {
          removeFilter = value?.length === 0;
          filter.values = value;
          filter.regexp = null;
          if (selectedMetadata.data.nullFallback) {
            const nullIndex = filter.values?.findIndex((v) => v === selectedMetadata.data.nullFallback) ?? -1;
            filter.allowNull = nullIndex !== -1;
            if (filter.allowNull) {
              // move the 'nullFallback' to the end of the values array
              filter.values?.splice(nullIndex, 1);
              filter.values?.push(selectedMetadata.data.nullFallback);
            }
          }
        }
        const newFilters = prevFilters.slice();
        const i = editFilter ?? newFilters.length;
        const r = editFilter !== null ? 1 : 0;
        if (removeFilter) {
          newFilters.splice(i, r);
        } else {
          newFilters.splice(i, r, filter);
        }
        return newFilters;
      });
    },
    [selectedMetadata, editFilter]
  );

  const handleSelectExistingOption = (i: number) => async (mdOption) => {
    setFilters((prevFilters) => {
      const newFilters = prevFilters.slice();
      if (!mdOption) {
        newFilters.splice(i, 1);
        return newFilters;
      }
      const filter = filterBase(mdOption);
      newFilters.splice(i, 1, filter);
      return newFilters;
    });
    if (!mdOption) {
      return;
    }

    await fetchNewMetadataValue(mdOption);
    setEditFilter(i);
  };

  const onCloseDialog = () => {
    setEditFilter(null);
    setSelectedMetadata(null);
  };

  const handleChangeRule = (rule: RuleFilterOptions, filter: AttributionFilter, option: MetadataOption | null) => {
    if (rule === RuleFilterOptions.MATCHES_REGEX && option) {
      option._regexp = " ";
      setSelectedMetadata(option);
    } else {
      setFilters((prev) =>
        prev.map((f) => (f.id === filter.id ? { ...f, inverse: rule === RuleFilterOptions.IS_NOT } : f))
      );
    }
  };

  return {
    editFilter,
    fetchNewMetadataValue,
    filters,
    handleChangeFilter,
    handleSelectExistingOption,
    onCloseDialog,
    selectedMetadata,
    setEditFilter,
    handleChangeRule,
  };
};

export default useAttributionCreate;
