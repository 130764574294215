import { type ReactElement } from "react";

import { Link, Stack, Tooltip, Typography } from "@mui/material";

const underlineStyle = {
  textDecoration: "underline",
  textDecorationStyle: "dotted",
};

const ValueComponent = ({ value, style }: { value?: string | ReactElement; style: object }) => {
  if (typeof value === "string") {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        display="flex"
        flex={1}
        sx={{ wordBreak: "break-word", ...style }}
      >
        {value}
      </Typography>
    );
  }

  return value;
};

const InfoRow = ({
  field,
  value,
  onClick,
  tooltip,
}: {
  field: string;
  value?: string | ReactElement;
  onClick?: () => void;
  tooltip?: string;
}) => {
  const cy = field.toLowerCase().replace(/ /g, "-");
  const style = tooltip ? underlineStyle : {};

  return (
    <Stack direction="row">
      <Typography variant="subtitle2" fontWeight={500} display="flex" flexBasis="115px" data-cy={`${cy}-field`}>
        {field}:
      </Typography>

      {onClick ? (
        <Link
          onClick={onClick}
          component="button"
          underline="hover"
          variant="body2"
          display="flex"
          flex={1}
          data-cy={`${cy}-value`}
        >
          {value}
        </Link>
      ) : (
        <Tooltip title={tooltip} placement="bottom">
          <div data-cy={`${cy}-value`}>
            <ValueComponent value={value} style={style} />
          </div>
        </Tooltip>
      )}
    </Stack>
  );
};

export default InfoRow;
