import { useCallback } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type AwsCredentials, useSauronAPI, useSauronStaffApi } from "../api/endpoints";
import { useErrorSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { useConcedefy } from "../Pages/MPA/hooks/useConcedefy";
import { consoleErrorWithSentry } from "./errorHandling";

const errorResponse: AwsCredentials = {
  sessionId: "",
  sessionKey: "",
  sessionToken: "",
  error: true,
};

export function useGetLoginUrl() {
  const { getAuthorizedAWSAccountLink } = useConcedefy();
  const api = useSauronAPI();

  const errorSnackbar = useErrorSnackbar(5);

  return useCallback(
    async (accountId: string, isStaff: boolean, customerId: string, isPlesCustomer?: boolean): Promise<string> => {
      // it is preferred that isPlesCustomer be defined by a hook in the parent component,
      // but that may not be possible (e.g. the MPA Table with records from more than one customer, or outside the CustomerContext)
      if (isPlesCustomer === undefined) {
        const doc = await getCollection(AppModel).doc("support").collection("plpsCustomers").doc(customerId).get();
        isPlesCustomer = doc.exists();
      }

      // concedefy func
      const concedefyApi = async (): Promise<string> => {
        const { data } = await getAuthorizedAWSAccountLink("support-request-passthrough", accountId, customerId);
        return data.console_url;
      };
      // sauron func
      const sauronApi = async (): Promise<string> => {
        const accountDetails = await api.account(accountId, isStaff);
        if (accountDetails.data?.account_number) {
          const supportRes = await api.supportConsole(accountId, isStaff);
          return supportRes.data?.url ?? "#";
        }

        consoleErrorWithSentry("Failed to get account details");
        return "#";
      };

      // PLES customers attempt Concedefy before Sauron
      if (isPlesCustomer) {
        try {
          return await concedefyApi();
        } catch (e) {
          consoleErrorWithSentry(e);

          // sauron, as fallback until this is deprecated and removed
          try {
            return await sauronApi();
          } catch (e) {
            consoleErrorWithSentry(e);
            errorSnackbar("Error connecting to AWS. Please contact support.");
          }
        }
        return "#";
      }

      // Not PLES customers, use Sauron
      try {
        return await sauronApi();
      } catch (e) {
        consoleErrorWithSentry(e);
      }

      // URL goes nowhere, does nothing
      return "#";
    },
    [api, errorSnackbar, getAuthorizedAWSAccountLink]
  );
}

export function useCredentials(accountId: string, customerId: string) {
  const { getAuthorizedCLICredentials } = useConcedefy();
  const errorSnackbar = useErrorSnackbar(5);
  const sauronApi = useSauronAPI();
  const sauronStaffApi = useSauronStaffApi(accountId);

  // concedefy func
  const concedefyApiCreds = useCallback(async (): Promise<AwsCredentials> => {
    const { data } = await getAuthorizedCLICredentials("support-request-passthrough", accountId, customerId);
    if (data?.credentials) {
      return data.credentials;
    }

    return errorResponse;
  }, [getAuthorizedCLICredentials, accountId, customerId]);

  // sauron func
  const sauronApiCreds = useCallback(async (): Promise<AwsCredentials> => {
    const accountDetails = await sauronApi.account(accountId, true);
    if (accountDetails.data?.has_role) {
      const supportRes = await sauronStaffApi.getSupportCredentials();
      if (supportRes.data?.credentials) {
        return supportRes.data?.credentials;
      }
      return errorResponse;
    }
    consoleErrorWithSentry("Failed to get account details. No Sauron role.");
    return errorResponse;
  }, [sauronApi, accountId, sauronStaffApi]);

  return useCallback(async (): Promise<AwsCredentials> => {
    try {
      return await concedefyApiCreds();
    } catch (e) {
      try {
        return await sauronApiCreds();
      } catch (err) {
        consoleErrorWithSentry(err);
        errorSnackbar("Error connecting to AWS. Please contact support.");
        return errorResponse;
      }
    }
  }, [concedefyApiCreds, errorSnackbar, sauronApiCreds]);
}
