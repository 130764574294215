import React, { useState } from "react";

import { type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Box, ClickAwayListener, Dialog, Grow, Paper, Popper, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { navigationId } from "../../../../constants";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useInAppNotifications } from "../../../../Context/inAppNotifications/InAppNotificationsContextProvider";
import {
  type InAppNotificationWithRead,
  setAllLastReadBy,
  setLastReadBy,
} from "../../../../Context/inAppNotifications/types";
import { useUserContext } from "../../../../Context/UserContext";
import { NotificationList } from "./NotificationsList";

export const InAppNotifications = ({
  openInDialog = false,
  onClickNotification = () => {},
}: {
  openInDialog?: boolean;
  onClickNotification?: () => void;
}) => {
  const isDarkTheme = useDarkThemeCheck();
  const theme = useTheme();
  const { userModel } = useUserContext({ allowNull: false });
  const { customer } = useCustomerContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const { notifications, loading, userRead } = useInAppNotifications();

  const notificationsWithRead: Map<string, InAppNotificationWithRead> = new Map(
    Array.from(notifications.values()).map((n) => {
      const readModel = userRead?.get(n.id);
      if (readModel) {
        if (n.updated) {
          return [n.id, { ...n, read: readModel.lastRead >= n.updated }];
        }
        return [n.id, { ...n, read: true }];
      }
      return [n.id, { ...n, read: false }];
    })
  );

  const unreadNotificationsCount = Array.from(notificationsWithRead.values()).filter((n) => !n.read).length;

  const handleMenuIconClick = (_event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(document.getElementById(navigationId));
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClickNotification = async (n: InAppNotificationWithRead, userRef: ModelReference<UserModel>) => {
    await setLastReadBy(n, userRef, customer.ref);

    setAnchorEl(null);
    setOpen(false);
    onClickNotification();
    window.open(n.urlPath, "_blank");
  };

  const markAllAsRead = async (userRef: ModelReference<UserModel>) => {
    await setAllLastReadBy(Array.from(notifications.values()), userRef, customer.ref);
  };

  return (
    <Box>
      <Tooltip title={open ? "" : "Notifications"} placement="bottom">
        <IconButton
          sx={{
            padding: (theme) => theme.spacing(0.75, 0.75),
            color: "white",
            borderRadius: 1.5,
            "&:hover": {
              backgroundColor: isDarkTheme ? "#454551" : "#262A50",
            },
          }}
          onClick={handleMenuIconClick}
        >
          <Badge
            variant="dot"
            sx={{
              "& .MuiBadge-dot": {
                backgroundColor: "#CAD2FF",
              },
            }}
            invisible={unreadNotificationsCount === 0}
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      {openInDialog ? (
        <Dialog open={open} onClose={handleClose}>
          <NotificationList
            handleClose={handleClose}
            notifications={notificationsWithRead}
            onlyShowUnread={onlyShowUnread}
            setOnlyShowUnread={setOnlyShowUnread}
            unreadNotificationsCount={unreadNotificationsCount}
            markAllAsRead={markAllAsRead}
            userRef={userModel.ref}
            loading={loading}
            handleClickNotification={handleClickNotification}
            dialogMode={true}
          />
        </Dialog>
      ) : (
        <Popper
          id="notifications"
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement="bottom-end" // Initial placement (important for correct offset calculation)
          modifiers={[
            {
              name: "flip", // Keep flip for screen edge cases
              options: {
                fallbackPlacements: ["top-end", "bottom-start", "top-start"],
              },
            },
            {
              name: "shift", // Prevent popper from being cut off
              options: {
                padding: 0,
              },
            },
            {
              name: "update", // Force updates on resize/scroll
              enabled: true,
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
                <Box
                  sx={{
                    position: "fixed",
                    top: theme.spacing(-6),
                    right: 0,
                  }}
                >
                  <Paper elevation={10}>
                    <NotificationList
                      handleClose={handleClose}
                      notifications={notificationsWithRead}
                      onlyShowUnread={onlyShowUnread}
                      setOnlyShowUnread={setOnlyShowUnread}
                      unreadNotificationsCount={unreadNotificationsCount}
                      markAllAsRead={markAllAsRead}
                      userRef={userModel.ref}
                      loading={loading}
                      handleClickNotification={handleClickNotification}
                      dialogMode={false}
                    />
                  </Paper>
                </Box>
              </Grow>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
