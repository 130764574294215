import { ClusterType, type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import OpenGCPConsole from "../../../../Components/OpenGCPConsole/OpenGCPConsole";
import { downloadFile } from "../../../../utils/files";
import { OpenAWSConsole } from "../../../EKS/Components/OpenAWSConsole";
import { getRbacYamlFileContent } from "../rbac";
import Fsk8sFeatureSelector from "./components/Fsk8sFeatureSelector";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

type EnableFeaturesStepProps = {
  projectId: string | null;
  cluster: ClusterWithCloudConnect | null;
  selectedFeatures: KubernetesFeature[];
  setSelectedFeatures: (features: KubernetesFeature[]) => void;
};

const baseCommand = `kubectl apply -f $FILE_NAME`;

const commandExecutionHelperTexts: Record<ClusterType, string> = {
  [ClusterType.EKS]: "Run the following commands on the AWS Console or a method of your choice",
  [ClusterType.GKE]: "Run the following commands on the GCP Console or a method of your choice",
  [ClusterType.SELF_MANAGED]: "Run the following commands on the Kubernetes cluster",
};

const EnableFeaturesStep = ({ projectId, cluster, selectedFeatures, setSelectedFeatures }: EnableFeaturesStepProps) => {
  if (!cluster) {
    return <CircularProgress />;
  }

  const fileName = `doit-${cluster.type}-${cluster.region}-${cluster.name}-rbac-setup.yaml`;

  const handleDownloadYaml = () => {
    const content = getRbacYamlFileContent(cluster, selectedFeatures);
    downloadFile(fileName, content, "text/yaml");
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Enable features for this cluster</Typography>
        <SelectedAccountAndCluster projectId={projectId} cluster={cluster} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2">1. Select which features you want to enable for this cluster</Typography>
        <Fsk8sFeatureSelector selectedFeatures={selectedFeatures} onSelect={setSelectedFeatures} />
      </Stack>

      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="body2">
            2. Download the YAML file to your local device. You will need this to complete step 3
          </Typography>
          <Button
            onClick={handleDownloadYaml}
            sx={{ width: "fit-content" }}
            startIcon={<DownloadIcon />}
            variant="outlined"
            color="primary"
            disabled={selectedFeatures.length === 0}
          >
            Download
          </Button>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="body2">{commandExecutionHelperTexts[cluster.type]}</Typography>
          {cluster.type === ClusterType.EKS ? (
            <OpenAWSConsole region={cluster.region} />
          ) : (
            <OpenGCPConsole project={projectId} />
          )}
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2" color="text.secondary">
            Apply the Kubernetes permissions configuration file
          </Typography>
          <CopyCodeBlock
            base={baseCommand}
            variables={{
              $FILE_NAME: { value: fileName, name: "File Name" },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EnableFeaturesStep;
