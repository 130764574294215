import { createContext, type ReactNode, useContext } from "react";

import {
  type Aggregator,
  type AnalyticsDataSource,
  type AttributionFilter,
  type MetricFilter,
  type Positions,
} from "@doitintl/cmp-models";

import {
  useAnalyticsDimensions,
  type UseAnalyticsDimensionsReturnType,
} from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { type MetadataOption } from "../../../types";
import { type KeyTypeValues } from "../api";
import { type Limit } from "../dialogs/limitsFilterDialog/limitsFilterDialog";
import { useReportConfig } from "./ReportConfigContext";
import { useReportContext } from "./ReportContext";

type ReportDimensionsContextType = UseAnalyticsDimensionsReturnType;

export const ReportDimensionsContext = createContext<ReportDimensionsContextType>({
  dimensions: undefined,
  getLabelsDimensions: (
    _: KeyTypeValues[],
    _filter?: AttributionFilter | undefined,
    _position?: Positions | undefined
  ) => new Promise((_, _reject) => {}),
  resetLimit: () => false,
  handleDimensionSelect: (_: MetadataOption[], _fromList: boolean, _filterExistingChip: boolean) => {},
  handleAggregatorChange: (_: Aggregator) => {},
  handleMetricFiltersChange: (_: MetricFilter[]) => {},
  handleLimitsFilterChange: (_: Limit[]) => {},
  handleDeleteDimension: (_: MetadataOption[]) => {},
  handleReportFilterChange: (_: string, _type: string, _value: any, _inverse?: boolean | undefined) => {},
  handleTimeIntervalChange: (_: string) => {},
  handleTreemapChange: () => {},
  handleMetricChange: () => {},
  handleSortComparativeDimensions: () => {},
  handleAttributionMdSelect: () => {},
  handleDimensionLabelsChange: (
    _: KeyTypeValues[],
    _filter?: AttributionFilter | undefined,
    _position?: Positions | undefined
  ) => new Promise((_, _reject) => {}),
  handleDraggableItemsUpdate: (_: Record<string, string[]>) => {},
  handleIncludeDatahubUpdated: (
    _dataSource: AnalyticsDataSource.BILLING | AnalyticsDataSource.BILLING_DATAHUB,
    _timeInterval: string
  ) => {},
});

export const ReportDimensionsContextProvider = ({ children }: { children?: ReactNode }) => {
  const { attributions } = useAttributionsContext();
  const { report } = useReportContext();
  const { reportConfig } = useReportConfig();
  const { metadata: metadataSnapshots } = useCloudAnalyticsMetadata();
  const analyticsDimensionsHandlers = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
    timeInterval: reportConfig.timeInterval,
    config: report?.data.config ?? undefined,
    calculatedMetric: reportConfig.calculatedMetric ?? undefined,
    metricFilters: reportConfig.metricFilters,
    isReport: true,
    initialFilters: reportConfig.optional ?? [],
    defaultDataSource: reportConfig.dataSource,
  });

  return (
    <ReportDimensionsContext.Provider value={analyticsDimensionsHandlers}>{children}</ReportDimensionsContext.Provider>
  );
};

export const useReportDimensionsContext = (): ReportDimensionsContextType => useContext(ReportDimensionsContext);
