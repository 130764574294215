import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure } from "./Asset";
import { type AssetType } from "./AssetSetting";
import { type ProductEnum } from "./Collection";
import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import {
  type Aggregator,
  type AnalyticsDataSource,
  type AnalyticsResourceType,
  type AttributionFilter,
  type Collaborators,
  type DashboardModelAttributionModel,
  type DashboardModelSavedReportsModel,
  type Feature,
  type Metric,
  type MetricFilterOperator,
  type PublicAccess,
  type Renderer,
  type ReportConfig,
  type Sort,
  type TimeInterval,
} from "./Dashboard";
import { type Label as ObjectLabel } from "./Label";
import { type OrganizationsModel } from "./Organizations";

export enum CalcMetricFormat {
  NUMERIC,
  PRECENTAGE,
  CURRENCY,
}

export type SlackChannel = {
  id: string;
  name: string;
  shared: boolean;
  customerId: string;
  workspace: string;
  type?: "private" | "public";
  accessToken?: string;
};

export type BudgetAlert = {
  percentage: number;
  amount?: number;
  forecastedDate?: Timestamp | null;
  triggered: boolean;
};

export enum AlertCondition {
  VALUE = "value",
  FORECAST = "forecast",
  PERCENTAGE = "percentage",
}

export type IgnoreValuesRange = {
  lowerBound: number;
  upperBound: number;
};

export type BasicFilter = {
  id: string;
  inverse?: boolean;
  regexp?: string | null;
  values?: string[] | null;
};

export type AlertConfig = {
  aggregator: Aggregator;
  calculatedMetric: Reference<CloudAnalyticsModelMetricModel> | null;
  condition: AlertCondition;
  currency: CurrencyCode;
  dataSource: AnalyticsDataSource;
  extendedMetric: string | null;
  filters?: AttributionFilter[];
  ignoreValuesRange?: IgnoreValuesRange | null;
  metric: Metric;
  operator: MetricFilterOperator;
  rows: string[];
  scope?: Reference<DashboardModelAttributionModel>[];
  timeInterval: TimeInterval;
  values: number[];
};

export enum LimitAggregation {
  TOP = "top",
  ALL = "all",
  NONE = "none",
}

export type BudgetRowsData = {
  forecastRows: { [key: string]: Array<number | null | string> };
  rows: Record<string, [year: string, month: string, day: string, num1: number, num2: number, num3: number]>;
  timeInterval: TimeInterval;
};

@subCollection("cloudAnalyticsAlertsDetected")
export class CloudAnalyticsAlertsDetectedModel {
  alert!: Reference<CloudAnalyticsModelAlertModel>;

  timeDetected!: Timestamp;

  breakdown?: string;

  breakdownLabel?: string;

  customer!: Reference<CustomerModel>;

  condition!: string;

  alertName!: string;

  recipients!: string[];
}

@subCollection("cloudAnalyticsAlerts")
export class CloudAnalyticsModelAlertModel {
  collaborators!: Collaborators;

  config!: AlertConfig;

  customer!: Reference<CustomerModel>;

  etag!: string;

  isValid!: boolean;

  name!: string;

  organization!: Reference<OrganizationsModel>;

  public!: PublicAccess;

  timeCreated!: Timestamp;

  timeLastAlerted: Timestamp | null = null;

  timeModified!: Timestamp;

  recipients!: string[];

  labels?: Reference<ObjectLabel>[] | null;

  subCollections?: {
    cloudAnalyticsAlertsDetected: CloudAnalyticsAlertsDetectedModel;
  };
}

@subCollection("cloudAnalyticsAttributionGroups")
export class CloudAnalyticsModelAttributionGroupsModel {
  attributions!: Reference<DashboardModelAttributionModel>[];

  collaborators!: Collaborators;

  customer!: Reference<CustomerModel> | null;

  name!: string;

  description!: string;

  organization!: Reference<OrganizationsModel> | null;

  hidden?: boolean;

  public!: PublicAccess;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  type!: AnalyticsResourceType;

  classification?: "invoice";

  cloud?: ProductEnum[] | null;

  nullFallback?: string | null;

  labels?: Reference<ObjectLabel>[] | null;
}

export type BudgetConfig = {
  alerts: BudgetAlert[];
  allowGrowth: boolean;
  amount: number;
  dataSource: AnalyticsDataSource;
  currency: CurrencyCode;
  endPeriod?: Timestamp;
  growthPerPeriod: number;
  metric: number;
  originalAmount: number;
  renderer?: Renderer;
  scope: Reference<DashboardModelAttributionModel>[];
  startPeriod: Timestamp;
  timeInterval: TimeInterval;
  type: number;
  usePrevSpend?: boolean;
  filters?: AttributionFilter[];
  rollover?: {
    enabled: boolean;
    limit?: number;
  };
  amortizedCost?: boolean;
};

export type BudgetNotificationType = "threshold" | "forecast";

@subCollection("cloudAnalyticsBudgetsNotifications")
export class BudgetNotificationModel {
  type!: BudgetNotificationType;

  customer!: Reference<CustomerModel>;

  budgetId!: string;

  created!: Timestamp;

  alertDate!: Timestamp;

  name!: string;

  alertPercentage!: number;

  alertAmount!: number;

  currencySymbol!: string;

  forecastedDate?: Timestamp;
}

@subCollection("cloudAnalyticsBudgets")
export class CloudAnalyticsModelBudgetModel {
  collaborators!: Collaborators;

  config!: BudgetConfig;

  customer!: Reference<CustomerModel>;

  description!: string;

  enforcedByMetering?: boolean;

  isPublic?: boolean;

  name!: string;

  public!: PublicAccess;

  recipients!: string[];

  recipientsSlackChannels?: SlackChannel[];

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  timeRefreshed?: Timestamp;

  utilization?: {
    current: number;
    forecasted: number;
    lastPeriod?: number;
    forecastedTotalAmountDate?: Timestamp;
  };

  labels?: Reference<ObjectLabel>[] | null;

  isValid?: boolean;

  draft?: boolean;

  subCollections?: {
    cloudAnalyticsBudgetsNotifications: BudgetNotificationModel;
  };

  data?: BudgetRowsData;
}

export type MetricVariable = {
  attribution: Reference<DashboardModelAttributionModel>;
  metric: number;
  extendedMetric?: string;
};

@subCollection("cloudAnalyticsMetrics")
export class CloudAnalyticsModelMetricModel {
  name!: string;

  customer!: Reference<CustomerModel> | null;

  owner!: string;

  description!: string;

  type!: string;

  variables!: MetricVariable[] | null;

  formula!: string;

  format!: CalcMetricFormat;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  labels?: Reference<ObjectLabel>[] | null;

  entitlements?: string[] | null;
}

@subCollection("cloudAnalyticsConfigs")
export class CloudAnalyticsConfigsModel {
  metrics!: ExtendedMetric[];
}

@subCollection("cloudAnalyticsUserConfigs")
export class CloudAnalyticsUserConfigsModel {
  useProdData?: boolean;
}

@subCollection("cloudAnalyticsReportsDefaultConfigs")
export class CloudAnalyticsReportsDefaultConfigsModel {
  config!: ReportConfig;
}

export type ExtendedMetric = {
  cloud: ProductEnum.GoogleCloud | ProductEnum.AmazonWebServices | null;
  dataSource: AnalyticsDataSource;
  key: string;
  label: string;
  type: string;
  visibility: "all" | "none" | "csp" | "regular" | "customer";
};

export type DataHubMetrics = {
  dataSource: AnalyticsDataSource;
  key: string;
  label: string;
};

export type ForecastMode = "grouping" | "totals";

export type CustomMode = {
  isCustomMode?: boolean;
  isCumulative?: boolean;
};

export type ForecastSettings = {
  historicalTimeIntervals: number;
  futureTimeIntervals: number;
  mode: ForecastMode;
};

type Variable = {
  metric: number;
};

type CalculatedMetric = {
  format: number;
  variables: Variable[];
};

export enum Fields {
  GKEClusterName = "GKE.cluster_name",
  GKELabels = "GKE.labels",
  GKENamespace = "GKE.namespace",
  GKEResourceName = "GKE.resource_name",
  TBillingAccountID = "T.billing_account_id",
  TCloudProvider = "T.cloud_provider",
  TKubernetesClusterName = "T.kubernetes_cluster_name",
  TKubernetesNamespace = "T.kubernetes_namespace",
  TLabels = "T.labels",
  TProjectLabels = "T.project.labels",
  TLocationRegion = "T.location.region",
  TOperation = "T.operation",
  TProjectID = "T.project_id",
  TProjectName = "T.project_name",
  TResourceID = "T.resource_id",
  TServiceDescription = "T.service_description",
  TSkuDescription = "T.sku_description",
  TSystemLabels = "T.system_labels",
  TUsageDateTime = "T.usage_date_time",
}

type Field = `${Fields}`;

export enum IDs {
  DatetimeHour = "datetime:hour",
  DatetimeDay = "datetime:day",
  DatetimeMonth = "datetime:month",
  DatetimeWeek = "datetime:week",
  DatetimeQuarter = "datetime:quarter",
  DatetimeYear = "datetime:year",
  FixedBillingAccountID = "fixed:billing_account_id",
  FixedCloudProvider = "fixed:cloud_provider",
  FixedKubernetesClusterName = "fixed:kubernetes_cluster_name",
  FixedKubernetesNamespace = "fixed:kubernetes_namespace",
  FixedOperation = "fixed:operation",
  FixedProjectID = "fixed:project_id",
  FixedProjectName = "fixed:project_name",
  FixedRegion = "fixed:region",
  FixedResourceID = "fixed:resource_id",
  FixedServiceDescription = "fixed:service_description",
  FixedSkuDescription = "fixed:sku_description",
  GkeClusterName = "gke:cluster_name",
  GkeLabelYXBW = "gke_label:YXBw",
  GkeNamespace = "gke:namespace",
  GkeResourceName = "gke:resource_name",
  LabelAzhzLWxhYmVsL2S4Cy1HcHA = "label:azhzLWxhYmVsL2s4cy1hcHA=",
  LabelZGVwbG95BWVudF9UYW1L = "label:ZGVwbG95bWVudF9uYW1l",
  SystemLabelY21WL2NvbW1PDG1LbnRfdHlwZQ = "system_label:Y21wL2NvbW1pdG1lbnRfdHlwZQ==",
  OptionalLabels = "optional:labels_keys",
}

export type ID = `${IDs}`;

export enum Keys {
  App = "app",
  BillingAccountID = "billing_account_id",
  CloudProvider = "cloud_provider",
  ClusterName = "cluster_name",
  CmpCommitmentType = "cmp/commitment_type",
  CostType = "cost_type",
  Country = "country",
  Credit = "credit",
  Day = "day",
  DeploymentName = "deployment_name",
  Feature = "feature",
  InvoiceMonth = "invoice_month",
  IsMarketplace = "is_marketplace",
  K8SLabelK8SApp = "k8s-label/k8s-app",
  KubernetesClusterName = "kubernetes_cluster_name",
  KubernetesNamespace = "kubernetes_namespace",
  eksClusterName = "eks:cluster-name",
  eksNodegroupName = "eks:nodegroup-name",
  Month = "month",
  Namespace = "namespace",
  Operation = "operation",
  PricingUnit = "pricing_unit",
  ProjectAncestryNames = "project_ancestry_names",
  ProjectID = "project_id",
  ProjectName = "project_name",
  ProjectNumber = "project_number",
  Region = "region",
  ResourceGlobalID = "resource_global_id",
  ResourceID = "resource_id",
  ResourceName = "resource_name",
  SavingsDescription = "savings_description",
  ServiceDescription = "service_description",
  ServiceID = "service_id",
  SkuDescription = "sku_description",
  SkuID = "sku_id",
  Week = "week",
  Year = "year",
  Zone = "zone",
}

export type Key = `${Keys}`;

export const DatahubFixedKeys: Key[] = [
  Keys.BillingAccountID,
  Keys.CostType,
  Keys.Country,
  Keys.Credit,
  Keys.Feature,
  Keys.InvoiceMonth,
  Keys.IsMarketplace,
  Keys.Operation,
  Keys.PricingUnit,
  Keys.ProjectAncestryNames,
  Keys.ProjectID,
  Keys.ProjectName,
  Keys.ProjectNumber,
  Keys.Region,
  Keys.ResourceGlobalID,
  Keys.ResourceID,
  Keys.SavingsDescription,
  Keys.ServiceDescription,
  Keys.ServiceID,
  Keys.SkuDescription,
  Keys.SkuID,
  Keys.Zone,
];

enum Labels {
  BillingAccount = "Billing Account",
  Cloud = "Cloud",
  Day = "Day",
  Empty = "",
  GKECluster = "GKE Cluster",
  GKENamespace = "GKE Namespace",
  GKEResource = "GKE Resource",
  Month = "Month",
  Operation = "Operation",
  ProjectAccountID = "Project/Account ID",
  ProjectAccountName = "Project/Account name",
  Region = "Region",
  Resource = "Resource",
  ScopeProjectAccount = "Scope (Project/Account)",
  Service = "Service",
  Sku = "SKU",
  Week = "Week",
  Year = "Year",
}

type Label = `${Labels}`;

enum ColTypes {
  Datetime = "datetime",
  Fixed = "fixed",
  Gke = "gke",
  GkeLabel = "gke_label",
  Label = "label",
  SystemLabel = "system_label",
}

type ColType = `${ColTypes}`;

export type Col = {
  allowNull: boolean;
  field: Field;
  filters: null;
  formula?: string;
  id: ID;
  includeInFilter: boolean;
  inverse: boolean;
  key: Key;
  label: Label;
  limit: number;
  limitMetric: null;
  limitOrder: null;
  position: Position;
  regexp: null;
  relation: string;
  type: ColType;
  values: null;
};

enum Positions {
  Col = "col",
  Row = "row",
}

type Position = `${Positions}`;

enum CloudAnalyticsWidgetsTypes {
  Custom = "custom",
  Preset = "preset",
}

type CloudAnalyticsWidgetsType = `${CloudAnalyticsWidgetsTypes}`;

@subCollection("cloudAnalyticsWidgets")
export class CloudAnalyticsModelWidgetModel {
  collaborators!: Collaborators;

  config!: {
    aggregator: Aggregator;
    calculatedMetric: CalculatedMetric | null;
    colOrder: Sort;
    cols: Col[];
    comparative: null;
    count: null;
    currency: CurrencyCode;
    excludePartialData: boolean;
    extendedMetric: string;
    extendedMetricType: string;
    includeSubtotals: boolean;
    features: Feature[] | null;
    forecastSettings?: ForecastSettings | null;
    logScale: boolean;
    metric: number;
    renderer: Renderer;
    rowOrder: Sort;
    rows: Col[];
    timezone: string;
  };

  customer!: Reference<CustomerModel>;

  customerID!: string;

  data!: {
    forecastRows: { [key: string]: Array<number | null | string> };
    rows: Record<string, [year: string, month: string, day: string, num1: number, num2: number, num3: number]>;
  };

  description!: string;

  expireBy?: Timestamp;

  isPublic!: boolean;

  name!: string;

  organization!: Reference<OrganizationsModel>;

  report!: string;

  size!: number;

  timeRefreshed!: Timestamp;

  type!: CloudAnalyticsWidgetsType;
}

export type StatusData = {
  lastUpdate?: Timestamp;
  status?: string;
};

@subCollection("cloudAnalyticsStatuses")
export class CloudAnalyticsModelStatusModel {
  status!: Record<string, StatusData>;

  timeModified!: Timestamp;

  overallLastUpdate!: Timestamp;

  customer!: Reference<CustomerModel>;
}

@subCollection("cloudAnalyticsScheduledReportsExecutions")
export class CloudAnalyticsScheduledReportsExecutionsModel {
  state!: number;

  timestamp!: Timestamp;
}

@subCollection("cloudAnalyticsGkeCostAllocations")
export class CloudAnalyticsModelGkeCostAllocationModel {
  billingAccountIds!: string[];

  labels!: {
    clusters: string[];
    namespaces: string[];
  };

  enabled!: boolean;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  fullyEnabled!: boolean;

  unenabledClusters!: string[];
}

@subCollection("cloudAnalyticsReportsDocumentation")
export class CloudAnalyticsReportsDocumentationModel {
  html!: string;
}

export enum TemplateVisibility {
  GLOBAL = "global",
  INTERNAL = "internal",
  PRIVATE = "private",
}

export enum TemplateApprovalStatus {
  APPROVED = "approved",
  CANCELED = "canceled",
  PENDING = "pending",
  REJECTED = "rejected",
}

export type TemplateMessage = {
  email: string;
  text: string;
  timestamp: Timestamp;
};

export type TemplateApproval = {
  approvedBy: string;
  changes: TemplateMessage[];
  createdBy: string;
  status: TemplateApprovalStatus;
  timeApproved: Timestamp;
};

@subCollection("reportTemplateVersions")
export class CloudAnalyticsReportTemplateVersionModel {
  active!: boolean;

  approval!: TemplateApproval;

  categories!: string[];

  cloud!: AssetType[];

  collaborators?: Collaborators;

  createdBy!: string;

  previousVersion?: Reference<CloudAnalyticsReportTemplateVersionModel>;

  report!: Reference<DashboardModelSavedReportsModel>;

  template!: Reference<CloudAnalyticsReportTemplateModel>;

  visibility!: TemplateVisibility;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;
}

@subCollection("templateLibraryFavorites")
export class CloudAnalyticsTemplateLibraryFavoritesModel {
  templates!: Reference<CloudAnalyticsReportTemplateModel>[];
}

@subCollection("templateLibraryReportTemplates")
export class CloudAnalyticsReportTemplateModel {
  activeReport?: Reference<DashboardModelSavedReportsModel>;

  activeVersion?: Reference<CloudAnalyticsReportTemplateVersionModel>;

  hidden!: boolean;

  lastVersion!: Reference<CloudAnalyticsReportTemplateVersionModel>;

  timeCreated!: Timestamp;
}

export enum RunReportChannelStatus {
  INITIALIZED = "initialized",
  CANCELLATION_REQUESTED = "cancellationRequested",
}

@subCollection("cloudAnalyticsRunReportChannels")
export class CloudAnalyticsRunReportChannelModel {
  timeUpdated!: Timestamp;

  status!: RunReportChannelStatus;

  uid!: string;

  email!: string;

  expireBy!: Timestamp;
}

class CloudAnalyticsModelWidgesDoc {
  subCollections?: {
    cloudAnalyticsWidgets: CloudAnalyticsModelWidgetModel;
  };
}

class GkeCostAllocationDoc {
  subCollections?: {
    cloudAnalyticsGkeCostAllocations: CloudAnalyticsModelGkeCostAllocationModel;
  };
}

class CloudAnalyticsModelAlertsDoc {
  subCollections?: {
    cloudAnalyticsAlerts: CloudAnalyticsModelAlertModel;
  };
}

class CloudAnalyticsModelAttributionGroupsDoc {
  subCollections?: {
    cloudAnalyticsAttributionGroups: CloudAnalyticsModelAttributionGroupsModel;
  };
}

class CloudAnalyticsModelMetricsDoc {
  subCollections?: {
    cloudAnalyticsMetrics: CloudAnalyticsModelMetricModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datahubMetrics: any;
  };
}

class CloudAnalyticsModelBudgetsDoc {
  subCollections?: {
    cloudAnalyticsBudgets: CloudAnalyticsModelBudgetModel;
  };
}

class CloudAnalyticsModelStatusesDoc {
  subCollections?: {
    cloudAnalyticsStatuses: CloudAnalyticsModelStatusModel;
  };
}

class CloudAnalyticsModelConfigsDoc {
  subCollections?: {
    cloudAnalyticsConfigs: CloudAnalyticsConfigsModel;
    cloudAnalyticsUserConfigs: CloudAnalyticsUserConfigsModel;
  };
}

class CloudAnalyticsModelReportsDoc {
  subCollections?: {
    cloudAnalyticsScheduledReportsExecutions: CloudAnalyticsScheduledReportsExecutionsModel;
    cloudAnalyticsReportsDefaultConfigs: CloudAnalyticsReportsDefaultConfigsModel;
    cloudAnalyticsReportsDocumentation: CloudAnalyticsReportsDocumentationModel;
    cloudAnalyticsRunReportChannels: CloudAnalyticsRunReportChannelModel;
  };
}

class CloudAnalyticsModelTemplateLibraryDoc {
  subCollections?: {
    templateLibraryReportTemplates: CloudAnalyticsReportTemplateModel;
    templateLibraryFavorites: CloudAnalyticsTemplateLibraryFavoritesModel;
  };
}

@collection("cloudAnalytics")
export class CloudAnalyticsModel {
  docs?: {
    alerts: CloudAnalyticsModelAlertsDoc;
    "attribution-groups": CloudAnalyticsModelAttributionGroupsDoc;
    budgets: CloudAnalyticsModelBudgetsDoc;
    "gke-cost-allocations": GkeCostAllocationDoc;
    metrics: CloudAnalyticsModelMetricsDoc;
    statuses: CloudAnalyticsModelStatusesDoc;
    widgets: CloudAnalyticsModelWidgesDoc;
    configs: CloudAnalyticsModelConfigsDoc;
    reports: CloudAnalyticsModelReportsDoc;
    "template-library": CloudAnalyticsModelTemplateLibraryDoc;
  };
}

export const DataSourceBillingAssets: ReadonlyArray<string> = [
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
];
