import { type ReactNode } from "react";

import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/ReportProblem";
import Alert, { type AlertColor } from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { colors } from "../../muiTheme";
import { ThemeModes } from "../../muiThemeTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.general.snackbar,
    color: theme.palette.general.backgroundDefault,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    "& .MuiButton-root:hover": {
      backgroundColor: theme.palette.snackbar.buttonHover,
    },
  },
  message: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    wordBreak: "break-word",
  },
  icon: {
    backgroundColor: ({ severity }: { severity: AlertColor }) =>
      colors[theme.palette.mode === ThemeModes.DARK ? ThemeModes.LIGHT : ThemeModes.DARK][severity].main,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: 4,
    marginLeft: `-${theme.spacing(1)}`,
  },
  action: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    paddingTop: 0,
    paddingRight: theme.spacing(0.25),
  },
}));

export type Props = {
  message: ReactNode;
  withIcon: boolean;
  severity?: AlertColor;
  action?: ReactNode;
};

/**
 * SharedSnackbar content v2
 * Inner content of <SharedSnackbar /> component
 */
const SharedSnackbarContent = ({ message, action, severity = "info", withIcon = true, ...otherProps }: Props) => {
  const classes = useStyles({ severity });

  return (
    <Alert
      classes={{
        root: classes.root,
        icon: classes.icon,
        action: classes.action,
      }}
      variant="filled"
      severity={severity}
      action={action}
      iconMapping={{
        success: <CheckIcon />,
        info: <InfoIcon />,
        warning: <WarningIcon />,
        error: <WarningIcon />,
      }}
      data-cy={otherProps["data-cy"] || `shared-snackbar-${severity}`}
      {...(withIcon ? {} : { icon: false })}
    >
      <Typography color="inherit" id="client-snackbar" className={classes.message} {...otherProps}>
        {message}
      </Typography>
    </Alert>
  );
};

export default SharedSnackbarContent;
