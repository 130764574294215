import { useCallback } from "react";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

import { sanitizeKeepingLocalDate } from "../../../../../../utils/common";
import { type Product as ProductType, type ProductOption, type Subscription } from "../../types";
import Product from "./Product";

type SubscriptionProps = {
  index: number;
  subscription: Subscription;
  productOptions: ProductOption[];
  onChange: (index: number, data: Subscription) => void;
  onRemove: (index: number) => void;
};

export default function Subscription({ onChange, onRemove, index, subscription, productOptions }: SubscriptionProps) {
  const handleChangeDate = useCallback(
    (name: "startDate" | "endDate") => (date: DateTime<true> | null) => {
      onChange(index, {
        ...subscription,
        [name]: date ? sanitizeKeepingLocalDate(date) : null,
        errors: {},
      });
    },
    [index, onChange, subscription]
  );

  const handleChangeProduct = useCallback(
    (productIndex: number, updatedProduct: ProductType) => {
      const updatedProducts = subscription.products.slice();
      updatedProducts.splice(productIndex, 1, updatedProduct);
      onChange(index, { ...subscription, products: updatedProducts });
    },
    [index, onChange, subscription]
  );

  const handleDeleteProduct = useCallback(
    (productIndex: number) => {
      const updatedProducts = subscription.products.slice();
      updatedProducts.splice(productIndex, 1);
      onChange(index, { ...subscription, products: updatedProducts });
    },
    [index, onChange, subscription]
  );

  const handleAddProduct = useCallback(() => {
    const updatedProducts = subscription.products.slice();
    updatedProducts.push({
      id: "",
      label: "",
      usageType: "",
      quantity: null,
      price: null,
      uniqueId: uuidv4(),
      errors: {},
    });
    onChange(index, { ...subscription, products: updatedProducts });
  }, [index, onChange, subscription]);

  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        defaultExpanded
        sx={{
          "&:before": {
            display: "none",
          },
          "& .MuiAccordionDetails-root": {
            padding: 0,
          },
          "& .MuiAccordionSummary-root": {
            flexDirection: "row-reverse",
            paddingX: "0px",
            "> .MuiAccordionSummary-expandIconWrapper": {
              "&.Mui-expanded": {
                transform: "none",
              },
              transform: "rotate(270deg)",
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-content`}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
            <Typography variant="h4">Subscription {index + 1}</Typography>
            {index !== 0 && (
              <IconButton
                data-cy="closeButton"
                aria-label="close"
                onClick={() => {
                  onRemove(index);
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <DatePicker
                renderInput={(params) => (
                  <TextField
                    data-cy="subscription-start-date"
                    margin="dense"
                    fullWidth
                    {...params}
                    error={!!subscription.errors.startDate}
                    helperText={subscription.errors.startDate}
                  />
                )}
                label="Start Date"
                value={subscription.startDate}
                onChange={handleChangeDate("startDate")}
                components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
                inputFormat="dd LLL, yyyy"
              />
              <DatePicker
                renderInput={(params) => (
                  <TextField
                    data-cy="subscription-end-date"
                    margin="dense"
                    fullWidth
                    {...params}
                    error={!!subscription.errors.endDate}
                    helperText={subscription.errors.endDate}
                  />
                )}
                label="End Date"
                value={subscription.endDate}
                onChange={handleChangeDate("endDate")}
                minDate={subscription.startDate ? subscription.startDate : undefined}
                components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
                inputFormat="dd LLL, yyyy"
              />
            </Stack>

            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Products
            </Typography>

            {subscription.products.map((product, productIndex) => (
              <Product
                key={product.uniqueId}
                product={product}
                productOptions={productOptions}
                productIndex={productIndex}
                onChange={handleChangeProduct}
                onDelete={handleDeleteProduct}
                deleteDisabled={subscription.products.length === 1}
              />
            ))}

            <Stack direction="row-reverse">
              <Button startIcon={<AddRoundedIcon />} variant="text" sx={{ px: 1 }} onClick={handleAddProduct}>
                Add Product
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ mt: 3 }} />
    </Box>
  );
}
