import { useCallback } from "react";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";

export function useMPARemoveRetiredRequest() {
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  return useCallback(
    async (masterPayerAccount: MasterPayerAccountsModelSnapshot) => {
      try {
        await masterPayerAccount.ref.update({ removeFromTable: true });
        showSuccess(`MPA ${masterPayerAccount.name} successfully removed from table`);
      } catch (error) {
        showError(`Failed to remove MPA ${masterPayerAccount.name} from table`);
        consoleErrorWithSentry(error);
      }
    },
    [showError, showSuccess]
  );
}
