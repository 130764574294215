import { useCallback, useEffect, useMemo, useState } from "react";

import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useGetGcpProjects } from "../../hooks";
import { mapCloudConnectData, useMergedProjectIds } from "../utils/gcpUtils";
import { useFieldValidationProps } from "./useFieldValidationProps";

const defaultFieldProps = {
  name: "",
  value: "",
  onChange: () => {},
  onBlur: () => {},
};

interface GCPParameterFormProps {
  readonly inputModel: StructureApiServiceModelDescriptor<Member>;
  readonly formikProps: FormikProps<{
    organization: string;
    serviceAccount: string;
  }>;
}

export default function GCPParameterForm({
  inputModel,
  formikProps: { values, setFormikState, getFieldProps, ...rest },
}: GCPParameterFormProps) {
  const { customer } = useCustomerContext();
  const [rawCloudConnectData, , isCloudConnectDataLoaded] = useCloudConnectData(customer.id);

  const projectIdFieldName = useMemo(
    () => Object.keys(inputModel.members || {}).find((key) => !["organization", "serviceAccount"].includes(key)),
    [inputModel]
  );

  const cloudConnectData = useMemo(() => mapCloudConnectData(rawCloudConnectData), [rawCloudConnectData]);

  const firstProjectData = useMemo(() => cloudConnectData.find((data) => data.project?.id), [cloudConnectData]);

  const firstOrgData = useMemo(
    () => cloudConnectData.find((data) => data.organizations.length > 0),
    [cloudConnectData]
  );

  const [organization, setOrganization] = useState<string>(values.organization || "");
  const [serviceAccount, setServiceAccount] = useState<string>(values.serviceAccount || "");

  const [projectId, setProjectId] = useState<string | null>(
    projectIdFieldName ? values[projectIdFieldName] || firstProjectData?.project?.id : null
  );

  const { data: projects, isLoading: gcpProjectsLoading } = useGetGcpProjects(customer.id, organization);

  const mergedProjectIds = useMergedProjectIds(cloudConnectData, projects);

  const projectIdProps = useMemo(
    () => (projectIdFieldName ? getFieldProps(projectIdFieldName) : defaultFieldProps),
    [getFieldProps, projectIdFieldName]
  );

  useEffect(() => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...(organization && { organization }),
        ...(serviceAccount && { serviceAccount }),
        [projectIdFieldName ?? "projectId"]: projectId,
      },
    }));
  }, [organization, projectId, projectIdFieldName, serviceAccount, setFormikState]);

  const { showError, errorMessage } = useFieldValidationProps(
    { values, setFormikState, getFieldProps, ...rest },
    projectIdProps
  );

  const handleServiceAccountChange = useCallback(
    (selectedProjectId?: string | null, selectedOrgName?: string) => {
      if (selectedProjectId) {
        const foundForProject = cloudConnectData.find((data) => data.project?.id === selectedProjectId);
        if (foundForProject?.project?.serviceAccount) {
          setServiceAccount(foundForProject.project.serviceAccount);
          return;
        }
      }
      if (selectedOrgName) {
        const foundForOrg = cloudConnectData.find((data) =>
          data.organizations.some((org) => org.name === selectedOrgName)
        );
        const matchedOrg = foundForOrg?.organizations.find((org) => org.name === selectedOrgName);
        if (matchedOrg?.serviceAccount) {
          setServiceAccount(matchedOrg.serviceAccount);
        }
      }
    },
    [cloudConnectData]
  );

  const handleOrganizationChange = useCallback(
    (newOrganizationName: string) => {
      setOrganization(newOrganizationName);
      handleServiceAccountChange(projectId, newOrganizationName);
    },
    [handleServiceAccountChange, projectId]
  );

  const handleProjectChange = useCallback(
    (newProjectId: string | null) => {
      setProjectId(newProjectId);
      handleServiceAccountChange(newProjectId, organization);
    },
    [handleServiceAccountChange, organization]
  );

  const [projectIdInitialized, setProjectIdInitialized] = useState(false);
  useEffect(() => {
    if (!projectIdInitialized && isCloudConnectDataLoaded) {
      if (firstProjectData?.project?.id) {
        handleProjectChange(firstProjectData.project.id);
      }
      setProjectIdInitialized(true);
    }
  }, [firstProjectData?.project?.id, handleProjectChange, projectIdInitialized, isCloudConnectDataLoaded]);

  const [organizationInitialized, setOrganizationInitialized] = useState(false);
  useEffect(() => {
    if (isCloudConnectDataLoaded && !organizationInitialized) {
      const defaultOrgName = firstOrgData?.organizations[0]?.name;
      if (defaultOrgName) {
        handleOrganizationChange(defaultOrgName);
      }
      setOrganizationInitialized(true);
    }
  }, [firstOrgData?.organizations, isCloudConnectDataLoaded, handleOrganizationChange, organizationInitialized]);

  const isCloudConnectEmpty = cloudConnectData.length === 0;
  if (isCloudConnectEmpty) {
    return null;
  }

  return (
    <Stack sx={{ gap: 2 }}>
      {firstOrgData && (
        <FormControl fullWidth>
          <TextField
            id="organization"
            name="organization"
            variant="outlined"
            select
            label="Organization"
            value={organization}
            onChange={(e) => {
              handleOrganizationChange(e.target.value);
            }}
            required
          >
            {cloudConnectData.flatMap((data) =>
              data.organizations.map((org) => (
                <MenuItem key={org.name} value={org.name}>
                  {org.displayName}
                </MenuItem>
              ))
            )}
          </TextField>
        </FormControl>
      )}

      {projectIdFieldName && (
        <FormControl fullWidth>
          <VirtualizedAutocomplete
            key={mergedProjectIds.length}
            loading={gcpProjectsLoading || !isCloudConnectDataLoaded}
            id="project"
            options={mergedProjectIds}
            disableClearable={!organization}
            onChange={(_, value) => {
              handleProjectChange(value as string | null);
            }}
            renderOption={({ key, ...props }, option) => (
              <MenuItem key={key} {...props} value={option}>
                {option}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                label="Project"
                fullWidth
                error={showError}
                helperText={errorMessage}
                variant="outlined"
                required
              />
            )}
            size="small"
            fullWidth
            value={projectId ?? null}
          />
        </FormControl>
      )}
    </Stack>
  );
}
