import { useCallback } from "react";

import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { useContractFormContext } from "../../../ContractsFormContext";

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  minDate?: DateTime;
  isEssentials?: boolean;
};

export const DciDatePicker = ({ label, fieldName, disabled, minDate }: Props) => {
  const { state, setState } = useContractFormContext();

  const onDateChange = useCallback(
    (date: DateTime | null) => {
      if (date) {
        setState((prevState) => ({
          ...prevState,
          [fieldName]: date,
          errors: {
            ...prevState.errors,
            [fieldName]: false,
          },
        }));

        if (fieldName === "startDate" && state.commitmentMonths !== -1) {
          setState((prevState) => ({
            ...prevState,
            endDate: date.plus({ months: state.commitmentMonths }),
          }));
        }
      }
    },
    [fieldName, setState, state.commitmentMonths]
  );

  return (
    <DatePicker
      disableMaskedInput
      label={label}
      value={state[fieldName]}
      onChange={onDateChange}
      renderInput={(params) => <TextField {...params} fullWidth error={state.errors[fieldName]} />}
      inputFormat="dd LLL, yyyy"
      disabled={disabled}
      minDate={minDate}
    />
  );
};
