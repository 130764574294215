import { useCallback, useEffect, useRef } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import { type Node, useReactFlow } from "@xyflow/react";

import { useIsCloudflowPublished } from "../Common/hooks/useIsCloudflowPublished";
import Title from "../Common/Title";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";
import type { AllNodeConfigs, RFNode } from "../../types";

export const Header = () => {
  const { updateNode, nodeConfig } = useNodeConfigurationContext();
  const isPublished = useIsCloudflowPublished();
  const initialTitle = useRef<string | null>(null);
  const nodeId = useRef<string>(nodeConfig.id);
  const { getNode } = useReactFlow<Node<RFNode>>();

  useEffect(() => {
    if (initialTitle.current === null || nodeId.current !== nodeConfig.id) {
      initialTitle.current = nodeConfig.name;
      nodeId.current = nodeConfig.id;
    }
  }, [nodeConfig.id, nodeConfig.name]);

  const handleUpdateTitle = useCallback(
    (title: string) => {
      updateNode({ name: title });
    },
    [updateNode]
  );

  const handleBlurTitle = useCallback(() => {
    updateNode((prevNode) =>
      !prevNode.name?.trim()
        ? {
            // HACK: casting here because node is a partial and doesnt have all fields of nodeconfigs
            // but I know it is a valid NodeConfig for usage with AllNodeConfigs
            name: getDefaultNodeName(initialTitle.current ?? "", prevNode as AllNodeConfigs, getNode),
          }
        : prevNode
    );
  }, [updateNode, getNode]);

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Title
        disabled={isPublished}
        title={nodeConfig.name}
        onUpdateTitle={handleUpdateTitle}
        onBlur={handleBlurTitle}
        placeholder="Add Node name"
      />
    </Stack>
  );
};

function getDefaultNodeName(
  initialTitle: string,
  nodeConfig: AllNodeConfigs,
  nodeGetter: (id: string) => Node<RFNode> | undefined
) {
  const getNodeName = (prefix: string, nodeId: string) => {
    const referencedNode = nodeGetter(nodeId);
    return referencedNode ? `${prefix} on ${referencedNode.data.nodeData.name}` : prefix;
  };

  switch (nodeConfig.type) {
    case CloudFlowNodeType.CONDITION:
      return getNodeName("IF statement", nodeConfig.parameters.referencedNodeId);
    case CloudFlowNodeType.FILTER:
      return getNodeName("Filter", nodeConfig.parameters.referencedNodeId);
    case CloudFlowNodeType.TRANSFORMATION:
      return getNodeName("Transform", nodeConfig.parameters.referencedNodeId);
    default:
      return initialTitle;
  }
}
