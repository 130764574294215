import { forwardRef, useCallback, useContext, useMemo } from "react";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box } from "@mui/material";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

import { WidgetContent } from "../../../../Components/Dashboard/WidgetContent";
import { ThreeDotsMenu } from "../../../../Components/ThreeDotsMenu";
import { useCurrentDashboardContext } from "../../../../Context/useCurrentDashboardContext";
import { WidgetHeaderWrapperContext } from "./Header/widgetHeaderContext";
import { WidgetMenuWrapperContext } from "./Header/widgetMenuContext";
import { WidgetsContext } from "./WidgetsGrid";

interface Props {
  id: string;
  index?: number;
  active?: boolean;
  clone?: boolean;
  layout?: "vertical" | "horizontal";
  onRemove?: () => void;
  style: React.CSSProperties;
  contentHeight: number;
  listeners?: SyntheticListenerMap;
}

export const WidgetContentWrapper = forwardRef<HTMLLIElement, Props>(function Page(
  { id, index, active, clone, contentHeight, layout, onRemove, style, listeners, ...props },
  ref
) {
  const [widgets, _width, disabled] = useContext(WidgetsContext);
  const { removeWidgetFromDashboard } = useCurrentDashboardContext();

  const widget = useMemo(() => widgets.find((w) => w.name === id), [id, widgets]);

  const removeWidget = useCallback(() => {
    if (widget?.name) {
      removeWidgetFromDashboard(widget.name);
    }
  }, [removeWidgetFromDashboard, widget?.name]);

  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        display: "flex",
        flexDirection: layout === "vertical" ? "column" : "row",
      }}
    >
      <WidgetMenuWrapperContext.Provider
        value={[
          !disabled && removeWidget,
          !disabled && (
            <ThreeDotsMenu
              key="delete-menu"
              options={[
                {
                  label: "Remove widget",
                  color: "error.main",
                  key: "delete",
                  action: removeWidget,
                },
              ]}
            />
          ),
        ]}
      >
        <WidgetHeaderWrapperContext.Provider
          value={
            !disabled && (
              <Box
                {...props}
                {...listeners}
                sx={{
                  width: 20,
                }}
              >
                <DragIndicatorIcon {...props} sx={{ color: "action.active" }} />
              </Box>
            )
          }
        >
          {widget && <WidgetContent widgetHeight={contentHeight} widget={widget} />}
        </WidgetHeaderWrapperContext.Provider>
      </WidgetMenuWrapperContext.Provider>
    </Box>
  );
});
