import { type CurrencyCode, type PaymentMethod } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";

import Payment from "../../Entity/PaymentMethods/Payment";
import { type StripeFromHandle } from "../../Entity/PaymentMethods/Stripe";

type Props = {
  country: string;
  entityId: string;
  paymentMethod: PaymentMethod;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setStripeElementsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  currency: CurrencyCode;
  settingPaymentMethod: boolean;
  stripeRef: React.RefObject<StripeFromHandle>;
  handleSetPayment: () => void;
  entityPriorityCompany?: string;
};

export const PaymentMethodStep = ({
  currency,
  country,
  entityId,
  paymentMethod,
  setPaymentMethod,
  setStripeElementsLoaded,
  handleSetPayment,
  settingPaymentMethod,
  stripeRef,
  entityPriorityCompany,
}: Props) => (
  <form
    noValidate
    id="paymentForm"
    onSubmit={(event) => {
      handleSetPayment();
      event?.preventDefault();
    }}
  >
    <Container maxWidth="sm">
      <Stack
        spacing={4}
        sx={{
          pt: 4,
          mx: -2,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "500",
          }}
        >
          Payment method
        </Typography>
        <Payment
          currency={currency}
          country={country}
          entityId={entityId}
          settingPaymentMethod={settingPaymentMethod}
          setStripeElementsLoaded={setStripeElementsLoaded}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          stripeRef={stripeRef}
          stripeReturnUrl={window.location.href.replace(/\/([^/]*)$/, "/support")}
          entityPriorityCompany={entityPriorityCompany}
        />
      </Stack>
    </Container>
  </form>
);
