import { type JSX } from "react";

import {
  type AmazonWebServicesAssetModel,
  type AmazonWebServicesStandaloneAssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  AssetTypeMicrosoftAzure,
  AssetTypeMicrosoftAzureStandalone,
  type EntityModel,
} from "@doitintl/cmp-models";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { timeText } from "../../assets/texts";
import { type AmazonWebServicesAssetsType, type AnyAsset, type Asset, type Assets } from "../../types";
import { type HeaderColumnStyle } from "../../types/FilterTable";
import { NotApplicable, NotAvailable } from "./Tabs/components/typographics";

/**
 * Build string for use as Billing Profile in asset fields (tables, asset editing, etc.).
 *
 * @param entityData the data from the entity returned in an asset
 *
 * @returns billing profile string, or standard accessible typographic {@link NotAvailable} if the billing profile
 * string cannot be built from the entity data provided.
 */
export const buildBillingProfileString = (entityData: EntityModel | null): string => {
  if (!entityData) {
    return NotAvailable;
  }

  const { priorityId, currency, name } = entityData;
  const sections: string[] = [];
  priorityId && sections.push(priorityId);
  currency && sections.push(`(${currency})`);
  if (name) {
    sections.length ? sections.push(`- ${name}`) : sections.push(name);
  }

  return sections.length ? sections.join(" ").trim() : NotAvailable;
};

/**
 * Produces an object detailing the number of assets for each Google cloud asset type.
 * NOTE: GCP Standalone assets are bundled with the GCP Direct assets.
 *
 * @param assetsByType Arrays of assets categorised by type.
 * @param displayGoogleSubTabs Whether google subtabs are being displayed
 */

export const buildGoogleCloudAssetCounts = (
  assetsByType,
  displayGoogleSubTabs
): Record<
  "google-cloud-project" | "google-cloud" | "google-cloud-direct" | "google-cloud-project-standalone",
  number
> => {
  const direct = assetsByType[AssetTypeGoogleCloudDirect] || [];
  const standalone = assetsByType[AssetTypeGoogleCloudStandalone] || [];
  const directAndStandalone = [...direct, ...standalone];
  return {
    [AssetTypeGoogleCloud]:
      displayGoogleSubTabs &&
      (assetsByType[AssetTypeGoogleCloud] || []).reduce(
        (acc: number, cur) => (cur.data.type === AssetTypeGoogleCloud ? acc + 1 : acc),
        0
      ),
    [AssetTypeGoogleCloudDirect]:
      displayGoogleSubTabs &&
      directAndStandalone.reduce(
        (acc: number, cur) =>
          [AssetTypeGoogleCloudDirect, AssetTypeGoogleCloudStandalone].includes(cur.data.type) ? acc + 1 : acc,
        0
      ),
    [AssetTypeGoogleCloudProject]:
      displayGoogleSubTabs &&
      (assetsByType[AssetTypeGoogleCloudProject] || []).reduce(
        (acc: number, cur) => (cur.data.type === AssetTypeGoogleCloudProject ? acc + 1 : acc),
        0
      ),
    [AssetTypeGoogleCloudProjectStandalone]:
      displayGoogleSubTabs &&
      (assetsByType[AssetTypeGoogleCloudProjectStandalone] || []).reduce(
        (acc: number, cur) => (cur.data.type === AssetTypeGoogleCloudProjectStandalone ? acc + 1 : acc),
        0
      ),
  };
};

const optionsHeader: HeaderColumnStyle = { align: "right", padding: "none" };

/**
 * For use in asset tables - header slot for the three-dot menu at the end of asset rows.
 */
export const threeDotHeaderSection = {
  value: "@",
  style: optionsHeader,
} as const;

export const mayNotBeAvailable = (value: string | JSX.Element) =>
  typeof value === "string" ? { value, label: value } : { value: NotAvailable, label: "-" };
export const mayNotBeApplicable = (value: string | JSX.Element) =>
  typeof value === "string" ? { value, label: value } : { value: NotApplicable, label: "Not applicable" };

export const mayNotBeAvailableNumeric = (value: number | typeof NotAvailable) =>
  typeof value === "number" ? { value, label: value.toString() } : { value: NotAvailable, label: "-" };

/**
 * Creates an array of assets based upon an asset object.
 * This erases entity groupings, but usually that's the whole point.
 * @param assetObject Assets grouped by entity
 */
export const extractAssetsFromAssetObject = (assetObject: Assets): AnyAsset[] => {
  const assetValues = Object.values(assetObject);
  return assetValues.flatMap((assets) => assets);
};

/**
 * Provides the default asset type for a bunch of assets.
 * This is defined as the asset type that comes alphabetically first among all asset types present.
 *
 * @param assetObject map of entity IDs and associated assets
 */
export const getDefaultAssetType = (
  assetObject: Record<string, AnyAsset[]>
): "amazon-web-services" | "g-suite" | "google-cloud" | "microsoft-azure" | "office-365" => {
  const allAssets: AnyAsset[] = extractAssetsFromAssetObject(assetObject);
  if (!allAssets.length) {
    return AssetTypeAmazonWebServices;
  }
  const allTypes: string[] = uniq(allAssets.map((asset) => asset.data.type)).sort();
  const firstType = allTypes[0];
  const standalones = {
    [AssetTypeAwsStandalone]: AssetTypeAmazonWebServices,
    [AssetTypeGoogleCloudStandalone]: AssetTypeGoogleCloud,
    [AssetTypeMicrosoftAzureStandalone]: AssetTypeMicrosoftAzure,
  };

  return firstType in standalones ? standalones[firstType] : firstType;
};

export const doesAssetBelongToPayerAccountOne = (payerId: string): boolean => payerId === "561602220360";

/**
 * Handles basic "appending 's'" plurals
 * @param word The singular form of the word
 * @param qty How many you have. Can be negative.
 */
export const sPluralise = (word: string, qty: number) => (Math.abs(qty) === 1 ? word : `${word}s`);

export const isAWSAsset = (asset: AnyAsset): asset is Asset<AmazonWebServicesAssetModel> =>
  (asset as Asset<AmazonWebServicesAssetModel>).data.type === "amazon-web-services" ||
  (asset as Asset<AmazonWebServicesStandaloneAssetModel>).data.type === "amazon-web-services-standalone";

/**
 * Assets without workload should be filtered from the assets table.
 * In other words: If the cloudhealth property exists but not the organization property
 */
export const filterRemoveAwsAssetsWithoutWorkload = (
  assets: Readonly<Array<AnyAsset<AmazonWebServicesAssetsType>>>
): AnyAsset<AmazonWebServicesAssetsType>[] =>
  assets.filter((asset) =>
    isAWSAsset(asset) ? !(asset.data.properties.cloudhealth && !asset.data.properties.organization) : true
  );

export const licensePurchaseCap = 9999;

/**
 * Builds an expiry date string for use in tables of assets that might be on commitment plans
 * @param commitment Whether or not the asset is on a commitment plan
 * @param expiryDate The expiry date of the plan (if applicable, if !commitment then expiry date is likely to be undefined)
 */
export const buildExpiryDate = (commitment: boolean, expiryDate?: DateTime): string => {
  if (!commitment || !expiryDate) {
    return timeText.NEVER;
  }
  const expired = expiryDate.endOf("day") < DateTime.now().toUTC();

  return `${expiryDate.toLocaleString(DateTime.DATE_MED)}${expired ? " - expired" : ""}`;
};

/**
 * Verifies whether to permit a given user to access an asset.
 * @param userCustomerId the customer ID of the current user (from either user context or customer context)
 * @param isDoitEmployee doer? Doers should be able to see all assets.
 * @param assetCustomerId the customer ID of the asset to which access is requested
 */
export const permitUserToViewAsset = (
  userCustomerId: string,
  isDoitEmployee: boolean,
  assetCustomerId?: string
): boolean => {
  const customerMatchesAsset = userCustomerId === assetCustomerId;
  return customerMatchesAsset || isDoitEmployee;
};

// by order of hierarchy - higher index = higher rank
export const assetSupportTypeOptionsMappingByHierarchy = [
  "Basic",
  "Developer",
  "Business",
  "Enterprise on-ramp",
  "Enterprise",
];

export const changedSupportTypeAlert = {
  OVERRIDDEN_FROM: "This support tier was overridden from:",
  BUTTON_TEXT: "Remove override",
  OVERRIDE_REASON_TITLE: "Override reason:",
};

export const impactBillingAlertText = "Overriding the support tier will impact customer billing.";
export const overrideReasonMissing = "Please enter a reason for overriding support tier";
export const PLESAssetIndicator = "Asset is part of PLES program";
export const SupportTierOverriddenOn = "Support tier overridden on";
export const BasicAWSSupportTierText = "Basic AWS support";
export const maxAssetTagLength = 75;
