import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { Metric } from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

import { useApiContext } from "../../../api/context";
import { useCloudAnalyticsTransforms } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { validateSplit } from "../../../Components/SplitCostModal/api";
import { type Split } from "../../../Components/SplitCostModal/types";
import { createMetricSplits } from "../../../Components/SplitCostModal/utils";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type MetadataOption } from "../../../types";
import { getMetricLabel, parseMetricSplitsForReport, parseMetricSplitsToSplitObj } from "../utilities";
import { useReportConfig } from "./ReportConfigContext";

type MetricSplitsContextType = {
  metricSplits: { [id: string]: Split };
  metricSplitModalData?: Split;
  metricSplitLabel: string;
  openSplitModal: (_: MetadataOption) => void;
  editSplit: (_: Split) => void;
  onIncludeOrigin: (_: string, _include: boolean) => void;
  deleteSplit: (_split: Split) => void;
  getInvalidTargetsIDs: (_splitData: Split) => Promise<Set<string>>;
  onMetricSplitDataSubmit: (_data?: Split) => void;
  deleteMetricSplits: (_dimension: MetadataOption) => void;
  metricSplitsInit: (_splits: Split[], _dimensions: MetadataOption[]) => void;
  metricSplitsInitialized: boolean;
  onClose: () => void;
};

export const MetricSplitsContext = createContext<MetricSplitsContextType>({
  metricSplits: {},
  metricSplitModalData: undefined,
  metricSplitLabel: "",
  openSplitModal: (_: MetadataOption) => {},
  editSplit: (_: Split) => {},
  onIncludeOrigin: (_: string, _include: boolean) => {},
  deleteSplit: (_split: Split) => {},
  getInvalidTargetsIDs: (_splitData: Split) => new Promise((_resolve, _reject) => new Set("")),
  onMetricSplitDataSubmit: (_data?: Split) => {},
  deleteMetricSplits: (_dimension: MetadataOption) => {},
  metricSplitsInit: (_splits: Split[], _dimensions: MetadataOption[]) => {},
  metricSplitsInitialized: false,
  onClose: () => {},
});

export const MetricSplitsContextProvider = ({ children }: { children?: ReactNode }) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { attributions } = useAttributionsContext();
  const transforms = useCloudAnalyticsTransforms();
  const {
    reportConfig: { extendedMetrics, calculatedMetric, metric, extendedMetric },
  } = useReportConfig();
  const [metricSplits, setMetricSplits] = useState<{ [id: string]: Split }>({});
  const [metricSplitsInitialized, setMetricSplitsInitialized] = useState(false);
  const [metricSplitModalData, setMetricSplitModalData] = useState<Split>();

  const openSplitModal = useCallback(
    (item: MetadataOption) => {
      const data = createMetricSplits(item, attributions, transforms);
      setMetricSplitModalData(data);
    },
    [attributions, transforms]
  );

  const editSplit = useCallback((data: Split) => {
    setMetricSplitModalData(data);
  }, []);

  const onIncludeOrigin = useCallback((id: string, include: boolean) => {
    setMetricSplits((prev) => {
      const temp = { ...prev };
      Object.values(temp).forEach((split) => {
        if (split.id === id) {
          split.includeOrigin = include;
        }
      });
      return temp;
    });
  }, []);

  const deleteSplit = useCallback((split: Split) => {
    setMetricSplits((prev) => {
      const temp = { ...prev };
      if (split.origin?.id) {
        delete temp[split.origin.id];
      }
      return temp;
    });
  }, []);

  const getInvalidTargetsIDs = useCallback(
    async (splitData: Split): Promise<Set<string>> => {
      const splitDataAllTargetsChecked = cloneDeep(splitData);
      splitDataAllTargetsChecked.targets.forEach((target) => {
        target.checked = true;
        target.value = "1";
      });

      const newMetricSplit = cloneDeep(metricSplits);
      if (!newMetricSplit || !splitDataAllTargetsChecked?.origin?.id) {
        return new Set("");
      }
      newMetricSplit[splitDataAllTargetsChecked?.origin?.id] = splitDataAllTargetsChecked;
      const data = parseMetricSplitsForReport(newMetricSplit);
      const invalidTargets = await validateSplit({ api, customerId: customer.id, data });

      const invalidTargetsIDs = new Set<string>();

      invalidTargets.forEach((invalidTarget) => {
        invalidTargetsIDs.add(invalidTarget.attributionId);
      });

      return invalidTargetsIDs;
    },
    [metricSplits, api, customer.id]
  );

  const onClose = useCallback(() => {
    setMetricSplitModalData(undefined);
  }, []);

  const onMetricSplitDataSubmit = useCallback(
    (data?: Split) => {
      setMetricSplits((prev) => {
        const temp = { ...prev };
        if (data?.origin?.id) {
          temp[data.origin.id] = data;
        }
        return temp;
      });
      onClose();
    },
    [onClose]
  );

  const deleteMetricSplits = useCallback(
    (dimension: MetadataOption) => {
      const filteredSplits = Object.values(metricSplits).filter((split) => split.id === dimension?.id);
      if (filteredSplits.length > 0) {
        setMetricSplits((prev) => {
          const temp = { ...prev };
          filteredSplits.forEach((split) => {
            if (split?.origin?.id) {
              delete temp[split.origin.id];
            }
          });
          return temp;
        });
      }
    },
    [metricSplits]
  );

  const metricSplitsInit = useCallback(
    (splits: Split[], dimensions: MetadataOption[]) => {
      if (attributions && splits) {
        setMetricSplits(parseMetricSplitsToSplitObj(splits, attributions, dimensions, transforms));
        setMetricSplitsInitialized(true);
      }
    },
    [attributions, transforms]
  );

  const metricLabel = useMemo(() => {
    const extendedMetricLabel = extendedMetrics?.find((m) => m.key === extendedMetric)?.label ?? "";
    return getMetricLabel(metric, extendedMetricLabel, calculatedMetric?.data.name || "");
  }, [calculatedMetric, extendedMetric, extendedMetrics, metric]);

  const metricSplitLabel = useMemo(
    () => (metric === Metric.CALCULATED ? `"${metricLabel}"` : metricLabel),
    [metric, metricLabel]
  );

  const value = useMemo(
    () => ({
      metricSplits,
      metricSplitModalData,
      metricSplitLabel,
      openSplitModal,
      editSplit,
      onIncludeOrigin,
      deleteSplit,
      getInvalidTargetsIDs,
      onMetricSplitDataSubmit,
      deleteMetricSplits,
      metricSplitsInit,
      metricSplitsInitialized,
      onClose,
    }),
    [
      metricSplits,
      metricSplitModalData,
      metricSplitLabel,
      openSplitModal,
      editSplit,
      onIncludeOrigin,
      deleteSplit,
      getInvalidTargetsIDs,
      onMetricSplitDataSubmit,
      deleteMetricSplits,
      metricSplitsInit,
      metricSplitsInitialized,
      onClose,
    ]
  );
  return <MetricSplitsContext.Provider value={value}>{children}</MetricSplitsContext.Provider>;
};

export const useMetricSplitsContext = (): MetricSplitsContextType => useContext(MetricSplitsContext);
