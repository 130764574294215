import { Add } from "@mui/icons-material";
import { Button, Paper, Stack, Typography } from "@mui/material";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";

type Props = {
  onAddAllocation: () => void;
  onAddUnallocatedCosts: () => void;
};

const AllocationSegment = ({ onAddAllocation, onAddUnallocatedCosts }: Props) => (
  <Paper
    variant="outlined"
    sx={({ palette }) => ({
      py: 2,
      px: 3,
      backgroundColor: palette.general.backgroundDark,
    })}
  >
    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
      {allocationTxt.ALLOCATION_SEGMENT_TITLE}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
      {allocationTxt.ALLOCATION_SEGMENT_DESCRIPTION}
    </Typography>

    <Stack direction="row" spacing={2}>
      <Button variant="outlined" size="medium" startIcon={<Add />} onClick={onAddAllocation}>
        {allocationTxt.ADD_ALLOCATION}
      </Button>
      <Button variant="text" size="medium" onClick={onAddUnallocatedCosts}>
        {allocationTxt.ADD_UNALLOCATED_COSTS}
      </Button>
    </Stack>
  </Paper>
);

export default AllocationSegment;
