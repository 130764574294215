import { createContext, useCallback, useContext, useMemo } from "react";

import {
  useAttachBlueprint,
  useCreateOrUpdateNode,
  useDeleteNode,
  useExecuteOperation,
  usePublishCloudflow,
  useTriggerCloudflow,
  useUnpublishCloudflow,
  useUpdateCloudflow,
  useUpdateCloudflowNodes,
} from "../../hooks";
import {
  type AttachBlueprintInput,
  type CloudflowOperationContextType,
  type CreateOrUpdateNode,
  type UpdateCloudflowInput,
  type UpdateCloudflowNodes,
} from "../../types";

const CloudflowOperationContext = createContext<CloudflowOperationContextType>({} as CloudflowOperationContextType);

export const useCloudflowOperations = () => {
  const context = useContext(CloudflowOperationContext);
  if (!context) {
    throw new Error("useCloudflowOperation must be used within CloudflowOperationProvider");
  }
  return context;
};

export const CloudflowOperationProvider = ({ children }) => {
  const { executeOperation } = useExecuteOperation();
  const [createOrUpdate, createOrUpdateLoading] = useCreateOrUpdateNode();
  const [remove, removeLoading] = useDeleteNode();
  const [updateNodes, updateNodesLoading] = useUpdateCloudflowNodes();
  const [updateCloudflow, updateCloudflowLoading] = useUpdateCloudflow();
  const [publishCloudflow, publishCloudflowLoading] = usePublishCloudflow();
  const [unpublishCloudflow, unpublishCloudflowLoading] = useUnpublishCloudflow();
  const [triggerCloudflow, triggerCloudflowLoading] = useTriggerCloudflow();
  const [attachBlueprint, bluerpintAttachLoading] = useAttachBlueprint();

  const httpOperationLoading =
    createOrUpdateLoading ||
    removeLoading ||
    updateNodesLoading ||
    updateCloudflowLoading ||
    publishCloudflowLoading ||
    unpublishCloudflowLoading ||
    triggerCloudflowLoading ||
    bluerpintAttachLoading;

  const createOrUpdateOperation = useCallback(
    async (customerId: string, flowId: string, data: CreateOrUpdateNode) =>
      executeOperation("createOrUpdate", createOrUpdate, customerId, flowId, data),
    [executeOperation, createOrUpdate]
  );
  const updateCloudflowOperation = useCallback(
    async (customerId: string, flowId: string, updateCloudflowInput: UpdateCloudflowInput) =>
      executeOperation("updateCloudflow", updateCloudflow, customerId, flowId, updateCloudflowInput),
    [executeOperation, updateCloudflow]
  );
  const removeOperation = useCallback(
    async (customerId: string, flowId: string, nodeId: string) =>
      executeOperation("remove", remove, customerId, flowId, nodeId),
    [executeOperation, remove]
  );
  const updateNodesOperation = useCallback(
    async (customerId: string, nodes: UpdateCloudflowNodes) =>
      executeOperation("updateNodes", updateNodes, customerId, nodes),
    [executeOperation, updateNodes]
  );
  const publishCloudflowOperation = useCallback(
    async (customerId: string, flowId: string) =>
      executeOperation("publishCloudflow", publishCloudflow, customerId, flowId),
    [executeOperation, publishCloudflow]
  );
  const unpublishCloudflowOperation = useCallback(
    async (customerId: string, flowId: string) =>
      executeOperation("unpublishCloudflow", unpublishCloudflow, customerId, flowId),
    [executeOperation, unpublishCloudflow]
  );
  const triggerCloudflowOperation = useCallback(
    async (customerId: string, flowId: string) =>
      executeOperation("triggerCloudflow", triggerCloudflow, customerId, flowId),
    [executeOperation, triggerCloudflow]
  );
  const attachBlueprintOperation = useCallback(
    async (input: AttachBlueprintInput) => executeOperation("attachBlueprint", attachBlueprint, input),
    [executeOperation, attachBlueprint]
  );

  const contextValue = useMemo(
    () => ({
      createOrUpdate: createOrUpdateOperation,
      updateCloudflow: updateCloudflowOperation,
      remove: removeOperation,
      updateNodes: updateNodesOperation,
      publishCloudflow: publishCloudflowOperation,
      unpublishCloudflow: unpublishCloudflowOperation,
      triggerCloudflow: triggerCloudflowOperation,
      attachBlueprint: attachBlueprintOperation,
      httpOperationLoading,
    }),
    [
      createOrUpdateOperation,
      updateCloudflowOperation,
      removeOperation,
      updateNodesOperation,
      publishCloudflowOperation,
      unpublishCloudflowOperation,
      triggerCloudflowOperation,
      attachBlueprintOperation,
      httpOperationLoading,
    ]
  );

  return <CloudflowOperationContext.Provider value={contextValue}>{children}</CloudflowOperationContext.Provider>;
};
