import { type Dispatch, type SetStateAction } from "react";

import { useHistory } from "react-router-dom";

import { useApiContext } from "../../../../../api/context";
import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { customersRoute } from "../../../../../Navigation/core/utils";
import { type FormState, type Subscription as SubscriptionType } from "../types";
import { formatSubscription } from "./formatSubscription";
import { validateSubscriptions } from "./validateSubscriptions";

type Props = {
  formState: FormState;
  onChange: Dispatch<SetStateAction<FormState>>;
  onChangeSubscriptions: Dispatch<SetStateAction<SubscriptionType[]>>;
};

type Error = { response: { data: { error: string } } };

const useSubmit = ({ formState, onChange, onChangeSubscriptions }: Props) => {
  const customerId = useCustomerId();
  const api = useApiContext();
  const history = useHistory();
  const showErrorSnackbar = useErrorSnackbar();
  const showSuccessSnackbar = useSuccessSnackbar(1);

  const returnToHomePage = () => {
    history.push(customersRoute(customerId));
  };

  const submit = async (subscriptions: SubscriptionType[]) => {
    onChange((state) => ({ ...state, loading: true }));
    const { validatedSubscriptions, isError, errorMessage } = validateSubscriptions({
      subscriptions,
      productOptions: formState.productOptions,
    });

    if (isError) {
      onChangeSubscriptions(validatedSubscriptions);
      onChange((state) => ({ ...state, loading: false }));

      if (errorMessage) {
        showErrorSnackbar(errorMessage);
      }
      return;
    }

    const { applicationKey, APIKey, orgIdRegion } = formState;

    try {
      await api.post(`/v1/customers/${customerId}/datadog/onboard-customer`, {
        connection: { applicationKey, APIKey, orgIdRegion },
        subscriptions: subscriptions.length ? subscriptions.map(formatSubscription) : null,
      });

      showSuccessSnackbar("Success");
      onChange((state) => ({ ...state, loading: false }));
      returnToHomePage();
    } catch (error) {
      const errorMessage =
        (error as Error)?.response?.data?.error || "Error testing connection. Check the data and try again.";
      showErrorSnackbar(errorMessage);
      onChange((state) => ({ ...state, loading: false }));
    }
  };

  return [submit];
};

export default useSubmit;
