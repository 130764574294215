import {
  type ComponentType,
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AssetTypeAmazonWebServices, CustomerModel, TierPackageTypes } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, useCollectionData } from "@doitintl/models-firestore";
import { DateTime } from "luxon";
import { getDisplayName } from "recompose";

import { PresentationModeSentinel } from "../Components/PresentationModeSentinel";
import { type Customer, CustomerType } from "../types";
import { type FirestoreTimestamp } from "../utils/firebase";
import { getCustomerTierTrialDatesAsTimestamp, isTierTrialUser, type TierTrialDates } from "../utils/tiers";
import { AttributionsContextProvider } from "./AttributionsContext";
import { useAuthContext } from "./AuthContext";
import {
  AssetsContextProvider,
  type FullAssetsContextType,
  ProcurementAssetTypes,
  useAssetsContext,
} from "./customer/AssetContext";
import { CloudConnectProvider } from "./customer/CloudCOnnectContext";
import { ContractsContextProvider, type ContractsContextType, useContractsContext } from "./customer/ContractsContext";
import { DebtContextProvider } from "./customer/CustomerDebt";
import { EntitiesContextProvider, type EntitiesContextType, useEntitiesContext } from "./customer/EntitiesContext";
import { OrgsContextProvider, type OrgsContextType, useOrgsContext } from "./customer/OrgsProvider";
import { DataHubProvider } from "./DataHubContext";
import {
  type CustomerSlackChannelsContextType,
  CustomerSlackChannelsProvider,
  useCustomerSlackChannelsContext,
} from "./SlackChannelsContextProvider";
import { TierProvider } from "./TierProvider";
import {
  isCustomerInPresentationMode,
  useCustomerOrPresentationModeCustomer,
} from "./useCustomerOrPresentationModeCustomer";

export type CustomerContextType = {
  customer: Customer | null;
  customerOrPresentationModeCustomer: Customer | null;
};

export type FullCustomerContextType = CustomerContextType &
  FullAssetsContextType &
  ContractsContextType &
  EntitiesContextType &
  CustomerSlackChannelsContextType &
  OrgsContextType & {
    freeTrialActive?: boolean;
    trialUser?: boolean;
    awsFeatures?: string[];
    init: boolean;
    navigatorTierTrialDates?: TierTrialDates<FirestoreTimestamp>;
    isNavigatorTierTrialUser?: boolean;
    isSolveTierTrialUser?: boolean;
    customerType: CustomerType;
    isProductOnlyCustomer: boolean;
  };

export type AugmentedFullCustomerContextType<T> = Omit<
  FullCustomerContextType,
  "customer" | "customerOrPresentationModeCustomer"
> & {
  customer: T;
  customerOrPresentationModeCustomer: T;
};

const customerContext = createContext<Partial<FullCustomerContextType>>({});

export function useCustomerContext(): AugmentedFullCustomerContextType<Customer>;
export function useCustomerContext({ allowNull }: { allowNull: true | undefined }): Partial<FullCustomerContextType>;
export function useCustomerContext({
  allowNull,
}: {
  allowNull: true;
}): AugmentedFullCustomerContextType<Customer | null>;
export function useCustomerContext(
  { allowNull }: { allowNull?: boolean } = { allowNull: false }
): Partial<CustomerContextType> {
  const context = useContext(customerContext);

  if (!context.customer || !context.customerOrPresentationModeCustomer) {
    if (allowNull) {
      return context;
    }
    throw new Error("useCustomerContext was used outside of its Provider or customer data is not yet ready");
  }

  return context;
}

// base provider doesn't s access firestore as tests uses it to inject data directly into the context without going through firestore
const BaseCustomerContextProvider = ({
  value,
  children,
}: {
  value: Partial<FullCustomerContextType>;
  children?: ReactNode;
}) => {
  const [freeTrialActive, setFreeTrialActive] = useState<boolean>();
  const [trialUser, setTrialUser] = useState<boolean>();
  const [navigatorTierTrialDates, setNavigatorTierTrialDates] = useState<TierTrialDates<FirestoreTimestamp>>();
  const [isNavigatorTierTrialUser, setIsNavigatorTierTrialUser] = useState<boolean>(false);
  const [isSolveTierTrialUser, setIsSolveTierTrialUser] = useState<boolean>(false);

  useEffect(() => {
    const trialEndDate = value.customer?.trialEndDate && DateTime.fromJSDate(value.customer.trialEndDate.toDate());
    setFreeTrialActive(trialEndDate ? trialEndDate.diff(DateTime.now()).milliseconds > 0 : false);
    setTrialUser(!!value.customer?.trialEndDate);
  }, [value.customer?.trialEndDate]);

  useEffect(() => {
    setNavigatorTierTrialDates(getCustomerTierTrialDatesAsTimestamp(value.customer, TierPackageTypes.NAVIGATOR));
    setIsNavigatorTierTrialUser(isTierTrialUser(value.customer, TierPackageTypes.NAVIGATOR));
    setIsSolveTierTrialUser(isTierTrialUser(value.customer, TierPackageTypes.SOLVE));
  }, [value.customer, value.customer?.tiers]);

  const contextValue = useMemo(
    () => ({
      ...value,
      freeTrialActive,
      trialUser,
      navigatorTierTrialDates,
      isNavigatorTierTrialUser,
      isSolveTierTrialUser,
    }),
    [value, freeTrialActive, trialUser, navigatorTierTrialDates, isNavigatorTierTrialUser, isSolveTierTrialUser]
  );

  return <customerContext.Provider value={contextValue}>{children}</customerContext.Provider>;
};

const getCustomerAmazonWebServices = (customerId?: string) =>
  customerId
    ? getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeAmazonWebServices)
    : undefined;

export const CustomerInitFromContexts = ({
  value,
  children,
}: {
  value: Partial<CustomerContextType>;
  children?: ReactNode;
}) => {
  const awsAccountsQuery = useMemo(() => getCustomerAmazonWebServices(value.customer?.id), [value.customer?.id]);
  const [awsFeatures, setAwsFeatures] = useState<string[]>();
  const [awsAccountsDocs, isLoadingAWSAccountsDocs] = useCollectionData(awsAccountsQuery);

  const entitiesContext = useEntitiesContext();
  const assetsContext = useAssetsContext();
  const contractsContext = useContractsContext();
  const slackChannelsContext = useCustomerSlackChannelsContext();
  const { organizations, userOrganization, organizationsLoading } = useOrgsContext();

  const [init, setInit] = useState(false);
  const [customerType, setCustomerType] = useState<CustomerType>(CustomerType.UNKNOWN);
  const isPresentationMode = value.customer ? isCustomerInPresentationMode(value.customer) : value.customer;

  useEffect(() => {
    setInit(
      !!value.customer?.id && !entitiesContext.entitiesLoading && !assetsContext.assetsLoading && !organizationsLoading
    );
  }, [value.customer?.id, entitiesContext.entitiesLoading, assetsContext.assetsLoading, organizationsLoading]);

  const hasProcurementAssetsOrContract = useMemo(() => {
    if (contractsContext.contractsLoading || isPresentationMode === undefined) {
      return;
    }

    return (
      !isPresentationMode &&
      (assetsContext.hasResoldAssets ||
        contractsContext.contracts.some((contract) =>
          ProcurementAssetTypes.some((type) => contract.type === type && contract.active)
        ))
    );
  }, [
    assetsContext.hasResoldAssets,
    contractsContext.contracts,
    contractsContext.contractsLoading,
    isPresentationMode,
  ]);

  useEffect(() => {
    if (
      !value.customer?.id ||
      assetsContext.assetsLoading ||
      hasProcurementAssetsOrContract === undefined ||
      isPresentationMode === undefined ||
      isPresentationMode === null
    ) {
      setCustomerType(CustomerType.UNKNOWN);
      return;
    }

    if (hasProcurementAssetsOrContract) {
      setCustomerType(
        assetsContext.hasStandaloneAssets ? CustomerType.PROCUREMENT_AND_PRODUCT : CustomerType.PROCUREMENT_ONLY
      );

      return;
    }

    // in case of no advantage assets / contracts:
    // has advantage prospects and doesn't have standalone assets - procurement only
    // no advantage prospects or has standalone assets - product only

    const customerType =
      value.customer?.onboarding?.advantage?.providers === undefined ||
      (value.customer?.onboarding?.advantage?.providers?.length && !assetsContext.hasStandaloneAssets)
        ? CustomerType.PROCUREMENT_ONLY
        : CustomerType.PRODUCT_ONLY;

    setCustomerType(customerType);
  }, [
    assetsContext.hasStandaloneAssets,
    assetsContext.hasResoldAssets,
    assetsContext.assetsLoading,
    hasProcurementAssetsOrContract,
    value.customer?.id,
    value.customer?.onboarding,
    isPresentationMode,
  ]);

  useEffect(() => {
    if (isLoadingAWSAccountsDocs) {
      return;
    }

    if (!awsAccountsDocs) {
      setAwsFeatures([]);
      return;
    }

    const uniqueAwsFeatures = awsAccountsDocs.reduce((acc, awsDoc) => {
      awsDoc.supportedFeatures?.forEach((feature) => {
        if (!feature.hasRequiredPermissions) {
          return;
        }
        acc.add(feature.name);
      });

      return acc;
    }, new Set<string>());

    setAwsFeatures(Array.from(uniqueAwsFeatures));
  }, [awsAccountsDocs, isLoadingAWSAccountsDocs]);

  return (
    <BaseCustomerContextProvider
      value={{
        ...value,
        ...assetsContext,
        ...contractsContext,
        ...entitiesContext,
        ...slackChannelsContext,
        awsFeatures,
        userOrganization,
        organizations,
        init,
        customerType,
        isProductOnlyCustomer:
          customerType === CustomerType.UNKNOWN ? undefined : customerType === CustomerType.PRODUCT_ONLY,
      }}
    >
      {children}
    </BaseCustomerContextProvider>
  );
};

export const CustomerContextProvider = ({
  value,
  children,
}: {
  value: Partial<CustomerContextType>;
  children?: ReactNode;
}) => {
  const customerOrPresentationModeCustomer = useCustomerOrPresentationModeCustomer(value.customer);
  return (
    <TierProvider customer={value.customer}>
      <DataHubProvider customer={value.customer}>
        <EntitiesContextProvider customer={value.customer}>
          <DebtContextProvider customer={value.customer}>
            <AssetsContextProvider customer={customerOrPresentationModeCustomer}>
              <ContractsContextProvider customer={value.customer}>
                <OrgsContextProvider customer={value.customer}>
                  <CustomerSlackChannelsProvider customer={value.customer}>
                    <CloudConnectProvider customer={value.customer}>
                      <AttributionsContextProvider
                        customer={value.customer}
                        customerOrPresentationModeCustomer={customerOrPresentationModeCustomer}
                      >
                        <CustomerInitFromContexts value={{ ...value, customerOrPresentationModeCustomer }}>
                          {value.customer && isCustomerInPresentationMode(value.customer) && (
                            <PresentationModeSentinel />
                          )}
                          {children}
                        </CustomerInitFromContexts>
                      </AttributionsContextProvider>
                    </CloudConnectProvider>
                  </CustomerSlackChannelsProvider>
                </OrgsContextProvider>
              </ContractsContextProvider>
            </AssetsContextProvider>
          </DebtContextProvider>
        </EntitiesContextProvider>
      </DataHubProvider>
    </TierProvider>
  );
};

type CustomerRefIdModel = ModelIdRef<CustomerModel>;

export const CustomerContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<AugmentedFullCustomerContextType<Partial<Customer> | Partial<CustomerRefIdModel>>>;
}) => {
  const { customerId } = useAuthContext();
  const assetsContext = useAssetsContext();
  const entitiesContext = useEntitiesContext();
  const orgsContext = useOrgsContext();
  const slackChannelsContext = useCustomerSlackChannelsContext();

  const actualValue = value ?? {};

  if (customerId) {
    if (!actualValue.customer) {
      actualValue.customer = {};
    }
    actualValue.customer.id = customerId;
    actualValue.init = true;
  }

  const ref = useMemo(() => {
    if (actualValue.customer?.id) {
      return getCollection(CustomerModel).doc(actualValue.customer.id);
    }
  }, [actualValue.customer?.id]);

  if (actualValue.customer) {
    actualValue.customer.ref = ref;
  }

  if (!actualValue.customerOrPresentationModeCustomer) {
    actualValue.customerOrPresentationModeCustomer = actualValue.customer;
  }

  return (
    <BaseCustomerContextProvider
      value={{
        ...(actualValue as CustomerContextType),
        ...assetsContext,
        ...entitiesContext,
        ...orgsContext,
        ...slackChannelsContext,
      }}
    >
      {children}
    </BaseCustomerContextProvider>
  );
};

type CustomerContextConsumerType =
  | { children: (value: FullCustomerContextType) => ReactNode }
  | { allowNull: true; children: (value: Partial<FullCustomerContextType>) => ReactNode };

export const CustomerContextConsumer = (props: CustomerContextConsumerType) => {
  const partialDataCallback = useCallback(
    (ctx: Partial<CustomerContextType>) => {
      // if `undefined`, throw an error
      if (!ctx.customer && !("allowNull" in props)) {
        throw new Error("useCustomerContext was used outside of its Provider");
      }

      // once customer is set we assume that all the other data is set
      return props.children(ctx as Required<FullCustomerContextType>);
    },
    [props]
  );

  return <customerContext.Consumer>{partialDataCallback}</customerContext.Consumer>;
};

type Props = CustomerContextType;

export type WithCustomerFlat = Props;
export type WithCustomer = {
  customer: Customer;
};

export function withCustomerFlat<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent = (props: P) => (
    <CustomerContextConsumer>{(context) => <Component {...context} {...props} />}</CustomerContextConsumer>
  );

  WrappedComponent.displayName = `withCustomerFlat(${getDisplayName(Component)})`;

  return WrappedComponent;
}
