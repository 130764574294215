import LanguageIcon from "@mui/icons-material/Language";
import StarIcon from "@mui/icons-material/Star";
import WorkIcon from "@mui/icons-material/Work";
import { Avatar, Badge, Box, Chip, Link, Tooltip, Typography } from "@mui/material";
import { type ClassNameMap } from "@mui/material/styles";
import clsx from "clsx";
import { type Hit } from "instantsearch.js";

import SlackIcon from "../../../../assets/slack-mark.svg";
import { type Person } from "../Person";
import { type HighlightResult, type PersonData } from "../types/types";
import { decodeHTMLEntities, timezoneToCountry } from "./helpers";
import { useStyles } from "./styles";

export type ColorScheme = "default" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
export type SkillType = "primary" | "secondary" | "specialty";

export const HighlightedText: React.FC<{
  text: string;
  highlightedValue?: string;
}> = ({ text, highlightedValue }) => {
  const styles = useStyles({});
  // Use the highlightedValue if provided, otherwise use text
  const valueToDisplay = highlightedValue || text;

  // Check if valueToDisplay exists
  if (!valueToDisplay) return null;

  // Decode HTML entities while preserving <mark> tags
  // First, split by <mark> tags
  const parts = valueToDisplay.split(/(<\/?mark>)/g);

  // Decode entities in each part that is not a <mark> tag
  const decodedParts = parts.map((part) => {
    if (part === "<mark>" || part === "</mark>") {
      return part; // Keep <mark> tags as is
    } else {
      return decodeHTMLEntities(part); // Decode HTML entities
    }
  });

  // Reconstruct the string
  const reconstructedValue = decodedParts.join("");

  // Now split again to process highlighting
  const finalParts = reconstructedValue.split(/<\/?mark>/g);
  let isHighlighted = false;

  const result: React.JSX.Element[] = [];

  finalParts.forEach((part, index) => {
    if (part) {
      result.push(
        isHighlighted ? (
          <Box key={`highlight-${part}-${index}`} component="span" className={styles.highlightedText}>
            {part}
          </Box>
        ) : (
          <span key={`text-${part}-${index}`}>{part}</span>
        )
      );
    }
    isHighlighted = !isHighlighted;
  });

  return <>{result}</>;
};

export type CategorizedSkills = {
  platform: HighlightResult[];
  category: HighlightResult[];
  service: HighlightResult[];
  activity: HighlightResult[];
  "pro-serv": HighlightResult[];
};

export type SkillCategory = {
  type: keyof CategorizedSkills | "other";
};

/**
 * Determines the category of a single skill based on its path structure (e.g. "CRE/platform")
 * @param skill - A skill string or HighlightResult object
 * @returns The category type for the skill
 */
export const categorizeSkill = (skill: string | HighlightResult): SkillCategory => {
  const skillValue = typeof skill === "string" ? skill : skill.value.replace(/<\/?mark>/g, "");
  const parts = skillValue.split("/");

  if (parts.length > 1) {
    const platform = parts[0].toUpperCase();

    // Special case: CRE/activity is categorized as service
    if (platform === "CRE" && parts[1] === "activity") {
      return { type: "service" };
    }

    // Map the second part of the path to a category
    switch (parts[1]) {
      case "platform":
        return { type: "platform" };
      case "category":
        return { type: "category" };
      case "service":
        return { type: "service" };
      case "activity":
        return { type: "activity" };
      case "pro-serv":
        return { type: "pro-serv" };
    }
  }
  return { type: "other" };
};

/**
 * Groups an array of skills into their respective categories
 * @param skills - Array of HighlightResult objects representing skills
 * @returns Object containing arrays of skills grouped by category
 */
export const categorizeSkills = (skills: HighlightResult[]): CategorizedSkills => {
  // Initialize empty arrays for each category
  const categorized: CategorizedSkills = {
    platform: [],
    category: [],
    service: [],
    activity: [],
    "pro-serv": [],
  };

  // Sort each skill into its appropriate category array
  skills.forEach((skill) => {
    const { type } = categorizeSkill(skill);
    if (type !== "other") {
      categorized[type].push(skill);
    }
  });

  return categorized;
};

/**
 * Checks if a person has any highlighted specialty skills
 */
export const hasHighlightedSpecialitySkill = (hit: Hit<PersonData>): boolean => {
  const highlightedSpecialitySkills = Array.isArray(hit._highlightResult?.speciality_skills)
    ? hit._highlightResult.speciality_skills.filter((skill) => skill.matchLevel !== "none")
    : [];

  return highlightedSpecialitySkills.length > 0;
};

/**
 * Converts a skill to a HighlightResult format
 */
export const convertToHighlightResult = (
  skill: { value: string; type: "primary" | "secondary" | "specialty" },
  highlightedSkills: HighlightResult[]
): HighlightResult & { type: "primary" | "secondary" | "specialty" } => {
  const highlighted = highlightedSkills.find((hs) => hs.value.replace(/<\/?mark>/g, "") === skill.value);

  return highlighted
    ? { ...highlighted, type: skill.type }
    : {
        value: skill.value,
        matchLevel: "none" as const,
        matchedWords: [],
        type: skill.type,
      };
};

/**
 * Gets the color scheme for a skill based on its category
 */
export const getSkillColorScheme = (skill: string): ColorScheme => {
  const categorizedSkill = categorizeSkill(skill);
  switch (categorizedSkill.type) {
    case "platform":
      return "primary";
    case "category":
      return "secondary";
    case "service":
      return "info";
    case "activity":
      return "warning";
    case "pro-serv":
      return "error";
    default:
      return "default";
  }
};

/**
 * Processes languages from a hit into HighlightResult format
 */
export const processLanguages = (
  languages: string[] | undefined,
  highlightedLanguages: HighlightResult[]
): HighlightResult[] =>
  Array.isArray(languages)
    ? languages.map((lang) => {
        const highlightedLang = highlightedLanguages.find((hl) => hl.value.replace(/<\/?mark>/g, "") === lang);
        return (
          highlightedLang || {
            value: lang,
            matchLevel: "none" as const,
            matchedWords: [],
          }
        );
      })
    : [];

type StyleClasses = ClassNameMap<string>;

export const SkillChips: React.FC<{
  items: (HighlightResult & { type?: SkillType })[];
  colorScheme: (item: string) => ColorScheme;
  classes: StyleClasses;
}> = ({ items, colorScheme, classes }) => {
  const styles = useStyles({});
  return (
    <>
      {items.map((item) => {
        const plainText = item.value.replace(/<\/?mark>/g, "");
        const isHighlightedSpecialty = item.type === "specialty" && item.matchLevel !== "none";
        return (
          <Chip
            key={`${item.type || "default"}-${plainText}`}
            label={
              <Box className={styles.skillChipLabel}>
                {item.type === "specialty" && "⭐"}
                {item.type === "secondary" && "‍🎓"}
                <HighlightedText text={plainText} highlightedValue={item.value} />
              </Box>
            }
            color={colorScheme(plainText)}
            size="small"
            className={clsx(classes.chipBase, {
              [classes.specialtyChip]: isHighlightedSpecialty,
            })}
          />
        );
      })}
    </>
  );
};

export const formatSkillChips = (
  items: (HighlightResult & { type?: SkillType })[],
  colorScheme: (item: string) => ColorScheme,
  classes: StyleClasses
) => <SkillChips items={items} colorScheme={colorScheme} classes={classes} />;

export const renderSkills = (skills: HighlightResult[], classes: StyleClasses) =>
  formatSkillChips(skills, getSkillColorScheme, classes);

export const renderLanguages = (languages: HighlightResult[], classes: StyleClasses) =>
  formatSkillChips(languages, () => "success", classes);

export const CategorizedSkills: React.FC<{
  specialtySkills: HighlightResult[];
  remainingSkills: HighlightResult[];
  classes: StyleClasses;
}> = ({ specialtySkills, remainingSkills, classes }) => {
  const styles = useStyles({});
  return (
    <Box>
      <Box className={styles.skillsFlexBox}>
        {specialtySkills.length > 0 && renderSkills(specialtySkills, classes)}
        {renderSkills(remainingSkills, classes)}
      </Box>
    </Box>
  );
};

export const renderCategorizedSkills = (
  specialtySkills: HighlightResult[],
  remainingSkills: HighlightResult[],
  classes: StyleClasses
) => <CategorizedSkills specialtySkills={specialtySkills} remainingSkills={remainingSkills} classes={classes} />;

export const SkillsSection: React.FC<{
  person: Person;
  highlightedSkills: (HighlightResult & { type?: SkillType })[];
  classes: StyleClasses;
}> = ({ person, highlightedSkills, classes }) => {
  const languageStyles = useStyles({ type: "language" });
  const specialtyStyles = useStyles({ type: "specialty" });
  const skillsStyles = useStyles({ type: "skills" });
  const baseStyles = useStyles({});

  const languages =
    person.languages?.map((lang) => ({
      value: lang,
      matchLevel: "none" as const,
      matchedWords: [],
    })) || [];

  // Process specialty skills with highlighting and sorting
  const specialtySkills = person.specialitySkills
    .map((skill) => {
      const highlightedSkill = person.highlightedSpecialitySkills.find(
        (hs) => hs.value.replace(/<\/?mark>/g, "") === skill
      );

      return {
        value: highlightedSkill ? highlightedSkill.value : skill,
        matchLevel: highlightedSkill ? ("full" as const) : ("none" as const),
        matchedWords: highlightedSkill?.matchedWords || [],
        type: "specialty" as const,
      };
    })
    .sort((a, b) => {
      // Sort highlighted skills to the top
      if (a.matchLevel === "full" && b.matchLevel !== "full") return -1;
      if (a.matchLevel !== "full" && b.matchLevel === "full") return 1;
      return a.value.localeCompare(b.value); // Secondary sort by name
    });

  // Sort regular skills by type (service -> category -> platform)
  const regularSkills = highlightedSkills
    .filter((skill) => skill.type !== "specialty")
    .sort((a, b) => {
      const getTypeOrder = (skill: HighlightResult) => {
        const { type } = categorizeSkill(skill);
        switch (type) {
          case "service":
            return 0;
          case "category":
            return 1;
          case "platform":
            return 2;
          default:
            return 3;
        }
      };

      const orderA = getTypeOrder(a);
      const orderB = getTypeOrder(b);

      if (orderA !== orderB) return orderA - orderB;
      return a.value.localeCompare(b.value); // Secondary sort by name
    });

  return (
    <Box className={languageStyles.skillBoxContainer}>
      <Box className={languageStyles.skillSection}>
        <Box className={languageStyles.sectionHeader}>
          <LanguageIcon className={baseStyles.iconSmall} />
          LANGUAGES
        </Box>
        {renderLanguages(languages, classes)}
      </Box>

      {specialtySkills.length > 0 && (
        <Box className={specialtyStyles.skillSection}>
          <Box className={specialtyStyles.sectionHeader}>
            <StarIcon className={baseStyles.iconSmall} />
            SPECIALIST SKILLS
          </Box>
          {renderSkills(specialtySkills, classes)}
        </Box>
      )}

      <Box className={skillsStyles.skillSection}>
        <Box className={skillsStyles.sectionHeader}>
          <WorkIcon className={baseStyles.iconSmall} />
          SKILLS
        </Box>
        <Box className={skillsStyles.skillsWrapper}>{renderSkills(regularSkills, classes)}</Box>
      </Box>
    </Box>
  );
};

export const renderAllSkills = (
  person: Person,
  highlightedSkills: (HighlightResult & { type?: SkillType })[],
  classes: StyleClasses
) => <SkillsSection person={person} highlightedSkills={highlightedSkills} classes={classes} />;

export const renderPersonInfo = (
  person: Person,
  isExpanded: boolean,
  remainingSkills: HighlightResult[],
  classes: StyleClasses
) => (
  <Box className={classes.personInfoBox}>
    <Box className={classes.personHeaderBox}>
      <Box className={classes.avatarContainer}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          className={`${classes.badge} ${person.isAvailable ? classes.available : classes.unavailable}`}
          badgeContent={person.titleEmoji}
        >
          <Avatar className={classes.avatar} src={person.avatar} />
        </Badge>
        <Tooltip title={(timezoneToCountry as Record<string, string>)[person.timezone] ?? person.timezone} arrow>
          <Box className={classes.timezoneBox}>
            <Typography variant="body2" className={classes.countryEmoji}>
              {person.countryFlagEmoji}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
      <Box className={classes.personInfoContent}>
        <Typography variant="h6" component="div" className={classes.nameText}>
          <Link href={`https://doitintl.zendesk.com/agent/users/${person.zendeskId}/`} className={classes.nameLink}>
            <HighlightedText text={person.name} highlightedValue={person._highlightResult?.name?.value as string} />
          </Link>
        </Typography>
        <Typography variant="subtitle1" className={classes.titleText}>
          {person.title}
        </Typography>
        <Box className={classes.slackBox}>
          <Link href={`slack://user?team=T2TG0KM5E&id=${person.slack}`} target="_blank">
            <Box component="img" src={SlackIcon as string} className={classes.slackIcon} />
          </Link>
        </Box>
      </Box>
    </Box>
    <Box className={classes.skillsBox}>
      {!isExpanded && person.hasHighlightedLanguage && (
        <Box className={classes.languageBox}>
          <Box className={classes.skillsContainer} sx={{ pt: 2 }}>
            {renderLanguages(person.highlightedLanguages, classes)}
          </Box>
        </Box>
      )}
      {isExpanded
        ? renderAllSkills(person, person.highlightedSkills as (HighlightResult & { type?: SkillType })[], classes)
        : person.hasHighlightedSkills &&
          renderCategorizedSkills(person.highlightedSpecialitySkills as HighlightResult[], remainingSkills, classes)}
    </Box>
  </Box>
);

export const hasAnyHighlight = (hit: Hit): boolean => {
  // Check if the _highlightResult object exists and has any highlighted fields
  if (!hit._highlightResult) return false;

  // Helper function to check if a highlight object contains a match
  const hasMatch = (obj: unknown): boolean => {
    if (!obj) return false;
    if (Array.isArray(obj)) {
      return obj.some((item) => hasMatch(item));
    }
    if (typeof obj === "object" && obj !== null) {
      const objWithMatchLevel = obj as { matchLevel?: string };
      if (objWithMatchLevel.matchLevel && objWithMatchLevel.matchLevel !== "none") return true;
      return Object.values(obj).some((value) => hasMatch(value));
    }
    return false;
  };

  return hasMatch(hit._highlightResult);
};
