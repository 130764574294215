import { useEffect, useState } from "react";

import { useHits, useSearchBox } from "react-instantsearch";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import EmptyResultsMessage from "./EmptyResultsMessage";
import { PersonCard } from "./PersonCard";
import { type Hit } from "./types/types";
import { hasAnyHighlight, hasHighlightedSpecialitySkill } from "./utils/personcardUtils";

type CustomHitsProps = {
  itemsToRender?: number;
};

export const CustomHits = ({ itemsToRender = Infinity }: CustomHitsProps) => {
  const { items } = useHits<Hit>();
  const { query } = useSearchBox();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [isFirstMount, setIsFirstMount] = useState(true);
  const limitedItems = items.slice(0, itemsToRender);

  useEffect(() => {
    // Only set isFirstMount to false when we have items
    if (items.length > 0) {
      setIsFirstMount(false);
    }
  }, [items]);

  // Only filter and sort items if there's a search query
  const filteredAndSortedItems = query
    ? [...limitedItems].filter(hasAnyHighlight).sort((a, b) => {
        // First, prioritize items with specialty skill highlights
        const aHasSpecialtyHighlight = hasHighlightedSpecialitySkill(a);
        const bHasSpecialtyHighlight = hasHighlightedSpecialitySkill(b);
        if (aHasSpecialtyHighlight !== bHasSpecialtyHighlight) {
          return aHasSpecialtyHighlight ? -1 : 1;
        }

        // Then sort by name highlight
        const aHasNameHighlight =
          typeof a._highlightResult?.name === "object" &&
          !Array.isArray(a._highlightResult.name) &&
          a._highlightResult.name?.matchLevel !== "none";
        const bHasNameHighlight =
          typeof b._highlightResult?.name === "object" &&
          !Array.isArray(b._highlightResult.name) &&
          b._highlightResult.name?.matchLevel !== "none";
        if (aHasNameHighlight !== bHasNameHighlight) {
          return aHasNameHighlight ? -1 : 1;
        }

        // Finally sort by language highlights
        const aHasLanguageHighlight =
          Array.isArray(a._highlightResult?.languages) &&
          a._highlightResult.languages.some((lang) => lang.matchLevel !== "none");
        const bHasLanguageHighlight =
          Array.isArray(b._highlightResult?.languages) &&
          b._highlightResult.languages.some((lang) => lang.matchLevel !== "none");
        if (aHasLanguageHighlight !== bHasLanguageHighlight) {
          return aHasLanguageHighlight ? -1 : 1;
        }

        // If all highlight types are equal, maintain original order
        return 0;
      })
    : limitedItems;

  const handleCardExpand = (id: string) => {
    setExpandedId(expandedId === id ? null : id);
  };

  if (isFirstMount && items.length === 0) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%", padding: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (limitedItems.length === 0) {
    return <EmptyResultsMessage />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: "center",
        width: "100%",
        padding: 2,
      }}
    >
      {filteredAndSortedItems.map((item) => (
        <Box
          key={item.objectID}
          sx={{
            // Add blur effect to non-expanded cards when there's an expanded card
            filter: expandedId && expandedId !== item.objectID ? "blur(2px)" : "none",
            transition: "filter 0.3s ease",
            // Prevent interaction with blurred cards
            pointerEvents: expandedId && expandedId !== item.objectID ? "none" : "auto",
          }}
        >
          <PersonCard
            hit={item}
            isExpanded={expandedId === item.objectID}
            onExpand={() => {
              handleCardExpand(item.objectID);
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
