import { useCallback, useMemo, useState } from "react";

import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { CustomerPicker } from "../../Components/CustomerPicker/CustomerPicker";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreateTableDialog = ({ open, onClose }: Props) => {
  const api = useApiContext();
  const showError = useErrorSnackbar(7);
  const showSuccessSnackbar = useSuccessSnackbar();

  const [selectedCustomer, setSelectedCustomer] = useState<{ id: string; name: string } | null>(null);
  const [startDate, setStartDate] = useState<DateTime>();
  const [spreadsheetUrl, setSpreadsheetUrl] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isFormValid = useMemo(
    () => selectedCustomer && startDate && spreadsheetUrl !== "",
    [selectedCustomer, startDate, spreadsheetUrl]
  );

  const onSubmit = useCallback(() => {
    const payload = {
      customerID: selectedCustomer?.id,
      startDate: startDate?.toFormat("yyyy-MM-dd"),
      spreadsheetURL: spreadsheetUrl,
    };

    setIsSubmitting(true);
    api
      .post(`/v1/pricebooks/google-cloud`, payload)
      .then(() => {
        showSuccessSnackbar("Success");
      })
      .catch((err) => {
        showError(err.response?.data?.error as string);
      })
      .finally(() => {
        setIsSubmitting(false);
        onClose();
      });
  }, [selectedCustomer?.id, startDate, spreadsheetUrl, api, showSuccessSnackbar, showError, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create pricebook table</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <CustomerPicker
              value={selectedCustomer?.id}
              onChange={(_event, newCustomer) => {
                setSelectedCustomer(
                  newCustomer ? { id: newCustomer?.objectID, name: newCustomer?.primaryDomain } : null
                );
              }}
              getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
                hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
              }
              getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
              TextFieldProps={{
                label: "Customer domain",
                variant: "outlined",
                required: true,
                sx: { width: "250px" },
              }}
              data-cy="customer-select"
            />

            <DatePicker
              renderInput={(params) => (
                <TextField data-cy="start-date" fullWidth required sx={{ width: "250px" }} {...params} />
              )}
              label="Start Date"
              value={startDate ?? null}
              onChange={(date) => {
                setStartDate(date ?? undefined);
              }}
              components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
              inputFormat="dd LLL, yyyy"
            />
            <TextField
              fullWidth
              label="Spreadsheet URL"
              helperText="Paste the URL of the Google Sheets document"
              variant="outlined"
              required
              onChange={(event) => {
                setSpreadsheetUrl(event.target.value);
              }}
            />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, borderColor: "divider" }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isSubmitting}
          onClick={onSubmit}
          autoFocus
          data-cy="confirm-btn"
          disabled={!isFormValid}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
