import { type HighlightResult, type PersonData } from "./types/types";
import { countryToFlagEmoji, getLanguageEmoji, timezoneToCountry } from "./utils/helpers";

export class Person {
  readonly name: string;

  readonly email: string;

  readonly primarySkills: string[];

  readonly secondarySkills: string[];

  readonly specialitySkills: string[];

  readonly isAvailable: boolean;

  readonly isFocusTime: boolean;

  readonly isOnVacation: boolean;

  readonly isBusy: boolean;

  readonly isOutOfOffice: boolean;

  readonly isWorkingHours: boolean;

  readonly title: string;

  readonly avatar: string;

  readonly timezone: string;

  readonly slack: string;

  readonly zendeskId: string;

  readonly languages: string[];

  readonly _highlightResult?: {
    primary_skills?: HighlightResult[];
    secondary_skills?: HighlightResult[];
    speciality_skills?: HighlightResult[];
    name?: HighlightResult;
    languages?: HighlightResult[];
  };

  constructor(hit: PersonData) {
    this.name = hit?.name ?? "";
    this.email = hit?.email ?? "";
    this.primarySkills = hit?.primary_skills ?? [];
    this.secondarySkills = hit?.secondary_skills ?? [];
    this.specialitySkills = hit?.speciality_skills ?? [];
    this.isAvailable = hit?.is_available ?? false;
    this.isFocusTime = hit?.is_focus_time ?? false;
    this.isOnVacation = hit?.is_on_vacation ?? false;
    this.isBusy = hit?.is_busy ?? false;
    this.isOutOfOffice = hit?.is_out_of_office ?? false;
    this.isWorkingHours = hit?.is_working_hours ?? false;
    this.title = hit?.title ?? "";
    this.avatar = hit?.avatar ?? "";
    this.timezone = hit?.timezone ?? "";
    this.slack = hit?.slack ?? "";
    this.zendeskId = hit?.zendesk_id ?? "";
    this.languages = hit?.languages ?? [];
    this._highlightResult = hit?._highlightResult;
  }

  get titleEmoji(): string {
    if (!this.isWorkingHours) return "🔴";
    if (this.isOnVacation) return "🌴";
    if (this.isFocusTime) return "🎧";
    if (this.isOutOfOffice) return "🚙";
    if (this.isBusy) return "📅";
    return "";
  }

  get languageEmojis(): Array<{ emoji: string; name: string }> {
    return this.languages.map((language) => ({
      emoji: getLanguageEmoji(language),
      name: language,
    }));
  }

  get country(): string {
    return this.timezone && timezoneToCountry[this.timezone] ? timezoneToCountry[this.timezone] : "Unknown";
  }

  get countryFlagEmoji(): string {
    return this.country && countryToFlagEmoji[this.country] ? countryToFlagEmoji[this.country] : "🌍";
  }

  get city(): string {
    if (!this.timezone) return "Unknown";
    const parts = this.timezone.split("/");
    return parts.length > 1 ? parts[1].replace(/_/g, " ") : "Unknown";
  }

  get formattedLanguages(): string {
    return this.languages.join(", ");
  }

  get hasPrimarySkills(): boolean {
    return Array.isArray(this.primarySkills) && this.primarySkills.length > 0;
  }

  get hasSecondarySkills(): boolean {
    return Array.isArray(this.secondarySkills) && this.secondarySkills.length > 0;
  }

  get hasSpecialtySkills(): boolean {
    return Array.isArray(this.specialitySkills) && this.specialitySkills.length > 0;
  }

  get hasAnySkills(): boolean {
    return this.hasPrimarySkills || this.hasSecondarySkills || this.hasSpecialtySkills;
  }

  get highlightedPrimarySkills(): (HighlightResult & { type: "primary" })[] {
    return Array.isArray(this._highlightResult?.primary_skills)
      ? this._highlightResult.primary_skills
          .filter((skill) => skill.matchLevel !== "none")
          .map((skill) => ({ ...skill, type: "primary" as const }))
      : [];
  }

  get highlightedSecondarySkills(): (HighlightResult & { type: "secondary" })[] {
    return Array.isArray(this._highlightResult?.secondary_skills)
      ? this._highlightResult.secondary_skills
          .filter((skill) => skill.matchLevel !== "none")
          .map((skill) => ({ ...skill, type: "secondary" as const }))
      : [];
  }

  get highlightedSpecialitySkills(): (HighlightResult & { type: "specialty" })[] {
    return Array.isArray(this._highlightResult?.speciality_skills)
      ? this._highlightResult.speciality_skills
          .filter((skill) => skill.matchLevel !== "none")
          .map((skill) => ({ ...skill, type: "specialty" as const }))
      : [];
  }

  get highlightedSkills(): (HighlightResult & { type: "primary" | "secondary" | "specialty" })[] {
    return [
      ...this.highlightedSpecialitySkills,
      ...this.highlightedPrimarySkills,
      ...this.highlightedSecondarySkills,
    ].reduce(
      (acc, curr) => {
        const plainText = curr.value.replace(/<\/?mark>/g, "");
        if (!acc.some((skill) => skill.value.replace(/<\/?mark>/g, "") === plainText)) {
          acc.push(curr);
        }
        return acc;
      },
      [] as (HighlightResult & { type: "primary" | "secondary" | "specialty" })[]
    );
  }

  get hasHighlightedSkills(): boolean {
    return this.highlightedSkills.length > 0;
  }

  get hasHighlightedSpecialitySkill(): boolean {
    return this.highlightedSpecialitySkills.length > 0;
  }

  get hasHighlightedLanguage(): boolean {
    return (
      Array.isArray(this._highlightResult?.languages) &&
      this._highlightResult.languages.some((lang) => lang.matchLevel !== "none")
    );
  }

  get highlightedLanguages(): HighlightResult[] {
    return Array.isArray(this._highlightResult?.languages) ? this._highlightResult.languages : [];
  }
}
