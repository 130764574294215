import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useCloudflowTemplates } from "../../hooks";
import { useModalManager } from "../Common/CloudflowModalsProvider";
import { useCloudflowOperations } from "../Common/CloudflowOperationsProvider";
import { BlueprintSearchItem } from "./BlueprintSearchItem";

export type BlueprintDialogProps = {
  handleAddBlueprint: (blueprintId: string, targetNodeId: string) => void;
  focusedNodeId: string;
};

export const BlueprintDialog = ({ handleAddBlueprint, focusedNodeId }: BlueprintDialogProps) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const { templates: blueprints } = useCloudflowTemplates("blueprint");
  const { closeModal } = useModalManager();
  const { httpOperationLoading } = useCloudflowOperations();

  return (
    <Dialog
      open
      onClose={() => {
        closeModal("blueprint");
      }}
      fullScreen={fullScreen}
      fullWidth
      PaperProps={{ sx: { maxWidth: 704, height: 704 } }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" pl={0.5} pr={2}>
        <DialogTitle>Add a blueprint to your CloudFlow</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            closeModal("blueprint");
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent
        sx={{
          backgroundColor: "general.backgroundDark",
          py: 1,
        }}
        dividers
      >
        <Stack spacing={1}>
          {blueprints?.map((b) => (
            <BlueprintSearchItem
              disabled={httpOperationLoading}
              key={b.id}
              item={b}
              onClick={() => {
                handleAddBlueprint(focusedNodeId, b.id);
              }}
            />
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
