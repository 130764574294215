import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button } from "@mui/material";

export interface OpenGCPConsoleProps {
  project: string | null;
}

const OpenGCPConsole = ({ project }: OpenGCPConsoleProps) => (
  <Button
    startIcon={<OpenNewIcon />}
    variant="outlined"
    sx={{ width: "fit-content" }}
    href={`https://console.cloud.google.com/cloudshell/editor?project=${project}`}
    target="_blank"
  >
    Open Google Cloud Console
  </Button>
);

export default OpenGCPConsole;
