import { Alert, Box, Stack, TextField, Typography } from "@mui/material";

type Props = {
  applicationKey: string;
  onApplicationKeyChange: (string) => void;
};

export default function AccountsAndAppKeyStep({ applicationKey, onApplicationKeyChange }: Props) {
  return (
    <Box data-cy="accounts-and-app-key-step">
      <Typography mb={4} variant="h3">
        Create Service accounts and App keys
      </Typography>
      <Stack spacing={2}>
        <Typography variant="body1">1. Navigate to the SERVICE ACCOUNTS pages</Typography>

        <Box>
          <Typography variant="body1">2. Create a new SERVICE ACCOUNT</Typography>
          <Box ml={3}>
            <Typography variant="body1">a. In the NAME field, you can use "DoiT Account"</Typography>
            <Typography variant="body1">b. Enter your email address</Typography>
            <Typography variant="body1">c. Select the DoiT Connector role you just created</Typography>
          </Box>
        </Box>

        <Typography variant="body1">3. Navigate to APPLICATION KEYS and create a NEW KEY</Typography>

        <Alert severity="warning">
          The key is only visible once. Please ensure you don't close the browser window before copying the key.
        </Alert>

        <Box>
          <Typography variant="body1" mb={3}>
            4. In Application key, enter the application key you created in step 3
          </Typography>
          <TextField
            label="Application key"
            fullWidth
            required
            value={applicationKey}
            onChange={(event) => {
              onApplicationKeyChange(event.target.value);
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
