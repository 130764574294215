import { useCallback, useMemo, useState } from "react";

import { type ContractModelType } from "@doitintl/cmp-models";
import { Box, Button, List, ListItem, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { CustomerPicker } from "../../../../Components/CustomerPicker/CustomerPicker";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import CancelInvoicesDialog from "./CancelInvoicesDialog";
import { useInvoices as _useInvoices } from "./hooks";
import InvoiceList from "./InvoiceList";
import { texts } from "./texts";
import { type CancelIssuedInvoicesPayload } from "./types";

const minDate = DateTime.fromObject({ year: 2019, month: 3, day: 1 }) as DateTime<true>;
const maxDate = DateTime.utc().endOf("month");

const DraftInvoices = ({ useInvoices = _useInvoices }) => {
  const [selectedCustomer, setSelectedCustomer] = useState<{ id: string; name: string } | null>(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const showError = useErrorSnackbar(7);
  const showSuccessSnackbar = useSuccessSnackbar();
  const api = useApiContext();
  const [billingMonth, setBillingMonth] = useState(DateTime.utc().startOf("month").minus({ months: 1 }));
  const { invoices, invoicesLoading, loadInvoices } = useInvoices(selectedCustomer?.id, billingMonth);
  const [loading, setLoading] = useState(false);

  const issuedInvoicesNum = useMemo(() => invoices.filter((invoice) => invoice.issuedAt).length, [invoices]);

  const cancelIssuedInvoices = useCallback(
    (type?: ContractModelType, cancellationReason?: string) => {
      setLoading(true);
      const invoiceEntities = uniq(invoices.filter((invoice) => invoice.issuedAt).map((invoice) => invoice.entity.id));

      const payload: CancelIssuedInvoicesPayload = {
        entityIds: invoiceEntities,
        month: billingMonth.toFormat("yyyy-MM"),
        cancellationReason,
      };

      if (type) {
        payload.types = [type];
      }

      api
        .post("/v1/invoicing/cancel-issued-invoices", payload)
        .then(() => {
          showSuccessSnackbar(texts.SUCCESSFUL_CANCELLATION_MESSAGE);
        })
        .catch((err) => {
          showError(err.response?.data?.errors);
        })
        .finally(() => {
          loadInvoices();
          setCancelDialogOpen(false);
          setLoading(false);
        });
    },
    [api, billingMonth, invoices, loadInvoices, showError, showSuccessSnackbar]
  );

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="h1" data-cy="page-title">
          {texts.DRAFT_INVOICES_PAGE_TITLE}
        </Typography>
      </Box>
      <Box data-cy="page-description">
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
              py: 0,
            },
          }}
        >
          <ListItem>This page lists finalized draft invoices that Engineering have issued.</ListItem>
          <ListItem>Drafts that have been issued must be cancelled for Engineering to re-issue.</ListItem>
          <ListItem>Cancellations to customer must still be done with finance</ListItem>
        </List>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <DatePicker
            renderInput={(params) => (
              <TextField data-cy="invoice-month" margin="none" sx={{ width: "180px" }} {...params} />
            )}
            label="Invoice Month"
            disabled={invoicesLoading}
            value={billingMonth}
            onChange={(date) => {
              setBillingMonth(date as DateTime<true>);
            }}
            views={["year", "month"]}
            openTo="month"
            minDate={minDate}
            maxDate={maxDate}
          />
          <CustomerPicker
            value={selectedCustomer?.id}
            onChange={(_event, newCustomer) => {
              setSelectedCustomer(newCustomer ? { id: newCustomer?.objectID, name: newCustomer?.primaryDomain } : null);
            }}
            getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
              hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
            }
            getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
            TextFieldProps={{
              label: "Customer domain",
              variant: "outlined",
              sx: { width: "250px", ml: "20px" },
            }}
            data-cy="customer-select-textfield"
          />
        </Box>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || invoicesLoading || !selectedCustomer || !issuedInvoicesNum}
          onClick={() => {
            setCancelDialogOpen(true);
          }}
          data-cy="open-cancel-invoice-dialog-button"
        >
          {texts.CANCEL_INVOICES_TEXT}
        </Button>
      </Box>
      <InvoiceList
        loading={invoicesLoading || loading}
        invoices={invoices}
        billingMonth={billingMonth.toFormat("yyyy-MM")}
      />
      <CancelInvoicesDialog
        open={cancelDialogOpen}
        onCancel={cancelIssuedInvoices}
        onClose={() => {
          setCancelDialogOpen(false);
        }}
        selectedCustomer={selectedCustomer}
        billingMonth={billingMonth}
        invoices={invoices}
      />
    </Box>
  );
};

export default DraftInvoices;
