import { type Dispatch, type FC } from "react";

import { Avatar, Chip, ListItemButton, ListItemText, Stack } from "@mui/material";
import { type SanitizeElementHookEvent } from "dompurify";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { SafeHtml } from "../../../../Components/SafeHtml";
import { useApiProviderLogo } from "../Common/utils";
import { type ListItem } from "./useActionSearchDialogStateMachine";

const transformHtml = (node: Element, data: SanitizeElementHookEvent) => {
  if (data.tagName === "p") {
    node.setAttribute("style", "margin: 0; word-wrap: break-word;");
  }
  if (data.tagName === "a") {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
  return node;
};

export const ActionSearchItem: FC<{ item: ListItem; onClick: Dispatch<ListItem> }> = ({ item, onClick }) => {
  const getLogo = useApiProviderLogo();
  const isDarkMode = useDarkThemeCheck();
  const chipBackgroundColor = isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight;
  return (
    <ListItemButton
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: "background.default",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 1,
        borderColor: "divider",
        mb: 1,
        p: 2,
        "&:hover": {
          borderColor: "primary.main",
          backgroundColor: "background.default",
        },
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <ListItemText
        primary={item.label}
        slotProps={{
          primary: {
            fontWeight: 500,
            mb: 0.5,
          },
        }}
        secondary={<SafeHtml html={item.caption} transform={transformHtml} />}
        sx={{ mt: 0, maxWidth: "100%" }}
      />
      <Stack direction="row" spacing={1}>
        <Avatar
          alt={item.providerId}
          src={getLogo(item.providerId)}
          sx={{
            backgroundColor: chipBackgroundColor,
            width: 26,
            height: 26,
            padding: 0.5,
          }}
        />
        {item.serviceId && (
          <>
            <Chip
              label={`Service: ${item.serviceName}`}
              sx={{ backgroundColor: chipBackgroundColor, color: "text.primary" }}
              size="small"
            />
            <Chip
              label={`Version: ${item.version}`}
              sx={{ backgroundColor: chipBackgroundColor, color: "text.primary" }}
              size="small"
            />
          </>
        )}
      </Stack>
    </ListItemButton>
  );
};
