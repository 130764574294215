import { useCallback, useMemo, useRef } from "react";

import {
  ACTUAL,
  BUDGET_AMOUNT,
  BUDGET_AMOUNT_TO_DATE,
  budgetTxt,
  FORECAST,
} from "../../../assets/texts/CloudAnalytics/budget";
import { generateHighchartList3 } from "../../../cmpBaseColors";
import { ThemeModes } from "../../../muiThemeTypes";
import { ECharts } from "../renderers/ECharts/ECharts";
import { getTotalForecastBarStyle } from "../renderers/ECharts/utils/forecastSeries";
import { type ChartSeriesRecord } from "../renderers/useChartsSeries";
import { type BudgetChartData, type BudgetPerformanceChart } from "./budgetViewTypes";
import { fillNullValues, getAlertTriggers } from "./utils";

type BudgetPerformanceChartProps = BudgetPerformanceChart;

const BudgetPerformanceChart = ({
  isCumulative,
  theme,
  categories,
  actualData,
  budgetAmountData,
  budgetAmountToDateData,
  forecastData,
  alerts,
  valueFormatter,
}: BudgetPerformanceChartProps) => {
  const chartRef = useRef<typeof ECharts | null>(null);

  const getSeriesFromChartData = useCallback(
    (
      chartData: BudgetChartData[] | null,
      chartSeriesName: string,
      fullRangeData: string[]
    ): ChartSeriesRecord | null => {
      if (!chartData?.length) {
        return null;
      }

      const transformedData: ChartSeriesRecord = {
        name: chartSeriesName,
        data: fillNullValues(
          (chartData ?? []).map((item) => item.y ?? 0),
          fullRangeData
        ),
        symbol: "circle",
        symbolSize: 8,
      };

      switch (chartSeriesName) {
        case ACTUAL:
          transformedData.areaStyle = {};
          transformedData.color = generateHighchartList3(
            theme.palette.mode === ThemeModes.LIGHT ? "light" : "dark"
          )[0] as string;
          break;
        case BUDGET_AMOUNT:
          transformedData.lineStyle = {
            type: "dashed",
            width: 1,
          };
          transformedData.color = generateHighchartList3(
            theme.palette.mode === ThemeModes.LIGHT ? "light" : "dark"
          )[1] as string;
          transformedData.symbol = "diamond";
          break;
        case BUDGET_AMOUNT_TO_DATE:
          transformedData.lineStyle = {
            type: "dotted",
            width: 1,
          };
          transformedData.color = generateHighchartList3(
            theme.palette.mode === ThemeModes.LIGHT ? "light" : "dark"
          )[4] as string;
          transformedData.markPoint = {
            data: getAlertTriggers(chartData ?? [], alerts ?? []),
            label: {
              show: true,
              position: "top",
              formatter: () => budgetTxt.THRESHOLD_TRIGGER,
              color: "#fff",
              padding: [5, 10],
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              borderRadius: 4,
              offset: [0, 10],
            },
            itemStyle: {
              color: "rgba(0, 0, 0, 0.75)",
            },
            symbolSize: 10,
            symbolOffset: [0, -12],
            symbol: "diamond",
          };
          break;
        case FORECAST:
          transformedData.data = fillNullValues(
            (chartData ?? []).map((item) => item.y ?? 0),
            fullRangeData,
            true
          );
          transformedData.itemStyle = getTotalForecastBarStyle(theme);
          transformedData.lineStyle = {
            type: "dotted",
            width: 1,
          };
          break;
        default:
          break;
      }

      return transformedData;
    },
    [theme, alerts]
  );

  // e-chart settings
  const seriesData = useMemo(() => {
    const actualSeries = getSeriesFromChartData(actualData, ACTUAL, categories);
    const budgetAmountSeries = getSeriesFromChartData(budgetAmountData, BUDGET_AMOUNT, categories);
    const forecastSeries = getSeriesFromChartData(forecastData, FORECAST, categories);

    const budgetAmountToDateSeries = getSeriesFromChartData(budgetAmountToDateData, BUDGET_AMOUNT_TO_DATE, categories);

    return [actualSeries, budgetAmountSeries, budgetAmountToDateSeries, forecastSeries].filter(Boolean);
  }, [getSeriesFromChartData, actualData, categories, budgetAmountData, forecastData, budgetAmountToDateData]);

  const categoryFormatter = (value: string) => value.toString();

  return (
    <ECharts
      key={isCumulative ? "cumulative" : "non-cumulative"}
      ref={chartRef}
      type="line"
      categories={categories}
      series={seriesData as ChartSeriesRecord[]}
      valueFormatter={valueFormatter}
      categoryFormatter={categoryFormatter}
      logScale={false}
      widgetView={false}
      customMode={{
        isCumulative,
        isCustomMode: true,
      }}
    />
  );
};

export default BudgetPerformanceChart;
