import { type DataColumns, type DefaultFilterColumns, type OnFilter } from "../../../types/FilterTable";
import { useFullScreen } from "../../../utils/dialog";
import EnhancedTableFilter from "../EnhancedTableFilter";

export type BaseTableFilterBarProps<T> = {
  tableName?: string;
  items: Readonly<Array<T>>;
  filterColumn: DataColumns<T>;
  defaultFilters?: DefaultFilterColumns<T>;
  placeholder?: string;
  skipOROperator?: boolean;
  mixpanelEventName?: string;
  reverseOperatorPrecedence?: boolean;
  onFilter: OnFilter<T>;
  tagsLimit?: number;
};

export function TableFilterBar<T>({
  items,
  onFilter,
  filterColumn,
  defaultFilters = [],
  placeholder = "Filter table",
  skipOROperator,
  tableName,
  mixpanelEventName,
  reverseOperatorPrecedence,
  tagsLimit = -1,
}: BaseTableFilterBarProps<T>) {
  const { isMobile: smDown } = useFullScreen();

  return (
    <EnhancedTableFilter
      tableName={tableName}
      data={items}
      columns={filterColumn}
      onFilter={onFilter}
      limitTags={smDown ? 2 : tagsLimit}
      placeholder={placeholder}
      defaultValue={defaultFilters}
      skipOROperator={skipOROperator}
      mixpanelEventName={mixpanelEventName}
      reverseOperatorPrecedence={reverseOperatorPrecedence}
    />
  );
}
