import { useCallback, useState } from "react";

import { useParams } from "react-router";
import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { type Node, useOnSelectionChange, useReactFlow } from "@xyflow/react";

import { useCloudFlowContext } from "../CloudflowBuilder/Common/CloudFlowProvider";
import NodeConfigurationPanel from "../CloudflowBuilder/ConfigurationPanel/NodeConfigurationPanel";
import { updateSelectedNode } from "../CloudflowBuilder/utils/updateUtils";
import type { CloudFlowNode, HandleUpdateNodeFn, RFNode } from "../types";

const SidePanelContent = () => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { setNodes } = useReactFlow();
  const [activeNode, setActiveNode] = useState<Node<RFNode> | undefined>();
  const { updateCloudFlowNode } = useCloudFlowContext();

  const handlePanelClose = useCallback(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id !== activeNode?.id) return node;

        return { ...node, selected: false };
      })
    );
  }, [activeNode, setNodes]);

  const onChange = useCallback(({ nodes }: { nodes: Node[] }) => {
    if (nodes.length === 0) {
      setActiveNode(undefined);
      return;
    }

    const node = nodes[0];
    if (node.type === CloudFlowNodeType.GHOST || node.type === CloudFlowNodeType.START_STEP) {
      return;
    }
    setActiveNode(node as Node<RFNode>);
  }, []);

  useOnSelectionChange({
    onChange,
  });

  const handleUpdateNodeConfig: HandleUpdateNodeFn = useCallback(
    (id, updateNodeAction) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id !== id) return node;

          const updatedNode = updateSelectedNode(node as Node<RFNode>, updateNodeAction);
          // filter for transitions is present because we don't allow ghost transitions in firestore Cloudflow nodes.
          updateCloudFlowNode({
            updatedNode: {
              id: updatedNode.id,
              ...updatedNode.data.nodeData,
              transitions: updatedNode.data.nodeData.transitions?.filter((t) => !t.targetNodeId.includes("ghost")),
            } as unknown as CloudFlowNode,
          });
          setActiveNode(updatedNode);
          return updatedNode;
        })
      );
    },
    [setNodes, updateCloudFlowNode]
  );

  if (!activeNode) {
    return null;
  }

  return (
    <NodeConfigurationPanel
      flowId={flowId}
      open={!!activeNode && activeNode.type !== CloudFlowNodeType.GHOST}
      onClose={handlePanelClose}
      activeNode={activeNode}
      onUpdateNode={handleUpdateNodeConfig}
    />
  );
};

export default SidePanelContent;
