import { Configure, InstantSearch, useSearchBox } from "react-instantsearch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import { AlgoliaHooks } from "../../Context/algoliaContext";
import { CustomHits } from "./components/CustomHits";
import { CustomSearchBox } from "./components/CustomSearchBox";
import { SearchProvider } from "./components/SearchContext";
import { SearchFilters } from "./components/SearchFilters";
import { SearchFiltersProvider } from "./components/SearchFiltersContext";
import { useStyles } from "./components/utils/styles";

const WhoisContent = () => {
  const classes = useStyles({});
  const { query } = useSearchBox();
  const { refine } = useSearchBox();

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContent}>
        <Box className={classes.topBar}>
          <Box className={classes.searchBoxWrapper}>
            <CustomSearchBox />
          </Box>
          <Box className={classes.filtersContainer}>
            <SearchFilters onSelectFilter={refine} currentQuery={query} disabled={false} />
          </Box>
        </Box>
        <Box className={classes.hitsContainer}>
          <Grid container direction="row">
            <CustomHits itemsToRender={300} />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export const Whois = () => {
  const algoliaContext = AlgoliaHooks.useAlgoliaConfig();
  if (!algoliaContext.searchClient) {
    return null;
  }

  return (
    <InstantSearch searchClient={algoliaContext.searchClient} indexName="whois-cre">
      <SearchFiltersProvider>
        <SearchProvider>
          <Configure
            hitsPerPage={300}
            enablePersonalization={true}
            queryType="prefixAll"
            removeWordsIfNoResults="allOptional"
            typoTolerance={true}
            attributesToHighlight={[
              "name",
              "primary_skills",
              "secondary_skills",
              "speciality_skills",
              "languages",
              "timezone",
            ]}
            advancedSyntax={true}
            highlightPreTag="<strong>"
            highlightPostTag="</strong>"
          />
          <WhoisContent />
        </SearchProvider>
      </SearchFiltersProvider>
    </InstantSearch>
  );
};
