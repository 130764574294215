import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { Redirect, useParams } from "react-router-dom";
import { CurrencyCodes } from "@doitintl/cmp-models";
import { Link } from "@mui/material";

import { useAlerts } from "../../Components/hooks/cloudAnalytics/alerts/useAlerts";
import { useAttributionGroups } from "../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useCloudAnalyticsMetadata } from "../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { useCustomMetrics } from "../../Components/hooks/cloudAnalytics/useCustomMetrics";
import { useDefaultReportsConfig } from "../../Components/hooks/cloudAnalytics/useDefaultReportsConfig";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { CircularProgressLoader } from "../../Components/Loader";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { PremiumFeatureCard } from "../../Components/Trial/TrialPremiumFeatureCard";
import { useAttributionsContext } from "../../Context/AttributionsContext";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { CSPCustomerID } from "../../utils/common";
import { useQuery } from "../../utils/useQuery";
import { AnalyticsPages } from "./AnalyticsPages";
import { AttributionGroups } from "./attributionGroups/AttributionGroups";
import { useDeleteAttributionGroupValidation } from "./attributionGroups/DeleteAttributionGroupValidation";
import { Attribution } from "./attributions/Attribution";
import { useDeleteAttributionValidation } from "./attributions/DeleteAttributionValidation";
import { CloudAnalyticsContextProvider } from "./CloudAnalyticsContext";
import { useReportsAndTemplates } from "./Context/useReportsAndTemplates";
import { useDataHubMetrics } from "./hooks/useDataHubMetrics";
import { useExtendedMetrics } from "./hooks/useExtendedMetrics";
import { Metric } from "./metrics";
import ReportContextWrapper from "./ReportContextWrapper";
import ReportCreate from "./reports/ReportCreate";
import { getReportTemplatesCards } from "./templateLibrary/utils";
import { getCloudFromAssets, getPremiumFeatureCardText, useCustomerIntegrations } from "./utilities";
import type { SharedSnackbarProps } from "../../Components/SharedSnackbar/types";

type Props = {
  pageId: string;
  presentationModeActive: boolean;
  assetsLoading: boolean;
};

type CloudAnalyticsParams = {
  reportId: string;
  attributionId: string;
  metricId: string;
  attributionGroupId: string;
  customerId: string;
};

export function CloudAnalytics({ pageId, assetsLoading, presentationModeActive }: Props) {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const routeMatchURL = useRouteMatchURL();
  const query = useQuery();
  const {
    reportId,
    attributionId,
    metricId,
    attributionGroupId,
    customerId: customerIdUrlParam,
  } = useParams<CloudAnalyticsParams>();
  const { customer, init: customerCtxInit, organizations } = useCustomerContext();
  const {
    filteredAttributions: filteredAttributionsByTier,
    attributions,
    attributionsLoading,
  } = useAttributionsContext();
  const sharedSnackbar = useSnackbar();
  const [reportRevertKey, setReportRevertKey] = useState(0);
  const [loadAnalyticsLabels, setLoadAnalyticsLabels] = useState(false);

  const [attributionGroups, attributionGroupLoading] = useAttributionGroups();
  const { getFeatureKey } = useTier();
  const {
    metadata: metadataSnapshots,
    loading: metadataLoading,
    hasMetadata,
    fetchMetadata,
    fetchAttributionGroupsMetadata,
    hasEKSData,
  } = useCloudAnalyticsMetadata();

  const { reports, reportTemplates, reportTemplateFavorites } = useReportsAndTemplates();

  const filteredAttributions = useMemo(
    () =>
      filteredAttributionsByTier.filter((attr) => {
        const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
        const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
        const isEKSAttribution = entitlementsKeys.includes("pdi:eks");
        const hasGKEEntitlement = entitlementsKeys.includes("pdi:gke:costAllocation");
        return (hasEKSData && isEKSAttribution) || hasGKEEntitlement || !isEntitlementAttribution;
      }),
    [filteredAttributionsByTier, getFeatureKey, hasEKSData]
  );

  const nonHiddenAttributions = useMemo(
    () => filteredAttributions.filter((a) => !a.data.hidden),
    [filteredAttributions]
  );
  const nonHiddenAttributionGroups = useMemo(
    () => attributionGroups.filter((ag) => !ag.data.hidden),
    [attributionGroups]
  );

  const handleRevertReport = useCallback(() => {
    setReportRevertKey((key) => key + 1);
  }, []);

  const showSharedSnackbar = useCallback(
    ({ message, variant = "success", autoHideDuration = 5000, action }: SharedSnackbarProps) => {
      sharedSnackbar.onOpen({
        message,
        variant,
        autoHideDuration,
        withClose: !action,
        action,
      });
    },
    [sharedSnackbar]
  );

  const handleMissingPermission = useCallback(
    (message: string) => {
      showSharedSnackbar({
        message,
        variant: "error",
        autoHideDuration: 6000,
        action: (
          <Link href={`/customers/${customer.id}/iam/users`} color="inherit" variant="button" underline="always">
            FIX
          </Link>
        ),
      });
    },
    [showSharedSnackbar, customer]
  );

  const refreshAttributionGroupsMetadata = useCallback(fetchAttributionGroupsMetadata, [
    fetchAttributionGroupsMetadata,
  ]);

  useEffect(() => {
    fetchMetadata(loadAnalyticsLabels, true);
  }, [fetchMetadata, loadAnalyticsLabels]);

  const loadAnalyticsLabelsCallback = useCallback(() => {
    setLoadAnalyticsLabels(true);
  }, []);

  const [metrics, metricsLoading] = useCustomMetrics();

  const [alertList] = useAlerts();

  const [extendedMetrics, extendedMetricsLoading] = useExtendedMetrics();

  const [datahubMetrics, datahubMetricsLoading] = useDataHubMetrics(customer.id);

  const [reportTemplatesWithVersions, dispatchReportTemplates] = reportTemplates;

  const [filteredReportTemplateFavorites, handleFavoriteReportTemplateUpdate] = reportTemplateFavorites;

  const [reportsList, filteredReports, reportsLoading] = reports;

  const reportTemplateCards = useMemo(
    () => getReportTemplatesCards(reportTemplatesWithVersions, reportsList),
    [reportTemplatesWithVersions, reportsList]
  );

  const { customerIntegrations } = useCustomerIntegrations();

  const filteredExtendedMetrics = useMemo(() => {
    const cloud = getCloudFromAssets(customer.assets);
    const visibility = customer.id === CSPCustomerID ? "csp" : "customer";

    return extendedMetrics?.metrics?.filter((metric) => {
      if (!!metric.cloud && !!cloud && metric.cloud !== cloud && !customerIntegrations?.includes(metric.cloud)) {
        return false;
      }

      return metric.visibility === "all" || metric.visibility === visibility;
    });
  }, [customer.assets, customer.id, customerIntegrations, extendedMetrics?.metrics]);

  const { defaultConfig, handleSaveDefaultReportConfig, loading: defaultConfigLoading } = useDefaultReportsConfig();

  const [DeleteAttributionValidation, handleDeleteValidationResponse] = useDeleteAttributionValidation({
    reports: filteredReports,
    attributions: nonHiddenAttributions,
    attributionGroups: nonHiddenAttributionGroups,
    alerts: alertList,
    metrics,
    organizations,
  });

  const [DeleteAttributionGroupValidation, handleDeleteAttributionGroupValidationResponse] =
    useDeleteAttributionGroupValidation({
      reports: filteredReports,
      // @ts-expect-error
      attributions: nonHiddenAttributions,
      attributionGroups: nonHiddenAttributionGroups,
      alerts: alertList,
      metrics,
      organizations,
    });

  const getTemplateIdByReportId = useCallback(
    (reportId: string) =>
      reportTemplatesWithVersions?.find((rt) => rt.template.activeReport?.id === reportId)?.template.id,
    [reportTemplatesWithVersions]
  );

  const context = useMemo(
    () => ({
      handleMissingPermission,
      showSharedSnackbar,
      extendedMetrics: filteredExtendedMetrics ?? [],
      datahubMetrics: datahubMetrics?.metrics ?? [],
      defaultReportConfig: defaultConfig,
      defaultConfigLoading,
      handleSaveDefaultReportConfig,
      handleDeleteValidationResponse,
      handleDeleteAttributionGroupValidationResponse,
      handleFavoriteReportTemplateUpdate,
      getTemplateIdByReportId,
      loadAnalyticsLabels: loadAnalyticsLabelsCallback,
    }),
    [
      handleMissingPermission,
      showSharedSnackbar,
      filteredExtendedMetrics,
      datahubMetrics?.metrics,
      defaultConfig,
      defaultConfigLoading,
      handleSaveDefaultReportConfig,
      handleDeleteValidationResponse,
      handleDeleteAttributionGroupValidationResponse,
      handleFavoriteReportTemplateUpdate,
      getTemplateIdByReportId,
      loadAnalyticsLabelsCallback,
    ]
  );

  const excludeSelectMetadataIdsSet = useMemo(() => {
    if (isDoitEmployee) {
      return new Set<string>();
    }
    return new Set(["fixed:invoice_month"]);
  }, [isDoitEmployee]);

  const premiumFeatureCardText = useMemo(() => getPremiumFeatureCardText(pageId), [pageId]);

  const AttributionGroupsDetails = useCallback(
    (draft: boolean) => {
      if (
        assetsLoading ||
        metadataLoading ||
        attributionsLoading ||
        attributionGroupLoading ||
        customerIdUrlParam !== customer.id
      ) {
        return null;
      }

      const selectedAttributionGroup = attributionGroups.find((b) => b.ref.id === attributionGroupId);
      if (!selectedAttributionGroup && !draft) {
        return <Redirect to="." />;
      }

      return (
        <AttributionGroups
          selectedAttributionGroup={selectedAttributionGroup}
          refreshAttributionGroupsMetadata={refreshAttributionGroupsMetadata}
        />
      );
    },
    [
      assetsLoading,
      attributionGroupId,
      attributionGroupLoading,
      attributionGroups,
      attributionsLoading,
      customer.id,
      customerIdUrlParam,
      metadataLoading,
      refreshAttributionGroupsMetadata,
    ]
  );

  if (!customerCtxInit) {
    return <CircularProgressLoader />;
  }

  const ReportDetails = () => {
    if (
      reportsLoading ||
      assetsLoading ||
      metadataLoading ||
      attributionsLoading ||
      metricsLoading ||
      extendedMetricsLoading ||
      datahubMetricsLoading ||
      customerIdUrlParam !== customer.id
    ) {
      return <CircularProgressLoader />;
    }

    const report = reportsList.find((r) => r.snapshot.id === reportId);

    if (!report || !hasMetadata) {
      return <Redirect to="." />;
    }

    const reportTemplateId = query.get("templateId") ?? "";
    let isFavoriteReportTemplate = false;

    if (reportTemplateId) {
      const reportTemplateWithVersion = reportTemplatesWithVersions?.find((rt) => rt.template.id === reportTemplateId);
      if (!isDoitEmployee && reportTemplateWithVersion?.template?.activeReport?.id !== reportId) {
        return <Redirect to={`/customers/${customer.id}/analytics/report-templates`} />;
      }
      isFavoriteReportTemplate = filteredReportTemplateFavorites.includes(reportTemplateId);
    }

    const defaultCurrency = report.data.config?.currency || customer.settings?.currency || CurrencyCodes.USD;

    return (
      <Fragment key={reportRevertKey}>
        <ReportContextWrapper
          report={report}
          calculatedMetrics={metrics}
          filteredExtendedMetrics={filteredExtendedMetrics}
          defaultCurrency={defaultCurrency}
          excludeSelectMetadataIds={excludeSelectMetadataIdsSet}
          handleRevertReport={handleRevertReport}
          attributionGroups={attributionGroups}
          reportTemplateId={reportTemplateId}
          isFavoriteReportTemplate={isFavoriteReportTemplate}
          reportTemplateCards={reportTemplateCards}
          dispatchReportTemplates={dispatchReportTemplates}
          datahubMetrics={datahubMetrics?.metrics ?? []}
        />
      </Fragment>
    );
  };
  const AttributionsDetails = () => {
    if (assetsLoading || metadataLoading || attributionsLoading || customerIdUrlParam !== customer.id) {
      return null;
    }

    let attribution;

    if (pageId !== "analytics:attributions:create") {
      attribution = attributions.find((attr) => attr.ref.id === attributionId);
      if (!attribution || !hasMetadata) {
        return <Redirect to="." />;
      }
    }

    const defaultCurrency = customer.settings?.currency ?? CurrencyCodes.USD;

    return (
      <Attribution
        attribution={attribution}
        excludeSelectMetadataIds={excludeSelectMetadataIdsSet}
        metadataSnapshots={metadataSnapshots}
        currency={defaultCurrency}
      />
    );
  };

  const MetricsDetails = () => {
    /* we need customerIdUrlParam !== customer.id because:
     * 1. a rerender would be triggered when the URL (with a new metricId) would be called (searching for a metrics from a different customer)
     * 2. metrics list (from the old customer) would try to find the metric with metricId (new URL)
     * 3. it will fail to find the new metric and would redirect us to the Metrics list.
     * 4. same logic applies for the other details pages
     */
    if (
      assetsLoading ||
      metadataLoading ||
      attributionsLoading ||
      metricsLoading ||
      customerIdUrlParam !== customer.id
    ) {
      return null;
    }

    const metric = metrics.find((m) => m.snapshot.id === metricId);

    if (!!metricId && !metric) {
      return <Redirect to="." />;
    }

    const defaultCurrency = customer.settings?.currency ?? CurrencyCodes.USD;

    return <Metric metric={metric} attributions={filteredAttributions} currency={defaultCurrency} />;
  };

  if (pageId === "analytics") {
    return <Redirect to={`${routeMatchURL}/reports`} />;
  }

  return (
    <CloudAnalyticsContextProvider value={context}>
      {presentationModeActive && premiumFeatureCardText && (
        <PremiumFeatureCard
          title={premiumFeatureCardText.title}
          detail={premiumFeatureCardText.details}
          tryFor={premiumFeatureCardText.tryFor}
        />
      )}
      <AnalyticsPages
        pageId={pageId}
        reports={filteredReports}
        reportsLoading={reportsLoading}
        attributionsLoading={attributionsLoading}
        attributions={nonHiddenAttributions}
        attributionGroups={nonHiddenAttributionGroups}
        alerts={alertList}
        reportTemplateCards={reportTemplateCards}
      />
      {pageId === "analytics:reports:create" && <ReportCreate />}
      {pageId === "analytics:reports:details" && ReportDetails()}
      {pageId === "analytics:attributions:create" && AttributionsDetails()}
      {pageId === "analytics:attributions:details" && AttributionsDetails()}
      {pageId === "analytics:metrics:create" && MetricsDetails()}
      {pageId === "analytics:metrics:details" && MetricsDetails()}
      {pageId === "analytics:attribution-groups:create" && AttributionGroupsDetails(true)}
      {pageId === "analytics:attribution-groups:edit" && AttributionGroupsDetails(false)}
      <DeleteAttributionValidation />
      <DeleteAttributionGroupValidation />
    </CloudAnalyticsContextProvider>
  );
}
