import React, { useState } from "react";

import { type AttributionFilter } from "@doitintl/cmp-models";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { useCloudAnalyticsTransforms } from "../hooks/cloudAnalytics/useCloudAnalyticsTransforms";

export type AllocationRuleChipsProps = {
  filter: AttributionFilter;
  nullFallback: string | null;
  title: string;
  maxChips?: number;
};

const AllocationRuleChips: React.FC<AllocationRuleChipsProps> = ({ filter, nullFallback, maxChips = 2, title }) => {
  const [showDialog, setShowDialog] = useState(false);
  const filteredValues = filter.values?.filter((v) => v && v !== nullFallback) || [];
  const showMaxChip = filteredValues.length > maxChips;
  const transforms = useCloudAnalyticsTransforms();

  const toggleDialog = () => {
    setShowDialog((prev) => !prev);
  };

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
        {filteredValues.length > 0 && (
          <>
            {filteredValues.slice(0, maxChips).map((value) => (
              <Chip
                key={value}
                label={transforms?.[filter.id]?.(value, nullFallback) ?? value}
                size="small"
                color="primary"
                variant="outlined"
              />
            ))}

            {filter.allowNull && (
              <Chip label={nullFallback ?? "[N/A]"} size="small" color="primary" variant="outlined" />
            )}

            {showMaxChip && (
              <Typography
                variant="caption"
                fontSize={12}
                color="primary"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={toggleDialog}
              >{`+${filteredValues.length - maxChips}`}</Typography>
            )}
          </>
        )}
      </Stack>

      <Dialog open={showDialog} onClose={toggleDialog} fullWidth maxWidth="sm">
        <DialogTitle>{title} Properties</DialogTitle>
        <DialogContent dividers={true}>
          <Typography variant="body2">
            This dimension consists of the following {filteredValues.length} properties:
          </Typography>
          <Box component="ul" sx={{ mt: 1, pl: 2 }}>
            {filteredValues.map((value) => (
              <li key={value}>
                <Typography variant="body2">{value}</Typography>
              </li>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllocationRuleChips;
