import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { Autocomplete, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { getCurrencySymbol } from "../../utils/common";

export type CurrencyProps = {
  value: CurrencyCode;
  onChange: (newValue: CurrencyCode) => void;
};

export function Currency({ value, onChange }: Readonly<CurrencyProps>) {
  return (
    <Autocomplete
      disableClearable
      options={Object.values<CurrencyCode>(CurrencyCodes)}
      value={value}
      data-cy="currency-selector"
      onChange={(_, newValue: CurrencyCode | null) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option} data-cy={`currency-option-${option}`}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {option}
            <Box sx={{ color: "text.secondary" }}>{getCurrencySymbol(option)}</Box>
          </Stack>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Currency"
          data-cy="Currency"
          variant="outlined"
          margin="normal"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <Box>
                  {getCurrencySymbol(value)}
                  {params.InputProps.endAdornment}
                </Box>
              ),
            },
          }}
        />
      )}
    />
  );
}
