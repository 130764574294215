import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Stack, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { type MetricWSnap } from "../../../types";
import { formatToLocaleTimestamp } from "../../../utils/common";
import { type LabelWithRef } from "../labels/types";
import { useThreeDotsMetricsMenu } from "./hooks";

const { metrics: metricTestIds } = cloudAnalytics;

type MetricRowProps = {
  row: MetricWSnap;
  labels: LabelWithRef[] | undefined;
};

export const MetricRow = ({ row, labels }: MetricRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const threeDotsMenu = useThreeDotsMetricsMenu({ row });
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  return (
    <CellsWrapper>
      <TableCell>
        <Stack spacing={1}>
          <Typography
            component={RouterLink}
            to={{
              pathname: `${routeMatchURL}/${row.snapshot.id}`,
              state: {
                prevPage: routeMatchURL, // or any other value you want to pass as state
              },
            }}
            color="inherit"
            variant="body2"
            data-cy={metricTestIds.browser.metricName}
          >
            {row.data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricOwner}>{row.data.owner}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricDescription}>{row?.data?.description}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricType}>{capitalize(row.data.type)}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricLastModified}>
          {formatToLocaleTimestamp(row.data?.timeModified)}
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricTimeCreated}>
          {formatToLocaleTimestamp(row.data?.timeCreated)}
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricThreeDotsMenu} padding="none">
          {threeDotsMenu}
        </TableCell>
      </Hide>
    </CellsWrapper>
  );
};
