import { useEffect, useMemo, useRef, useState } from "react";

// Models
import { type AttributionFilter } from "@doitintl/cmp-models";
// MUI
import { Autocomplete, type AutocompleteRenderInputParams, Checkbox, Chip, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
// Lodash
import sortBy from "lodash/sortBy";
import union from "lodash/union";

import { attributionText } from "../../../../assets/texts";
// Local imports
import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCloudAnalyticsTransforms } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { type AttributionMetadataOption as MetadataOption } from "../../../../types/Report";
import {
  createFilterOptions,
  filterBase,
  getBaseAttributionFilter,
  getBaseMetadataOption,
  getTypeString,
} from "../../utilities";
import AttributionBuilderRowControl from "./AttributionBuilderRowControl";

const useStyles = makeStyles(() => ({
  autocomplete: {
    "& .MuiInputAdornment-root": {
      "& button": {
        visibility: "hidden",
      },
    },
    "&:hover": {
      "& .MuiInputAdornment-root button": {
        visibility: "visible",
      },
    },
    "&.Mui-focused": {
      "& .MuiInputAdornment-root button": {
        visibility: "visible",
      },
    },
  },
}));

export type AttributionBuilderRowProps = {
  currentIndex: number;
  dimensionTypes: MetadataOption[];
  filtersLength: number;
  handleRowsUpdate: (update: string) => void;
  handleSelectOption: (option: MetadataOption) => void;
  onRowChange: (type: string, value: any, inverse?: boolean) => void;
  selectedDimensionOptions: string[];
  selectedType: MetadataOption;
  setFilters: (filters: AttributionFilter[] | ((prevState: AttributionFilter[]) => AttributionFilter[])) => void;
};

const AttributionBuilderRow = ({
  currentIndex,
  dimensionTypes,
  filtersLength,
  handleRowsUpdate,
  handleSelectOption,
  onRowChange,
  selectedDimensionOptions,
  selectedType,
  setFilters,
}: AttributionBuilderRowProps) => {
  const classes = useStyles();
  const transforms = useCloudAnalyticsTransforms();
  const transform = transforms?.[selectedType?.id] ?? null;
  const gridRef = useRef<HTMLDivElement>(null);
  const [initialOptionsHeight, setInitialOptionsHeight] = useState(0);
  const [buttonLayout, setButtonLayout] = useState("center");
  const optionsHeight = gridRef?.current?.getBoundingClientRect().height ?? 0;

  useMountEffect(() => {
    if (gridRef.current) {
      setInitialOptionsHeight(gridRef.current.getBoundingClientRect().height);
    }
  });

  useEffect(() => {
    if (optionsHeight > 0) {
      if (optionsHeight > initialOptionsHeight) {
        setButtonLayout("flex-start");
      } else {
        setButtonLayout("center");
      }
    }
  }, [optionsHeight, initialOptionsHeight]);

  const dimensionOptions = useMemo(() => {
    let selectedOptions = selectedType?.data?.values ?? [];
    selectedOptions = sortBy(union(selectedOptions, selectedType._filter));
    if (selectedType?.data?.nullFallback) {
      selectedOptions = union([selectedType.data.nullFallback], selectedOptions);
    }
    return selectedOptions;
  }, [selectedType]);

  const sortedDimensionTypes = useMemo(
    () =>
      dimensionTypes.toSorted((a, b) => {
        const groupA = getTypeString(a);
        const groupB = getTypeString(b);

        // First, sort by group
        const groupComparison = groupA.localeCompare(groupB, undefined, { sensitivity: "base" });

        if (groupComparison !== 0) {
          return groupComparison; // If groups are different, use this comparison
        }

        // Second, sort by another field (e.g., 'name' or any other field)
        return a.data.label.localeCompare(b.data.label, undefined, { sensitivity: "base" });
      }),
    [dimensionTypes]
  );

  const isLastRow = currentIndex === filtersLength - 1;

  const filterOptions = createFilterOptions<MetadataOption>({
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: "any",
  });

  return (
    <Grid
      ref={(el) => (currentIndex === filtersLength - 1 ? el?.scrollIntoView() : -1)}
      container
      spacing={1}
      sx={{
        alignItems: buttonLayout,
      }}
      size={12}
    >
      <Grid
        size={{
          xs: 12,
          md: 4,
          lg: 4,
        }}
      >
        <Autocomplete
          fullWidth
          options={sortedDimensionTypes}
          filterSelectedOptions={true}
          getOptionLabel={(option) => option.data.label}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => getTypeString(option)}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.data.label}
            </li>
          )}
          filterOptions={filterOptions}
          size="small"
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={option.data.key} label={option.data.label} />
            ))
          }
          onChange={(_, newValue, reason) => {
            switch (reason) {
              case "clear":
                setFilters((prevFilters) => {
                  const newFilters = prevFilters.slice();
                  newFilters.splice(currentIndex, 1, getBaseAttributionFilter());
                  return newFilters;
                });
                handleSelectOption(getBaseMetadataOption());
                break;
              case "selectOption":
                setFilters((prevFilters) => {
                  const newFilters = prevFilters.slice();
                  const fltr = filterBase(newValue as MetadataOption);
                  newFilters.splice(currentIndex, 1, fltr);
                  return newFilters;
                });
                handleSelectOption(newValue as MetadataOption);
                break;
            }
          }}
          openOnFocus
          // disable clearable when no option selected.
          disableClearable={!selectedType.id || !isLastRow}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField {...params} label={attributionText.DIMENSION_TYPE} variant="outlined" margin="dense" />
          )}
          value={selectedType}
          getOptionDisabled={(option) => option._disabled}
        />
      </Grid>
      <Grid
        ref={gridRef}
        size={{
          xs: 10,
          md: 4,
          lg: 7,
        }}
      >
        <VirtualizedAutocomplete
          className={classes.autocomplete}
          disabled={Boolean(!selectedType?.data?.values?.length) && Boolean(!selectedType.data.nullFallback)}
          disableClearable
          disableCloseOnSelect
          multiple
          ChipProps={{ sx: { maxWidth: "36% !important" } }}
          options={dimensionOptions}
          getOptionLabel={(option) => transform?.(option, selectedType.data.nullFallback) ?? option}
          onChange={(e, filters) => {
            onRowChange("values", filters);
          }}
          openOnFocus
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option} data-custom-height={12}>
              <Checkbox checked={selected} />
              {transform?.(option, selectedType.data.nullFallback) ?? option}
            </li>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => <Chip {...getTagProps({ index })} key={option} label={option} />)
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" margin="dense" label={attributionText.DIMENSION_OPTIONS} />
          )}
          size="small"
          limitTags={3}
          fullWidth
          value={selectedDimensionOptions}
        />
      </Grid>
      <Grid sx={{ pl: "2px !important" }}>
        <AttributionBuilderRowControl
          disabled={isLastRow && !selectedDimensionOptions.length}
          addMode={isLastRow}
          handleRowsUpdate={handleRowsUpdate}
        />
      </Grid>
      {isLastRow && currentIndex !== 0 && (
        <Grid sx={{ pl: "0 !important" }}>
          <AttributionBuilderRowControl
            disabled={isLastRow && currentIndex === 0}
            addMode={false}
            handleRowsUpdate={handleRowsUpdate}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AttributionBuilderRow;
