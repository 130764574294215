import { useCallback } from "react";

import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../assets/texts";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCloudflowExecutions } from "../hooks";
import CloudflowHistoryBrowser from "./CloudflowHistoryBrowser";

const CloudflowHistory = () => {
  const { cloudflowExecutions, cloudflowExecutionsLoading } = useCloudflowExecutions();
  const onNameClick = useCallback(noop, []);
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const history = useHistory();

  const returnToDashboard = (): void => {
    history.push(`/customers/${customer.id}/cloudflow`);
  };
  return (
    <>
      <Stack direction="row" sx={{ my: 1 }}>
        <IconButton aria-label="Back to CloudFlow list" onClick={returnToDashboard} sx={{ ml: -1.5 }}>
          <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography variant="h1" data-cy="title" sx={{ pt: 1, fontWeight: "fontWeightMedium" }}>
          {cloudflowTexts.HISTORY.COMPLETED}
        </Typography>
      </Stack>
      <Box
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <Box
          sx={{
            mt: -4,
          }}
        >
          <Box
            sx={{
              mb: 3,
            }}
          />
          <CloudflowHistoryBrowser
            cloudflowExecutions={cloudflowExecutions}
            loading={cloudflowExecutionsLoading}
            actions={{ onNameClick }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CloudflowHistory;
