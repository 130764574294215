import { useCallback } from "react";

import { type CloudConnectGoogleCloud, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";
import { useCustomerContext } from "../../../Context/CustomerContext";
import mixpanel from "../../../utils/mixpanel";
import { consoleErrorWithSentry } from "../../Integrations/Slack/utils";
import { type CloudConnectData } from "./types";

export function useDeleteServiceAccount() {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useAsyncLoadingFunction(
    useCallback(
      async (serviceAccount: CloudConnectData<CloudConnectGoogleCloud>) => {
        try {
          if (serviceAccount.data.categoriesStatus?.["bigquery-finops"] === 1 && serviceAccount.data.clientId) {
            await api.request({
              method: "post",
              url: `/v1/customers/${customer.id}/bq-lens/offboarding`,
              data: { clientId: serviceAccount.data.clientId },
            });
          }
          await api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/cloudconnect/google-cloud/remove`,
            data: { client_id: serviceAccount.data.clientId },
            headers: { "Content-Type": "multipart/form-data" },
          });
        } catch (error) {
          consoleErrorWithSentry(error);
          throw error;
        }

        await getCollection(CustomerModel).doc(customer.id).collection("cloudConnect").doc(serviceAccount.id).delete();

        mixpanel.track("credentials.google-cloud.remove");
      },
      [api, customer.id]
    )
  );
}
