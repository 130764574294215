import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import {
  type CustomerModel,
  type CustomerModelCloudConnectModel,
  type CustomerModelSandboxPoliciesModel,
} from "./Customer";
import { type EntityModel } from "./Entity";

type IntegrationModelFlexibleReservedInstancesModelConfig = {
  accountId: string;
  autoRenew?: Timestamp | null;
  endDate: Timestamp;
  instanceFamily: string;
  instanceType: string;
  note?: string;
  numInstances: number;
  operatingSystem: string;
  payerAccountId: string;
  region: Region;
  sizeFlexible?: boolean | null;
  startDate: Timestamp;
  tenancy: string;
};

@subCollection("flexibleReservedInstances")
export class IntegrationModelFlexibleReservedInstancesModel {
  autopilot?: AutopilotData;

  clientId!: number;

  config!: IntegrationModelFlexibleReservedInstancesModelConfig;

  createdAt!: Timestamp;

  customer!: Reference<CustomerModel>;

  email!: string;

  entity!: Reference<EntityModel>;

  execution?: "autopilot-v1" | "unmanaged" | "";

  id!: number;

  invoiceAdjustments!: {
    underUtilized: null | string;
    utilized: null | string;
  };

  metadata!: {
    customer: {
      name: string;
      primaryDomain: string;
    };
    isRenewDone?: boolean;
    isSentReminderEmail?: boolean;
  };

  normalizedUnits!: NormalizedUnits | null;

  pricing!: Pricing;

  status!: "new" | "active" | "canceled" | "pending" | "retired" | "failed";

  uid!: string;

  utilization!: { [key: string]: number };
}

@subCollection("configuration")
export class FlexsaveConfigurationModel {
  id?: string;

  GCP?: FlexsaveCloudSavingsModel;

  AWS?: AWSFlexsaveConfigurationModel;
}

@subCollection("googleKubernetesEngineTables")
export class IntegrationModelGKETablesModel {
  customer!: Reference<CustomerModel>;

  completedSuccesfully!: boolean | null;

  timeCreated!: Timestamp;

  timeFirstCompleted!: Timestamp | null;

  timeLastCompleted!: Timestamp | null;

  timeUpdated!: Timestamp;
}

@subCollection("sandboxAccounts")
export class IntegrationModelSandboxAccountModel {
  billingAccountResourceName!: string;

  budgetResourceName!: string;

  createdAt!: Timestamp;

  customer!: Reference<CustomerModel>;

  email!: string;

  policy!: Reference<CustomerModelSandboxPoliciesModel>;

  projectId!: string;

  projectNumber!: number;

  projectResourceName!: string;

  status!: SandboxStatus;

  updatedAt!: Timestamp;

  utilization!: Record<string, number>;
}

export enum SandboxStatus {
  ACTIVE = "active",
  DISABLED = "disabled",
  DELETED = "deleted",
  ALERTED = "alerted",
}

export enum Status {
  active = "active",
  disabled = "disabled",
  pending = "pending",
}

export enum Criteria {
  flexsave = "FLEXSAVE",
  sagemaker = "SAGEMAKER",
  rds = "RDS",
}

export enum PricebookStatus {
  active = "active",
  retired = "retired",
}

export type DiscountDetails = { discount?: number; effectiveDate?: Date; criteria?: string };

export enum FlexsavePayerType {
  resold = "aws-flexsave-resold",
  standalone = "aws-flexsave-standalone",
}

export enum ManagedType {
  auto = "auto",
  manual = "manual",
}

export type FlexsaveFlavour = "compute" | "rds" | "sagemaker";

export type StatusTransition<T extends FlexsaveFlavour> = {
  service: T;
  changedBy: string;
  reason: string;
  updatedAt: string;
};

type ComputeStatusTransition = StatusTransition<"compute">;

type RDSStatusTransition = StatusTransition<"rds">;

type SageMakerStatusTransition = StatusTransition<"sagemaker">;

export type FlexsavePayerConfigs = {
  customerId: string;
  accountId: string;
  type: FlexsavePayerType;
  managed?: ManagedType;
  discountDetails?: DiscountDetails[];
  lastUpdated: string;
  status: Status;
  timeEnabled?: string;
  timeDisabled?: string;
  sagemakerStatus: Status | "";
  rdsStatus: Status | "";
  maxSpend?: number;
  minSpend?: number;
  targetPercentage?: number;
  primaryDomain?: string;
  sagemakerTimeEnabled: string | null;
  sagemakerTimeDisabled: string | null;
  rdsTimeEnabled: string | null;
  rdsTimeDisabled: string | null;
  rdsTargetPercentage?: number;
  name?: string;
  friendlyName?: string;
  seasonal?: boolean;
  keepActiveEvenWhenOnCredits: boolean;
  lastStatusTransition: Array<ComputeStatusTransition | RDSStatusTransition | SageMakerStatusTransition>;
};

@subCollection("gkeClients")
export class IntegrationModelGKEClients {
  customer!: Reference<CustomerModel>;

  tables!: GkeClientsTable[];

  timestamp!: Timestamp;
}

@subCollection("cloudhealthCustomers")
export class IntegrationModelCloudhealthCustomersModel {
  customer!: string;

  disabled!: boolean;

  id!: number;

  name!: string;

  timestamp!: Timestamp;
}

@subCollection("cloudhealthPricebooks")
export class IntegrationModelCloudhealthPricebooksModel {
  assignments!: Assignment[] | null;

  bookName!: string;

  createdAt!: string;

  editable!: boolean;

  fileHash?: string;

  id!: number;

  specification!: Specification;

  updatedAt!: string;

  updatedBy!: string;

  payerId?: string;

  customerId?: string;

  customerName?: string;

  status?: PricebookStatus;
}

@subCollection("stripeCustomers")
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class IntegrationModelStripeCustomerModel {}

export type Path = {
  format?: string;
  granularity?: string;
  pathPrefix: string;
  reportName: string;
  noResource?: boolean;
};

export type CurPath = {
  paths: Path[] | null;
  state: "valid" | "invalid" | "NAN";
};

export type StandaloneOnboardingAWS = {
  accountId: string;
  savings?: StandaloneEstimationsAWS;
  completed?: boolean;
  agreedToContract?: boolean;
  selectedPriorityId?: string;
  errors?: StandaloneOnboardingErrors;
  timeActivated?: Timestamp;
  isMissingAWSRole?: boolean;
  stackCreationState?: "loading" | "success" | "failure";
  curPaths?: CurPath;
  lastUpdated?: Timestamp;
};

export type StandaloneOnboardingGCP = {
  billingAccountId: string;
  completed?: boolean;
  errors?: StandaloneOnboardingErrors;
  timeActivated?: Timestamp;
  lastUpdated?: Timestamp;
};

@subCollection("fs-onboarding")
export class FlexsaveStandAloneOnBoardingAWSModel {
  accounts?: Record<string, StandaloneOnboardingAWS>;
}

@subCollection("fs-onboarding")
export class FlexsaveStandaloneOnboardingGCPModel {
  type!: "GCP";

  orgId?: string;

  savings?: StandaloneEstimationsGCP;

  serviceAccountEmail?: string;

  completed?: boolean;

  agreedToContract?: boolean;

  selectedPriorityId?: string;

  errors?: StandaloneOnboardingErrors;

  billingAccountId?: string;
}

export type FlexsaveSageMakerReasonCantEnable = "no_spend";

@subCollection("configuration-sagemaker")
export class FlexsaveSageMaker {
  reasonCantEnable!: FlexsaveSageMakerReasonCantEnable[];

  timeEnabled!: Timestamp | null;

  savingsSummary!: {
    currentMonth: string;
    nextMonthSavings: number;
    canBeEnabledBasedOnRecommendations: boolean;
  };

  dailySavingsHistory!: Record<string, MonthSavings>;

  savingsHistory!: Record<string, MonthSavings>;
}

@subCollection("configuration-rds")
export class FlexsaveRDS {
  canBeEnabledBasedOnRecommendations?: boolean;

  reasonCantEnable!: string[];

  timeEnabled!: Timestamp | null;

  savingsSummary!: {
    currentMonth: string;
    nextMonthSavings: number;
  };

  dailySavingsHistory!: Record<string, MonthSavings>;

  savingsHistory!: Record<string, MonthSavings>;
}

@doc("flexsave")
export class FlexsaveDoc {
  totalSavings?: { AWS: FlexsaveTotalSavings };

  awsOrdersOffset!: number;

  subCollections?: {
    "configuration-rds": FlexsaveRDS;
    "configuration-sagemaker": FlexsaveSageMaker;
    configuration: FlexsaveConfigurationModel;
    "gcp-avg-usage": GCPAverageUsage;
    "gcp-global-purchase-rules": GlobalRule;
    "gcp-global-purchase-config": RulesConfig;
    "gcp-total-avg-usage": TotalAverageUsageModal;
    "flexsave-gcp-sku-prices": GCPSkuPricesModal;
    "gcp-ops-workloads": GCPWorkloadModel;
    "gcp-ops-customers-aggr": GCPCustomersAggregationModel;
    "customer-savings-plans": CustomerSavingsPlansModel;
    "gcp-operations": GCPOperations;
  };
}

export class PricebooksAssignmentsModel {
  assigendAt?: Timestamp;

  assignedAt?: Timestamp;

  contractId!: string;

  customerId!: string;

  customerName!: string;

  payerId!: string;

  pricebookId!: number;
}

@doc("cloudhealth")
export class CloudHealthDoc {
  subCollections?: {
    cloudhealthPricebooks: IntegrationModelCloudhealthPricebooksModel;
    pricebooksAssignments: PricebooksAssignmentsModel;
    cloudhealthCustomers: IntegrationModelCloudhealthCustomersModel;
  };
}

export type GcpRecommendation = {
  category: "COST";
  currentInstance: string;
  description: string;
  duration: "2592000s";
  etag: string;
  instance: string;
  lastRefreshTime: Timestamp;
  name: string;
  projectId: string;
  projectNumber: number;
  recommenderSubtype: "CHANGE_MACHINE_TYPE";
  replaceResource: string;
  saving: string;
  state: "ACTIVE" | "CLAIMED" | "DISMISSED" | "FAILED" | "SUCCEEDED";
  zone: string;
};

@collection("recommender")
export class RecommenderModel {
  customer!: Reference<CustomerModel>;

  isServiceEnabled!: boolean;

  recommendations!: GcpRecommendation[] | null;

  errorMsg?: string;
}

@doc("google-cloud")
export class GoogleCloudDoc {
  subCollections?: {
    sandboxAccounts: IntegrationModelSandboxAccountModel;
    recommender: RecommenderModel;
    googleKubernetesEngineTables: IntegrationModelGKETablesModel;
  };
}

@subCollection("standaloneOnboarding")
export class BillingStandaloneOnboardingGCPModel {
  type!: "GCP";

  billingAccountId?: string;

  serviceAccountEmail?: string;

  agreedToContract?: boolean;

  accounts?: Record<string, StandaloneOnboardingGCP>;
}
@subCollection("standaloneOnboarding")
export class BillingStandaloneOnboardingAWSModel {
  accounts?: Record<string, StandaloneOnboardingAWS>;
}

@doc("billing-standalone")
export class BillingStandaloneDoc {
  subCollections?: {
    standaloneOnboarding: BillingStandaloneOnboardingGCPModel | BillingStandaloneOnboardingAWSModel;
  };
}

@doc("flexsave-standalone")
export class FlexsaveStandaloneDoc {
  subCollections?: {
    "fs-onboarding": FlexsaveStandAloneOnBoardingAWSModel | FlexsaveStandaloneOnboardingGCPModel;
  };
}

type Party = {
  id: string;
  type: string;
};

export interface HandshakeModel {
  action: string;
  arn: string;
  customer: Reference<CustomerModel>;
  entity: Reference<EntityModel>;
  expirationTimestamp: Timestamp;
  id: string;
  parties: Party[];
  payerAccount: {
    displayName: string;
    id: string;
  };
  requestedTimestamp: Timestamp;
  requester: string;
  state: string;
  target: string;
  timestamp: Timestamp;
  visible: boolean;
}

export class AccountModel {
  id!: string;

  name!: string;

  status!: string;

  arn!: string;

  email!: string;

  joinedMethod!: string;

  joinedTimestamp!: Timestamp;

  timestamp!: Timestamp;

  payerAccount?: Reference<PayerAccount>;
}

type PayerAccount = {
  id: string;
  displayName: string;
};

@doc("amazon-web-services")
export class AmazonWebServicesDoc {
  subCollections?: {
    handshakes: HandshakeModel;
    accounts: AccountModel;
    flexibleReservedInstances: IntegrationModelFlexibleReservedInstancesModel;
  };
}

@collection("stripeCustomers")
class StripeCustomerModel {
  email!: string;

  id!: string;

  livemode!: boolean;

  metadata!: {
    customer_id?: string;
    customer_name?: string;
    entity_id?: string;
    priority_id: string;
  };

  timestamp!: Timestamp;
}

@doc("stripe")
export class StripeDoc {
  subCollections?: {
    stripeCustomers: StripeCustomerModel;
  };
}

type UserMessagingToken = {
  displayName?: string;
  email: string;
  token: string;
  topics: string[];
  timestamp: Timestamp;
};

class MessagingDoc {
  subCollections?: {
    tokens: UserMessagingToken;
  };
}

@collection("integrations")
export class IntegrationModel {
  docs?: {
    flexsave: FlexsaveDoc;
    cloudhealth: CloudHealthDoc;
    "google-cloud": GoogleCloudDoc;
    "flexsave-standalone": FlexsaveStandaloneDoc;
    "billing-standalone": BillingStandaloneDoc;
    "amazon-web-services": AmazonWebServicesDoc;
    stripe: StripeDoc;
    fcm: MessagingDoc;
    zapier: ZapierDoc;
    "k8s-metrics": K8SMetricsModel;
    zendesk: ZendeskModel;
    fsk8s: FSK8SDoc;
  };
}

type CommentModel = {
  ticket_id: number;
};

@doc("zendesk")
export class ZendeskModel {
  timeZones!: Map<string, string>;

  subCollections?: {
    ticketFields: TicketFieldsModel;
    comments: CommentModel;
  };
}

export type ZendeskCategoryModel = { platforms: string[]; title: string; value: string };
export type ZendeskPlatformModel = {
  asset: string;
  helperText: string;
  label: string;
  order: number;
  title: string;
  value: string;
  resourceDisabled?: boolean;
};

export type TicketFieldsModelPlatform = {
  values: ZendeskPlatformModel[];
};

export type TicketFieldsModelCategories = {
  values: ZendeskCategoryModel[];
};

type TicketFieldsModel = {
  docs: {
    categories: TicketFieldsModelCategories;
    platforms: TicketFieldsModelPlatform;
  };
};

export interface AutopilotData {
  mtdFlexRILineUnits: number;
  mtdFlexRIPenalty: number;
  mtdFlexRISavings: number;
  mtdFlexRIUtilization: number;
  mtdNonFlexRIUtilization: number;
  utilization: { [key: string]: { [key: string]: number } };
}

export interface Pricing {
  discount?: number;
  flexible: number;
  flexibleNormalized: number;
  onDemand: number;
  onDemandNormalized: number;
  percentage: number;
  reserved: number;
  reservedNormalized: number;
  savingsPerHour: number;
  savingsPerHourNormalized: number;
}

export interface NormalizedUnits {
  factor: number;
  hours: number;
  total: number;
  underUtilized: number;
  unitsPerDay: number;
  unitsPerHour: number;
  utilized: number;
}

export type PurchaseStatus =
  | "pending"
  | "in-progress"
  | "optimized"
  | "error"
  | "covered"
  | "purchase-required"
  | "purchased"
  | "waiting-for-optimizer"
  | "waiting-for-recommendations"
  | "not-enough-data"
  | "refreshing"
  | "rejected";

export type OptInDetails = {
  timeOptIn: Timestamp;
  optInBy: string;
};

export type WorkloadDetails = {
  billingAccountId: string;
  region: string;
  hardware: string;
  type: string;
  currentDoitCoverage: number;
  currentCustomerCoverage: number;
  currentOverallCoverage: number;
  usageOnDemand: number;
};

export type TotalUsageStatistics = {
  totalCuds: number;
  coverage: number;
  doitCuds: number;
  customerShare: number;
};

export type TotalAverageUsageModal = {
  id: string;
  familyType: string;
  hardware: string;
  region: string;
  totalOnDemand: number;
  totalCustomers: number;
} & Omit<TotalUsageStatistics, "customerShare">;

export type GCPSkuPricesModal = {
  cud1Yr: number;
  cud3Yr: number;
  description: string;
  familyType: string;
  hardware: string;
  price: number;
  region: string;
  skuId: string;
  usageType: string;
};

export type PlanDetails = WorkloadDetails & {
  price: number;
  googleRecommendations: number;
};

export type DoneDetails = {
  total: number;
  success: number;
  fail: number;
};

export type PurchasePlan = {
  timeCreated: Timestamp;
  lastRefreshTime: Timestamp;
  timeUpdated: Timestamp;
  timeApproved?: Timestamp;
  status: PurchaseStatus;
  refreshing: boolean;
  errorMessage?: string;
  approvedBy: string;
  cost: number;
  plan: PlanDetails[];
  doneDetails: DoneDetails;
  currentDoitCoverage: number;
  currentOverallCoverage: number;
};

export type GCPAverageUsage = {
  workloads: WorkloadDetails[];
};

@collection("gcp-global-purchase-config")
export class RulesConfig {
  docs?: {
    regions: Region;
    familyTypes: FamilyType;
  };
}

type Region = {
  regions: string[];
};

type FamilyType = {
  familyTypes: string[];
};

export type BaseRule = {
  id?: string;
  region: string[];
  familyType: string[];
  hardware: string[];
  ruleCoverage: number;
};

export type GlobalRule = BaseRule & {
  timeLastUpdate: Timestamp;
  updatedBy: string;
};

export type FlexsaveOperations = {
  primaryDomain: string;
  maxCoverageConsideration: number;
  customerType: string;
  optIn: OptInDetails;
  purchase: PurchasePlan;
  targetCoverages?: number[];
  enableOverrideGlobalRules?: boolean[];
};

export type MonthSavings = {
  savings: number;
  onDemandSpend: number;
  totalOnDemandSpend?: number;
  savingsRate?: number;
};

export type TotalSavings = {
  savings: number;
  savingsRate: number;
};

export type CommitTypeRaw = "on-demand" | "commited";

export type FlexsaveCloudSavingsModel = {
  enabled: boolean;
  disableNotified?: boolean;
  reasonCantEnable?: string;
  savingsSummary: {
    annualNetRevenueEstimation: number;
    currentMonth: {
      month: string;
      projectedSavings: number;
    };
    nextMonth: MonthSavings;
    flexsaveEligibleUsageDays?: number;
  };
  savingsHistory: Record<string, MonthSavings>;
  operations?: FlexsaveOperations;
  commitType?: CommitTypeRaw;
};

export type ReasonCantEnableAWS =
  | ""
  | "no contract"
  | "no spend"
  | "low spend"
  | "no assets"
  | "no billing profile"
  | "other"
  | "cloudhealth not configured"
  | "no spend in over thirty days"
  | "aws activate credits";

export type AWSFlexsaveConfigurationModel = {
  timeDisabled: Timestamp | null;
  timeEnabled: Timestamp | null;
  reasonCantEnable: ReasonCantEnableAWS;
  enabled: boolean;
  dailySavingsHistory?: Record<string, MonthSavings>;
  savingsSummary: null | {
    currentMonth: {
      month: string;
    };
    nextMonth: MonthSavings;
  };
  savingsHistory: Record<string, MonthSavings> | null;
  hasActiveResold?: boolean;
};

export type Assignment = {
  accountAssignments: AccountAssignment[];
  createdAt: string;
  customerId: number;
  customerName: string;
  id: number;
  pricebookId: number;
  updatedAt: string;
};

export type AccountAssignment = {
  billingAccountOwnerId: string;
  billingAccountOwnerIds: string[] | null;
  customerId: number;
  id: number;
  pricebookAssignmentId: number;
};

export type Specification = {
  comment: string;
  createdBy: string;
  date: string;
  ruleGroups: RuleGroup[] | null;
};

export type RuleGroup = {
  billingRules: BillingRule[];
  enabled: boolean;
  startDate: string;
};

export type BillingRule = {
  basicBillingRule: BasicBillingRule;
  includeDataTransfer: boolean;
  name: string;
  product: Product;
};

export type BasicBillingRule = {
  billingAdjustment: number;
  billingRuleType: string;
};

export type Product = {
  lineItemDescription?: LineItemDescription[];
  productName: string;
  endDate?: string;
  startDate?: string;
  usageType?: string[];
  operation?: string[];
  region?: string[];
};

export type LineItemDescription = {
  contains?: string;
  startsWith?: string;
  matchesRegex?: string;
};

export type GkeClientsTable = {
  dataset: string;
  location: string;
  project: string;
};

export type StandaloneEstimationsGCP = {
  monthlySavings: number;
  annualSavings: number;
};

export type StandaloneOnboardingErrorType =
  | "general"
  | "empty_savings"
  | "insufficient_permissions"
  | "linked_account"
  | "incorrect_cur_file";

export type StandaloneOnboardingError = {
  type: StandaloneOnboardingErrorType;
  message: string;
  timeLastUpdated: Timestamp;
};

export type StandaloneOnboardingErrors = {
  savings?: StandaloneOnboardingError;
  billingProfile?: StandaloneOnboardingError;
  initOnboarding?: StandaloneOnboardingError;
  contractAgreement?: StandaloneOnboardingError;
  activation?: StandaloneOnboardingError;
};

export type StandaloneEstimationsAWS = {
  estimatedSavings: number;
  lastMonthComputeSpend: number;
};

export type FlexsaveTotalSavings = {
  customersNumber: number;
  totalSavings: number;
};

export type PurchaseRisk = {
  causes: {
    customerTotalCost?: number;
    customerShare?: number;
    newTotalCoverage?: number;
    excludeCustomerFromBulk?: boolean;
  };
  approvedAt?: Timestamp;
  approvedBy?: string;
  rejectedAt?: Timestamp;
  rejectedBy?: string;
};

export type GCPWorkloadModel = {
  id: string;
  billingAccountId: string;
  customerId: string;
  familyType: string;
  hardware: string;
  targetCoverage?: number;
  doitRecommendation?: {
    unitCost: number;
    recommendation: number;
    timeUpdated: Timestamp;
  };
  optimizerStats?: {
    currentCudCoverage: number;
    onDemandBaseline: number;
  };
  lastPurchase?: {
    timeExecuted: Timestamp;
    error: string;
  };
  purchaseStats: {
    currentCudCoverage: number;
    onDemandBaseline: number;
    currentDoitCudCoverage: number;
    currentCustomerCudCoverage: number;
  };
  purchasePlan?: {
    amount: number;
    targetCoverage: number;
    cost: number;
    risks?: PurchaseRisk;
    newCustomerShare: number;
    newGlobalDoitCoverage: number;
    newGlobalTotalCoverage: number;
  };
  region: string;
  status: PurchaseStatus;
  timeUpdated?: Timestamp;
  forceTargetCoverage: boolean;
};

export type GCPOpsPageState = {
  bulkRefreshing: boolean;
  excludeCustomersBulkChangedAt: Timestamp;
  latestBulkRefresh: Timestamp;
};

export type GCPBillingProcessState = {
  googleExportDelay: boolean;
  googleExportDelayUpdated: Timestamp;
};

@collection("gcp-operations")
export class GCPOperations {
  docs?: {
    opsPageState: GCPOpsPageState;
    billingProcessState: GCPBillingProcessState;
  };
}

export type GCPCustomersAggregationModel = {
  customerId: string;
  targetCoverage?: number;
  purchaseStats?: {
    currentCudCoverage: number;
  };
  optimizerStats?: {
    currentCudCoverage: number;
  };
  flexCuds?: {
    activeFlexcuds: number;
  }[];
  forceTargetCoverage: boolean;
  bulkPurchase?: {
    excludeCustomerFromBulk: boolean;
    timeUpdated: Timestamp;
  };
};

export type CustomerSavingsPlansModel = {
  savingsPlans: Array<CustomerSavingsPlan>;
};

export type CustomerSavingsPlan = {
  savingsPlanID: string;
  paymentOption: string;
  type: string;
  term: string;
  commitment: number;
  mtdSavings: number;
  expirationDate: Timestamp;
};

@doc("zapier")
export class ZapierDoc {
  subCollections?: {
    webhooks: ZapierWebhookModel;
  };
}

@subCollection("webhooks")
class ZapierWebhookModel {
  userEmail!: string;

  customer!: Reference<CustomerModel>;

  itemId!: string;

  eventType!: string;

  targetUrl!: string;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;
}

@doc("k8s-metrics")
export class K8SMetricsModel {
  subCollections?: {
    eks: EKSMetrics;
  };
}

export type EKSMetrics = {
  accountId: string;
  awsSplitEnabled?: boolean;
  bucketLastMetricsData?: Timestamp;
  bucketName: string;
  bucketRegion: string;
  bucketStatus?: "running" | "stopped";
  cloudFormationStatus?: "deleted" | "success";
  cloudFormationUpdateTime?: Date;
  clusterName: string;
  clusterOIDCIssuerURL?: string;
  currentDeploymentId?: string;
  customerId: string;
  customerType?: "resold" | "standalone";
  deployedAt?: Timestamp;
  deploymentMethod?: "helm" | "kubectl" | "terraform";
  desiredDeploymentId: string;
  isEc2Cluster?: boolean;
  isManual: boolean;
  payerAccountId: string;
  payerTables?: string[] | null;
  region: string;
  verificationError?: string;

  subCollections: {
    deployments: EKSMetricsDeployments;
  };
};

@subCollection("deployments")
export class EKSMetricsDeployments {
  awsRoleName!: string;

  bucketName!: string;

  bucketRegion!: string;

  createdAt?: Timestamp;

  kubeStateImage!: string;

  kubeStateRoleName!: string;

  namespace!: string;

  otelImage!: string;

  otelRoleName!: string;

  template!: string;
}

export enum ClusterType {
  GKE = "GKE",
  EKS = "EKS",
  SELF_MANAGED = "SelfManaged",
}

export enum ClusterConnectionStatus {
  HEALTHY = "healthy",
  UNHEALTHY = "unhealthy",
  UNKNOWN = "unknown",
}

export enum ClusterDiscoveryStatus {
  HEALTHY = "healthy",
  DELETED = "deleted",
  NONE = "none",
}

@subCollection("fsk8sClusters")
export class Cluster {
  id!: string;

  type!: ClusterType;

  projectId!: string;

  region!: string;

  name!: string;

  customerRef!: Reference<CustomerModel>;

  cloudConnectRef!: Reference<CustomerModelCloudConnectModel>;

  connectionStatus!: ClusterConnectionStatus;

  connectionStatusUpdated!: Timestamp;

  connectionErrors!: Record<string, string>;

  kubernetesControlPlaneVersion!: string;

  discoveryStatus!: ClusterDiscoveryStatus;

  discoveryStatusUpdated!: Timestamp;

  host!: string;

  caCertBase64!: string;

  serviceAccountSecretId!: string;

  isActivated!: boolean;

  isPrivate!: boolean;

  features!: Record<KubernetesFeatureType, ClusterKubernetesFeature>;
}

export type KubernetesFeatureType = "cloudflow" | "fsk8s";

export class ClusterKubernetesFeature {
  isActivated!: boolean;

  issues!: string[];

  name!: string;

  status!: KubernetesFeatureStatus;

  statusUpdated!: Timestamp;
}

@subCollection("fsk8sFeatures")
export class KubernetesFeature {
  id!: string;

  name!: string;

  description!: string;

  requirements!: KubernetesFeatureRequirement[];

  featureFlag!: string;
}

@subCollection("fsk8sFeatureRequirements")
export class KubernetesFeatureRequirement {
  resource!: string;

  verb!: string;

  namespace?: string;

  group?: string;

  version?: string;

  subresource?: string;

  name?: string;
}

export enum KubernetesFeatureStatus {
  HEALTHY = "healthy",
  PARTIAL = "partial",
  UNHEALTHY = "unhealthy",
  UNKNOWN = "unknown",
}

export class ClusterWithCloudConnect extends Cluster {
  cloudConnect?: CustomerModelCloudConnectModel;
}

@doc("fsk8s")
export class FSK8SDoc {
  subCollections?: {
    fsk8sClusters: Cluster;
    fsk8sFeatures: KubernetesFeature;
  };
}
