import { type Dispatch, type SetStateAction, useCallback, useMemo, useState } from "react";

import { TextField } from "@mui/material";
import debounce from "lodash/debounce";

import { formulaFieldText } from "../../../assets/texts";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";

export type FormulaState = {
  value: string;
  valid: boolean;
  autocompleteOn: boolean;
  shouldRun: boolean;
};

type VariablesObject = { [key: string]: number };

export enum formulaElementType {
  operator = "isOperator",
  variable = "isVariable",
  negation = "NOT",
  openingParenthesis = "(",
  closingParenthesis = ")",
  unspecified = "unspecified",
}

type FormulaFieldProps = {
  formulaState: FormulaState;
  setFormulaState: Dispatch<SetStateAction<FormulaState>>;
  disabled: boolean;
  variables: string[];
  label?: string;
};

const formulaPattern = /^[a-zA-Z()\s]+$/;

const getType = (val: string, variablesObj: VariablesObject) => {
  const operators = new Set().add("AND").add("OR");

  if (operators.has(val)) {
    return formulaElementType.operator;
  } else if (variablesObj[val] >= 0) {
    return formulaElementType.variable;
  } else if (val === formulaElementType.negation) {
    return formulaElementType.negation;
  } else if (val === formulaElementType.openingParenthesis) {
    return formulaElementType.openingParenthesis;
  } else if (val === formulaElementType.closingParenthesis) {
    return formulaElementType.closingParenthesis;
  }
  return formulaElementType.unspecified;
};

const checkMissingVars = (variablesObj: VariablesObject) => {
  const vars: string[] = [];

  for (const [key, value] of Object.entries(variablesObj)) {
    if (value === 0) {
      vars.push(key);
    }
  }

  if (vars.length) {
    return formulaFieldText.FORMULA_MISSING_VAR(vars);
  }

  return "";
};

const validateParentheses = (formula: string): string => {
  let i = 0;
  let symbolsBetween = true;

  for (const symbol of formula) {
    switch (symbol) {
      case formulaElementType.openingParenthesis:
        i++;
        symbolsBetween = false;
        break;
      case formulaElementType.closingParenthesis:
        if (!symbolsBetween) {
          return formulaFieldText.INVALID_FORMULA;
        }
        i--;
        break;
      default:
        if (symbol !== " ") {
          symbolsBetween = true;
        }
        break;
    }

    if (i < 0) {
      return formulaFieldText.NO_OPENING_PARENTHESIS;
    }
  }

  if (i > 0) {
    return formulaFieldText.NO_CLOSING_PARENTHESIS;
  }

  return "";
};

const checkElementWithPrevious = (elem: string, prevElement: string, variablesObj: VariablesObject): string => {
  const elemType = getType(elem, variablesObj);
  const prevElementType = getType(prevElement, variablesObj);

  switch (elemType) {
    case formulaElementType.variable:
      if (
        prevElementType === formulaElementType.variable ||
        prevElementType === formulaElementType.closingParenthesis
      ) {
        return formulaFieldText.INVALID_FORMULA;
      }
      variablesObj[elem]++;
      break;
    case formulaElementType.operator:
      if (
        prevElementType === formulaElementType.operator ||
        prevElementType === formulaElementType.negation ||
        prevElementType === formulaElementType.openingParenthesis
      ) {
        return formulaFieldText.INVALID_FORMULA;
      }
      break;
    case formulaElementType.negation:
      if (
        prevElementType === formulaElementType.variable ||
        prevElementType === formulaElementType.closingParenthesis
      ) {
        return formulaFieldText.INVALID_FORMULA;
      }
      break;
    case formulaElementType.openingParenthesis:
      if (prevElementType === formulaElementType.variable) {
        return formulaFieldText.INVALID_FORMULA;
      }
      break;
    case formulaElementType.closingParenthesis:
      if (prevElementType === formulaElementType.operator || prevElementType === formulaElementType.negation) {
        return formulaFieldText.INVALID_FORMULA;
      }
      break;
    default:
      return formulaFieldText.INVALID_FORMULA;
  }

  return "";
};

const validateFormulaSyntax = (formula: string, variables: string[]): string => {
  const formulaWithSpaces = formula.replace(/[()]/g, (val) =>
    val === formulaElementType.openingParenthesis ? `${val} ` : ` ${val}`
  );
  const formulaArray = formulaWithSpaces.split(/\s/).filter(Boolean);
  const variablesObj: VariablesObject = variables.reduce((a, v) => ({ ...a, [v]: 0 }), {});

  const firstElem = formulaArray[0];
  const firstElemType = getType(firstElem, variablesObj);

  if (firstElemType === formulaElementType.variable) {
    variablesObj[firstElem]++;
  } else if (firstElemType === formulaElementType.operator || firstElemType === formulaElementType.unspecified) {
    return formulaFieldText.INVALID_FORMULA;
  }

  for (let i = 1; i < formulaArray.length; i++) {
    const errorText = checkElementWithPrevious(formulaArray[i], formulaArray[i - 1], variablesObj);
    if (errorText) {
      return errorText;
    }
  }

  const lastElement = formulaArray[formulaArray.length - 1];
  const lastElementType = getType(lastElement, variablesObj);

  if (lastElementType === formulaElementType.operator || lastElementType === formulaElementType.negation) {
    return formulaFieldText.INVALID_FORMULA;
  }

  return checkMissingVars(variablesObj);
};

export const FormulaField = ({ formulaState, setFormulaState, disabled, variables, label }: FormulaFieldProps) => {
  const { value: formula, valid: isValid, autocompleteOn } = formulaState;
  const [errorMessage, setErrorMessage] = useState("");

  const updateFormulaState = useCallback(
    (value: string, error: string) => {
      setErrorMessage(error);
      setFormulaState((prevState: FormulaState) => ({
        ...prevState,
        value,
        valid: !error,
        shouldRun: true,
      }));
    },
    [setFormulaState]
  );

  const validateFormula = useCallback(
    (formula: string) => {
      let errorText = validateParentheses(formula);
      if (errorText) {
        updateFormulaState(formula, errorText);
        return;
      }

      errorText = validateFormulaSyntax(formula, variables);
      updateFormulaState(formula, errorText);
    },
    [variables, updateFormulaState]
  );

  const validateFormulaDebounced = useMemo(
    () =>
      debounce((formula: string) => {
        validateFormula(formula);
      }, 1000),
    [validateFormula]
  );

  useUpdateEffect(() => {
    if (!variables.length) {
      return;
    }

    if (autocompleteOn) {
      let newValue = variables[0];
      if (variables.length > 1) {
        for (let i = 1; i < variables.length; i++) {
          newValue += ` AND ${variables[i]}`;
        }
      }
      setFormulaState((prevState: FormulaState) => ({ ...prevState, value: newValue, valid: true, shouldRun: true }));
    } else {
      validateFormula(formula.toUpperCase());
    }
  }, [autocompleteOn, formula, setFormulaState, validateFormula, variables]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (value !== "" && !formulaPattern.test(value)) {
        return;
      }

      setFormulaState((prevState: FormulaState) => ({
        ...prevState,
        value,
        valid: false,
        autocompleteOn: false,
      }));
      setErrorMessage("");
      validateFormulaDebounced(value.toUpperCase());
    },
    [setFormulaState, validateFormulaDebounced]
  );

  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      size="small"
      required
      error={!isValid && !!errorMessage}
      value={formula}
      onChange={handleChange}
      helperText={errorMessage}
      disabled={disabled}
      label={label}
      sx={({ palette }) => ({ backgroundColor: palette.general.backgroundDefault })}
      slotProps={{
        htmlInput: {
          "data-cy": "formulaTextField",
          sx: { textTransform: "uppercase" },
          autoComplete: "off",
        },
      }}
    />
  );
};
