import { type KubernetesFeature } from "@doitintl/cmp-models";
import { Box, Checkbox, CircularProgress, Divider, Stack, Typography } from "@mui/material";

import { useKubernetesFeatures } from "../../../hooks";

interface Fsk8sFeatureSelectorProps {
  selectedFeatures: KubernetesFeature[];
  onSelect: (features: KubernetesFeature[]) => void;
}

const Fsk8sFeatureSelector = ({ onSelect, selectedFeatures }: Fsk8sFeatureSelectorProps) => {
  const [features, featuresAreLoading] = useKubernetesFeatures();

  const selectedKeys = selectedFeatures.map((f) => f.id);

  const handleToggleFeature = (featureId: string) => () => {
    if (!features) return;

    const newSelectedFeatures: KubernetesFeature[] = selectedKeys.includes(featureId)
      ? selectedFeatures.filter((f) => f.id !== featureId)
      : [...selectedFeatures, features.find((f) => f.id === featureId)!];

    onSelect(newSelectedFeatures);
  };

  if (featuresAreLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack>
      {features?.map((feature) => (
        <Box key={feature.id}>
          <Box sx={{ display: "flex", padding: 2, gap: 1 }}>
            <Box sx={{ width: 40, alignContent: "start", justifyContent: "center", display: "flex" }}>
              <Checkbox onChange={handleToggleFeature(feature.id)} checked={selectedKeys.includes(feature.id)} />
            </Box>
            <Stack>
              <Typography variant="body2">{feature.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {feature.description}
              </Typography>
            </Stack>
          </Box>
          <Divider />
        </Box>
      ))}
    </Stack>
  );
};

export default Fsk8sFeatureSelector;
