import { type ReactNode } from "react";

import { CardHeader } from "@mui/material";

type NodeCardHeaderProps = {
  avatar: ReactNode;
  subheader: string;
  title: string;
  action?: ReactNode;
};

export const NodeCardHeader = ({ action, title, avatar, subheader }: NodeCardHeaderProps) => (
  <CardHeader
    avatar={avatar}
    subheader={subheader}
    title={title}
    titleTypographyProps={{
      variant: "body2",
      textDecoration: "none",
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 250,
    }}
    subheaderTypographyProps={{ variant: "caption" }}
    action={action}
    sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
  />
);
