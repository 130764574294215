import { type ComponentType } from "react";

import { CloudFlowProvider, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { type FormikProps, useFormikContext } from "formik";

import { type GCPPermissionConfigValues, type PermissionConfigValues } from "../ConfigurationPanel/hooks";
import AWSPermissionForm from "./AWSPermissionForm";
import GCPPermissionForm from "./GCPPermissionForm";

const providerComponents: Record<
  CloudFlowProvider,
  ComponentType<{
    inputModel: StructureApiServiceModelDescriptor<Member>;
    formikProps: FormikProps<PermissionConfigValues>;
    resetPermissions: () => void;
  }>
> = {
  [CloudFlowProvider.AWS]: () => <AWSPermissionForm />,
  [CloudFlowProvider.GCP]: ({ inputModel, formikProps, resetPermissions }) => (
    <GCPPermissionForm
      resetPermissions={resetPermissions}
      inputModel={inputModel}
      formikProps={formikProps as FormikProps<GCPPermissionConfigValues>}
    />
  ),
  [CloudFlowProvider.DoiT]: () => <></>,
};

const CloudSpecificPermissionForm = ({
  provider,
  inputModel,
  resetPermissions,
}: {
  provider: CloudFlowProvider;
  inputModel: StructureApiServiceModelDescriptor<Member>;
  resetPermissions: () => void;
}) => {
  const formikProps = useFormikContext();

  const ProviderFormComponent = providerComponents[provider];

  return ProviderFormComponent ? (
    <ProviderFormComponent
      resetPermissions={resetPermissions}
      inputModel={inputModel}
      formikProps={formikProps as FormikProps<PermissionConfigValues>}
    />
  ) : null;
};

export default CloudSpecificPermissionForm;
