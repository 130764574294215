import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

export enum ZendeskTicketStatus {
  NEW = "new",
  OPEN = "open",
  PENDING = "pending",
  HOLD = "hold",
  SOLVED = "solved",
  CLOSED = "closed",
}

export enum ZendeskPriority {
  URGENT = "urgent",
  HIGH = "high",
  NORMAL = "normal",
  LOW = "low",
}

export type SatisfactionRating = {
  comment: string;
  rating: string;
};

export type Assignee = {
  time_zone: string;
  locale: string;
  language: string;
  id: string;
  organization: {
    tags: string[];
    id: string;
    custom_fields: {
      SN_Org_Data: string;
      Notes: string;
      sn_notes: string;
    };
    external_id: string;
    name: string;
  };
  custom_fields: {
    SN_User_Data: string;
    agent_hq: string;
    mute_slack_reminders: string;
    enable_zensim: string;
    sn_notes: string;
  };
  tags: string[];
  email: string;
};

@subCollection("tickets")
export class SupportZendeskTicketModel {
  customerRef!: Reference<CustomerModel>;

  firestoreAddedDate!: Timestamp;

  firestoreUpdatedDate!: Timestamp;

  sandbox!: boolean;

  ticket!: {
    id: string;
    subject: string;
    description: string;
    status: ZendeskTicketStatus;
    priority: ZendeskPriority;
    created_at: Timestamp;
    updated_at: Timestamp;
    requester_id: number;
    requester_email: string;
    assignee_id: number | null;
    organization_id: number;
    collaborator_ids: number[];
    email_cc_ids: number[];
    is_public: boolean;
    tags: string[];
    fields: Record<string, string>;
    satisfaction_rating: SatisfactionRating | null;
    assignee: Assignee;
  };

  zendeskId!: string;
}

@collection("support")
export class SupportZendeskModel {
  subCollections?: {
    tickets: SupportZendeskTicketModel;
  };
}
