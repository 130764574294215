import capitalize from "lodash/capitalize";

import { globalText, metricText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type DataColumn } from "../../../types/FilterTable";
import { type MetricWSnap } from "../../../types/Metric";

export const metricItemDescriptors: FilterTableItemDescriptor<MetricWSnap>[] = [
  {
    value: "data.name",
    headerText: metricText.TABLE_HEADERS.NAME,
    tooltip: metricText.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterOptions: {
      comparators: ["==", "!=", "contains"],
    },
    filterType: "string",
  },
  {
    value: "data.owner",
    headerText: metricText.TABLE_HEADERS.OWNER,
    tooltip: metricText.TABLE_HEADERS.OWNER,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "string",
  },
  {
    value: "data.description",
    headerText: metricText.TABLE_HEADERS.DESCRIPTION,
    tooltip: metricText.TABLE_HEADERS.DESCRIPTION,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterOptions: {
      comparators: ["contains"],
    },
    filterType: "string",
  },
  {
    value: "data.type",
    headerText: metricText.TABLE_HEADERS.TYPE,
    tooltip: metricText.TABLE_HEADERS.TYPE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterOptions: {
      toOption: (value: string) => ({ value, label: capitalize(value) }),
    },
  },
  {
    value: "data.timeModified",
    headerText: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
    filterType: "date",
  },
  {
    value: "data.timeCreated",
    headerText: globalText.TIME_CREATED,
    tooltip: globalText.TIME_CREATED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    hiddenByDefault: true,
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
    filterType: "date",
  },
  // 3 dots menu placeholder
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
];

const additionalFilters: DataColumn<MetricWSnap>[] = [{ comparators: ["=="], label: "ID", path: "snapshot.id" }];

const { headerColumns, filters: baseFilters } = generateHeadersAndFilters(metricItemDescriptors);
const filters = [...additionalFilters, ...baseFilters];

export { filters, headerColumns };
