import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../Components/FilterTable/filterTableUtils";
import { type DatasetItem } from "./DataHubSources";

export const datahubSourceItemDescriptors: FilterTableItemDescriptor<DatasetItem>[] = [
  {
    value: "dataset",
    headerText: datahubTxt.TABLE_HEADERS.NAME,
    tooltip: datahubTxt.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
    filterOptions: {
      comparators: ["==", "!=", "contains"],
    },
  },
  {
    value: "updatedBy",
    headerText: datahubTxt.TABLE_HEADERS.UPDATED_BY,
    tooltip: datahubTxt.TABLE_HEADERS.UPDATED_BY,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
    filterOptions: {
      comparators: ["contains"],
    },
  },
  {
    value: "records",
    headerText: datahubTxt.TABLE_HEADERS.RECORDS,
    tooltip: datahubTxt.TABLE_HEADERS.RECORDS,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "number",
  },
  {
    value: "lastUpdated",
    headerText: datahubTxt.TABLE_HEADERS.LAST_UPDATED,
    tooltip: datahubTxt.TABLE_HEADERS.LAST_UPDATED,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
    },
  },
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "checkbox" },
    hidden: { smDown: true },
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(datahubSourceItemDescriptors);
