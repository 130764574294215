import { useMemo } from "react";

import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Alert, Autocomplete, FormControl, Link, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { AccountStatus } from "../../../../Pages/Settings/AWS/types";
import { useAWSAccounts } from "../../../Settings/AWS/hooks";
import { useAwsData } from "../../hooks";
import { useFieldValidationProps } from "./useFieldValidationProps";

export const ALL_REGIONS = "All regions";

const AWSParameterForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const customerId = useCustomerId();
  const [accounts] = useAWSAccounts();

  const accountIdProps = formikProps.getFieldProps("accountId");
  const regionsProps = formikProps.getFieldProps("regions");
  const { showError, errorMessage } = useFieldValidationProps(formikProps, accountIdProps);
  const { showError: showRegionsError, errorMessage: regionsErrorMessage } = useFieldValidationProps(
    formikProps,
    regionsProps
  );

  const { regions } = useAwsData(accountIdProps.value, "regions" in inputModel.members);

  const { linkedAccountIds, unlinkedAccountIds } = useMemo(
    () =>
      accounts.reduce(
        (acc, account) => {
          account.status === AccountStatus.notConfigured
            ? acc.unlinkedAccountIds.push(account.accountId)
            : acc.linkedAccountIds.push(account.accountId);
          return acc;
        },
        { linkedAccountIds: [] as string[], unlinkedAccountIds: [] as string[] }
      ),
    [accounts]
  );

  const showWarning = accountIdProps.value && unlinkedAccountIds.includes(accountIdProps.value);

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <FormControl>
        <Autocomplete
          options={[...linkedAccountIds, ...unlinkedAccountIds]}
          groupBy={(option) => (linkedAccountIds.includes(option) ? "Linked accounts" : "Unlinked accounts")}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option} value={option}>
              {option}
            </MenuItem>
          )}
          disableClearable
          onChange={(_, value) => formikProps.setFieldValue(accountIdProps.name, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              label="Account"
              name={accountIdProps.name}
              onBlur={accountIdProps.onBlur}
              required
              fullWidth
              error={showError}
              helperText={errorMessage}
              variant="outlined"
            />
          )}
          size="small"
          fullWidth
          value={accountIdProps.value ?? null}
        />
      </FormControl>
      {showWarning && (
        <Alert severity="warning">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
              }}
              flexShrink={1}
            >
              Connect your AWS account to use CloudFlow.
            </Typography>
            <Link
              href={`/customers/${customerId}/settings/aws/link`}
              target="_blank"
              underline="hover"
              color="inherit"
              flexShrink={0}
            >
              Link account
            </Link>
          </Stack>
        </Alert>
      )}
      {"regions" in inputModel.members && (
        <FormControl>
          <Autocomplete
            multiple
            options={regions}
            onChange={async (_, value) => formikProps.setFieldValue(regionsProps.name, value)}
            renderOption={(props, option) => (
              <MenuItem {...props} value={option}>
                {option}
              </MenuItem>
            )}
            disableCloseOnSelect
            getOptionDisabled={(option) => formikProps.values?.regions?.includes(ALL_REGIONS) && option !== ALL_REGIONS}
            disabled={regions.length === 0}
            renderInput={(params) => {
              const { InputProps, ...rest } = params;
              if (
                (!Array.isArray(regionsProps.value) || regionsProps.value.length === 0) &&
                !rest.inputProps["aria-expanded"]
              ) {
                InputProps.startAdornment = (
                  <Typography
                    sx={{
                      pl: 1,
                    }}
                  >
                    {ALL_REGIONS}
                  </Typography>
                );
              }
              return (
                <TextField
                  {...rest}
                  type="text"
                  label="Regions"
                  name={regionsProps.name}
                  onBlur={regionsProps.onBlur}
                  fullWidth
                  error={showRegionsError}
                  helperText={regionsErrorMessage}
                  variant="outlined"
                  slotProps={{
                    input: InputProps,
                  }}
                />
              );
            }}
            size="small"
            fullWidth
            value={formikProps.values?.regions ?? []}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default AWSParameterForm;
