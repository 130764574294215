import { type JSX, useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";

import { type ToolbarButton } from "../types";
import { ColumnOrderMenu } from "./ColumnsOrder/ColumnOrderMenu";

export type FilterTableToolbarProps = {
  title?: string | JSX.Element;
  customSlot?: JSX.Element;
  allowToEditColumns?: boolean;
  deleteButton?: ToolbarButton;
  primaryButton?: ToolbarButton;
  secondaryButtons?: ToolbarButton[];
  shareButton?: ToolbarButton;
  gap?: number;
};

export function FilterTableToolbar({
  title,
  allowToEditColumns,
  primaryButton,
  secondaryButtons,
  deleteButton,
  shareButton,
  customSlot,
  gap = 1,
}: FilterTableToolbarProps) {
  const titleContent = useMemo(() => {
    if (typeof title === "string") {
      return <Typography variant="h1">{title}</Typography>;
    }
    return title;
  }, [title]);

  return (
    <Stack
      direction="row"
      sx={{
        pb: 1,
        justifyContent: "space-between",
        alignItems: "center",
        gap,
      }}
    >
      <Box>{titleContent}</Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flex: 1,
          justifyContent: "end",
        }}
      >
        {customSlot}
        {primaryButton && (
          <Tooltip arrow placement="bottom" title={primaryButton.tooltipTitle}>
            <span>
              <Button
                color={primaryButton.color ? primaryButton.color : "primary"}
                variant={primaryButton.variant ? primaryButton.variant : "contained"}
                onClick={primaryButton.onClick}
                disabled={primaryButton.disabled}
                data-cy={primaryButton["data-cy"]}
                aria-description={primaryButton["aria-description"]}
                startIcon={primaryButton.startIcon}
              >
                {primaryButton.text}
              </Button>
            </span>
          </Tooltip>
        )}
        {secondaryButtons?.map((secondaryButton) => (
          <Tooltip arrow placement="bottom" title={secondaryButton.tooltipTitle} key={secondaryButton.text}>
            <span>
              <Button
                color={secondaryButton.color ? secondaryButton.color : "primary"}
                variant="outlined"
                onClick={secondaryButton.onClick}
                disabled={secondaryButton.disabled}
                data-cy={secondaryButton["data-cy"]}
                startIcon={secondaryButton.startIcon}
                aria-description={secondaryButton["aria-description"]}
              >
                {secondaryButton.text}
              </Button>
            </span>
          </Tooltip>
        ))}
        {shareButton && (
          <Button
            color={shareButton.color ? shareButton.color : "primary"}
            onClick={shareButton.onClick}
            disabled={shareButton.disabled}
            data-cy={shareButton["data-cy"]}
            startIcon={shareButton.startIcon}
            aria-description={shareButton["aria-description"]}
          >
            {shareButton.text}
          </Button>
        )}
        {deleteButton && (
          <Tooltip arrow placement="bottom" title={deleteButton.tooltipTitle}>
            <span>
              <Button
                color={deleteButton.color ? deleteButton.color : "error"}
                startIcon={<DeleteIcon />}
                onClick={deleteButton.onClick}
                disabled={deleteButton.disabled}
                data-cy={deleteButton["data-cy"]}
                aria-description={deleteButton["aria-description"]}
              >
                {deleteButton.text}
              </Button>
            </span>
          </Tooltip>
        )}
        {allowToEditColumns && <ColumnOrderMenu />}
      </Box>
    </Stack>
  );
}
