import { useMemo } from "react";

import { ComparativeSign } from "../utilities";
import { type FormatterFunc } from "./ECharts/types";
import type ReportData from "../ReportData";

const isValidColKey = (colKeyStr: string, isComparative: boolean): boolean =>
  !isComparative || (isComparative && colKeyStr.includes(ComparativeSign));

type useChartsRendererProps = {
  data: ReportData | null;
  isComparative: boolean;
};

type AreaStyleOption = {
  color?: string;
  opacity?: number;
};

type ItemStyleOption = {
  color?: string;
};

type LineStyleOption = {
  type?: string;
  width?: number;
};
interface MarkPoint {
  label?: {
    show?: boolean;
    position?: string;
    offset?: [number, number];
    fontSize?: number;
    color?: string;
    padding?: [number, number];
    backgroundColor?: string;
    borderRadius?: number;
    formatter?: FormatterFunc;
  };
  itemStyle?: {
    color?: string;
  };
  symbol?: string;
  symbolSize?: number | number[];
  symbolOffset?: [number, number];
  data?: MarkPointData[];
}

export type MarkPointData = {
  coord?: [number, number];
  value?: string;
};

export type ChartSeriesRecord = {
  id?: string;
  type?: string;
  color?: string;
  name: string;
  data: number[];
  symbol?: string;
  symbolSize?: number;
  lineWidth?: number;
  tooltip?: unknown;
  marker?: unknown;
  dashStyle?: string;
  areaStyle?: AreaStyleOption;
  itemStyle?: ItemStyleOption;
  lineStyle?: LineStyleOption;
  markPoint?: MarkPoint;
};

export type TreeChartSeriesRecord = {
  name: string;
  value: number;
  children: TreeChartSeriesRecord[];
};

export type useChartsRendererResult = {
  categories: string[];
  series: ChartSeriesRecord[];
  reversed: boolean;
};

function useChartsSeries({ data, isComparative = false }: useChartsRendererProps): useChartsRendererResult {
  return useMemo(() => {
    const categories: string[] = [];
    const series: ChartSeriesRecord[] = [];
    let reversed = true;
    if (!data) {
      return { categories, series, reversed };
    }

    const rowKeys = data.getRowKeys();
    const colKeys = data.getColKeys();

    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      categories.push("Total");
    } else {
      for (const colKey of colKeys) {
        const colId = colKey.join("-");
        if (isValidColKey(colId, isComparative)) {
          categories.push(colId);
        }
      }
    }

    rowKeys.forEach((rowKey) => {
      let name;
      if (rowKeys.length === 1 && rowKeys[0].length === 0) {
        name = "Total";
      } else {
        name = rowKey.join(";");
      }
      const s: ChartSeriesRecord = {
        name,
        data: [],
      };

      colKeys.forEach((colKey) => {
        const colId = colKey.join("-");
        if (isValidColKey(colId, isComparative)) {
          const value: number = data.getAggregator(rowKey, colKey).value();
          s.data.push(value);
          reversed = reversed && value <= 0;
        }
      });

      series.push(s);
    });

    return { categories, series, reversed };
  }, [data, isComparative]);
}

export default useChartsSeries;
