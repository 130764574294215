import { useCallback, useState } from "react";

import { GCPCustomerPricebooksModel } from "@doitintl/cmp-models";
import { getCollectionGroup, useCollectionData } from "@doitintl/models-firestore";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";

import { pricebooksText } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import { CreateTableDialog } from "../../../Pages/Pricebooks/CreateTableDialog";
import GoogleCloudFormDialog from "../../../Pages/Pricebooks/GoogleCloudFormDialog";
import { FilterTable } from "../../FilterTable/FilterTable";
import { GCPfilterColumns, getGCPHeaders } from "../Columns";
import { type GCPPricebook } from "../Types";
import { GCPPricebookRow } from "./GCPPricebookRow";

export default function GCPPricebooks() {
  const [pricebooks] = useCollectionData(
    getCollectionGroup(GCPCustomerPricebooksModel).where("type", "==", "google-cloud"),
    { refField: "ref" }
  );

  const { isDoitDeveloper, isDoitOwner } = useAuthContext({ mustHaveUser: true });
  const [pricebookForDialog, setPricebookForDialog] = useState<GCPPricebook | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [createTableDialogOpen, setCreateTableDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(
    (row: GCPPricebook | null) => () => {
      setPricebookForDialog(row);
      setDialogOpen(true);
    },
    []
  );

  const GCPPricebooksRowWrapper = useCallback(
    (props: { row: GCPPricebook }) => <GCPPricebookRow row={props.row} handleOpenDialog={handleOpenDialog} />,
    [handleOpenDialog]
  );

  return (
    <>
      {isDoitDeveloper && dialogOpen && (
        <GoogleCloudFormDialog
          onClose={() => {
            setDialogOpen(false);
          }}
          pricebook={pricebookForDialog}
        />
      )}
      {createTableDialogOpen && (
        <CreateTableDialog
          onClose={() => {
            setCreateTableDialogOpen(false);
          }}
          open={createTableDialogOpen}
        />
      )}
      <FilterTable<GCPPricebook>
        defaultSortingColumnValue="metadata.customer.name"
        filterBarPlaceholder={pricebooksText.FILTER_PRICEBOOKS}
        filterColumns={GCPfilterColumns}
        headerColumns={getGCPHeaders(isDoitDeveloper)}
        itemUniqIdentifierField="ref.id"
        persistenceKey="pricebooks_gcp_tab"
        rowComponent={GCPPricebooksRowWrapper}
        showRowsSelection={false}
        tableItems={pricebooks}
      >
        <Grid>
          {isDoitDeveloper && (
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog(null)}
                disabled={!isDoitOwner}
                size="large"
              >
                {pricebooksText.NEW_PRICEBOOK}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCreateTableDialogOpen(true);
                }}
                disabled={!isDoitOwner}
                size="large"
              >
                {pricebooksText.NEW_PRICEBOOK_TABLE}
              </Button>
            </Stack>
          )}
        </Grid>
      </FilterTable>
    </>
  );
}
