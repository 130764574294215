import { type AttributionFilter } from "@doitintl/cmp-models";
import * as yup from "yup";
import { create, type StoreApi, type UseBoundStore } from "zustand";

// Define the form validation schema
const formSchema = yup.object({
  name: yup.string().required("Name is required"),
  filterFields: yup.array().min(1, "At least one filter field is required"),
  formula: yup.string().required("Formula is required"),
});

// Define the form state interface
interface FormState {
  values: {
    name: string;
    filterFields: AttributionFilter[];
    formula: string;
  };
  errors: Record<string, string | Record<string, string>[]>;
  touched: Record<string, boolean | boolean[]>;
  isSubmitting: boolean;
  // Actions
  setName: (name: string) => void;
  addFilterField: (fields: AttributionFilter[]) => void;
  setFormula: (formula: string) => void;
  setFieldTouched: (field: string, index?: number) => void;
  resetForm: () => void;
  handleSubmit: () => void;
}

// Create the form store
const createFormStore = () =>
  create<FormState>((set, get) => ({
    // Initial state
    values: {
      name: "",
      filterFields: [],
      formula: "",
    },
    errors: {},
    touched: {},
    isSubmitting: false,

    // Actions
    setName: (name: string) => {
      set((state) => ({
        values: {
          ...state.values,
          name,
        },
      }));
    },

    addFilterField: (fields) => {
      set((state) => ({
        values: {
          ...state.values,
          filterFields: fields,
        },
      }));
    },

    setFormula: (formula: string) => {
      set((state) => ({
        values: {
          ...state.values,
          formula,
        },
      }));
    },

    setFieldTouched: (field: string, index?: number) => {
      set((state) => {
        if (field === "filterFields" && typeof index === "number") {
          const touchedFields = [...((state.touched.filterFields as boolean[]) || [])];
          touchedFields[index] = true;
          return {
            touched: {
              ...state.touched,
              filterFields: touchedFields,
            },
          };
        }
        return {
          touched: {
            ...state.touched,
            [field]: true,
          },
        };
      });
    },

    resetForm: () => {
      set({
        values: {
          name: "",
          filterFields: [],
          formula: "",
        },
        errors: {},
        touched: {},
        isSubmitting: false,
      });
    },

    handleSubmit: () => {
      set({ isSubmitting: true });

      try {
        // Validate all fields
        const values = get().values;
        formSchema.validate(values, { abortEarly: false });

        // Reset form after successful submission
        get().resetForm();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Transform validation errors into record
          const errors: Record<string, string | Record<string, string>[]> = {};

          set({ errors });
        }
      } finally {
        set({ isSubmitting: false });
      }
    },
  }));

// Create a store manager to handle multiple form instances
class FormStoreManager {
  private stores: Map<string, UseBoundStore<StoreApi<FormState>>> = new Map();

  getStore(id: string) {
    if (!this.stores.has(id)) {
      this.stores.set(id, createFormStore());
    }
    return this.stores.get(id)!;
  }

  removeStore(id: string) {
    this.stores.delete(id);
  }
}

export const formStoreManager = new FormStoreManager();
