import { useCallback, useEffect, useState } from "react";

import { ClusterType, type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CircularProgress, Stack, Typography, useTheme } from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { consoleErrorWithSentry } from "../../../../utils";
import {
  type ClusterFeatureActivationPayload,
  updateClusterFeatureActivationStatus,
  updateClusterFeatureHealthStatus,
} from "../../api";
import { useKubernetesFeatures } from "../../hooks";
import FeatureStatus from "./components/FeatureConnectionStatus";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface TestConnectionStepProps {
  projectId: string | null;
  cluster: ClusterWithCloudConnect | null;
  selectedFeatures: KubernetesFeature[];
}

const TestConnectionStep = ({ projectId, cluster, selectedFeatures }: TestConnectionStepProps) => {
  const api = useApiContext();

  const [availableFeatures] = useKubernetesFeatures();
  const [connectionHasBeenTested, setConnectionHasBeenTested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);

  const theme = useTheme();

  const updateClusterFeatures = useCallback(async () => {
    if (!cluster?.id) return;

    const payload: ClusterFeatureActivationPayload = { newFeatureStates: {} };

    availableFeatures?.forEach((feature) => {
      const isSelected = selectedFeatures.some((selectedFeature) => selectedFeature.id === feature.id);
      payload.newFeatureStates[feature.id] = isSelected;
    });

    try {
      await updateClusterFeatureActivationStatus(api, cluster?.id, payload);
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [api, availableFeatures, cluster?.id, selectedFeatures]);

  const handleTestConnection = useCallback(async () => {
    if (!cluster?.id || loading) return;

    setLoading(true);
    try {
      await updateClusterFeatures();
      setConnectionHasBeenTested(false);
      await updateClusterFeatureHealthStatus(api, cluster?.id);
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
      setConnectionHasBeenTested(true);
    }
  }, [api, cluster?.id, updateClusterFeatures, loading]);

  useEffect(() => {
    if (!connectionHasBeenTested && selectedFeatures.length > 0 && availableFeatures) {
      handleTestConnection();
    }
  }, [handleTestConnection, connectionHasBeenTested, availableFeatures, selectedFeatures.length]);

  const handleRetryConnection = async () => {
    if (!cluster?.id) return;

    setRetryLoading(true);
    try {
      await updateClusterFeatures();
      await updateClusterFeatureHealthStatus(api, cluster?.id);
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setRetryLoading(false);
    }
  };

  const title =
    cluster?.type === ClusterType.EKS ? "Test feature connection and activate EKS cluster" : "Test feature connection";

  if (!cluster) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">{title}</Typography>
        <SelectedAccountAndCluster projectId={projectId} cluster={cluster} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2">
          1. Test cluster connection to the DoiT Console and activate successful clusters
        </Typography>
        <LoadingButton
          onClick={handleTestConnection}
          sx={{ width: "fit-content" }}
          variant="outlined"
          loading={loading}
          disabled={connectionHasBeenTested}
        >
          Test connection and activate clusters
        </LoadingButton>
      </Stack>

      {connectionHasBeenTested && (
        <Card>
          <CardContent sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
            <Box display="flex" justifyContent="space-between">
              <Stack spacing={1}>
                <Typography fontWeight={500} variant="body1">
                  Activation summary
                </Typography>
              </Stack>
              <Box>
                <LoadingButton
                  onClick={handleRetryConnection}
                  loading={retryLoading}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                >
                  Retry
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
          {selectedFeatures.map((feature) => (
            <FeatureStatus loading={retryLoading} key={feature.id} cluster={cluster} feature={feature} />
          ))}
        </Card>
      )}
    </Stack>
  );
};

export default TestConnectionStep;
