import { analyticsAlertText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type DataColumn } from "../../../types/FilterTable";
import { type AnalyticsAlertWithRef } from "./types";

export const analyticsAlertItemDescriptors: FilterTableItemDescriptor<AnalyticsAlertWithRef>[] = [
  {
    value: "data.name",
    headerText: analyticsAlertText.TABLE_HEADERS.NAME,
    tooltip: analyticsAlertText.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
  },
  {
    value: "transform.conditionString",
    headerText: analyticsAlertText.TABLE_HEADERS.CONDITION,
    tooltip: analyticsAlertText.TABLE_HEADERS.CONDITION,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    filterType: "string",
  },
  {
    value: "@",
    headerText: analyticsAlertText.TABLE_HEADERS.SCOPE,
    tooltip: analyticsAlertText.TABLE_HEADERS.SCOPE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    disableSorting: true,
  },
  {
    value: "data.timeLastAlerted",
    headerText: analyticsAlertText.TABLE_HEADERS.LAST_SENT,
    tooltip: analyticsAlertText.TABLE_HEADERS.LAST_SENT,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    filterType: "date",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "data.timeModified",
    headerText: analyticsAlertText.TABLE_HEADERS.LAST_MODIFIED,
    tooltip: analyticsAlertText.TABLE_HEADERS.LAST_MODIFIED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    filterType: "date",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "transform.owner",
    headerText: analyticsAlertText.TABLE_HEADERS.OWNER,
    tooltip: analyticsAlertText.TABLE_HEADERS.OWNER,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    filterType: "string",
  },
  {
    value: "data.timeCreated",
    headerText: analyticsAlertText.TABLE_HEADERS.TIME_CREATED,
    tooltip: analyticsAlertText.TABLE_HEADERS.TIME_CREATED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    hiddenByDefault: true,
    filterType: "date",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  // Investigate button
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "none" },
  },
  // 3 dots menu - please do not remove
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "none" },
  },
];

const additionalFilters: DataColumn<AnalyticsAlertWithRef>[] = [{ comparators: ["=="], label: "ID", path: "ref.id" }];

const { headerColumns, filters: baseFilters } = generateHeadersAndFilters(analyticsAlertItemDescriptors);
const filters = [...additionalFilters, ...baseFilters];

export { filters, headerColumns };
