import { useState } from "react";

import { useHistory } from "react-router";
import { CategoryStatus, type Cluster, ClusterDiscoveryStatus, ClusterType } from "@doitintl/cmp-models";
import { modelFromPath } from "@doitintl/models-firestore";
import { type SxProps, TableCell, type Theme } from "@mui/material";
import { type AxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getAxiosErrorMessage } from "../../../utils/axios";
import { AWSFeatureName } from "../../Settings/AWS/types";
import { activateCluster, connectEksCluster, deactivateCluster, deleteCluster, testClusterConnection } from "../api";
import { ClusterDialog } from "../ClusterDialog";
import DeleteClusterDialog from "../Components/DeleteClusterDialog";
import ClusterStatus from "./ClusterStatus";
import ClusterThreeDotsMenu from "./ClusterThreeDotsMenu";
import ClusterTypeDisplay from "./ClusterType";

interface ClusterTableRowProps {
  row: Cluster;
}

const ClusterTableRow = ({ row }: ClusterTableRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();

  const callbackUrl = encodeURIComponent(routeMatchURL);

  const { awsFeatures, customer } = useCustomerContext();
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const [actionLoading, setActionLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const runClusterAction = async (action: (() => Promise<any>) | (() => void)) => {
    try {
      setActionLoading(true);
      await action();
    } catch (e) {
      const message = getAxiosErrorMessage(e as AxiosError);
      errorSnackbar(`Error performing action on cluster ${row.name}: ${message}`);
    } finally {
      setActionLoading(false);
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const startEksClusterActivation = async () => {
    const hasFsk8sCore = awsFeatures?.includes(AWSFeatureName.fsk8s_core);
    const hasFsk8sAutoConnect = awsFeatures?.includes(AWSFeatureName.fsk8s_auto_connect);

    if (hasFsk8sCore) {
      if (hasFsk8sAutoConnect) {
        await runClusterAction(() => connectEksCluster(api, row.id));
      }
      history.push(`${routeMatchURL}/activate/eks?clusterId=${row.id}&projectId=${row.projectId}`);
    } else {
      history.push(`/customers/${customer.id}/settings/aws/${row.projectId}?callbackUrl=${callbackUrl}`);
    }
  };

  const activateGkeCluster = async () => {
    const ref = modelFromPath(row.cloudConnectRef.path);
    const doc = await ref.get();
    const gcpCloudConnect = doc.data();

    history.push(
      gcpCloudConnect?.status === CategoryStatus.Healthy
        ? `${routeMatchURL}/activate/gke?clusterId=${row.id}&projectId=${row.projectId}`
        : `/customers/${customer.id}/settings/gcp?callbackUrl=${callbackUrl}`
    );
  };

  const handleTestConnection = async () => {
    await runClusterAction(() => testClusterConnection(api, row.id));
  };

  const handleActivateCluster = async () => {
    if (row.type === ClusterType.EKS) {
      await startEksClusterActivation();
      return;
    }

    if (row.type === ClusterType.GKE) {
      await activateGkeCluster();
      return;
    }

    await runClusterAction(() => {
      activateCluster(api, row.id);
    });
  };

  const handleDeactivateCluster = async () => {
    await runClusterAction(() => deactivateCluster(api, row.id));
    successSnackbar(`Cluster deactivated successfully`);
  };

  const handleDeleteCluster = async () => {
    setDeleteDialogOpen(false);
    await runClusterAction(() => deleteCluster(api, row.id));
    successSnackbar(`Cluster deleted successfully`);
  };

  const clusterNameStyle: SxProps<Theme> = {
    textDecoration: row.discoveryStatus === ClusterDiscoveryStatus.DELETED ? "line-through" : "none",
  };

  return (
    <>
      {deleteDialogOpen && (
        <DeleteClusterDialog
          cluster={row}
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onDelete={handleDeleteCluster}
        />
      )}
      {editDialogOpen && <ClusterDialog open={editDialogOpen} setOpen={setEditDialogOpen} cluster={row} />}
      <CellsWrapper>
        <TableCell sx={clusterNameStyle}>{row.name}</TableCell>
        <TableCell>
          <ClusterTypeDisplay type={row.type} />
        </TableCell>
        <TableCell>{row.region}</TableCell>
        <TableCell>{row.projectId || "N/A"}</TableCell>
        <TableCell>{row.kubernetesControlPlaneVersion || "Unknown"}</TableCell>
        <TableCell>
          <ClusterStatus cluster={row} />
        </TableCell>
        <TableCell>
          <ClusterThreeDotsMenu
            cluster={row}
            onTestConnection={handleTestConnection}
            onActivateCluster={handleActivateCluster}
            onDeactivateCluster={handleDeactivateCluster}
            onDeleteCluster={openDeleteDialog}
            onEditCluster={openEditDialog}
            loading={actionLoading}
          />
        </TableCell>
      </CellsWrapper>
    </>
  );
};

export default ClusterTableRow;
