import { useMemo, useState } from "react";

import { type Key, Metric, Renderer } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import ChartsRenderer from "../../../Pages/CloudAnalytics/renderers/ChartsRenderer";
import TableRenderer from "../../../Pages/CloudAnalytics/renderers/TableRenderer/TableRenderer";
import { type ColKeySort } from "../../../Pages/CloudAnalytics/ReportData";
import { updateColKeySort } from "../../../Pages/CloudAnalytics/utilities";
import { useFullScreen } from "../../../utils/dialog";
import { useMetricFormatter } from "../../hooks/cloudAnalytics/useMetricFormatter";
import useFormatter from "../../hooks/useFormatter";
import { useCreateWidgetReportData } from "./hooks";

type Props = {
  rawReport: any;
  selectedFilter: string;
  cloudAnalyticsKey?: Key;
};

export const CloudReportCardContent = ({ rawReport, selectedFilter, cloudAnalyticsKey }: Props) => {
  const { config } = useMemo(() => rawReport ?? { config: {} }, [rawReport]);

  const [colKeySort, setColKeySort] = useState<ColKeySort>();
  const reportData = useCreateWidgetReportData(rawReport, colKeySort, selectedFilter, { cloudAnalyticsKey });

  const {
    comparative,
    metric,
    renderer,
    aggregator,
    calculatedMetric,
    currency,
    extendedMetricType,
    includeSubtotals,
  } = config ?? {};

  const baseFormatter = useFormatter({
    aggregator,
    currency,
    metric,
    options: { extendedMetricMode: extendedMetricType },
  });
  const calculatedMetricFormatter = useMetricFormatter(aggregator, currency, calculatedMetric);
  const formatter = calculatedMetric ? calculatedMetricFormatter : baseFormatter;
  const { isMobile: smDown } = useFullScreen();
  const heatmapReverseColor = metric === Metric.SAVINGS || metric === Metric.MARGIN;
  if (reportData) {
    let plot;
    switch (renderer) {
      case Renderer.TABLE:
      case Renderer.HEATMAP:
      case Renderer.ROW_HEATMAP:
      case Renderer.COL_HEATMAP: {
        const forecastStart = reportData?.getForecastStartIndex();
        const heatmapPropMap = {
          [Renderer.HEATMAP]: "full",
          [Renderer.ROW_HEATMAP]: "row",
          [Renderer.COL_HEATMAP]: "col",
        };
        plot = (
          <TableRenderer
            data={reportData}
            forecastStart={forecastStart}
            formatter={formatter}
            height="100%"
            smDown={smDown}
            heatmap={heatmapPropMap[renderer]}
            reverseColors={renderer !== Renderer.TABLE && heatmapReverseColor}
            sortByCol={(colKey) => {
              updateColKeySort(setColKeySort, colKey);
            }}
            colKeySort={colKeySort}
            showSubtotals={includeSubtotals}
          />
        );
        break;
      }
      default:
        plot = (
          <ChartsRenderer
            data={reportData}
            type={renderer}
            formatter={formatter}
            comparative={comparative}
            isWidget={!cloudAnalyticsKey}
          />
        );
    }

    return <Box sx={{ height: "100%", margin: "0 6px 0px 6px" }}>{plot}</Box>;
  }
  return null;
};
