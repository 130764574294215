import { useMemo } from "react";

import { type DataConnectorDatadogModel } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../../Components/FilterTable/FilterTableSkeleton";
import { filters, headerColumns } from "./DatadogAssetColumns";
import DatadogAssetRow, { type DatadogAssetRowData } from "./DatadogAssetRow";

const DatadogAssetTable = ({ configs }: { configs: DataConnectorDatadogModel[] | undefined }) => {
  const data = useMemo<DatadogAssetRowData[]>(() => {
    const data: DatadogAssetRowData[] = [];

    if (!configs || configs.length === 0) {
      return data;
    }

    configs.forEach((config) => {
      if (!config) {
        return;
      }
      data.push({
        site: config.site,
        orgName: config.orgId,
        status: config.status === "active" ? "Active" : "Inactive",
      } as DatadogAssetRowData);
    });

    return data;
  }, [configs]);

  const rowWrapper = ({ row }: { row: DatadogAssetRowData }) => <DatadogAssetRow data={row} />;

  return (
    <Box
      sx={{
        mb: 4,
      }}
    >
      {configs === undefined ? (
        <FilterTableSkeleton />
      ) : (
        <FilterTable<DatadogAssetRowData>
          tableItems={data}
          rowComponent={rowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          persistenceKey="datadog_asset_table"
          data-cy="datadog-asset-table"
        />
      )}
    </Box>
  );
};

export default DatadogAssetTable;
