import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import NoEntitlement, { CardsComponent } from "../../Components/BlankStateUpsell/NoEntitlement";
import DeleteDialog from "../../Components/DeleteDialog";
import { FilterTable } from "../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../Components/FilterTable/FilterTableSkeleton";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import useGenerateReport from "../../Components/hooks/useGenerateReport";
import { CircularProgressLoader } from "../../Components/Loader";
import LoadingButton from "../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDataHubContext } from "../../Context/DataHubContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { TimestampFromDate } from "../../utils/firebase";
import CreateDatahubDialog from "./CreateDatahubDialog";
import ImportCSVStepper from "./CSVImport/ImportCSVStepper";
import DataHubSourceRow from "./DataHubSourceRow";
import { filters, headerColumns } from "./DataHubSourcesColumns";
import ImportDataDialog from "./ImportDataDialog";
import {
  buildConfig,
  deleteProcessingMinutes,
  isAnySelectedUpdatedWithinLastXMinutes,
  metadataProcessingMinutes,
} from "./utils";

export type DatasetItem = {
  dataset: string;
  updatedBy: string;
  records: number;
  lastUpdated: string;
  processing?: number; // holds the number of records that are currently being processed
  description: string;
};

const DataHubSources = () => {
  const generateReport = useGenerateReport();
  const history = useHistory();
  const customerId = useCustomerId();
  const { customer } = useCustomerContext();
  const { datahubItems, loading, fetchDataHubItems, deleteDataHubDatasets } = useDataHubContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [selected, setSelected] = useState<DatasetItem[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [loadingExplore, setLoadingExplore] = useState(false);
  const [openImportDataDialog, setOpenImportDataDialog] = useState(false);
  const [datasetsRefreshed, setDatasetsRefreshed] = useState(false);
  const [showImportStepper, setShowImportStepper] = useState(false);
  const [currentDatasetName, setCurrentDatasetName] = useState("");
  const [accountManagerMail, setAccountManagerMail] = useState("");
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const isEntitled = useIsFeatureEntitled("pdi:datahub");
  const init = useRef(false);

  const isPresentationMode = isCustomerInPresentationMode(customer);

  useEffect(() => {
    if (!init.current && !loading) {
      init.current = true;
    }
  }, [loading]);

  useEffect(() => {
    const accountManagerId = customer.accountManager?.id;
    if (!accountManagerId || !accountManagers) {
      setAccountManagerMail("");
      return;
    }

    const customerAccountManager = accountManagers.find((am) => am.id === accountManagerId);

    setAccountManagerMail(customerAccountManager?.email ?? "");
  }, [accountManagers, customer.accountManager]);

  const handleDeleteDataSources = useCallback(async () => {
    const selectedWithinLast90Minutes = isAnySelectedUpdatedWithinLastXMinutes(
      selected,
      "lastUpdated",
      deleteProcessingMinutes
    );
    if (selectedWithinLast90Minutes) {
      errorSnackbar(datahubTxt.DATASET_RECENTLY_INGESTED);
      return;
    }

    const res = await deleteDataHubDatasets(selected.map((item) => item.dataset));
    if (res.status === 200) {
      successSnackbar(datahubTxt.DATASETS_DELETED_SUCCESSFULLY);
    } else if (res?.response?.data?.error === datahubTxt.DATASET_RECENTLY_INGESTED) {
      errorSnackbar(datahubTxt.DATASET_RECENTLY_INGESTED);
    } else {
      errorSnackbar(datahubTxt.DATASET_FAILED_DELETE);
    }
  }, [deleteDataHubDatasets, errorSnackbar, selected, successSnackbar]);

  const deleteDataSourcesDisabled = useMemo(
    () =>
      isPresentationMode || isAnySelectedUpdatedWithinLastXMinutes(selected, "lastUpdated", deleteProcessingMinutes),
    [selected, isPresentationMode]
  );

  const openInReportsDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes(selected, "lastUpdated", metadataProcessingMinutes),
    [selected]
  );

  const handleExplore = useCallback(
    async (dataset?: string) => {
      setLoadingExplore(true);
      const values = dataset ? [dataset] : selected.map((item) => item.dataset);
      const name = dataset ? `${datahubTxt.EXPLORE} ${dataset}` : datahubTxt.EXPLORE_DATASETS_NAME;
      const config = buildConfig(values);
      const newReportId = await generateReport(
        {
          name,
          config,
          draft: true,
          expireBy: TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
        },
        true
      );
      setLoadingExplore(false);
      history.push(`/customers/${customerId}/analytics/reports/${newReportId}?run-on-open=true`);
    },
    [customerId, generateReport, history, selected]
  );

  const onImportData = useCallback((datasetName: string) => {
    setOpenImportDataDialog(true);
    setCurrentDatasetName(datasetName);
  }, []);

  const handleOpenDeleteDialog = useCallback((dataset: DatasetItem) => {
    setSelected([dataset]);
    setOpenDeleteDialog(true);
  }, []);

  const handleOnClickRefresh = useCallback(async () => {
    setIsRefreshLoading(true);
    await fetchDataHubItems(true);
    setTimeout(() => {
      setIsRefreshLoading(false);
    }, 10000);
    setDatasetsRefreshed(true);
  }, [fetchDataHubItems]);

  const DataHubSourceRowWrapper = useCallback(
    ({ row }: { row: DatasetItem }) => (
      <DataHubSourceRow
        data={row}
        handleExplore={handleExplore}
        handleDelete={handleOpenDeleteDialog}
        onImportData={onImportData}
        datasetsRefreshed={datasetsRefreshed}
        isPresentationMode={isPresentationMode}
      />
    ),
    [isPresentationMode, datasetsRefreshed, handleExplore, handleOpenDeleteDialog, onImportData]
  );

  const deleteTooltipTitle = useMemo(() => {
    if (isPresentationMode) {
      return datahubTxt.DELETE_DISABLED_PRESENTATION_MODE;
    } else if (selected.length === 0) {
      return datahubTxt.DELETE_DISABLED_NO_SELECT;
    } else if (deleteDataSourcesDisabled) {
      return datahubTxt.DATASET_RECENTLY_INGESTED;
    } else {
      return datahubTxt.DELETE_TITLE;
    }
  }, [deleteDataSourcesDisabled, selected.length, isPresentationMode]);

  const openInReportsTooltipTitle = useMemo(() => {
    if (selected.length === 0) {
      return datahubTxt.EXPLORE_DISABLED_NO_SELECT;
    } else if (openInReportsDisabled) {
      return datahubTxt.DATASET_REPORT_DATA_UNAVAILABLE;
    } else {
      return datahubTxt.EXPLORE_DATASETS_REPORT;
    }
  }, [openInReportsDisabled, selected.length]);

  if (isEntitled === false) {
    return (
      <NoEntitlement
        {...{
          cards: (
            <CardsComponent
              cards={datahubTxt.NO_ENTITLEMENT_CARDS}
              title={datahubTxt.HOW_DATAHUB_CAN_HELP}
              bgcolor="background.paper"
            />
          ),
          accountManagerMail,
          title: datahubTxt.NO_ENTITLEMENT_TITLE,
          description: datahubTxt.NO_ENTITLEMENT_TEXT_TEXT_BEFORE_LIST,
          listItems: datahubTxt.NO_ENTITLEMENT_LIST_ITEMS,
        }}
      />
    );
  }

  if (!isRefreshLoading && !showImportStepper && (isEntitled === undefined || loading)) {
    return init.current ? <CircularProgressLoader /> : <FilterTableSkeleton />;
  }

  return (
    <Box>
      {showImportStepper ? (
        <ImportCSVStepper
          onClose={() => {
            setShowImportStepper(false);
          }}
          datasetName={currentDatasetName}
        />
      ) : (
        <FilterTable<DatasetItem>
          tableItems={datahubItems}
          showRowsSelection={true}
          onRowsSelected={setSelected}
          rowComponent={DataHubSourceRowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          filterBarPlaceholder={datahubTxt.FILTER_PLACEHOLDER}
          persistenceKey="dataset_tab"
          itemUniqIdentifierField="dataset"
          defaultSortingColumnValue="dataset"
          toolbarProps={{
            title: datahubTxt.TITLE,
            allowToEditColumns: true,
            deleteButton: {
              text: datahubTxt.DELETE,
              onClick: () => {
                setOpenDeleteDialog(true);
              },
              disabled: selected.length === 0 || deleteDataSourcesDisabled,
              tooltipTitle: deleteTooltipTitle,
            },
            customSlot: (
              <>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isPresentationMode}
                    onClick={() => {
                      setIsCreateDialogOpen(true);
                    }}
                  >
                    {datahubTxt.CREATE_DATASET}
                  </Button>
                </Grid>
                <Grid>
                  <Tooltip arrow title={datahubTxt.REFRESH_DATASET}>
                    <LoadingButton
                      variant="text"
                      onClick={handleOnClickRefresh}
                      loading={isRefreshLoading}
                      startIcon={<AutorenewIcon />}
                      mixpanelEventId="datahub.datasets.refresh"
                    >
                      {datahubTxt.REFRESH}
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid>
                  <Tooltip arrow title={openInReportsTooltipTitle}>
                    <LoadingButton
                      color="primary"
                      variant="text"
                      loading={loadingExplore}
                      onClick={() => handleExplore()}
                      mixpanelEventId="datahub.datasets.explore"
                      disabled={selected.length === 0 || openInReportsDisabled}
                    >
                      {datahubTxt.OPEN_IN_REPORTS}
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </>
            ),
          }}
        />
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={datahubTxt.DELETE_TITLE}
          message={datahubTxt.DELETE_CONFIRM}
          onDelete={handleDeleteDataSources}
          onClose={() => {
            setOpenDeleteDialog(false);
          }}
          mixpanelEventId="datahub.datasets.delete"
        />
      )}
      {openImportDataDialog && (
        <ImportDataDialog
          onClose={() => {
            setOpenImportDataDialog(false);
          }}
          onOpenImportStepper={() => {
            setShowImportStepper(true);
          }}
        />
      )}
      {isCreateDialogOpen && (
        <CreateDatahubDialog
          onClose={() => {
            setIsCreateDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default DataHubSources;
