import { useMemo } from "react";

import { type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { type SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { type InAppNotification, type InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";
import { getDateOfInterestLocal } from "./getNotificationDisplayDate";
import { InAppNotificationsLoading } from "./InAppNotificationsLoading";
import NoNotificationsNotice from "./NoNotificationsNotice";
import NotificationsListControls from "./NotificationsListControls";
import NotificationsListSection from "./NotificationsListSection";

export enum NotificationTimeSection {
  LastThreeDays = "lastThreeDays",
  Earlier = "earlier",
}

export type NotificationsListProps = {
  handleClose: () => void;
  notifications: Map<string, InAppNotificationWithRead>;
  onlyShowUnread: boolean;
  setOnlyShowUnread: (value: boolean) => void;
  unreadNotificationsCount: number;
  markAllAsRead: (userRef: ModelReference<UserModel>) => void;
  userRef: ModelReference<UserModel>;
  loading: boolean;
  handleClickNotification: (n: InAppNotificationWithRead, userRef: ModelReference<UserModel>) => Promise<void>;
  dialogMode: boolean;
};

export const cyIds = {
  list: "notifications-list",
  title: "notifications-list-title",
  close: "notifications-list-close",
  loading: "notifications-list-loading",
};

export const NotificationList = ({
  handleClose,
  notifications,
  onlyShowUnread,
  setOnlyShowUnread,
  unreadNotificationsCount,
  markAllAsRead,
  userRef,
  loading,
  handleClickNotification,
  dialogMode,
}: NotificationsListProps) => {
  const localNow = DateTime.now().toLocal();
  const timeStampDesc = (a: InAppNotification, b: InAppNotification) => (a.created > b.created ? -1 : 1);

  const sortedNotifications = useMemo(() => Array.from(notifications.values()).sort(timeStampDesc), [notifications]);

  const notificationSections = useMemo<Record<NotificationTimeSection, InAppNotificationWithRead[]>>(() => {
    const lastThreeDays: InAppNotificationWithRead[] = [];
    const earlier: InAppNotificationWithRead[] = [];

    sortedNotifications.forEach((n) => {
      const localDate = getDateOfInterestLocal(n);
      if (!localDate) {
        return;
      }

      const isWithinLastThreeDays = [0, 1, 2].some((days) => localDate.hasSame(localNow.minus({ days }), "day"));
      isWithinLastThreeDays ? lastThreeDays.push(n) : earlier.push(n);
    });

    return {
      lastThreeDays,
      earlier,
    };
  }, [localNow, sortedNotifications]);

  const widthSettings: SxProps = dialogMode ? { width: "30vw", minWidth: 400, maxWidth: 500 } : { width: "380px" };

  return (
    <Stack data-cy={cyIds.list} sx={{ height: "calc(100vh - 52px)" }}>
      <Stack direction="row" sx={{ px: 2, py: 2 }}>
        <Typography variant="h3" sx={{ maxWidth: "calc(100% - 48px)", height: "1.5rem" }} data-cy={cyIds.title}>
          Notifications
        </Typography>
        <IconButton onClick={handleClose} sx={{ ml: "auto", my: -1, mr: -1 }} data-cy={cyIds.close}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <NotificationsListControls
        notifications={notifications}
        onlyShowUnread={onlyShowUnread}
        setOnlyShowUnread={setOnlyShowUnread}
        unreadNotificationsCount={unreadNotificationsCount}
        markAllAsRead={markAllAsRead}
        userRef={userRef}
      />
      <List sx={{ ...widthSettings, overflow: "auto", padding: 0 }}>
        {loading ? (
          <InAppNotificationsLoading />
        ) : (
          <>
            <NoNotificationsNotice
              notifications={notifications}
              onlyShowUnread={onlyShowUnread}
              unreadNotificationsCount={unreadNotificationsCount}
            />
            <NotificationsListSection
              section={NotificationTimeSection.LastThreeDays}
              notifications={notificationSections[NotificationTimeSection.LastThreeDays]}
              onlyShowUnread={onlyShowUnread}
              handleClickNotification={handleClickNotification}
              userRef={userRef}
              localNow={localNow}
            />
            <NotificationsListSection
              section={NotificationTimeSection.Earlier}
              notifications={notificationSections[NotificationTimeSection.Earlier]}
              onlyShowUnread={onlyShowUnread}
              handleClickNotification={handleClickNotification}
              userRef={userRef}
              localNow={localNow}
            />
          </>
        )}
      </List>
    </Stack>
  );
};
