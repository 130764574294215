import { type Dispatch, type SetStateAction } from "react";

import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import { useApiContext } from "../../../../../api/context";
import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import LoadingButton from "../../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type FormState, type ProductOption } from "../types";

type Props = {
  formState: FormState;
  onChange: Dispatch<SetStateAction<FormState>>;
  clearSubscriptions: () => void;
};

type Error = { response: { data: { error: string } } };

export default function ValidationStep({ formState, onChange, clearSubscriptions }: Props) {
  const customerId = useCustomerId();
  const api = useApiContext();
  const showErrorSnackbar = useErrorSnackbar();
  const showSuccessSnackbar = useSuccessSnackbar(1);

  const testConnection = async () => {
    let connectionTestResult = false;
    onChange((state) => ({ ...state, loading: true }));
    const { applicationKey, APIKey, orgIdRegion } = formState;
    const productOptions: ProductOption[] = [];
    let connectionError = "";

    try {
      const response: { data: ProductOption[] } = await api.post(`/v1/customers/${customerId}/datadog/fetch-products`, {
        applicationKey,
        APIKey,
        orgIdRegion,
      });

      productOptions.push(...response.data);
      connectionTestResult = true;
      showSuccessSnackbar("Success");
    } catch (error) {
      const errorMessage = capitalize((error as Error)?.response?.data?.error);
      const defaultErrorMessage = "Error testing connection. Check the data and try again.";
      connectionError = errorMessage || defaultErrorMessage;
      showErrorSnackbar(connectionError);
    }
    onChange((state) => ({ ...state, connectionTestResult, connectionError, productOptions, loading: false }));
    clearSubscriptions();
  };

  return (
    <Stack data-cy="validation-step" spacing={4}>
      <Typography variant="h3">Test your Datadog connection with DoiT</Typography>

      <Stack spacing={2}>
        <Typography variant="body1">
          To make sure the setup is successful, we need to test the connection. This should take a couple of seconds.
        </Typography>
        <LoadingButton
          variant="outlined"
          sx={{ width: "140px" }}
          onClick={testConnection}
          disabled={formState.connectionTestResult !== null}
          loading={formState.loading}
          mixpanelEventId="datadog.onboarding"
        >
          Test connection
        </LoadingButton>
        {formState.connectionTestResult === false && (
          <Alert severity="error">
            <Stack>
              <AlertTitle>Test failed</AlertTitle>
              {formState.connectionError}
            </Stack>
          </Alert>
        )}
        {formState.connectionTestResult && <Alert severity="success">Test successful</Alert>}
      </Stack>
    </Stack>
  );
}
