import { type JSX, useCallback, useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MenuItem, TextField, Tooltip, Typography } from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import useFiltersFromUrl from "../../../Components/hooks/useFiltersFromUrl";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useGetLoginUrl } from "../../../utils/useGetLoginUrl";
import { EditPendingMPADialog } from "../Components/EditPendingMPADialog";
import { useMPARetireDialogFacade } from "../hooks/useMPARetireDialogFacade";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { useMPAActivationDialogFacade } from "../MPAActivationDialog/useMPAActivationDialogFacade";
import { useMPADeletionDialogFacade } from "../MPADeletionDialog/useMPADeletionDialogFacade";
import { cyIds, formatMonth } from "../MPAHeaderCards/MpaHeaderCards";
import { useMPARemoveRetiredDialogFacade } from "../MPARemoveRetiredDialog/useMPARemoveRetiredDialogFacade";
import { mpaColumns, mpaHeaders } from "./MpaTableColumns";
import MpaTableRow, { type MpaTableRowData } from "./MpaTableRow";

type Props = {
  masterPayerAccounts: MpaTableRowData[];
  masterPayerAccountsLoading: boolean;
  onNewMpaClicked: () => void;
  selectedMonth: string;
  setSelectedMonth: (month: string) => void;
  availableMonths: string[];
};

const MpaTable = ({
  masterPayerAccounts,
  masterPayerAccountsLoading,
  onNewMpaClicked,
  selectedMonth,
  setSelectedMonth,
  availableMonths,
}: Props) => {
  const { MPAActivationDialogComponent, showMPAActivationDialog } = useMPAActivationDialogFacade();
  const { MPADeletionDialogComponent, showMPADeletionDialog } = useMPADeletionDialogFacade();
  const { MPARetireDialogComponent, showMPARetireDialog } = useMPARetireDialogFacade();
  const { MPARemoveRetiredDialogComponent, showMPARemoveRetiredDialog } = useMPARemoveRetiredDialogFacade();

  const [pendingMasterPayerAccountToEdit, setPendingMasterPayerAccountToEdit] =
    useState<MasterPayerAccountsModelSnapshot | null>(null);
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const filtersFromQueryString = useFiltersFromUrl<MpaTableRowData>();
  const getLoginUrl = useGetLoginUrl();
  const snackbar = useSnackbar();

  const lengthenedSelectedMonth = useMemo<string>(() => `${selectedMonth}-01`, [selectedMonth]);

  const openAWSSupport = useCallback(
    async (accountId: string, customerId: string) => {
      try {
        const url = await getLoginUrl(String(accountId), isDoitEmployee, customerId);
        window.open(url, "_blank");
      } catch (e) {
        snackbar.onOpen({
          autoHideDuration: 3000,
          message: "Failed to redirect to AWS console",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          withClose: true,
          variant: "error",
        });
      }
    },
    [getLoginUrl, isDoitEmployee, snackbar]
  );

  const persistenceKey = filtersFromQueryString ? "" : "master_payer_accounts";

  const pendingEditSuccess = "MPA successfully updated";
  const supportModelTierEditSuccess = "Support model/tier successfully updated";

  const editSuccessMessage =
    pendingMasterPayerAccountToEdit?.status === "pending" ? pendingEditSuccess : supportModelTierEditSuccess;

  const availableMonthsOptions = useMemo<JSX.Element[]>(
    () =>
      availableMonths.map((month) => (
        <MenuItem key={month} value={month}>
          {formatMonth(month)}
        </MenuItem>
      )),
    [availableMonths]
  );

  const handleMonthChange = useCallback<(event: React.ChangeEvent) => void>(
    (event) => {
      const target = event.target as HTMLInputElement;
      setSelectedMonth(target.value);
    },
    [setSelectedMonth]
  );

  const dropdownHelpText =
    "This dropdown will only update fields relevant to billing issues: Billing status, PPA margin, EDP margin, Support margin, Total margin, Last refreshed";

  const dropdown = useMemo<JSX.Element>(
    () => (
      <>
        <TextField
          select
          value={selectedMonth}
          onChange={handleMonthChange}
          label="Month"
          size="small"
          sx={{ width: "20ch", mr: "auto", my: -0.5 }}
          data-cy={cyIds.controls.month}
          slotProps={{
            inputLabel: { shrink: true },
          }}
        >
          {availableMonthsOptions}
        </TextField>
        <Tooltip title={dropdownHelpText} arrow={true} placement="right">
          <Typography component="span">
            <InfoOutlinedIcon color="action" fontSize="medium" sx={{ pt: "0.25rem", ml: "0.5ch" }} />
          </Typography>
        </Tooltip>
      </>
    ),
    [availableMonthsOptions, handleMonthChange, selectedMonth]
  );

  const rowComponent = useCallback(
    ({ row }: { row: MpaTableRowData }) => (
      <MpaTableRow
        row={row}
        onEditOrActivateMPA={() => {
          showMPAActivationDialog(row.snapshot);
        }}
        onEditPendingMPA={() => {
          setPendingMasterPayerAccountToEdit(row.snapshot);
        }}
        onDeletePendingMPA={() => {
          showMPADeletionDialog(row.snapshot);
        }}
        onRetireMPA={() => {
          showMPARetireDialog(row.snapshot);
        }}
        onRemoveRetiredMPA={() => {
          showMPARemoveRetiredDialog(row.snapshot);
        }}
        onOpenSupport={() => openAWSSupport(row.number, row.customerId)}
        selectedMonth={lengthenedSelectedMonth}
      />
    ),
    [
      lengthenedSelectedMonth,
      openAWSSupport,
      showMPAActivationDialog,
      showMPADeletionDialog,
      showMPARemoveRetiredDialog,
      showMPARetireDialog,
    ]
  );

  const populatedTable = (
    <FilterTable<MpaTableRowData>
      toolbarProps={{
        title: dropdown,
        primaryButton: { text: "Create new MPA", onClick: onNewMpaClicked },
        allowToEditColumns: true,
      }}
      rowComponent={rowComponent}
      defaultFilters={filtersFromQueryString}
      headerColumns={mpaHeaders}
      filterColumns={mpaColumns}
      tableItems={masterPayerAccounts}
      defaultSortDirection="desc"
      defaultSortingColumnValue="payerNumber"
      persistenceKey={persistenceKey}
      data-cy="mpaFilterTable"
    >
      <MPAActivationDialogComponent />
      <MPADeletionDialogComponent />
      <MPARetireDialogComponent />
      <MPARemoveRetiredDialogComponent />
      <EditPendingMPADialog
        masterPayerAccount={pendingMasterPayerAccountToEdit}
        successMessage={editSuccessMessage}
        onClose={() => {
          setPendingMasterPayerAccountToEdit(null);
        }}
      />
    </FilterTable>
  );

  return masterPayerAccountsLoading ? <FilterTableSkeleton /> : populatedTable;
};

export default MpaTable;
