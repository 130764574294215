import { type ReactNode } from "react";

import { Box, Typography } from "@mui/material";

interface EmptyResultsMessageProps {
  children?: ReactNode;
}

const EmptyResultsMessage: React.FC<EmptyResultsMessageProps> = () => (
  <Box
    sx={{
      textAlign: "center",
      padding: 3,
      paddingTop: 6,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Typography
      variant="h2"
      sx={{
        fontSize: "3rem",
        fontWeight: "normal",
        marginBottom: 4,
      }}
    >
      No results found :'(
    </Typography>
    <Typography
      variant="h5"
      sx={{
        maxWidth: "100%",
        margin: "0 auto",
        whiteSpace: "nowrap",
        letterSpacing: "0.05em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
      }}
    >
      Try adjusting your search or filters
    </Typography>
  </Box>
);

export default EmptyResultsMessage;
