import { useCallback } from "react";

import { useHistory, useLocation } from "react-router";
import { type AlertColor, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useSnackbarTemplate } from "../SharedSnackbar/SharedSnackbar.context";

interface SnackbarContentProps {
  message: string;
  goBackText: string;
  callbackUrl: string;
}

const SnackbarContent = ({ message, goBackText, callbackUrl }: SnackbarContentProps) => {
  const history = useHistory();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center", justifyContent: "center" }}>
      <Typography>{message}</Typography>
      <Button
        variant="contained"
        onClick={() => {
          history.push(callbackUrl);
        }}
      >
        {goBackText}
      </Button>
    </Box>
  );
};

interface UseCallbackUrlSnackbarProps {
  message: string;
  variant?: AlertColor;
  goBackText?: string;
  urlQueryParam?: string;
  autoHideAfterSeconds?: number;
}

/**
 * Custom hook that displays a snackbar with a link to a callback URL.
 *
 * @param {Object} props - The properties for the hook.
 * @param {string} props.message - The message to display in the snackbar.
 * @param {string} [props.goBackText="Go back"] - The text for the go back button in the snackbar.
 * @param {string} [props.urlQueryParam="callbackUrl"] - The query parameter name for the callback URL.
 * @param {number} [props.autoHideAfterSeconds=10] - The duration in seconds after which the snackbar will auto-hide.
 * @param {AlertColor} [props.variant="success"] - The variant of the snackbar (info, success, error, warning).
 *
 * @returns {Function} A callback function that shows the snackbar if the callback URL is present in the query parameters.
 */
export const useCallbackUrlSnackbar = ({
  message,
  goBackText = "Go back",
  urlQueryParam = "callbackUrl",
  autoHideAfterSeconds = 10,
  variant = "success",
}: UseCallbackUrlSnackbarProps) => {
  const location = useLocation();

  const snackbar = useSnackbarTemplate(variant, autoHideAfterSeconds * 1000);

  return useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const callbackUrl = queryParams.get(urlQueryParam);

    if (callbackUrl) {
      snackbar(<SnackbarContent message={message} goBackText={goBackText} callbackUrl={callbackUrl} />);
    }
  }, [location.search, urlQueryParam, snackbar, message, goBackText]);
};
