export const INITIAL_SKILLS_SHOWN = 5;
export const SKILLS_INCREMENT = 5;
export const MIN_SKILL_USERS = 0;

export const SKILL_PLATFORMS = [
  { prefix: "aws/", label: "AWS" },
  { prefix: "gcp/", label: "GCP" },
  { prefix: "azure/", label: "Azure" },
  { prefix: "gsuite/", label: "Google Workspace" },
  { prefix: "ms365/", label: "Microsoft 365" },
  { prefix: "gdr/", label: "GetDoer" },
  { prefix: "cre/", label: "CRE" },
] as const;
