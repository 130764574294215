import { useCallback, useEffect, useMemo, useState } from "react";

import { type AttributionFilter, type DashboardModelAttributionModel, Metadata, Relation } from "@doitintl/cmp-models";
import Grid from "@mui/material/Grid2";

import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { Loader } from "../../../../Components/Loader";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AttributionMetadataOption } from "../../../../types";
import { getLetterForIndex } from "../../../../utils/string";
// Context and Local imports
import { getBaseAttributionFilter, getBaseMetadataOption, labelTagList, prepareLabelsFilters } from "../../utilities";
import AttributionBuilderRelation from "./AttributionBuilderRelation";
import AttributionBuilderRow from "./AttributionBuilderRow";

type AttributionBuilderProps = {
  attributionData: Partial<DashboardModelAttributionModel> | null;
  setValidAttribution: (valid: boolean) => void;
  setAttributionData: (attribution: Partial<DashboardModelAttributionModel> | null) => void;
};

const AttributionBuilder = ({ attributionData, setValidAttribution, setAttributionData }: AttributionBuilderProps) => {
  const { customer } = useCustomerContext();
  const { metadata, fetchMetadata } = useCloudAnalyticsMetadata();

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const metadataSnapshots = useMemo(
    () =>
      metadata?.filter(
        (md) => md.id !== `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}` && !md.id.startsWith(Metadata.DATETIME)
      ),
    [metadata]
  );
  const filterLabels = prepareLabelsFilters(attributionData?.filters || []);
  const { dimensions, handleDimensionLabelsChange } = useAnalyticsDimensions({
    metadataSnapshots,
    initialFilters: filterLabels,
  });
  const [relation, setRelation] = useState(Relation.AND);
  const [filters, setFilters] = useState(attributionData?.filters ?? [getBaseAttributionFilter()]);
  const [selected, setSelected] = useState<AttributionMetadataOption>(getBaseMetadataOption());

  const isValidAttribution = useMemo(() => {
    if (filters.length === 0 || !filters[0]?.id) {
      return false;
    }
    for (const filter of filters) {
      if (!(filter?.values?.length || filter?.allowNull || filter?.regexp)) {
        return false;
      }
    }
    return true;
  }, [filters]);

  const options = useMemo(() => {
    const mdOptions = dimensions?.filter((md) => md._visible || filters.findIndex((f) => f?.id === md.id) > -1) ?? [];
    return [...mdOptions, getBaseMetadataOption()];
  }, [dimensions, filters]);

  const formula = useMemo(() => {
    if (!isValidAttribution) {
      return "";
    }
    return Array.from({ length: filters.length }, (_, i) => getLetterForIndex(i)).join(` ${relation} `);
  }, [filters.length, relation, isValidAttribution]);

  useEffect(() => {
    setValidAttribution(isValidAttribution);
    if (isValidAttribution) {
      setAttributionData({
        customer: customer.ref,
        filters,
        formula,
      });
    }
  }, [customer.ref, filters, isValidAttribution, formula, setAttributionData, setValidAttribution]);

  // duplicate in attribution.jsx
  const handleChangeFilter = useCallback(
    (index: number) => (type: string, value, inverse?: boolean) => {
      setFilters((prevFilters) => {
        const filter = filters[index];
        filter.inverse = !!inverse;
        if (type === "regexp") {
          filter.values = null;
          filter.regexp = value;
        } else {
          filter.values = value;
          filter.regexp = null;
        }
        const newFilters = prevFilters.slice();
        newFilters.splice(index, 1, filter);
        return newFilters;
      });
    },
    [filters]
  );

  const handleSelectOption = async (newValue: AttributionMetadataOption, filter?: AttributionFilter) => {
    const type = newValue.data.type;
    const isLabel = type && labelTagList.includes(type);
    if (isLabel) {
      await handleDimensionLabelsChange([{ key: newValue.data.key, type: newValue.data.type }], filter);
    }
    const newSelected = { ...newValue };
    if (filter) {
      const { nullFallback } = newValue.data;
      newSelected._filter = filter.allowNull && nullFallback ? [nullFallback, ...(filter.values ?? [])] : filter.values;
    }
    setSelected(newSelected);
  };

  const handleRowsUpdate = useCallback(
    (index: number) => (update: string) => {
      if (update === "remove") {
        setFilters((prevFilters) => {
          const newFilters = prevFilters.slice();
          newFilters.splice(index, 1);
          return newFilters;
        });
        return;
      }
      // add row
      setFilters([...filters, getBaseAttributionFilter()]);
      setSelected(getBaseMetadataOption());
    },
    [filters]
  );

  return (
    <Loader loading={metadata.length === 0}>
      <Grid container>
        <Grid size={12}>
          <AttributionBuilderRelation relation={relation} setRelation={setRelation} />
        </Grid>
        {!!options?.length &&
          filters.map((f, i) => {
            const md = options.find((m) => m?.id === f?.id) ?? selected;
            return (
              <Grid
                key={f.id}
                container
                spacing={1}
                sx={{
                  alignItems: "center",
                }}
                size={12}
              >
                <AttributionBuilderRow
                  currentIndex={i}
                  dimensionTypes={options}
                  handleRowsUpdate={handleRowsUpdate(i)}
                  handleSelectOption={handleSelectOption}
                  onRowChange={handleChangeFilter(i)}
                  selectedDimensionOptions={f.values ?? []}
                  selectedType={md}
                  setFilters={setFilters}
                  filtersLength={filters.length}
                />
              </Grid>
            );
          })}
      </Grid>
    </Loader>
  );
};

export default AttributionBuilder;
