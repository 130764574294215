import { useCallback, useMemo } from "react";

import { useInstantSearch } from "react-instantsearch";
import CodeIcon from "@mui/icons-material/Code";
import PublicIcon from "@mui/icons-material/Public";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box } from "@mui/material";

import { MIN_SKILL_USERS, SKILL_PLATFORMS } from "./constants/searchFilters";
import { FilterDropdown } from "./FilterDropdown";
import { useSearch } from "./SearchContext";
import { useSearchFilters } from "./SearchFiltersContext";
import { type FilterOption, type Hit, type SkillGroup } from "./types/searchFilters";
import { cleanSkillName, groupTimezoneByContinent, isSkillHighlighted } from "./utils/searchFilterUtils";

type SearchFiltersProps = {
  onSelectFilter: (query: string) => void;
  currentQuery: string;
  disabled?: boolean;
};

// Add this interface above the component
interface FilterResults {
  timezoneFilters: FilterOption[];
  skillFilters: SkillGroup[];
  languageFilters: FilterOption[];
}

export const SearchFilters = ({ onSelectFilter, currentQuery, disabled }: SearchFiltersProps) => {
  const { initialHits } = useSearchFilters();
  const { results } = useInstantSearch();
  const { chips, setChips } = useSearch(); // Use the shared context

  const activeFilters = useMemo(() => {
    if (!currentQuery) return new Set<string>();
    return new Set(
      currentQuery
        .split(" ")
        .filter(Boolean)
        .map((term) => {
          // If it's a language term (not a platform/skill prefix), convert to lowercase
          if (!term.includes("/")) {
            return term.toLowerCase();
          }
          return term;
        })
    );
  }, [currentQuery]);

  const handleFilterItemSelection = useCallback(
    (filter: FilterOption) => {
      const currentChips = new Set(chips);

      if (currentChips.has(filter.query)) {
        currentChips.delete(filter.query);
      } else {
        currentChips.add(filter.query);
      }

      const newChips = Array.from(currentChips);
      setChips(newChips);
      onSelectFilter(newChips.join(" "));
    },
    [chips, setChips, onSelectFilter]
  );

  const { timezoneFilters, skillFilters, languageFilters } = useMemo<FilterResults>(() => {
    const currentHits = (results?.hits as Hit[]) ?? [];
    const availableSkills = new Set<string>();
    const availableLanguages = new Set<string>();
    const availableContinents = new Map<string, string>();

    // Collect available options from current results
    currentHits.forEach((hit) => {
      hit.languages?.forEach((lang) => availableLanguages.add(lang));
      hit.primary_skills?.forEach((skill) => availableSkills.add(skill));
      hit.secondary_skills?.forEach((skill) => availableSkills.add(skill));
      hit.speciality_skills?.forEach((skill) => availableSkills.add(skill));

      if (hit.timezone) {
        const { continent, query } = groupTimezoneByContinent(hit.timezone);
        availableContinents.set(continent, query);
      }
    });

    // Collect all possible options from initial hits with counts
    const allSkills = new Map<string, number>(); // Track skill counts
    const allLanguages = new Set<string>();
    const allContinents = new Map<string, string>();

    initialHits.forEach((hit: Hit) => {
      hit.languages?.forEach((lang) => allLanguages.add(lang));

      // Count occurrences of each skill
      const addSkill = (skill: string) => {
        allSkills.set(skill, (allSkills.get(skill) ?? 0) + 1);
      };

      hit.primary_skills?.forEach(addSkill);
      hit.secondary_skills?.forEach(addSkill);
      hit.speciality_skills?.forEach(addSkill);

      if (hit.timezone) {
        const { continent, query } = groupTimezoneByContinent(hit.timezone);
        allContinents.set(continent, query);
      }
    });

    const groupSkills = (skills: Map<string, number>) => {
      // Create groups for each platform without the Services subgroup
      const groups: SkillGroup[] = SKILL_PLATFORMS.map((platform) => ({
        label: platform.label,
        subGroups: [
          { label: "", skills: [] }, // Empty label
        ],
      }));

      const otherGroup: SkillGroup = {
        label: "Other",
        subGroups: [
          { label: "", skills: [] }, // Empty label
        ],
      };

      // Create a set of highlighted skills from current results
      const highlightedSkills = new Set<string>();
      (results?.hits as Hit[]).forEach((hit) => {
        const hitHighlights = isSkillHighlighted(hit);
        hitHighlights.forEach((skill) => highlightedSkills.add(skill));
      });

      [...skills.entries()]
        .filter(([skill, count]) => count >= MIN_SKILL_USERS || highlightedSkills.has(skill))
        .sort((a, b) => {
          // First sort by availability
          const aAvailable = availableSkills.has(a[0]);
          const bAvailable = availableSkills.has(b[0]);
          if (aAvailable !== bAvailable) {
            return bAvailable ? 1 : -1;
          }

          // Then sort by highlighted status
          const aHighlighted = highlightedSkills.has(a[0]);
          const bHighlighted = highlightedSkills.has(b[0]);
          if (aHighlighted !== bHighlighted) {
            return bHighlighted ? 1 : -1;
          }

          // Finally sort by count
          return b[1] - a[1];
        })
        .forEach(([skill, count]) => {
          // Process all CRE and GDR skills, but only service skills for other platforms
          const platform = skill.split("/")[0];
          if (!skill.includes("/service/") && !["cre", "gdr"].includes(platform)) return;

          const parts = skill.split("/");
          const lastPart = parts[parts.length - 1];

          const labelParts = parts
            .slice(1)
            .filter((part) => part !== "service")
            // Remove "activity" from GDR skills
            .filter((part) => !(platform === "gdr" && part.toLowerCase() === "activity"))
            .map((part) => cleanSkillName(part, false));

          const filter: FilterOption = {
            label: labelParts.join(" ").trim(),
            query: cleanSkillName(lastPart, true),
            available: activeFilters.size === 0 || availableSkills.has(skill),
            _count: count,
          };

          // Find the appropriate platform group
          const platformGroup = SKILL_PLATFORMS.findIndex((p) => skill.startsWith(p.prefix));
          const targetGroup = platformGroup !== -1 ? groups[platformGroup] : otherGroup;

          targetGroup.subGroups[0].skills.push(filter);
        });

      // Filter out empty groups
      const nonEmptyGroups = [...groups, otherGroup]
        .map((group) => ({
          ...group,
          subGroups: group.subGroups.filter((subGroup) => subGroup.skills.length > 0),
        }))
        .filter((group) => group.subGroups.length > 0);

      return nonEmptyGroups;
    };

    // Track language counts
    const languageCounts = new Map<string, number>();

    initialHits.forEach((hit) => {
      hit.languages?.forEach((lang) => {
        const normalizedLang = lang.toLowerCase();
        languageCounts.set(normalizedLang, (languageCounts.get(normalizedLang) ?? 0) + 1);
      });
    });

    return {
      timezoneFilters: [...allContinents.entries()]
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([continent, query]) => ({
          label: `${continent} timezone`,
          query: query.toLowerCase(),
          available: activeFilters.size === 0 || availableContinents.has(continent),
        })),
      skillFilters: groupSkills(allSkills),
      languageFilters: [...allLanguages]
        .map((lang) => {
          const normalizedLang = lang.toLowerCase();
          return {
            label: `${lang} Speakers`,
            query: normalizedLang,
            available:
              activeFilters.size === 0 || availableLanguages.has(lang) || availableLanguages.has(normalizedLang),
            _count: languageCounts.get(normalizedLang) ?? 0,
          };
        })
        .sort((a, b) => {
          const aSelected = activeFilters.has(a.query);
          const bSelected = activeFilters.has(b.query);

          if (aSelected !== bSelected) {
            return bSelected ? -1 : 1;
          }

          const countDiff = b._count - a._count;
          if (countDiff !== 0) {
            return countDiff;
          }

          return a.label.localeCompare(b.label);
        }),
    };
  }, [initialHits, results?.hits, activeFilters]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        flexShrink: 0,
      }}
    >
      <FilterDropdown
        title="Timezone"
        icon={<PublicIcon />}
        filters={timezoneFilters}
        activeFilters={activeFilters}
        onSelect={handleFilterItemSelection}
        disabled={disabled}
      />
      <FilterDropdown
        title="Skills"
        icon={<CodeIcon />}
        filters={skillFilters}
        activeFilters={activeFilters}
        onSelect={handleFilterItemSelection}
        disabled={disabled}
      />
      <FilterDropdown
        title="Language"
        icon={<TranslateIcon />}
        filters={languageFilters}
        activeFilters={activeFilters}
        onSelect={handleFilterItemSelection}
        disabled={disabled}
      />
    </Box>
  );
};
