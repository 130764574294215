import { useEffect, useMemo, useState } from "react";

import { type TModelCtor } from "@doitintl/models-types";
import { type DocumentData, type FirestoreError, type SnapshotListenOptions } from "firebase/firestore";

import {
  addIdRef,
  type FirebaseQueryModel,
  type FirebaseQuerySnapshotModel,
  getDocsFromNamedQuery,
  type GetSnapshotOptions,
  type IDOptions,
  type ModelData,
} from "../../core";
import { useFirestoreCollection, type UseFirestoreHookOptions } from "../../react-query";
import { useLoadingValue } from "../util";
import { useIsFirestoreQueryWithKeysEqual } from "./compares";
import { handleRefListenQuery, snapshotToData, useIsFirestoreQueryEqual } from "./helpers";
import {
  type CachingEnabledOptions,
  type CachingOptions,
  type CollectionDataHook,
  type CollectionHook,
  type DataOptions,
  type IDOptionsWithTransform,
  type OnceDataOptions,
  type TReturnData,
  type TypeFromTransform,
  type TypeOrUndefined,
} from "./types";
import { shouldEnabledCaching } from "./utils";

export const useCollection = <T extends DocumentData>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: SnapshotListenOptions
): CollectionHook<T> => useCollectionInternal<T>(query, { subscribe: true, ...options });

export const useCollectionOnce = <T extends DocumentData>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: GetSnapshotOptions
): CollectionHook<T> => useCollectionInternal<T>(query, options);

export const useCollectionData = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: DataOptions<T, IDField, RefField, TTransformModel>
): CollectionDataHook<TReturnData<T, IDField, RefField, TTransformModel>> =>
  useCollectionDataInternal<T, IDField, RefField, TTransformModel>(query, { subscribe: true, ...options });

export const useCollectionDataOnce = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: OnceDataOptions<T, IDField, RefField, TTransformModel> & { disableReactQuery?: boolean }
): CollectionDataHook<TReturnData<T, IDField, RefField, TTransformModel>> => {
  const actualSource = options?.source ?? "default";
  return useCollectionDataInternal<T, IDField, RefField, TTransformModel>(query, {
    ...options,
    source: actualSource,
  });
};

export const useCollectionReactQuery = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: UseFirestoreHookOptions &
    IDOptionsWithTransform<T, IDField, RefField, TTransformModel> &
    CachingEnabledOptions
) => {
  const [queryState, setQueryState] = useState(query);
  const [cachingState, setCachingState] = useState(options?.cachingKeys);
  useIsFirestoreQueryWithKeysEqual(query ? { query, cachingKeys: options?.cachingKeys } : undefined, (value) => {
    setQueryState(value?.query);
    setCachingState(value?.cachingKeys);
  });

  const queryKeys = queryState
    ? [
        ...(cachingState ?? []),
        "query",
        options?.subscribe ? "listen" : "data",
        queryState.collectionName,
        ...queryState.queryKeys,
      ]
    : undefined;
  const collectionDataResult = useFirestoreCollection(queryKeys, queryState, options);

  return [
    collectionDataResult.data as FirebaseQuerySnapshotModel<T>,
    collectionDataResult.isLoading,
    collectionDataResult.error ?? undefined,
  ] as CollectionHook<T>;
};

export const useCollectionDirect = <T extends DocumentData>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: UseFirestoreHookOptions
): CollectionHook<T> => {
  const { error, loading, reset, setError, setValue, value } = useLoadingValue<
    FirebaseQuerySnapshotModel<T>,
    FirestoreError
  >();
  const ref = useIsFirestoreQueryEqual<FirebaseQueryModel<T>>(query, reset);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleRefListenQuery({ ref: ref.current, setValue, setError }, options), [ref.current]);

  return useMemo(
    () => [value as FirebaseQuerySnapshotModel<T>, loading, error] as CollectionHook<T>,
    [value, loading, error]
  );
};

const useCollectionInternal = <T extends DocumentData>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options?: UseFirestoreHookOptions & CachingOptions
): CollectionHook<T> =>
  shouldEnabledCaching(options)
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useCollectionReactQuery(query, options)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useCollectionDirect(query, options);

const useCollectionDataInternal = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  query: TypeOrUndefined<FirebaseQueryModel<T>>,
  options: UseFirestoreHookOptions & IDOptionsWithTransform<T, IDField, RefField, TTransformModel>
): CollectionDataHook<TReturnData<T, IDField, RefField, TTransformModel>> => {
  const { idField, refField, snapshotOptions, transform, ...restOptions } = options;

  const [snapshot, loading, error] = useCollectionInternal<T>(query, restOptions);

  const [values, setValues] = useState<TReturnData<T, IDField, RefField, TTransformModel>[] | undefined>();

  const isLoading = useMemo(() => loading || (values === undefined && !error), [loading, values, error]);

  const { serverTimestamps } = snapshotOptions ?? {};

  useEffect(() => {
    if (!snapshot) {
      setValues(undefined);
      return;
    }

    const promisesOrValues = snapshot.docs.map((doc) =>
      snapshotToData(doc, idField, refField, transform, { serverTimestamps })
    );

    if (!promisesOrValues.length) {
      setValues([]);
      return;
    }

    if (promisesOrValues[0] instanceof Promise) {
      void Promise.all(promisesOrValues).then((results) => {
        setValues(results.filter((r) => !!r) as TReturnData<T, IDField, RefField, TTransformModel>[]);
      });
    } else {
      setValues(promisesOrValues.filter((r) => !!r) as TReturnData<T, IDField, RefField, TTransformModel>[]);
    }
  }, [snapshot, transform, serverTimestamps, idField, refField]);

  return [values, isLoading, error] as CollectionDataHook<TReturnData<T, IDField, RefField, TTransformModel>>;
};

export const useCacheQueryData = <
  T extends DocumentData,
  IDField extends string | undefined,
  RefField extends string | undefined,
>(
  name: string,
  options: IDOptions<IDField, RefField> & { model: TModelCtor<T> }
): CollectionDataHook<ModelData<T, IDField, RefField>> => {
  const [state, setState] = useState<CollectionDataHook<ModelData<T, IDField, RefField>>>([undefined, true, undefined]);

  useEffect(() => {
    getDocsFromNamedQuery<T>(name)
      .then((snapshot) =>
        snapshot.docs.map((doc) =>
          addIdRef(options.model, doc.snapshot, { idField: options.idField, refField: options.refField })
        )
      )
      .then((data) => {
        setState([data, false, undefined] as CollectionDataHook<ModelData<T, IDField, RefField>>);
      })
      .catch((error: unknown) => {
        setState([undefined, false, error as FirestoreError]);
      });
  }, [name, options.idField, options.model, options.refField]);

  return state;
};
