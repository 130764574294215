import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNew from "@mui/icons-material/OpenInNew";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";

import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { formatDecimalNumber } from "../../../utils/common";
import { handleBackClick } from "../../../utils/navigation";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { useInsightsContext } from "../context";
import { EasyWinChip } from "../EasyWin/EasyWin";
import { useInsightsInvestigation, useMdxContent } from "../hooks";
import { sendInsightEvent } from "../metrics";
import { NotFound } from "../NotFound/NotFound";
import { savingsPeriodToLabel } from "../Preview/InsightObjectivePreview";
import { InsightStatusInfoBar } from "../Preview/InsightStatusInfoBar";
import { Preview } from "../Preview/Preview";
import { type BreakdownData } from "../types";
import {
  convertToDisplaySecurityRiskBreakdown,
  getAlert,
  totalCustomProblems,
  totalPotentialDailySavings,
  totalSecurityRisksBreakdown,
} from "../utils";
import { DetailedBreakdown } from "./DetailedBreakdown";
import { GetStarted } from "./GetStarted";

type ExpectedResultItemProps = {
  status: string | undefined;
  bold: boolean;
};

const ExpectedResultItem = (props: React.PropsWithChildren<ExpectedResultItemProps>) => (
  <li>
    <Typography
      mt={0.5}
      variant="subtitle1"
      sx={{
        fontWeight: props.bold ? "500" : "regular",
      }}
      color={props.status === "dismissed" ? "text.primary" : "success.main"}
      fontSize="16px"
    >
      {props.children}
    </Typography>
  </li>
);

export function InsightsDetails() {
  const { customer } = useCustomerContext();
  const { savingsPeriod, calculateSavingsForSelectedPeriod, selectedInsight: matchingInsight } = useInsightsContext();
  const theme = useTheme();
  const history = useHistory();
  const mixpanelInsight = `${matchingInsight?.key}#${matchingInsight?.providerId}`;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { trackEvent } = useSegmentTrackEvent();
  const getInvestigationReportUrl = useInsightsInvestigation({ insightName: matchingInsight?.title });
  const insightDescription = useMdxContent(matchingInsight?.detailedDescriptionMdx ?? "");
  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();
  const [savings, setSavings] = useState("");
  const [isSavingsComputed, setIsSavingsComputed] = useState(false);

  useEffect(() => {
    setIsFirstRender(false);
  }, [mixpanelInsight]);

  const handleOpenReport = useCallback(async () => {
    let reportUrl = matchingInsight?.reportUrl || "";

    if (!reportUrl) {
      reportUrl = await getInvestigationReportUrl({
        config: matchingInsight?.results?.reportConfig,
      });
    }

    window.open(reportUrl, "_blank");
  }, [getInvestigationReportUrl, matchingInsight?.results?.reportConfig, matchingInsight?.reportUrl]);

  useEffect(() => {
    const main = async () => {
      if (!matchingInsight) {
        return;
      }

      const potentialDailySavings = totalPotentialDailySavings(matchingInsight);
      if (potentialDailySavings) {
        const potentialDailySavingsForPeriod = calculateSavingsForSelectedPeriod(potentialDailySavings);
        const valueConverted = await asyncConvertCurrency(potentialDailySavingsForPeriod);
        setSavings(`${customerCurrencySymbol}${formatDecimalNumber(valueConverted, 0)}`);
      }

      setIsSavingsComputed(true);
    };

    main();
  }, [asyncConvertCurrency, calculateSavingsForSelectedPeriod, customerCurrencySymbol, matchingInsight]);

  useEffect(() => {
    if (!matchingInsight || !isSavingsComputed) return;

    sendInsightEvent({
      insight: matchingInsight,
      eventName: "Insight Detail Viewed",
      trackEvent,
      isFirstRender,
      savingsPeriod,
      insightTotalSavingsString: savings,
    });
  }, [matchingInsight, isSavingsComputed, savingsPeriod, savings, trackEvent, isFirstRender]);

  if (!matchingInsight) {
    return <NotFound />;
  }

  let newSupportTicketUrl = `/customers/${customer.id}/support/new`;

  // To pre-fill the support template, we need to have exactly one cloud tag, and a category on the insight
  if (matchingInsight.cloudTags.length === 1 && matchingInsight.supportCategory) {
    const platform = matchingInsight.cloudTags.includes("gcp") ? "google_cloud_platform" : "amazon_web_services";

    // Step 1: Platform + Category (Product)
    newSupportTicketUrl += `/insights-support?platform=${platform}&category=${matchingInsight.supportCategory}`;

    // Step 2: Severity (from the Firestore template)
    // Step 3: Subject, Project ID + Message body parameters (body from the Firestore template)
    newSupportTicketUrl += `&subject=${encodeURIComponent(`Support for applying "${matchingInsight.title}" insight`)}`;

    // Step 4: Tags
    newSupportTicketUrl += `&tags=insight,insightsKey:${matchingInsight.key},insightsProvider:${matchingInsight.providerId}`;

    let breakdownData: BreakdownData[] | null = null;

    // Add options here for other result types
    if (matchingInsight.results?.potentialDailySavings?.breakdown?.data?.length) {
      breakdownData = matchingInsight.results.potentialDailySavings.breakdown.data;
    } else if (matchingInsight.results?.customProblems?.breakdown?.data?.length) {
      breakdownData = matchingInsight.results.customProblems.breakdown.data;
    }

    if (breakdownData) {
      const sortedData = [...breakdownData];
      sortedData.sort((a, b) => b.value - a.value);

      // The first dimension is GCP project/AWS account
      newSupportTicketUrl += `&resource=${sortedData[0].dimensionValues[0]}`;
    }

    newSupportTicketUrl += `&insight_url=${encodeURIComponent(window.location.href)}`;

    if (
      matchingInsight.customInsightAttributes?.showPublishingUser &&
      matchingInsight.customInsightAttributes?.publishingUserEmail &&
      matchingInsight.publishingUser !== null
    ) {
      newSupportTicketUrl += `&collaboratorEmail=${matchingInsight.customInsightAttributes?.publishingUserEmail}`;
    }
  }

  const customProblemsTotal = totalCustomProblems(matchingInsight);

  const securityRiskSummary = totalSecurityRisksBreakdown(matchingInsight);

  const customProblemsUnit = matchingInsight.results?.customProblems?.unitLongPlural;

  const showAlert = Boolean(getAlert(matchingInsight));

  const potentialSavingsPresent = matchingInsight.results?.potentialDailySavings?.breakdown;
  const otherOutcomesPresent = matchingInsight.results?.customProblems?.breakdown;
  const securityRisksPresent = matchingInsight.results?.securityRisks?.breakdown;

  const showExpectedOutcomes =
    !showAlert ||
    (matchingInsight.userStatusChanges?.status === "dismissed" &&
      (potentialSavingsPresent || otherOutcomesPresent || securityRisksPresent));

  return (
    <Container maxWidth="lg">
      {/* Space to top should be 6, but 1 is already added to the main page area, and 2 is added by the CardHeader for the back arrow */}
      <Box
        sx={{
          mt: 3,
          mb: 4,
        }}
      >
        {/* Do not set `spacing` instead here - this will add a negative margin-top, which will collapse with the margin above */}
        <Grid container columnSpacing={3}>
          <Grid
            size={{
              xs: 12,
              md: 8,
            }}
          >
            <Card sx={{ border: "none" }}>
              <CardHeader
                sx={{
                  ".MuiCardHeader-avatar": {
                    mt: -1,
                    alignSelf: "flex-start",
                  },
                }}
                title={
                  <Preview
                    key={`${matchingInsight.providerId}#${matchingInsight.key}`}
                    insight={matchingInsight}
                    isListView={false}
                  />
                }
                avatar={
                  <IconButton
                    aria-label="Back"
                    onClick={handleBackClick(history, `/customers/${customer.id}/insights`)}
                    size="large"
                  >
                    <BackIcon sx={{ color: "text.primary" }} />
                  </IconButton>
                }
              />
              {matchingInsight.isInternal && (
                <Box sx={{ pl: 10, paddingRight: "16px" }}>
                  <Alert severity="info" variant="filled" sx={{ mt: 1, backgroundColor: theme.palette.primary.main }}>
                    This insight is only visible to DoiT employees and should not be shared with customers (yet).
                  </Alert>
                </Box>
              )}{" "}
              {showAlert && (
                <CardContent sx={{ pl: 10, py: 0, mb: 3, mt: 1 }}>
                  <InsightStatusInfoBar insight={matchingInsight} />
                </CardContent>
              )}
              {showExpectedOutcomes && (
                <CardContent sx={{ pl: 10, py: 0, mb: 3 }}>
                  <Divider />
                  <Typography variant="subtitle1" color="text.primary" fontSize="16px" fontWeight="500" mt={3}>
                    Expected outcomes
                  </Typography>
                  <Typography
                    mt={2}
                    variant="subtitle1"
                    color={matchingInsight.userStatusChanges?.status === "dismissed" ? "text.primary" : "success.main"}
                    fontWeight="500"
                    fontSize="16px"
                  >
                    <ul style={{ paddingInlineStart: "30px" }}>
                      {matchingInsight.results?.potentialDailySavings?.breakdown && (
                        <>
                          <li>
                            <div
                              data-cy="potential-savings-up-to"
                              style={{ display: "flex", alignItems: "center", gap: "3px" }}
                            >
                              Potential savings of up to {savings} per {savingsPeriodToLabel[savingsPeriod]}{" "}
                              <Tooltip
                                title={
                                  "Savings amount is based on the maximum estimated potential savings on the day this insight was published and may not be accurate depending on many factors."
                                }
                              >
                                <InfoOutlinedIcon sx={{ color: "text.secondary" }} />
                              </Tooltip>
                            </div>
                          </li>
                          {matchingInsight.easyWinDescription && (
                            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-start" }}>
                              <EasyWinChip
                                tooltipText={matchingInsight.easyWinDescription}
                                tooltipPlacement={"right-end"}
                              />
                            </Box>
                          )}
                        </>
                      )}
                      {matchingInsight.customInsightAttributes &&
                        matchingInsight.results?.customProblems?.breakdown?.data.map((problem) => {
                          const problemCount = problem.value;
                          const problemUnit = problem.dimensionValues[0];

                          return (
                            <ExpectedResultItem
                              key={problemUnit}
                              bold={false}
                              status={matchingInsight.userStatusChanges?.status}
                            >
                              {problemCount} {problemUnit}
                            </ExpectedResultItem>
                          );
                        })}
                      {!matchingInsight?.customInsightAttributes && customProblemsTotal && (
                        <ExpectedResultItem bold={false} status={matchingInsight.userStatusChanges?.status}>
                          {customProblemsTotal} {customProblemsUnit}
                        </ExpectedResultItem>
                      )}
                      {matchingInsight.results?.securityRisks && securityRiskSummary && (
                        <>
                          {Object.keys(securityRiskSummary).map((k) => {
                            if (securityRiskSummary[k] === 0) {
                              return;
                            }
                            return (
                              <ExpectedResultItem
                                key={k}
                                bold={k === "high" || k === "critical"}
                                status={matchingInsight.userStatusChanges?.status}
                              >
                                Resolution of {securityRiskSummary[k]} {k.toLowerCase()} severity security issues
                              </ExpectedResultItem>
                            );
                          })}
                        </>
                      )}
                    </ul>
                  </Typography>
                </CardContent>
              )}
              {insightDescription && (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Divider />

                  <Typography
                    variant="h4"
                    sx={{
                      mb: 2,
                      mt: 3,
                    }}
                  >
                    Description
                  </Typography>

                  {insightDescription}
                </CardContent>
              )}
              {!matchingInsight.customInsightAttributes && matchingInsight.results?.potentialDailySavings?.breakdown ? (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 3,
                    }}
                  >
                    Estimated potential {savingsPeriod} savings
                  </Typography>
                  {/* Skip any entries less than a cent */}
                  <DetailedBreakdown
                    breakdown={matchingInsight.results?.potentialDailySavings.breakdown}
                    dataFilterFunction={(row) => row.value >= 0.01}
                    allowCondensedBreakdown={false}
                    unit="Savings"
                    valueFormatter={async (value) => {
                      const valueConverted = await asyncConvertCurrency(calculateSavingsForSelectedPeriod(value));

                      return `${customerCurrencySymbol}${formatDecimalNumber(valueConverted, 2)}`;
                    }}
                  />
                </CardContent>
              ) : null}
              {!matchingInsight.customInsightAttributes && matchingInsight.results?.customProblems?.breakdown ? (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 3,
                    }}
                  >
                    {matchingInsight.results.customProblems.unitLongPlural}
                  </Typography>
                  <DetailedBreakdown
                    breakdown={matchingInsight.results.customProblems.breakdown}
                    dataFilterFunction={(row) => row.value > 0}
                    allowCondensedBreakdown
                    unit={matchingInsight.results.customProblems.unitShortPlural}
                    valueFormatter={(value) => formatDecimalNumber(value, 0)}
                  />
                </CardContent>
              ) : null}
              {!matchingInsight.customInsightAttributes && matchingInsight.results?.securityRisks?.breakdown ? (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 3,
                    }}
                  >
                    Security Risks
                  </Typography>
                  <DetailedBreakdown
                    breakdown={
                      convertToDisplaySecurityRiskBreakdown(
                        matchingInsight.providerId,
                        matchingInsight.results.securityRisks
                      )!
                    }
                    dataFilterFunction={(row) => row.value > 0}
                    allowCondensedBreakdown={false}
                    unit="Number of Risks"
                    valueFormatter={(value) => formatDecimalNumber(value, 0)}
                  />
                </CardContent>
              ) : null}
              {(matchingInsight?.results?.reportConfig || matchingInsight?.reportUrl) && (
                <Box sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mt: 3,
                    }}
                  >
                    Further investigation
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
                    For an in-depth analysis of the data behind these recommendations, you can check the report
                    generated by DoiT Cloud Intelligence™️.
                  </Typography>
                  <Button variant="outlined" endIcon={<OpenInNew />} onClick={() => handleOpenReport()}>
                    Open report
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
          {/* The CardHeader adds 2 spacing which we need to add also here if we have two columns */}
          <Grid
            sx={{
              mt: { md: 2 },
            }}
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <GetStarted
              insight={matchingInsight}
              newSupportTicketUrl={newSupportTicketUrl}
              mixpanelInsight={mixpanelInsight}
              savingsPeriod={savingsPeriod}
              totalSavings={savings}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
