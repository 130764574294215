import { type CSSProperties, memo } from "react";

import { Handle, Position } from "@xyflow/react";

type Props = {
  id: string;
  style?: CSSProperties;
  idSuffix?: string;
};

type HandleProps = Props & {
  type: "source" | "target";
  position: Position;
};

const BaseHandle = ({ id, type, position, style, idSuffix }: HandleProps) => (
  <Handle
    id={`${type}-${id}${idSuffix ? `-${idSuffix}` : ""}`}
    type={type}
    position={position}
    style={{ visibility: "hidden", ...style }}
  />
);

const InternalSourceHandle = ({ id, style, idSuffix }: Props) => (
  <BaseHandle id={id} type="source" position={Position.Bottom} style={style} idSuffix={idSuffix} />
);

const InternalTargetHandle = ({ id, style, idSuffix }: Props) => (
  <BaseHandle id={id} type="target" position={Position.Top} style={style} idSuffix={idSuffix} />
);

const SourceHandle = memo(InternalSourceHandle);
const TargetHandle = memo(InternalTargetHandle);

export { SourceHandle, TargetHandle };
