import { type AuthenticationProvider } from "@doitintl/cmp-models";

export type UserAuthConfig = {
  providerId: string;
  provider: string;
  allowedProvider?: `${AuthenticationProvider}`; // the customer level configured provider
  tenantId: string;
  useSsoWithoutProxy?: boolean;
  isPartner?: boolean;
  hasPasswordProvider?: boolean;
};
export type HandleEmailResponse = {
  userData: {
    email: string;
    uid: string;
  };
  tenantId: string;
};
export type LoginType = "login" | "signup" | "completeSignup";
export type FormType =
  | "checkEmail"
  | "checkEmailSuccess" // email sent successfully - check your inbox (either email link or registration email)
  | "enterEmailForEmailLink"
  | "enterPassword"
  | "verifyEmailForEmailLinkSignIn" // user clicked on email link and no local storage data exists
  | "sso"
  | "forgotPassword"
  | "signup"
  | "completeSignup";

export enum LoginHookActionType {
  initLoading = "initLoading",
  stopLoading = "stopLoading",
  back = "back",
  setTenantId = "setTenantId",
  setFormType = "setFormType",
  setEmail = "setEmail",
  setPassword = "setPassword",
  setLoginType = "setLoginType",
  setShowContactUs = "setShowContactUs",
}

export type LoginState = {
  isLoading: boolean;
  formType: FormType;
  tenantId: string | null;
  email: string | null;
  loginType?: LoginType;
  password?: string;
  showContactUs?: boolean;
};

export type LoginHookAction =
  | {
      type: LoginHookActionType.initLoading | LoginHookActionType.stopLoading | LoginHookActionType.back;
    }
  | {
      type: LoginHookActionType.setTenantId;
      tenantId: string;
    }
  | {
      type: LoginHookActionType.setFormType;
      formType: FormType;
    }
  | {
      type: LoginHookActionType.setEmail;
      email: string;
    }
  | {
      type: LoginHookActionType.setPassword;
      password: string;
    }
  | {
      type: LoginHookActionType.setLoginType;
      loginType: LoginType;
      formType: FormType;
    }
  | {
      type: LoginHookActionType.setShowContactUs;
      showContactUs: boolean;
    };

export enum SupportedAuthProviders {
  Google = "Google",
  Microsoft = "Microsoft",
}

export type EmailPasswordSubmit = (
  { email, password }: { email: string; password: string },
  { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
) => Promise<void>;

export type EmailSubmit = (
  { email }: { email: string; fssaFirstName?: string; fssaLastName?: string },
  { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
) => Promise<void>;
