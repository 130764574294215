import { ClusterConnectionStatus, ClusterType } from "@doitintl/cmp-models";
import { Box, CircularProgress } from "@mui/material";

import { Stepper } from "../../../Components/Stepper";
import { useClusterConnectionStatusCheck, useKubernetesClusterActivation } from "./hooks";
import { AccessEntryStep, EnableFeaturesStep, TestConnectionStep } from "./Steps";
import { type StepConfig, useSteps } from "./utils";

const EksClusterActivation = () => {
  const {
    currentStep,
    selectedProjectId,
    selectedCluster,
    selectedFeatures,
    setCurrentStep,
    setSelectedFeatures,
    goBack,
    handleSubmit,
  } = useKubernetesClusterActivation(ClusterType.EKS);

  const { statusHasBeenChecked } = useClusterConnectionStatusCheck(selectedCluster);

  const stepConfig: StepConfig[] = [
    {
      label: "Enable features",
      isComplete: selectedFeatures.length > 0,
      children: (
        <EnableFeaturesStep
          projectId={selectedProjectId}
          cluster={selectedCluster}
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
        />
      ),
    },
    {
      label: "Test connection",
      children: (
        <TestConnectionStep
          projectId={selectedProjectId}
          cluster={selectedCluster}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
  ];

  if (selectedCluster?.connectionStatus !== ClusterConnectionStatus.HEALTHY) {
    stepConfig.unshift({
      label: "Access entry",
      children: <AccessEntryStep accountId={selectedProjectId} cluster={selectedCluster} />,
    });
  }

  const steps = useSteps({ stepConfig, currentStep });

  if (!statusHasBeenChecked) {
    return (
      <Box sx={{ height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        pt: 4,
        pb: 5,
      }}
    >
      <Stepper
        submitButtonLabel="Done"
        steps={steps}
        loading={false}
        onCancel={goBack}
        onSubmit={handleSubmit}
        overrideStep={currentStep}
        getCurrentStep={setCurrentStep}
        footerMaxWidth={830}
        maxWidth={1000}
        contentSx={{
          mt: 3,
          mb: 3,
          width: 1000,
          padding: "0 101px",
        }}
      />
    </Box>
  );
};

export default EksClusterActivation;
