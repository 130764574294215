import { useState } from "react";

import { Alert, AlertTitle, Stack, Typography } from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import LoadingButton from "../../../../Components/LoadingButton";
import { UsefulSnowflakeLinks } from "./UsefulSnowflakeLinks";
import { getCustomSnowflakeErrorMessage, type SnowflakeOnboardingRequest } from "./utils";

type Props = {
  onboardingRequest: SnowflakeOnboardingRequest;
  wasTestSuccessful: boolean;
  setWasTestSuccessful: (wasTestSuccessful: boolean) => void;
};

export default function SnowflakeStepTestConnection({
  onboardingRequest,
  wasTestSuccessful: wasTestSuccessful,
  setWasTestSuccessful: setWasTestSuccessful,
}: Props) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = useApiContext();
  const customerId = useCustomerId();

  const testConnection = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/snowflake-onboarding/store-config?customerID=${customerId}`,
        onboardingRequest,
        {
          timeout: 90000,
        }
      );
      if (response.status === 200) {
        setError(true);
        setSuccess(true);
        setWasTestSuccessful(true);
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch (e: any) {
      setErrorMessage(getCustomSnowflakeErrorMessage(e?.response?.data?.error ?? e.message, e.code === "ECONNABORTED"));
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2} data-cy="test-connection-step">
      <Typography variant="h1">Test your Snowflake connection with DoiT</Typography>
      <Stack
        spacing={2}
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <Stack
          key={1}
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={{
              mb: 2,
            }}
          >
            To make sure the setup is successful, we need to test the connection. This should take a couple of seconds.
          </Typography>
          <LoadingButton
            data-cy="snowflake-test-connection-button"
            mixpanelEventId="Test Snowflake Connection"
            loading={loading}
            disabled={wasTestSuccessful}
            variant="outlined"
            onClick={testConnection}
          >
            Test connection
          </LoadingButton>
        </Stack>
      </Stack>
      {success ? (
        <Stack
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Alert severity="success" data-cy="snowflake-test-connection-success" sx={{ width: "100%" }}>
            <AlertTitle>Test successful</AlertTitle>
            You have successfully connected your Snowflake account and we will start importing your billing data.{" "}
          </Alert>
        </Stack>
      ) : error ? (
        <Alert severity="error" data-cy="snowflake-test-connection-error">
          <AlertTitle>Test Failed</AlertTitle>
          {errorMessage ? errorMessage : "Onboarding error, please contact support."}
        </Alert>
      ) : null}
      <UsefulSnowflakeLinks snowflakeLink="https://docs.snowflake.com/en/developer" />
    </Stack>
  );
}
