import { useCallback, useEffect, useState } from "react";

import { type EarlyAccessFeature, IntegrationModel, type KubernetesFeature } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useCloudConnect } from "../../Components/hooks/useCloudConnect";
import { useCustomerContext } from "../../Context/CustomerContext";
import { getCachingKeys } from "../../utils/cachingKeys";
import { customerHasGKECluster } from "./api";

export function useClusters() {
  const { customer } = useCustomerContext({ allowNull: true });

  const query = getCollection(IntegrationModel)
    .doc("fsk8s")
    .collection("fsk8sClusters")
    .where("customerRef", "==", customer?.ref);

  return useCollectionData(query, {
    caching: true,
    cachingKeys: getCachingKeys(customer?.id),
    idField: "id",
  });
}

export function useKubernetesFeatures() {
  const { customer } = useCustomerContext({ allowNull: true });

  const canCustomerAccessFeature = (feature: KubernetesFeature) => {
    if (!customer || !Array.isArray(customer.earlyAccessFeatures) || !feature.featureFlag) {
      return true;
    }

    return customer.earlyAccessFeatures.includes(feature.featureFlag as EarlyAccessFeature);
  };

  const query = getCollection(IntegrationModel).doc("fsk8s").collection("fsk8sFeatures");

  const [features, loading] = useCollectionData(query, {
    idField: "id",
  });

  return [features?.filter(canCustomerAccessFeature), loading] as const;
}

export function useGKEAndCloudConnect(customer) {
  const [hasGKE, setHasGKE] = useState(true);
  const api = useApiContext();

  const fetchGKECluster = useCallback(async () => {
    const result = await customerHasGKECluster(api, customer.id);
    setHasGKE(Boolean(result.data));
  }, [api, customer.id]);

  useEffect(() => {
    fetchGKECluster();
  }, [fetchGKECluster]);

  const [cloudConnect] = useCloudConnect(customer);
  // Whether the customer has any healthy cloud connect doc, in which case we don't show the banner
  const hasCloudConnect =
    cloudConnect?.some((sa) => sa.cloudPlatform === "google-cloud" && sa.categoriesStatus?.["fsk8s-core"] === 1) ||
    false;

  // Whether the customer has any GCP organization where the fsk8s feature isn't enabled
  const unhealthyOrganization =
    cloudConnect?.find(
      (sa) =>
        sa.cloudPlatform === "google-cloud" && sa.categoriesStatus?.["fsk8s-core"] !== 1 && sa.scope === "organization"
    ) || null;

  return { hasGKE, hasCloudConnect, unhealthyOrganization };
}
