import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { threeDotHeaderSection } from "../../../assetUtils";
import { type DatadogAssetRowData } from "./DatadogAssetRow";

const datadogItemDescriptors: FilterTableItemDescriptor<DatadogAssetRowData>[] = [
  {
    headerText: "Organization",
    value: "orgName",
    filterType: "string",
  },
  {
    headerText: "Site",
    value: "site",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
  },
];

const { filters, headerColumns: baseHeaderColumns } = generateHeadersAndFilters(datadogItemDescriptors);
const headerColumns = [...baseHeaderColumns, threeDotHeaderSection] as const;

export { filters, headerColumns };
