import { memo } from "react";

import { CloudFlowNodeType, Frequencies, type NodeConfigScheduleTriggerParameters } from "@doitintl/cmp-models";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { DateTime } from "luxon";

import { type RFNode } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode<CloudFlowNodeType.TRIGGER>;
  selected?: boolean;
};

export const getDisplayName = ({
  frequency,
  customFrequency,
  customFrequencyAmount,
  time,
  timeZone,
}: NodeConfigScheduleTriggerParameters) => {
  if (!time) {
    return "Trigger";
  }

  let dateTime = DateTime.fromObject(time, { zone: timeZone || "utc" });

  if (!dateTime.isValid) {
    return "Trigger";
  }

  dateTime = dateTime.toLocal();

  const formattedTime = dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);

  if ([Frequencies.Daily, Frequencies.Weekly, Frequencies.Monthly].includes(frequency)) {
    return `${frequency} at ${formattedTime}`;
  }

  if (frequency === Frequencies.Custom && customFrequency && customFrequencyAmount) {
    const plural = customFrequencyAmount > 1 ? "s" : "";
    const customFreqDisplay = `${customFrequencyAmount} ${customFrequency}${plural}`;
    return `Every ${customFreqDisplay} at ${formattedTime}`;
  }

  return `Scheduled at ${formattedTime}`;
};

const InternalTriggerNode = ({ id, data, selected }: Props) => {
  const title = data.nodeData.type === CloudFlowNodeType.TRIGGER ? "Trigger: Scheduled" : "Trigger: Manual";
  const getName = () => {
    if (data.nodeData.type === CloudFlowNodeType.TRIGGER) {
      return data.nodeData.parameters ? getDisplayName(data.nodeData.parameters) || "Trigger" : "Trigger";
    } else {
      return "Manual Trigger";
    }
  };

  return (
    <>
      <NodeWrapper
        title={title}
        errorMessages={data.nodeData.errorMessages || {}}
        status={data.nodeData.status || "validated"}
        onEdit={data.onEditNode}
        isActive={!!selected}
        name={`${data.stepNumber}. ${getName()}`}
        icon={<TimerOutlinedIcon color="primary" fontSize="small" />}
      />
      <SourceHandle id={id} />
    </>
  );
};

export const TriggerNode = memo(InternalTriggerNode);
