import { useEffect, useMemo, useCallback, Fragment, useState } from "react";

import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { DialogContent, DialogContentText, CircularProgress, Box } from "@mui/material";
import Link from "@mui/material/Link";
import uniq from "lodash/uniq";
import toLower from "lodash/toLower";
import trim from "lodash/trim";
import split from "lodash/split";
import find from "lodash/find";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import Typography from "@mui/material/Typography";
import { AccountManagerCompanies, AccountManagerModel, AssetModel, UserPermissions } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import mixpanel from "../../utils/mixpanel";
import { platformName } from "../utils";
import { Platform } from "../types";
import { entitlementsToContactSupport, usePriorities } from "../consts";
import {
  ticketNotification,
  jsUcfirst,
  copyToClipboard,
  assetTypeName,
  getCustomerAccountManagers,
} from "../../utils/common";
import { helpURLs } from "../../assets/urls";
import { useCustomerContext } from "../../Context/CustomerContext";
import SimpleDialog from "../../Components/SimpleDialog";
import { useFullScreen } from "../../utils/dialog";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { isJapanese } from "../../utils";
import { SeverityRadioSelect } from "./SeverityRadioSelect";
import ProductStep from "./TicketSteps/ProductStep";
import DetailsStep, { validateSubject } from "./TicketSteps/DetailsStep";
import { AccessStep } from "./Stepper/AccessStep";
import ConfirmDialog from "./ConfirmDialog";
import { NewTicketStepper } from "./Stepper/NewTicketStepper";
import {
  resourceDetailsComponentTypeMap,
  resourceDetailsPriorityTrigger,
} from "./ResourceDetails/getResourceDetailsComponent";
import { useTranslation } from "react-i18next";
import { setCaseIqSessionId, stripHtmlTags, useABTestingCaseIQ } from "./CaseIQ/utils";
import { useIsFeatureEntitledMultiple } from "../../Context/TierProvider";
import { useIsPLESCustomer } from "./TicketSteps/hooks";
import { useGetCustomerMPAs } from "../../Pages/MPA/hooks/useGetCustomerMPAs";
import PropTypes from "prop-types";
import { useRoles } from "../../Components/hooks/IAM/useRoles";
import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    marginTop: 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "100%",
    height: 310,
    maxWidth: 650,
  },
  progress: {
    marginTop: -35,
    marginLeft: 20,
  },
  dialogAction: {
    marginTop: 40,
    flex: 1,
    justifyContent: "center",
  },
  button: {
    marginRight: 10,
  },
  buttonNext: {
    width: 100,
  },
  concedefyDialogText: {
    textAlign: "center",
  },
  code: {
    padding: 24,
    backgroundColor: theme.palette.background.default,
    border: 0,
    overflow: "auto",
    font: "500 90%/1 Roboto Mono,monospace",
    fontSize: 14,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    margin: "15px 0",
  },
  copyIcon: {
    marginRight: 16,
  },
}));
const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const CreateTicket = (props) => {
  const classes = useStyles();
  const { isFetchingTicketList, organization, slas } = props;
  const { isMobile } = useFullScreen();
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const { template } = useParams();
  const { onOpen: showSnackbar } = useSnackbar();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [platform, setPlatform] = useState("");
  const [categoryInfo, setCategoryInfo] = useState("");
  const [helperText, setHelperText] = useState("");
  const [priority, setPriority] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [subject, setSubject] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState(props.defaultEmailsList);
  const [files, setFiles] = useState([]);
  const [cc, setCc] = useState("");
  const [speakWithCre, setSpeakWithCre] = useState(false);
  const [textArea, setTextArea] = useState("");
  const [error] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedCustomerUser, setSelectedCustomerUser] = useState("");
  const [includeDraftConfirm, setIncludeDraftConfirm] = useState(false);
  const [grantAccessDialogOpen, setGrantAccessDialogOpen] = useState(false);
  const [comfortableWithEnglish, setComfortableWithEnglish] = useState(false);
  const [GCProjectName, setGCProjectName] = useState("");
  const [unsupportedAsset, setUnsupportedAsset] = useState({ open: false, asset: null });
  const [awsAsset, setAwsAsset] = useState(null);
  const [requesterError, setRequesterError] = useState(null);
  const priorities = usePriorities();
  const { t } = useTranslation("services");
  const abTestingCaseIQ = useABTestingCaseIQ({ severity: priority?.value, cloudProduct: category?.id });
  const [billingProfile, setBillingProfile] = useState();

  const { customerMPAs, getAwsAssetMPAByAccountId } = useGetCustomerMPAs(false);

  const [resourceDetails, setResourceDetails] = useState([]);

  const classification = organization.organization_fields?.classification;

  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  // create CaseIQ ID here, and use it for DetailsStep CaseIQ interaction, and submitting Zendesk ticket ID
  const caseIqSessionId = useMemo(() => crypto.randomUUID(), []);
  useEffect(() => {
    setCaseIqSessionId(caseIqSessionId);
  }, [caseIqSessionId]);

  const handleChangePlatform = useCallback(
    (val) => {
      const inputVal = val.target.value;

      setCategoryInfo("");
      setSelectedItem([]);
      setAwsAsset(null);

      const platformObj = find(props.platforms, { value: val.target.value });

      if (platformObj.asset) {
        setSelectedPlatform(platformObj);
      } else {
        setSelectedPlatform(null);
      }

      const platformValue = find(props.platforms, { value: inputVal });
      const filterdCategoriesByPlatform = props.categories.filter(
        (item) => item.platform === platformName(platformValue.value)
      );

      if (filterdCategoriesByPlatform.length === 0) {
        setCategories(props.categories);
        setCategory(props.categories[0]);
      } else {
        setCategories(filterdCategoriesByPlatform);
        setCategory(null);
      }

      setPlatform(inputVal);
      let selectedPlatform = find(props.platforms, { value: inputVal });
      selectedPlatform = selectedPlatform ? selectedPlatform : find(props.platforms, { title: val.target.value });
      setHelperText(selectedPlatform.helperText);
    },
    [props.categories, props.platforms]
  );

  useEffect(() => {
    if (props.createTicket.category) {
      handleChangePlatform({ target: { value: props.createTicket.platform } });
      setCategory(find(props.categories, { id: props.createTicket.category }));
      setCategoryInfo(props.createTicket.resource);
      setPriority(priorities.find((p) => p.value === props.createTicket.severity));
      setSubject(props.createTicket.subject);

      // don't overwrite text loaded from local storage (unfinished business)
      if (!textArea) {
        setTextArea(props.createTicket.body);
      }
      setCurrentStep(2);
    }
  }, [handleChangePlatform, props.categories, props.createTicket, template, priorities, textArea]);

  const handleCChange = useCallback(
    (key, isOut = false) => {
      setCc(key.target.value);
      const emails = split(key.target.value, ",");
      if (emails.length < 2 && !isOut) {
        return;
      }

      const tmp = selectedEmails;
      emails.forEach((rawEmail) => {
        const email = toLower(trim(rawEmail));
        if (isEmailValid(email)) {
          tmp.push(email);
          setCc("");
        }
      });
      setSelectedEmails(uniq(tmp));
    },
    [selectedEmails]
  );

  const handleChangeCategory = (data) => {
    setCategory(data);
  };
  const handleChangePriority = (priorityVal) => {
    setPriority(priorityVal);
  };
  const handleDelete = useCallback(
    (item) => {
      const newArr = selectedEmails.filter((sItem) => sItem !== item);
      setSelectedEmails(uniq(newArr));
    },
    [selectedEmails]
  );

  const handleChangeSubject = useCallback((name) => {
    setSubject(name.target.value);
  }, []);
  const handleChangeSpeakWithCre = (isChecked) => {
    setSpeakWithCre(isChecked);
  };
  const onSelectUser = useCallback(
    (email) => {
      const isValid = isEmailValid(email);
      const emailAndValid = email && isValid;
      setSelectedCustomerUser(emailAndValid ? email : "");
      setRequesterError(emailAndValid ? null : t("DetailsStep.missingRequester"));
    },
    [t]
  );

  const onChangeTextarea = useCallback(
    (inputData) => {
      const store = { ticketID: "new", comment: inputData };

      const skipWriteToLocalStorage =
        template && stripHtmlTags(props.createTicket.body ?? "") === stripHtmlTags(inputData ?? "");
      if (!includeDraftConfirm) {
        if (!skipWriteToLocalStorage) {
          localStorage.setItem("new_comment", JSON.stringify(store));
        }
        setTextArea(inputData);
      }
    },
    [includeDraftConfirm, props.createTicket.body, template]
  );
  const handleCChangeOut = useCallback(
    (key) => {
      handleCChange(key, true);
    },
    [handleCChange]
  );

  const handleChangeResourceDetails = useCallback((newResourceDetails) => {
    setResourceDetails(newResourceDetails);
  }, []);

  const isPlesCustomer = useIsPLESCustomer(customer.id);

  const addAccountManagerEmail = useCallback(
    async (refID) => {
      const docRef = await getCollection(AccountManagerModel).doc(refID).get();
      if (docRef.data()) {
        const obj = { target: { value: `${docRef.data().email},` } };
        if (selectedEmails.indexOf(docRef.data().email) > -1) {
          // abort
          return;
        }

        handleCChange(obj);
      }
    },
    [handleCChange, selectedEmails]
  );

  const getAwsAsset = useCallback(
    async (asset) => {
      const assetName = asset ?? categoryInfo;
      if (!assetName) {
        return null;
      }

      const docRef = await getCollection(AssetModel)
        .where("customer", "==", customer.ref)
        .where("properties.friendlyName", "==", assetName)
        .get();
      return docRef.docs?.[0];
    },
    [categoryInfo, customer.ref]
  );

  const changeCategoryInfo = useCallback(
    async (inputData) => {
      if (!inputData) {
        setCategoryInfo(null);
      }

      if (inputData) {
        if (!inputData.target) {
          if (inputData.preferMpaId) {
            setCategoryInfo(inputData.mpa ?? inputData.accountId);
          } else {
            setCategoryInfo(inputData.accountId ?? inputData.identifier);
          }
        } else {
          setCategoryInfo(inputData.target.value);
        }

        if (platform === "amazon_web_services") {
          const asset = await getAwsAsset(inputData.identifier);
          setAwsAsset(asset ? asset.data() : null);
        } else {
          setAwsAsset(null);
        }
      }
    },
    [getAwsAsset, platform]
  );

  const addAccountManager = useCallback(async () => {
    const gsuite = "google_g_suite";
    const pVal = find(ticketNotification, { name: jsUcfirst(priority?.value) });

    if (!props.contextUser.accountTeam) {
      return;
    }

    const validAMs = props.contextUser.accountTeam.filter(
      (item) => item.supportNotificationLevel && item.supportNotificationLevel <= pVal?.value
    );
    const doitAMs = validAMs.filter((item) => item.company.includes(AccountManagerCompanies.DOIT));
    const tmpPlatform = platform === gsuite ? AccountManagerCompanies.GCP : platform;
    const vendorsAM = validAMs.filter((item) => item.company.includes(tmpPlatform));
    const ams = [...doitAMs, ...vendorsAM];

    await Promise.all(ams.map((item) => addAccountManagerEmail(item.ref.id)));
  }, [priority?.value, props.contextUser.accountTeam, platform, addAccountManagerEmail]);

  const getDraftComment = useCallback(() => {
    const commentContentStr = localStorage.getItem("new_comment");
    const newComment = commentContentStr ? JSON.parse(commentContentStr).comment : "";

    if (
      !textArea &&
      ((template && newComment && newComment !== props.createTicket.body) || (!template && newComment))
    ) {
      setIncludeDraftConfirm(true);
    }
  }, [props.createTicket.body, template, textArea]);

  const submitTicket = useCallback(async () => {
    let accountManagersEmail;

    // if an amEmail is set from parameters, use that
    if (props.createTicket.accountManagersEmail) {
      accountManagersEmail = new Array(props.createTicket.accountManagersEmail);
    }

    // submit
    let followUp = null;
    if (props.match.params.fid) {
      followUp = props.match.params.fid;
    }

    if (unsupportedAsset.asset) {
      const accountManagers = await getCustomerAccountManagers(customer.ref);
      accountManagersEmail = accountManagers.map((manager) => manager.email);
    }

    const isAbTestingEligible =
      resourceDetailsPriorityTrigger.includes(priority?.value) &&
      Object.keys(resourceDetailsComponentTypeMap).includes(category?.id);

    const ccEmails = Array.from(selectedEmails);
    if (props.createTicket.collaboratorEmail) {
      ccEmails.push(props.createTicket.collaboratorEmail);
    }

    const createTicketDetails = {
      product: category,
      category,
      categoryInfo,
      platform,
      priority: priority?.value,
      subject,
      selectedItem,
      selectedEmails: ccEmails,
      textArea,
      files,
      followUp,
      selectedCustomerUser,
      unsupportedAsset: unsupportedAsset.asset,
      accountManagersEmail,
      resourceDetails,
      isAbTestingEligible,
      isProductOnlyCustomer,
      billingProfile,
      caseIqSessionId,
      currentUser,
      abTestingCaseIQ,
    };

    if (abTestingCaseIQ) {
      createTicketDetails.product.tags.push("cmp:ab/case_iq_0/yes");
    }

    if (speakWithCre) {
      createTicketDetails.product.tags.push("vc:callback_requested");
    }

    if (isProductOnlyCustomer) {
      createTicketDetails.product.tags.push("saas_user");
    }

    if (props.createTicket.tags) {
      const desiredTags = props.createTicket.tags.split(",");
      createTicketDetails.product.tags.push(...desiredTags);
    }

    if (isJapanese(textArea)) {
      createTicketDetails.product.tags.push("lang:ja");
    }

    createTicketDetails.product.tags.push(
      isJapanese(textArea) && !comfortableWithEnglish ? "lang-acpt:ja" : "lang-acpt:en"
    );

    if (platform === "google_cloud_platform" || platform === "amazon_web_services") {
      setGCProjectName(categoryInfo);
    }

    props.saveNewTicket(createTicketDetails);
    mixpanel.track("support.ticket.new", { ticket: createTicketDetails });
    localStorage.removeItem("new_comment");
  }, [
    props,
    unsupportedAsset.asset,
    priority?.value,
    category,
    categoryInfo,
    platform,
    subject,
    selectedItem,
    selectedEmails,
    textArea,
    files,
    selectedCustomerUser,
    resourceDetails,
    isProductOnlyCustomer,
    billingProfile,
    caseIqSessionId,
    abTestingCaseIQ,
    speakWithCre,
    comfortableWithEnglish,
    customer.ref,
    currentUser,
  ]);

  const onChangeStep = useCallback(
    (step) => {
      setCurrentStep(step);
      if (step === 2) {
        addAccountManager();
        getDraftComment();
      }

      if (Platform.credits === platform) {
        setPriority(priorities[0]);
      }
    },
    [addAccountManager, getDraftComment, platform, priorities]
  );

  const confirmDraft = useCallback(() => {
    const commentContentStr = localStorage.getItem("new_comment");
    const commentContent = JSON.parse(commentContentStr);
    setTextArea(commentContent?.comment);
    setTimeout(() => {
      setIncludeDraftConfirm(false);
    }, 1000);
  }, []);
  const cancelConfirm = useCallback(() => {
    setIncludeDraftConfirm(false);
    localStorage.removeItem("new_comment");
  }, []);
  const closeGrantAccessDialog = useCallback(() => {
    setGrantAccessDialogOpen(false);
    props.history.push(`/customers/${customer.id}/support`);
  }, [customer.id, props.history]);
  const installSupportRole = useCallback(() => {
    closeGrantAccessDialog();
    window.open(
      `https://console.aws.amazon.com/cloudformation/home?account=${props.categoryInfo}#/stacks/create/review?templateURL=https://doit-support.s3.amazonaws.com/doit-support.json&stackName=doit-support-gateway`,
      "_blank"
    );
  }, [closeGrantAccessDialog, props.categoryInfo]);
  const onCloseUnsupportedAsset = useCallback(() => {
    setUnsupportedAsset({ open: false });
    setPlatform(null);
  }, []);
  const grantAccessDialogBody = useCallback(() => {
    if (platform === "google_cloud_platform") {
      return (
        <>
          <Typography component="span">
            To expedite your request, please grant read-only access to your Google Cloud environment by running the
            following command. The access will be automatically removed when this request is resolved.{" "}
            <Link target="_blank" href={helpURLs.GRANT_GCP_ACCESS}>
              Learn more
            </Link>
          </Typography>
          <span onClick={(e) => copyToClipboard(e, showSnackbar)} className={classes.code}>
            <FileCopyOutlinedIcon id="icon" className={classes.copyIcon} fontSize="small" />
            gcloud projects add-iam-policy-binding {`${GCProjectName}`} \
            <br />
            --member="group:{`${props.concedefyReadOnlyEmail}`}" \
            <br />
            --role=roles/viewer --condition=None
          </span>
        </>
      );
    }
    if (platform === "amazon_web_services") {
      return (
        <>
          <Typography component="span">
            Please consider providing read-only access for DoiT International to your AWS account by deploying the
            following AWS CloudFormation stack. By providing access, you may expedite your request with us.{" "}
            <Link target="_blank" href={helpURLs.GRANT_AWS_ACCESS}>
              {" "}
              Learn more
            </Link>
          </Typography>
        </>
      );
    }
  }, [GCProjectName, classes.code, classes.copyIcon, props.concedefyReadOnlyEmail, platform, showSnackbar]);

  const onCloseTicketStepper = useCallback(
    () => props.history.push(`/customers/${customer.id}/support`),
    [customer.id, props.history]
  );

  const getStepStatus = useCallback((condition) => (condition ? "editing" : "complete"), []);

  const noResource = useMemo(
    () =>
      Platform.finance === selectedPlatform?.value ||
      Platform.cmp === selectedPlatform?.value ||
      Platform.credits === selectedPlatform?.value,
    [selectedPlatform?.value]
  );

  const stepState1 = useMemo(
    () => getStepStatus(selectedPlatform === null || category === null),
    [category, getStepStatus, selectedPlatform]
  );

  const stepState2 = useMemo(() => getStepStatus(priority === ""), [getStepStatus, priority]);

  const stepState3 = useMemo(() => {
    const regexNoWhiteSpace = /^\S*$/;
    return getStepStatus(
      !validateSubject(subject) ||
        textArea === "" ||
        ((!categoryInfo || !regexNoWhiteSpace.test(categoryInfo)) && !noResource) ||
        (isDoitEmployee && selectedCustomerUser === "")
    );
  }, [getStepStatus, subject, textArea, categoryInfo, noResource, isDoitEmployee, selectedCustomerUser]);

  const platformBreadcrumb = useMemo(() => {
    const serviceTitle = !isMobile ? category?.name : "";
    return category ? `${selectedPlatform?.title}, ${serviceTitle}` : "";
  }, [category, isMobile, selectedPlatform?.title]);

  const categoryInfoList = useMemo(() => {
    // AWS needs MPA field; all other products don't need processing.
    if (selectedPlatform?.asset !== "amazon-web-services") {
      return props.clientProductsProperties;
    }

    return props.clientProductsProperties
      .filter((cat) => !!cat.accountId)
      .map((cat) => ({
        ...cat,
        mpa: getAwsAssetMPAByAccountId(cat.accountId)?.masterPayerAccountId ?? "",
      }))
      .filter((awsAccount) => {
        if (!customerMPAs) {
          return false;
        }

        return customerMPAs.some((mpa) => mpa.accountNumber === awsAccount.mpa);
      });
  }, [customerMPAs, getAwsAssetMPAByAccountId, props.clientProductsProperties, selectedPlatform]);

  const steps = useMemo(
    () => [
      <ProductStep
        key={0}
        platforms={props.platforms}
        platform={platform}
        handleChangePlatform={handleChangePlatform}
        categories={categories}
        category={category}
        handleChangeCategory={handleChangeCategory}
        helperText={helperText}
        changeCategoryInfo={changeCategoryInfo}
        categoryInfo={categoryInfo ? categoryInfo : ""}
        selectedPlatform={selectedPlatform}
        categoryInfoList={categoryInfoList}
        error={error}
        knownIssues={props.knownIssues}
        setCurrentStep={setCurrentStep}
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
      />,
      <SeverityRadioSelect
        key={1}
        platform={selectedPlatform?.value}
        severity={priority}
        onChange={handleChangePriority}
      />,

      <DetailsStep
        key={2}
        handleDelete={handleDelete}
        handleCChange={handleCChange}
        handleChangeSubject={handleChangeSubject}
        handleChangeSpeakWithCre={handleChangeSpeakWithCre}
        onChangeTextarea={onChangeTextarea}
        subject={subject}
        selectedEmails={selectedEmails}
        textArea={textArea}
        cc={cc}
        files={files}
        handleCChangeOut={handleCChangeOut}
        setFiles={setFiles}
        error={error}
        buttonMode={props.buttonMode}
        customerUsers={props.customerUsers}
        onSelectUser={onSelectUser}
        selectedCustomerUser={selectedCustomerUser}
        userDetails={props.userDetails}
        requesterError={requesterError}
        selectedPlatform={selectedPlatform}
        platforms={props.platforms}
        categoryInfoList={categoryInfoList}
        changeCategoryInfo={changeCategoryInfo}
        categoryInfo={categoryInfo ? categoryInfo : ""}
        step={2}
        severity={priority}
        cloudProduct={category}
        cloudPlatform={platform}
        handleChangeResourceDetails={handleChangeResourceDetails}
        comfortableWithEnglish={comfortableWithEnglish}
        setComfortableWithEnglish={setComfortableWithEnglish}
        helperText={helperText}
        handleChangeCategory={handleChangeCategory}
        categories={categories}
        isPlesCustomer={isPlesCustomer}
      />,
      <AccessStep
        key={3}
        classification={classification}
        severity={priority}
        slas={slas}
        platform={selectedPlatform?.value}
        gcp={{
          concedefyEmail: props.concedefyReadOnlyEmail,
          concedefyRole: organization?.organization_fields?.concedefy_role,
          projectName: GCProjectName,
        }}
        aws={{
          projectName: awsAsset?.properties?.accountId,
        }}
        concedefyDisabled={!!organization?.organization_fields?.concedefy_disabled}
      />,
    ],
    [
      props.platforms,
      props.knownIssues,
      props.buttonMode,
      props.customerUsers,
      props.userDetails,
      props.concedefyReadOnlyEmail,
      platform,
      handleChangePlatform,
      categories,
      category,
      helperText,
      changeCategoryInfo,
      categoryInfo,
      selectedPlatform,
      categoryInfoList,
      error,
      awsAsset?.properties?.accountId,
      billingProfile,
      priority,
      handleDelete,
      handleCChange,
      handleChangeSubject,
      onChangeTextarea,
      subject,
      selectedEmails,
      textArea,
      cc,
      files,
      handleCChangeOut,
      onSelectUser,
      selectedCustomerUser,
      requesterError,
      handleChangeResourceDetails,
      comfortableWithEnglish,
      isPlesCustomer,
      classification,
      slas,
      organization?.organization_fields?.concedefy_role,
      organization?.organization_fields?.concedefy_disabled,
      GCProjectName,
      setBillingProfile,
    ]
  );

  return (
    <>
      {!isFetchingTicketList || props.categories?.length > 0 ? (
        <NewTicketStepper
          states={[stepState1, stepState2, stepState3]}
          stepComponents={steps}
          breadcrumb={[platformBreadcrumb, priority?.title]}
          onCancel={onCloseTicketStepper}
          platform={selectedPlatform?.value}
          onChangeStep={onChangeStep}
          onSubmitTicket={submitTicket}
          submitLoading={props.isLoading}
          errorMsg={props.errorMsg}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <Box
          sx={{
            p: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress className={classes.progress} size={40} />
        </Box>
      )}
      <ConfirmDialog
        title={t("NewCommentCard.unfinishedDialog")}
        text={t("NewCommentCard.unfinishedDialogText")}
        acceptTitle={t("NewCommentCard.yes")}
        cancelTitle={t("NewCommentCard.no")}
        onCancel={cancelConfirm}
        onAccept={confirmDraft}
        open={includeDraftConfirm}
      />
      <ConfirmDialog
        title="Grant read-only access"
        text={grantAccessDialogBody()}
        acceptTitle="CLOSE"
        onAccept={closeGrantAccessDialog}
        open={grantAccessDialogOpen && !props.isLoading}
      />
      {platform === "amazon_web_services" && (
        <ConfirmDialog
          title="Grant Read-Only Access"
          text={grantAccessDialogBody()}
          acceptTitle="Deploy"
          cancelTitle="Close"
          onCancel={closeGrantAccessDialog}
          onAccept={installSupportRole}
          open={grantAccessDialogOpen && !props.isLoading}
        />
      )}
      <SimpleDialog
        open={unsupportedAsset.open}
        onConfirm={() => setUnsupportedAsset({ open: false, asset: selectedPlatform?.asset })}
        title="Unsupported Assets"
        onCancel={onCloseUnsupportedAsset}
        confirmButtonText={t("CreateTicket.continueAnyway")}
        cancelButtonText={t("CreateTicket.cancel")}
        confirmButtonColor="primary"
      >
        <DialogContent>
          <DialogContentText>
            You have selected {assetTypeName(selectedPlatform?.asset)}, however, while we do support{" "}
            {assetTypeName(selectedPlatform?.asset)} in general, this Cloud Platform is not included in your service
            contract
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};

CreateTicket.propTypes = {
  categories: PropTypes.object,
  createTicket: PropTypes.object,
  isFetchingTicketList: PropTypes.bool,
  platforms: PropTypes.object,
};

export default CreateTicket;
