import { useEffect, useMemo, useRef, useState } from "react";

import {
  Aggregator,
  type AnalyticsDataSource,
  type CurrencyCode,
  type ForecastSettings,
  Metric,
  Renderer,
  type ReportFilter,
  type TimeInterval,
} from "@doitintl/cmp-models";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableIcon from "@mui/icons-material/TableChart";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { cloudAnalyticsText } from "../../../assets/texts";
import useFormatter from "../../../Components/hooks/useFormatter";
import { useKeyPress } from "../../../Components/hooks/useKeyPress";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import { type AttributionWRef } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { type AttributionGroupsPayload } from "../attributionGroups/attributionGroupsPayload";
import ChartsRenderer from "../renderers/ChartsRenderer";
import TableRenderer from "../renderers/TableRenderer/TableRenderer";
import { QueryType } from "../report/ReportQuery";
import { type DataRecord } from "../ReportData";
import { type TimeRangeOption } from "../utilities";
import { useQueryRun } from "./useQueryRun";
import type ReportData from "../ReportData";

export type AttributionGroupData = {
  // we could have preview for attribution group without id and name
  id?: string;
  name?: string;
  attributions: string[];
  nullFallback?: string;
};

export type PreviewData = {
  currency: CurrencyCode;
  scope: string[];
  attributionGroups: AttributionGroupData[];
  timeInterval: TimeInterval;
  timeRangeOptions: TimeRangeOption;
  rows: DataRecord[];
  cols: DataRecord[];
  attributionGroupsPayload: AttributionGroupsPayload[];
  filters: ReportFilter[];
  cloudProviders?: string[];
  dataSource?: AnalyticsDataSource;
  forecastSettings?: ForecastSettings | null;
  extendedMetric?: string | null;
  metric?: number | null;
  type?: QueryType;
};

type Props = {
  attributions: AttributionWRef[];
  disablePreview?: boolean;
  displayForecast?: boolean;
  previewData: PreviewData;
  renderer: string;
  runQuery: boolean;
  setRenderer: (renderer: Renderer) => void;
  setRunQuery: (runQuery: boolean) => void;
  queryType?: QueryType;
  getCurrentLastPeriods?: (
    periods: {
      lastPeriod: number;
      currentPeriod: number;
    } | null
  ) => void;
  getQueryRunningStatus?: (queryRunning: boolean) => void;
};

const PreviewReport = ({
  attributions,
  disablePreview,
  displayForecast = false,
  previewData,
  renderer = Renderer.STACKED_COLUMN_CHART,
  runQuery,
  setRenderer,
  setRunQuery,
  queryType,
  getCurrentLastPeriods,
  getQueryRunningStatus,
}: Props) => {
  const toggleTable = useKeyPress("t");
  const previewHeight = `30vh`;
  const formatter = useFormatter({ aggregator: Aggregator.TOTAL, currency: previewData.currency, metric: Metric.COST });
  const { isMobile: smDown } = useFullScreen();
  const [reportError, setReportError] = useState<string | null>(null);
  const reportDataWForecasts = useRef<ReportData | null>(null);
  const reportData = reportDataWForecasts.current;
  const [queryRunning, setQueryRunning] = useState(false);
  useQueryRun({
    attributions,
    previewData,
    queryRunning,
    setQueryRunning,
    reportDataWForecasts,
    runQuery,
    setRunQuery,
    setReportError,
    forecastMode: displayForecast,
    queryType,
  });

  useEffect(() => {
    if (getCurrentLastPeriods && reportData && !queryRunning) {
      getCurrentLastPeriods(reportData.getCurrentLastPeriods());
    }

    getQueryRunningStatus?.(queryRunning);
  }, [getCurrentLastPeriods, getQueryRunningStatus, queryRunning, reportData]);

  const plot = useMemo(() => {
    if (reportData) {
      if (getCurrentLastPeriods) {
        getCurrentLastPeriods(reportData.getCurrentLastPeriods());
      }
      if (renderer === Renderer.TABLE) {
        let forecastStart: undefined | number;
        if (displayForecast) {
          forecastStart = reportData.getForecastStartIndex();
        }
        return (
          <TableRenderer
            data={reportData}
            formatter={formatter}
            smDown={smDown}
            forecastStart={forecastStart}
            reverseColors={false}
            height="100%"
          />
        );
      }
      return (
        <ChartsRenderer
          data={reportData}
          type={renderer}
          height={previewHeight}
          formatter={formatter}
          isWidget={true}
        />
      );
    }
  }, [reportData, getCurrentLastPeriods, renderer, previewHeight, formatter, displayForecast, smDown]);

  useUpdateEffect(() => {
    if (toggleTable) {
      const inputs = document.querySelectorAll("input");
      const userIsTyping = Array.from(inputs).some((input) => input === document.activeElement);

      if (!userIsTyping) {
        const newRenderer = renderer === Renderer.TABLE ? Renderer.STACKED_COLUMN_CHART : Renderer.TABLE;
        if (renderer !== newRenderer) {
          mixpanel.track("analytics.preview-report.toggle-view", {
            view: newRenderer,
            prevView: renderer,
          });

          setRenderer(newRenderer);
        }
      }
    }
  }, [toggleTable]);

  const previewHeader = useMemo(
    () => (
      <CardContent>
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
        >
          <Grid size={6}>
            <Typography variant="subtitle2">{cloudAnalyticsText.PREVIEW.PREVIEW_TITLE}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              justifyContent: "flex-end",
            }}
            size={6}
          >
            <ToggleButtonGroup
              size="small"
              value={renderer}
              exclusive
              onChange={(_, newRenderer) => {
                if (newRenderer) {
                  setRenderer(newRenderer);
                }
              }}
            >
              <ToggleButton value={Renderer.STACKED_COLUMN_CHART}>
                <BarChartIcon />
              </ToggleButton>
              <ToggleButton value={Renderer.TABLE}>
                <TableIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    ),
    [renderer, setRenderer]
  );

  const previewContent = useMemo(() => {
    if (queryRunning) {
      return (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          >
            {cloudAnalyticsText.PREVIEW.FETCHING_PREVIEW}
          </Typography>
          <Skeleton animation="pulse" variant="rectangular" height={previewHeight} />
        </Box>
      );
    }
    if (disablePreview) {
      return (
        <CardContent>
          <Alert variant="standard" severity="info">
            <AlertTitle>{cloudAnalyticsText.PREVIEW.PREVIEW_TITLE}</AlertTitle>
            <Typography variant="body1" color="textSecondary">
              {cloudAnalyticsText.PREVIEW.PREVIEW_DISABLED}
            </Typography>
          </Alert>
        </CardContent>
      );
    }
    if (reportError) {
      return (
        <CardContent>
          <Alert variant="standard" severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography variant="body1" color="textSecondary">
              {reportError === "result_empty"
                ? cloudAnalyticsText.PREVIEW.CHANGE_ATTRIBUTIONS
                : cloudAnalyticsText.PREVIEW.NO_PREVIEW}
            </Typography>
          </Alert>
        </CardContent>
      );
    }
    if (reportData && renderer) {
      return (
        <>
          {queryType !== QueryType.TEMPLATE && previewHeader}
          <CardContent>{plot}</CardContent>
        </>
      );
    }
  }, [disablePreview, reportError, queryRunning, reportData, renderer, plot, previewHeader, previewHeight, queryType]);

  return <Card>{previewContent}</Card>;
};

export default PreviewReport;
