import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { Box, Button, Stack, TableCell, Typography } from "@mui/material";

import { anomaliesText, globalText } from "../../../assets/texts";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import useAlertInvestigation from "../../../Components/hooks/cloudAnalytics/alerts/useAlertInvestigation";
import { useCloudAnalyticsTransforms } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { type MetadataOption } from "../../../types";
import { type LabelWithRef } from "../labels/types";
import { useThreeDotsAlertsMenu } from "./hooks";
import { getScopeDescription } from "./shared";
import { type AnalyticsAlertWithRef } from "./types";

type AlertRowProps = {
  setOpenDeleteDialog: (open: boolean) => void;
  setShareDialogOpen: (open: boolean) => void;
  setClickedRow: (alert: AnalyticsAlertWithRef) => void;
  row: AnalyticsAlertWithRef;
  dimensions: MetadataOption[];
  labels: LabelWithRef[] | undefined;
};

export const AlertRow = ({
  setOpenDeleteDialog,
  setShareDialogOpen,
  setClickedRow,
  row,
  dimensions,
  labels,
}: AlertRowProps) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();
  const getInvestigationReportUrl = useAlertInvestigation(row, dimensions);
  const [conditionText, setConditionText] = useState<JSX.Element>();
  const transforms = useCloudAnalyticsTransforms();
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const handleInvestigate = useCallback(async () => {
    const reportUrl = await getInvestigationReportUrl();
    history.push(reportUrl);
  }, [getInvestigationReportUrl, history]);

  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(true);
    setClickedRow(row);
  }, [setOpenDeleteDialog, setClickedRow, row]);

  const handleShare = useCallback(() => {
    setShareDialogOpen(true);
    setClickedRow(row);
  }, [setShareDialogOpen, setClickedRow, row]);

  const threeDotMenu = useThreeDotsAlertsMenu({
    row,
    handleDelete,
    handleShare,
  });

  useEffect(() => {
    setConditionText(row.transform.conditionText);
  }, [row.transform.conditionText]);

  const { label, values, desc } = getScopeDescription(row.data.config.filters, dimensions, transforms);

  return (
    <CellsWrapper>
      <TableCell>
        <Stack spacing={1}>
          <Typography component={RouterLink} to={`${routeMatchURL}/${row.ref.id}`} color="inherit" variant="body2">
            {row.data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide smDown>
        <TableCell>{conditionText}</TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>
          {label} {desc} {values}
        </TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{row.transform.lastSent ?? globalText.NA}</TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{row.transform.timeModified}</TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{row.transform.owner}</TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{row.transform.timeCreated}</TableCell>
      </Hide>
      <TableCell padding="none">
        <Button onClick={handleInvestigate} variant="outlined" color="primary" size="small">
          {anomaliesText.INVESTIGATE}
        </Button>
      </TableCell>
      <TableCell padding="none">
        <Box>{threeDotMenu}</Box>
      </TableCell>
    </CellsWrapper>
  );
};
