import { type Dispatch, type SetStateAction } from "react";

import {
  ContractTypeApigee,
  ContractTypeAws,
  ContractTypeAwsPpaCloudfront,
  ContractTypeAwsPpaDto,
  ContractTypeAwsPpaEc2,
  ContractTypeAwsPpaPinpoint,
  ContractTypeAwsPpaS3,
  ContractTypeAwsPpaSes,
  ContractTypeAwsPpaShield,
  ContractTypeAwsStandalone,
  ContractTypeBettercloud,
  ContractTypeBeyondcorpEnterprise,
  ContractTypeChronicleSecurity,
  ContractTypeDoitCloudIntelligence,
  ContractTypeDoitCloudNavigator,
  ContractTypeDoitCloudSolve,
  ContractTypeDoitOneTimeServiceFee,
  ContractTypeGoogleCloud,
  ContractTypeGoogleCloudAnthos,
  ContractTypeGoogleCloudMandiant,
  ContractTypeGoogleCloudPartnerLedPremiumSupport,
  ContractTypeGoogleCloudPpaWebRisk,
  ContractTypeGoogleCloudPremiumSupport,
  ContractTypeGoogleCloudStandalone,
  ContractTypeGoogleGeolocationServices,
  ContractTypeGSuite,
  ContractTypeLooker,
  ContractTypeLookerStudioPro,
  ContractTypeMicrosoftAzure,
  ContractTypeOffice365,
  ContractTypeRecaptchaEnterprise,
  ContractTypeSaasConsoleAws,
  ContractTypeSaasConsoleGcp,
  ContractTypeSecurityCommandCenter,
  ContractTypeVirusTotal,
  ContractTypeZendesk,
} from "@doitintl/cmp-models";

import { type ContractStateType } from "../types";
import AwsSupportStep from "./AwsSupportStep/AwsSupportStep";
import { validateAwsSupportStep } from "./AwsSupportStep/awsSupportStepValidator";
import { CommitmentStep } from "./CommitmentStep/CommitmentStep";
import { validateCommitmentStep, validateVendorCommitmentStep } from "./CommitmentStep/commitmentStepValidator";
import { VendorCommitmentStep } from "./CommitmentStep/VendorCommitmentStep";
import { ContractDiscountStep, VendorContractDiscountStep } from "./ContractDiscountStep/ContractDiscountStep";
import { validateDiscountStep } from "./ContractDiscountStep/validateDiscountStep";
import { ContractStep } from "./ContractStep/ContractStep";
import { validateContractStep } from "./ContractStep/contractStepValidator";
import { DciSubscriptionStep } from "./DciSubscriptionStep/DciSubscriptionStep";
import { validateDciSubscriptionStep } from "./DciSubscriptionStep/dciSubscriptionStepValidator";
import { DocumentsStep } from "./DocumentsStep/DocumentsStep";
import { validateDocumentsStep } from "./DocumentsStep/documentStepValidator";
import { GcpPLPSStep } from "./GcpPartnerLedPremiumSupport/GcpPLPSStep";
import { validateGcpPLPSStep } from "./GcpPartnerLedPremiumSupport/gcpPLPSValidator";
import { GeneralStep } from "./GeneralStep/GeneralStep";
import { validateGeneralStep } from "./GeneralStep/generalStepValidator";
import { LookerContractSKUsStep } from "./LookerContractSKUsStep/LookerContractSKUsStep";
import { validateLookerContractSKUs } from "./LookerContractSKUsStep/lookerContractSKUsValidator";
import { LookerSubscriptionStep } from "./LookerSubscriptionStep/LookerSubscriptionStep";
import { validateLookerSubscriptionStep } from "./LookerSubscriptionStep/lookerSubscriptionStepValidator";
import { validateOneTimeServiceDetailsStep } from "./OneTimeServiceDetailsStep/oneTimeServiceDetailsStepValidator";
import { OneTimeServiceFeeDetailsStep } from "./OneTimeServiceDetailsStep/OneTimeServiceFeeDetailsStep";
import { SubscriptionStep } from "./SubscriptionStep/SubscriptionStep";
import { validateSubscriptionStep } from "./SubscriptionStep/subscriptionStepValidator";
import { VendorContractStep } from "./VendorContractStep/VendorContractStep";
import { validateVendorContract } from "./VendorContractStep/vendorContractStepValidator";

export type StepConfig = {
  title: string;
  component: React.ReactNode;
  isValid: (state: ContractStateType, setState: Dispatch<SetStateAction<ContractStateType>>) => boolean;
  skip?: () => boolean;
};

export const getStepsConfig = (state: ContractStateType): StepConfig[] => {
  let steps: StepConfig[];

  switch (state.type) {
    case ContractTypeAws:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        { title: "Pricing", component: <AwsSupportStep />, isValid: validateAwsSupportStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          skip: () => !state.isCommitment,
          isValid: validateCommitmentStep,
        },
        { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep(false) },
        {
          title: "Vendor contract",
          component: <VendorContractStep />,
          skip: () => !state.isCommitment,
          isValid: validateVendorContract,
        },
        {
          title: "Vendor commitment",
          component: <VendorCommitmentStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateVendorCommitmentStep,
        },
        {
          title: "Vendor discount",
          component: <VendorContractDiscountStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateDiscountStep(true),
        },
      ];
      break;

    case ContractTypeGoogleCloud:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          skip: () => !state.isCommitment,
          isValid: validateCommitmentStep,
        },
        { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep(false) },
        {
          title: "Vendor contract",
          component: <VendorContractStep />,
          skip: () => !state.isCommitment,
          isValid: validateVendorContract,
        },
        {
          title: "Vendor commitment",
          component: <VendorCommitmentStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateVendorCommitmentStep,
        },
        {
          title: "Vendor discount",
          component: <VendorContractDiscountStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateDiscountStep(true),
        },
      ];
      break;

    case ContractTypeLooker:
      steps = [
        { title: "Looker contract SKUs", component: <LookerContractSKUsStep />, isValid: validateLookerContractSKUs },
        {
          title: "Looker contract subscription",
          component: <LookerSubscriptionStep />,
          isValid: validateLookerSubscriptionStep,
        },
      ];
      break;

    case ContractTypeGoogleCloudPartnerLedPremiumSupport:
      steps = [{ title: "Customer contract", component: <GcpPLPSStep />, isValid: validateGcpPLPSStep }];
      break;

    case ContractTypeDoitCloudSolve:
    case ContractTypeDoitCloudNavigator:
      steps = [
        { title: "Subscription contract", component: <SubscriptionStep />, isValid: validateSubscriptionStep },
        // { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep },
      ];
      break;

    case ContractTypeDoitCloudIntelligence:
      steps = [
        { title: "Subscription contract", component: <DciSubscriptionStep />, isValid: validateDciSubscriptionStep },
      ];
      break;

    case ContractTypeDoitOneTimeServiceFee:
      steps = [
        { title: "Details", component: <OneTimeServiceFeeDetailsStep />, isValid: validateOneTimeServiceDetailsStep },
      ];
      break;

    case ContractTypeAwsPpaCloudfront:
    case ContractTypeAwsPpaS3:
    case ContractTypeApigee:
    case ContractTypeBettercloud:
    case ContractTypeBeyondcorpEnterprise:
    case ContractTypeChronicleSecurity:
    case ContractTypeAwsStandalone:
    case ContractTypeGoogleCloudStandalone:
    case ContractTypeGoogleCloudAnthos:
    case ContractTypeGoogleCloudPremiumSupport:
    case ContractTypeGoogleCloudPpaWebRisk:
    case ContractTypeGoogleGeolocationServices:
    case ContractTypeGSuite:
    case ContractTypeLookerStudioPro:
    case ContractTypeGoogleCloudMandiant:
    case ContractTypeMicrosoftAzure:
    case ContractTypeOffice365:
    case ContractTypeSaasConsoleAws:
    case ContractTypeSaasConsoleGcp:
    case ContractTypeSecurityCommandCenter:
    case ContractTypeVirusTotal:
    case ContractTypeZendesk:
    case ContractTypeRecaptchaEnterprise:
    case ContractTypeAwsPpaSes:
    case ContractTypeAwsPpaPinpoint:
    case ContractTypeAwsPpaDto:
    case ContractTypeAwsPpaEc2:
    case ContractTypeAwsPpaShield:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          isValid: validateCommitmentStep,
          skip: () => !state.isCommitment,
        },
      ];
      break;

    default:
      steps = [];
  }

  return [
    { title: "General", component: <GeneralStep />, isValid: validateGeneralStep },
    ...steps,
    { title: "Finalize", component: <DocumentsStep />, isValid: validateDocumentsStep },
  ];
};
