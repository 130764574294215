import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import { Box, Link, Stack, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";

const AWSPermissionForm = () => {
  const { setActiveTab } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const accountId = nodeConfig.parameters?.configurationValues?.accountId as string | undefined;

  return (
    <Stack sx={{ gap: 2 }}>
      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 500,
          }}
        >
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mt: 1,
          }}
          color={"text.secondary"}
        >
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS_DESCRIPTION}
        </Typography>
      </Box>
      <Stack direction="row" gap={1} sx={{ mt: 1 }}>
        <Typography variant="body2">{cloudflowTexts.PERMISSIONS.ACCOUNT_ID}:</Typography>{" "}
        {accountId ? (
          <Typography variant="subtitle2">{accountId}</Typography>
        ) : (
          <Typography variant="subtitle2">{cloudflowTexts.PERMISSIONS.NO_ACCOUNT_ID}</Typography>
        )}
      </Stack>
      <Typography variant="body2" color={"text.secondary"}>
        {cloudflowTexts.PERMISSIONS.UPDATE_VALUES}{" "}
        <Link
          variant="body2"
          underline="hover"
          onClick={() => {
            setActiveTab("Parameters");
          }}
          style={{ cursor: "pointer" }}
        >
          {cloudflowTexts.PERMISSIONS.PARAMETERS_TAB}
        </Link>
      </Typography>
    </Stack>
  );
};

export default AWSPermissionForm;
