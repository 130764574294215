import { type Hit } from "../types/searchFilters";
import { type HighlightResult } from "../types/types";

export const groupTimezoneByContinent = (timezone: string): { continent: string; query: string } => {
  if (timezone.includes("Europe/")) return { continent: "Europe", query: "Europe" };
  if (timezone.includes("Asia/")) return { continent: "Asia", query: "Asia" };
  if (timezone.includes("America/")) return { continent: "America", query: "America" };
  if (timezone.includes("Australia/")) return { continent: "Australia", query: "Australia" };
  if (timezone.includes("Africa/")) return { continent: "Africa", query: "Africa" };
  return { continent: "Other", query: timezone };
};

export const isSkillHighlighted = (hit: Hit): Set<string> => {
  const highlightedSkills = new Set<string>();

  const checkSkills = (skills?: HighlightResult[]) => {
    skills?.forEach((skill) => {
      if (skill.matchLevel !== "none") {
        highlightedSkills.add(skill.value.replace(/<\/?mark>/g, ""));
      }
    });
  };

  checkSkills(hit._highlightResult?.primary_skills);
  checkSkills(hit._highlightResult?.secondary_skills);
  checkSkills(hit._highlightResult?.speciality_skills);

  return highlightedSkills;
};

export const cleanSkillName = (name: string, forQuery: boolean = false): string => {
  // First clean the name by removing aws/amazon prefix
  const cleaned = name.replace(/^(?:amazon|aws)\s*/i, "").trim();

  if (forQuery) {
    // For query parameter, keep the original logic
    return cleaned.replace(/(?:\s+)/g, "-").replace(/(?:^-+|-+$)/g, "");
  }

  // For display, first replace hyphens with spaces
  const withSpaces = cleaned.replace(/(?:-)/g, " ");

  // List of words that should remain lowercase
  const keepLowercase = new Set(["for", "to", "on"]);

  // List of 3-letter words that should be capitalized instead of uppercase
  const capitalizeOnly = new Set(["app", "key", "pub", "sub"]);

  // Format each word based on length and special cases
  return withSpaces
    .split(" ")
    .map((word) => {
      const lowercaseWord = word.toLowerCase();

      // Check for words that should remain lowercase
      if (keepLowercase.has(lowercaseWord)) {
        return lowercaseWord;
      }

      // Check for 3-letter words that should only be capitalized
      if (capitalizeOnly.has(lowercaseWord)) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }

      if (word.length <= 3) {
        // Convert to uppercase if 3 letters or less (and not in special cases)
        return word.toUpperCase();
      } else {
        // Capitalize first letter for longer words
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(" ");
};
