import { useCallback } from "react";

import { AnalyticsDataSource, Metric } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Autocomplete, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";

import { metricText } from "../../../assets/texts";
import MetricSelect from "../../../Components/Selects/CloudAnalytics/MetricSelect";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { type MetricWSnap } from "../../../types";
import { textFieldBaseProps } from "../budgets/shared";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import { createFilterOptions, MetricOptions } from "../utilities";
import { getVariableName } from "./MetricFormula";
import metricStyles, { inputLabelsStyles } from "./MetricStyles";

export type MetricVariableValue = {
  metric?: Metric;
  attribution: string | null;
  extendedMetric?: string;
};

const useStyles = metricStyles;

type MetricVariableProps = {
  idx: number;
  variable: MetricVariableValue;
  disabled: boolean;
  attributions: string[];
  validateVariables: boolean;
  showDelete: boolean;
  allowDelete: boolean;
  handleChangeMetric: (idx: number, value: string | undefined, metric: Metric) => void;
  handleChangeAttribution: (idx: number, value: string | null) => void;
  handleDeleteAttribution: (idx: number) => void;
  smDown: boolean;
};

export const MetricVariableRow = ({
  idx,
  variable,
  disabled,
  attributions,
  validateVariables,
  showDelete,
  allowDelete,
  handleChangeMetric,
  handleChangeAttribution,
  handleDeleteAttribution,
  smDown,
}: MetricVariableProps) => {
  const classes = useStyles();
  const { extendedMetrics, datahubMetrics } = useCloudAnalyticsContext();

  const filteredOptions = createFilterOptions({
    trim: true,
    ignoreAccents: true,
    ignoreCase: true,
    matchFrom: "any",
    stringify: (option: string) => option,
  });

  const handleMetricSelect = useCallback(
    (metricVal: Metric, value: MetricWSnap | string) => {
      const basicMetric = MetricOptions.find((m) => m.value === metricVal);

      if (basicMetric) {
        handleChangeMetric(idx, basicMetric.label, basicMetric.value);
      } else if (metricVal === Metric.EXTENDED && typeof value === "string") {
        handleChangeMetric(idx, value, Metric.EXTENDED);
      }
    },
    [handleChangeMetric, idx]
  );

  return (
    <Grid
      container
      spacing={1}
      sx={{
        alignItems: "center",
      }}
      size={{
        md: 12,
        lg: 12,
      }}
    >
      <Grid
        size={{
          xs: 9,
          sm: 2,
          md: 2,
          lg: 2,
        }}
      >
        <MetricSelect
          dataCy={cloudAnalytics.metrics.metric.baseMetric}
          datahubMetrics={datahubMetrics}
          extendedMetrics={extendedMetrics}
          forcePopupIcon={false}
          handleMetricSelect={handleMetricSelect}
          inputLabel={variable.metric === undefined ? metricText.VARIABLE_METRIC : ""}
          selectedCalculatedMetric={null}
          selectedExtendedMetric={variable.extendedMetric}
          selectedMetric={variable.metric ?? null}
          dataSource={AnalyticsDataSource.BILLING_DATAHUB}
          disabled={disabled}
          showCustomMetrics={false}
        />
      </Grid>
      <Grid
        className={clsx(classes.attribution, classes.flex)}
        size={{
          xs: 9,
          sm: 7,
          md: 4,
          lg: 3,
        }}
      >
        {!smDown && (
          <Grid>
            <Typography className={clsx(classes.floatLeft, classes.metricRowText, classes.attributionText)}>
              of
            </Typography>
          </Grid>
        )}
        <Autocomplete
          className={clsx(classes.floatRight, classes.attributionInput)}
          options={attributions}
          filterOptions={filteredOptions}
          getOptionLabel={(option) => option}
          size="small"
          autoComplete
          forcePopupIcon={false}
          value={variable.attribution}
          disabled={disabled}
          {...textFieldBaseProps}
          onChange={(event, newValue, reason) => {
            switch (reason) {
              case "clear":
              case "selectOption":
                handleChangeAttribution(idx, newValue);
                break;
              default:
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              data-cy={cloudAnalytics.metrics.metric.attribution}
              error={validateVariables && !variable.attribution && variable.metric !== null}
              variant="outlined"
              label={!variable.attribution ? metricText.VARIABLE_ATTRIBUTION : ""}
              placeholder={!variable.attribution ? metricText.VARIABLE_ATTRIBUTION : ""}
              size="small"
              slotProps={{
                inputLabel: {
                  classes: inputLabelsStyles(),
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid
        className={classes.flex}
        size={{
          xs: 3,
          sm: 3,
          lg: 3,
        }}
      >
        <Grid>
          <Typography className={clsx(classes.floatLeft, { [classes.variableName]: showDelete })}>
            as {getVariableName(idx)}
          </Typography>
        </Grid>
        {showDelete && (
          <Grid>
            <Tooltip title={!allowDelete ? "This variable cannot be removed since it is used in the formula" : ""}>
              <div>
                <IconButton
                  aria-label="delete"
                  disabled={!allowDelete}
                  color="primary"
                  onClick={() => {
                    handleDeleteAttribution(idx);
                  }}
                  className={classes.floatRight}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
