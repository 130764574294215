import { create } from "zustand";

// Define the store state type
type AllocationDndStore = {
  items: Record<string, string[]>;
  addItem: (item: string) => void;
  removeItem: (item: string) => void;
  replaceItem: (items: string[]) => void;
  setItems: (items: Record<string, string[]>) => void;
};

// Create the store
const useAllocationDndStore = create<AllocationDndStore>((set) => ({
  // Initial state
  items: { main: [] },

  addItem: (item) => {
    set((state) => ({ items: { main: [...state.items.main, item] } }));
  },

  removeItem: (item) => {
    set((state) => ({ items: { main: state.items.main.filter((id) => id !== item) } }));
  },
  replaceItem: (items) => {
    set(() => ({ items: { main: items } }));
  },
  setItems: (items) => {
    set(items);
  },
}));

export default useAllocationDndStore;
