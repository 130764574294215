import { useMemo } from "react";

import { EarlyAccessFeature, SaaSConsoleType } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useShowFreeTrialCard } from "../../../Navigation/Components/Header/hooks";
import { isTrialActive } from "../../../Pages/Settings/General/helpers";
import { useHasBillingProfile } from "../../../Pages/SolveOnboarding/hooks";
import { CustomerType } from "../../../types";
import { requiresSaaSConsoleConfiguration, requiresStandaloneConfiguration } from "../../../utils/common";
import { ProductOnlyCustomerProspects, SaasConsoleTypes } from "./consts";
import { getCustomerProspect, shouldShowCard } from "./utils";

export const useShouldShowOnboardingCards = () => {
  const shouldShowFreeTrial = useShowFreeTrialCard();
  const hasBillingProfile = useHasBillingProfile();
  const { customer, customerOrPresentationModeCustomer, isProductOnlyCustomer, customerType } = useCustomerContext();
  const { onboarding, enabledFlexsave, tiers } = customer;
  const customerProspect = getCustomerProspect(customer);
  const isAdvantageCustomerWithoutBillingProfile =
    !!onboarding?.advantage?.providers?.length && !(hasBillingProfile ?? true);
  const navigatorTierTrialEndDate = tiers?.navigator?.trialEndDate?.toDate();
  const endDate = navigatorTierTrialEndDate ? DateTime.fromJSDate(navigatorTierTrialEndDate) : undefined;

  const isDatadogConnectEarlyAccess = useFeatureFlag(EarlyAccessFeature.DATADOG_CONNECT);

  const isSnowflakeConnectEntitled = useIsFeatureEntitled("snowflake:connect");
  const isDatadogConnectEntitled = useIsFeatureEntitled("datadog:connect");

  return useMemo(
    () => ({
      shouldShowNavigatorWelcomeBlock: customerProspect === "navigator",
      shouldShowSolveWelcomeBlock: customerProspect === "solve",
      shouldShowFreeTrial,
      shouldShowBillingProfileCard: customerProspect === "advantage" && isAdvantageCustomerWithoutBillingProfile,
      shouldShowBillingProfileCardForHybridCustomer: isAdvantageCustomerWithoutBillingProfile,
      shouldShowAzureCloudConnect: enabledFlexsave && shouldShowCard(SaaSConsoleType.AZURE, customer, "navigator"),
      shouldShowStandaloneCloudSelector: requiresStandaloneConfiguration(
        customerOrPresentationModeCustomer,
        isProductOnlyCustomer
      ),
      shouldShowSaasCloudSelector:
        requiresSaaSConsoleConfiguration(customerOrPresentationModeCustomer, isProductOnlyCustomer) &&
        !(customer.onboarding?.navigator?.providers?.length || customer.onboarding?.solve?.providers?.length),
      shouldShowWelcomeBlock:
        shouldShowFreeTrial ||
        isAdvantageCustomerWithoutBillingProfile ||
        SaasConsoleTypes.some((cloudType) =>
          ProductOnlyCustomerProspects.reduce(
            (acc, prospect) => acc || (shouldShowCard(cloudType, customer, prospect) ?? false),
            false
          )
        ),
      shouldShowHeritageCloudConnect:
        customerType === CustomerType.PROCUREMENT_ONLY &&
        isTrialActive(endDate) &&
        getCustomerProspect(customer) === "unknown",
      shouldShowSnowflakeOnboardingCard: isSnowflakeConnectEntitled,
      shouldShowDatadogOnboardingCard: isDatadogConnectEntitled || isDatadogConnectEarlyAccess,
    }),
    [
      customerProspect,
      shouldShowFreeTrial,
      isAdvantageCustomerWithoutBillingProfile,
      enabledFlexsave,
      customer,
      customerOrPresentationModeCustomer,
      isProductOnlyCustomer,
      customerType,
      endDate,
      isSnowflakeConnectEntitled,
      isDatadogConnectEntitled,
      isDatadogConnectEarlyAccess,
    ]
  );
};
