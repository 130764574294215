import { useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import { CategoryStatus, type CloudConnectCategory, CustomerModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";

import { useApiContext } from "../../../api/context";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { useCallbackUrlSnackbar } from "../../../Components/hooks/useCallbackUrlSnackbar";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { InnerScreenHeader } from "../Components/InnerScreenHeader";
import { doitConnectSA, roleID } from "./constants";
import { FeaturesTable } from "./FeaturesTable";
import { usePermissionCategoriesByType, useSelectItemList } from "./hooks";

export const EditConnectionPage = () => {
  const { customer } = useCustomerContext();
  const { type, id } = useParams<{ type: "organization" | "project"; id: string }>();
  const history = useHistory();
  const api = useApiContext();

  const showSuccessCallbackUrlSnackbar = useCallbackUrlSnackbar({
    message: `${capitalize(type)} status updated`,
    variant: "success",
  });
  const showError = useErrorSnackbar();

  const availableCategories = usePermissionCategoriesByType(type);

  const [existingDocument, isExistingDocumentLoading] = useDocumentData(
    getCollection(CustomerModel).doc(customer.id).collection("cloudConnect").doc(id)
  );

  const defaultCategoryIds = availableCategories
    .filter((category) => category.isDefault)
    .map((category) => category.id);

  // Start with all default categories selected
  const [selectedFeatures, onFeatureSelect] = useSelectItemList<CloudConnectCategory["id"]>(defaultCategoryIds);

  // Once the document is loaded, select also the already configured categories
  const alreadySelectedCategoryIds = useMemo(
    () =>
      existingDocument?.categoriesStatus
        ? Object.entries(existingDocument?.categoriesStatus)
            .filter(([_, status]) => status === CategoryStatus.Healthy)
            .map(([categoryId]) => categoryId)
        : [],
    [existingDocument?.categoriesStatus]
  );

  useEffect(() => {
    alreadySelectedCategoryIds.forEach((categoryId) => {
      if (!selectedFeatures.includes(categoryId)) {
        onFeatureSelect(categoryId, true);
      }
    });
  }, [alreadySelectedCategoryIds, selectedFeatures, onFeatureSelect]);

  // Depending on the selected features, get a list of newly selected categories
  const newlySelectedCategoryIds = useMemo(
    () => selectedFeatures.filter((categoryId) => !alreadySelectedCategoryIds.includes(categoryId)),
    [selectedFeatures, alreadySelectedCategoryIds]
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // If these change, and they are then empty -> document got updated, close the dialog
  useEffect(() => {
    if (newlySelectedCategoryIds.length === 0) {
      setIsDrawerOpen(false);
    }
  }, [newlySelectedCategoryIds]);

  const [isHealthCheckLoading, setIsHealthCheckLoading] = useState(false);

  const onHealthCheckSuccess = () => {
    showSuccessCallbackUrlSnackbar();
  };

  const checkHealth = async () => {
    setIsHealthCheckLoading(true);

    try {
      // TODO(laura): this checks all cloud connect docs, which can be slow, it would be nicer if we could just check the one we just updated
      await api.request({
        method: "get",
        url: `/v1/customers/${customer.id}/cloudconnect/health`,
      });

      setIsHealthCheckLoading(false);
      setIsDrawerOpen(false);
      onHealthCheckSuccess();
    } catch (error) {
      consoleErrorWithSentry(error);
      setIsHealthCheckLoading(false);
      showError(`Failed to check new status of ${type}`);
    }
  };

  const backToSettings = () => {
    history.push(`/customers/${customer.id}/settings/gcp`);
  };

  const permissionsToAdd = availableCategories
    .filter((category) => newlySelectedCategoryIds.includes(category.id))
    .flatMap((category) => category.permissions)
    .filter((permission, index, self) => self.indexOf(permission) === index);

  const updateCodeBlock = `gcloud iam roles update ${existingDocument?.roleId || roleID} --project=${existingDocument?.projectId} --add-permissions=${permissionsToAdd.join(
    ","
  )}`;

  const workloadIdentityFixCodeBlock = `gcloud iam service-accounts add-iam-policy-binding ${existingDocument?.clientEmail}${type === "project" ? ` --project=${existingDocument?.projectId}` : ""} --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`;

  // Display alert for VPC access if:
  // 1. VPC access status is not healthy
  // 2. WIF status is healthy (otherwise VPC access check will fail anyway)
  // 3. At least one category that requires VPC access is otherwise healthy
  const displayVPCAccessCheckAlert =
    existingDocument?.bigQueryLensVPCAccessStatus !== CategoryStatus.Healthy &&
    existingDocument?.workloadIdentityFederationStatus === CategoryStatus.Healthy &&
    availableCategories.some(
      (category) =>
        category.checkVPCAccess &&
        existingDocument?.categoriesStatus &&
        existingDocument?.categoriesStatus[category.id] === CategoryStatus.Healthy
    );

  return (
    <>
      <Card>
        <InnerScreenHeader title={`Edit ${type}`} subtitle={customer.name} backPageCallback={backToSettings} />
        <CardContent>
          <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" mb={3}>
              Select features you'd like to add to your {type}
            </Typography>

            {isExistingDocumentLoading ? (
              <CircularProgress />
            ) : (
              <>
                {/* We want customers to use WIF - if it's not healthy, prompt them to set it up */}
                {existingDocument?.workloadIdentityFederationStatus !== CategoryStatus.Healthy && (
                  <Card sx={{ mb: 6 }}>
                    <CardContent sx={{ ":last-child": { pb: 2 } }}>
                      <Alert severity="error">
                        <AlertTitle>Missing Service Account Impersonation</AlertTitle>
                        <Typography variant="body2">
                          In order to make the connection of the DoiT Console to your organization more secure, we
                          require you to allow service account impersonation.
                        </Typography>
                      </Alert>

                      <Typography variant="body1" sx={{ opacity: 0.6 }} mt={2} mb={1}>
                        Bind ServiceAccount TokenCreator role to the service account under DoiT organization
                      </Typography>

                      <CopyCodeBlock base={workloadIdentityFixCodeBlock} />

                      <LoadingButton
                        onClick={checkHealth}
                        color="primary"
                        variant="outlined"
                        disabled={isHealthCheckLoading}
                        loading={isHealthCheckLoading}
                        sx={{ marginTop: 2 }}
                        mixpanelEventId="settings.workload-identity-federation-status.test-connection"
                      >
                        Test Connection
                      </LoadingButton>
                    </CardContent>
                  </Card>
                )}

                {displayVPCAccessCheckAlert && (
                  <Card sx={{ mb: 6 }}>
                    <CardContent sx={{ ":last-child": { pb: 2 } }}>
                      <Alert severity="warning">
                        <AlertTitle>VPC Service Controls active</AlertTitle>
                        <Typography variant="body2">
                          Your organization is using a{" "}
                          <Link href="https://cloud.google.com/vpc-service-controls/docs/overview" target="_blank">
                            VPC Service Controls
                          </Link>{" "}
                          policy and prohibits access from DoiT's network. Please refer to the{" "}
                          <Link
                            href="https://help.doit.com/docs/dashboards/bigquery-lens/set-up-bigquery-lens#vpc-service-controls"
                            target="_blank"
                          >
                            help center
                          </Link>{" "}
                          for more information.
                        </Typography>
                      </Alert>

                      <LoadingButton
                        onClick={checkHealth}
                        color="primary"
                        variant="outlined"
                        disabled={isHealthCheckLoading}
                        loading={isHealthCheckLoading}
                        sx={{ marginTop: 2 }}
                        mixpanelEventId="settings.workload-identity-federation-status.test-connection"
                      >
                        Test Connection
                      </LoadingButton>
                    </CardContent>
                  </Card>
                )}

                <FeaturesTable
                  type={type}
                  selectedFeatures={selectedFeatures}
                  onFeatureSelect={onFeatureSelect}
                  forceIncludeCategoryIds={[...defaultCategoryIds, ...alreadySelectedCategoryIds]}
                  categoryStatuses={existingDocument?.categoriesStatus}
                />
              </>
            )}

            <Button
              variant="contained"
              sx={{ my: 5, alignSelf: "flex-end" }}
              disabled={newlySelectedCategoryIds.length === 0}
              onClick={() => {
                setIsDrawerOpen(true);

                // TODO(laura): check if this is still valid - leftovers from V1
                mixpanel.track("gcp.link-account");
                if (selectedFeatures.some((feature) => feature === "core")) {
                  mixpanel.track("gcp.link-account.quotas");
                }
              }}
            >
              Generate gcloud commands
            </Button>
          </Container>
        </CardContent>
      </Card>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        PaperProps={{ sx: { maxWidth: "650px" } }}
      >
        <DialogTitle>Update role for Google Cloud Service Account</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Run these gcloud commands in Google Cloud Shell to update the role with additional permissions
          </Typography>

          <Button
            variant="outlined"
            size="medium"
            startIcon={<OpenInNewIcon />}
            target="_blank"
            href="https://shell.cloud.google.com/?fromcloudshell=true&show=terminal"
            sx={{ my: 3 }}
          >
            Open Google Cloud Shell
          </Button>

          <Box mb={2} data-cy="command-block">
            <Typography variant="body1" sx={{ opacity: 0.6 }} mb={1}>
              Update Custom Role
            </Typography>
            <CopyCodeBlock base={updateCodeBlock} />
          </Box>

          <LoadingButton
            variant="contained"
            loading={isHealthCheckLoading}
            mixpanelEventId="settings.gcp-platform-configuration.upload-key"
            onClick={async () => {
              await checkHealth();
            }}
          >
            Done
          </LoadingButton>
        </DialogContent>
      </Drawer>
    </>
  );
};
