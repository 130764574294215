import { useCallback, useMemo } from "react";

import { type CloudFlowNodeType, type ReferencedNodeValue } from "@doitintl/cmp-models";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { getCloudIcon } from "../../../../utils/common";
import { type CloudFlowNode, type NodeConfigs, type PartialUpdateNodesList } from "../../types";

export function useApiProviderLogo() {
  const isDarkTheme = useDarkThemeCheck();
  const iconSet = useMemo(
    () => ({
      AWS: getCloudIcon("amazon-web-services", isDarkTheme),
      GCP: getCloudIcon("google-cloud", isDarkTheme),
      DoiT: "https://storage.googleapis.com/hello-static-assets/emoji/doit-mark.png",
    }),
    [isDarkTheme]
  );

  return useCallback((provider: string): string | undefined => iconSet[provider], [iconSet]);
}

export function getFieldRef(nodeConfig: NodeConfigs<CloudFlowNodeType>): ReferencedNodeValue | undefined {
  return "referencedNodeId" in nodeConfig.parameters
    ? {
        referencedNodeId: nodeConfig.parameters.referencedNodeId,
        referencedField: nodeConfig.parameters.referencedField,
      }
    : undefined;
}

/*
This function is primarily used with ADD and DELETE operations. It is necessary because the user may have updated the node locally,
but the updatedNode returned from the API reflects the old node with only the transitions updated.
*/
export function extractTransitions(nodes: CloudFlowNode[]): PartialUpdateNodesList {
  return nodes.map((node) => {
    const { id, transitions } = node;

    return { id, transitions };
  });
}
