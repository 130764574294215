import { memo } from "react";

import ForkRightRoundedIcon from "@mui/icons-material/ForkRightRounded";

import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

const InternalConditionNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title="IF"
      name={`${data.stepNumber}. ${data.nodeData.name || "IF statement"}`}
      status={data.nodeData.status || "validated"}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={!!selected}
      icon={<ForkRightRoundedIcon color="primary" fontSize="small" />}
    />
    <SourceHandle id={id} idSuffix="true" style={{ left: "45%" }} />
    <SourceHandle id={id} idSuffix="false" style={{ left: "55%" }} />
    <TargetHandle id={id} />
  </>
);

export const ConditionNode = memo(InternalConditionNode);
